import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Kpi } from '../../models/kpi';
import { KpiComponent, KpiMode } from '../kpi/kpi.component';

@Component({
    selector: 'app-kpi-grid',
    templateUrl: './kpi-grid.component.html',
    styleUrls: ['./kpi-grid.component.scss'],
    standalone: true,
    imports: [KpiComponent, CommonModule],
})
export class KpiGridComponent {
    /** KPIs to display */
    @Input() kpis: Array<Kpi>;

    /** edit mode enabled */
    @Input() edit = false;

    /** edit mode: 'add', 'delete' */
    @Input() mode: KpiMode = 'delete';

    /** whether sortable or not */
    @Input() sortable = false;

    /** Kpi deleted event */
    @Output() kpiDeleted: EventEmitter<Kpi> = new EventEmitter<Kpi>();

    /** Kpi added event */
    @Output() kpiAdded: EventEmitter<Kpi> = new EventEmitter<Kpi>();

    onKpiDeleted(kpi: Kpi) {
        this.kpiDeleted.emit(kpi);
    }

    onKpiAdded(kpi: Kpi) {
        this.kpiAdded.emit(kpi);
    }
}
