<div class="kpi-config-wrapper flex flex-col w-full">
    <div class="title-wrapper flex justify-between items-center">
        <div>
            <span class="title">KPI Config</span>
        </div>

        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="closeDialog()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="content flex flex-col">
        <div class="flex flex-col lg:flex-row">
            <mat-card
                appearance="outlined"
                class="kpi-grid-wrapper !flex flex-col justify-between items-center"
            >
                <mat-card-title> Selected KPIs </mat-card-title>
                <mat-card-content>
                    <app-kpi-grid
                        [kpis]="selected()"
                        [sortable]="true"
                        [edit]="true"
                        (kpiDeleted)="onKpiDeleted($event)"
                    >
                    </app-kpi-grid>
                </mat-card-content>
            </mat-card>

            <mat-card
                appearance="outlined"
                class="kpi-grid-wrapper !flex flex-col justify-between items-center"
            >
                <mat-card-title> Available KPIs </mat-card-title>
                <mat-card-content>
                    <app-kpi-grid
                        [kpis]="available()"
                        [sortable]="true"
                        [edit]="true"
                        [mode]="'add'"
                        (kpiAdded)="onKpiAdded($event)"
                    >
                    </app-kpi-grid>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
