<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-gears icon-w-text"></i>Select default group</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div class="flex flex-col gap-4">
    <div class="content flex flex-col">
        <mat-form-field>
            <input
                type="text"
                placeholder="Type group name"
                aria-label="Group name"
                matInput
                class="search-group"
                [formControl]="searchControl"
                [matAutocomplete]="auto"
            />
        </mat-form-field>
        <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayGroup"
            (optionSelected)="onGroupSelect($event)"
        >
            <mat-option *ngFor="let group of filteredGroups$ | async" [value]="group">
                {{ displayGroup(group) }}
            </mat-option>
        </mat-autocomplete>
    </div>

    <div class="button-bar flex justify-end items-center gap-4">
        <button
            [disabled]="!groupSelected"
            mat-raised-button
            color="accent"
            class="popup-button-dash"
            (click)="closeOk()"
        >
            Select default Group
        </button>
    </div>
</div>
