import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    finalize,
    map,
    shareReplay,
    startWith,
    switchMap,
} from 'rxjs';

import { UserService } from '../../services/user.service';
import { matchPassword } from '../../utils/utils';

interface NoToken {
    type: 'no-token';
    usernameControl: UntypedFormControl;
}

interface InvalidToken {
    type: 'invalid-token';
    usernameControl: UntypedFormControl;
}

interface ExpiredToken {
    type: 'expired-token';
    usernameControl: UntypedFormControl;
}

interface ValidToken {
    type: 'valid-token';
    token: string;
    username: string;
    setPasswordForm: UntypedFormGroup;
}

type Status = NoToken | InvalidToken | ExpiredToken | ValidToken;

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
    public status: Status;

    /** The form is being sent */
    public sending = false;

    public success$ = this.activatedRoute.queryParams.pipe(map((params) => params?.success));

    private expiredTokenError$ = new Subject<void>();

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        combineLatest([
            this.activatedRoute.queryParams,
            this.expiredTokenError$.pipe(startWith(undefined as void)),
        ])
            .pipe(
                map(([params, _]) => params.token),
                distinctUntilChanged(),
                switchMap<string, Observable<Status>>((token) =>
                    token === null || token === undefined
                        ? of({
                              type: 'no-token' as const,
                              usernameControl: new UntypedFormControl('', Validators.required),
                          })
                        : this.userService.getResetPasswordInfo(token).pipe(
                              map(({ hasExpired, username }) =>
                                  hasExpired
                                      ? {
                                            type: 'expired-token' as const,
                                            usernameControl: new UntypedFormControl(
                                                username,
                                                Validators.required
                                            ),
                                        }
                                      : {
                                            type: 'valid-token' as const,
                                            username,
                                            token,
                                            setPasswordForm: new UntypedFormGroup(
                                                {
                                                    password: new UntypedFormControl(
                                                        '',
                                                        Validators.required
                                                    ),
                                                    repeatPassword: new UntypedFormControl(
                                                        '',
                                                        Validators.required
                                                    ),
                                                },
                                                matchPassword
                                            ),
                                        }
                              ),
                              catchError(() =>
                                  of({
                                      type: 'invalid-token' as const,
                                      usernameControl: new UntypedFormControl(
                                          '',
                                          Validators.required
                                      ),
                                  })
                              )
                          )
                ),
                shareReplay()
            )
            .subscribe((status) => {
                this.status = status;
            });
    }

    public resetPassword(status: ValidToken) {
        this.sending = true;
        const { password, repeatPassword } = status.setPasswordForm.value;
        this.userService
            .resetPassword(status.token, password, repeatPassword)
            .pipe(
                finalize(() => {
                    this.sending = false;
                })
            )
            .subscribe({
                next: () => {
                    const message = 'Your password has been set.';
                    this.router.navigate(['.'], {
                        relativeTo: this.activatedRoute,
                        queryParams: { success: message },
                        queryParamsHandling: 'merge',
                        replaceUrl: true,
                    });
                },
                error: () => {
                    this.expiredTokenError$.next();
                },
            });
    }

    public restorePassword(username: string) {
        this.sending = true;
        this.userService
            .restorePassword(username)
            .pipe(
                finalize(() => {
                    this.sending = false;
                })
            )
            .subscribe((response: any) => {
                this.router.navigate(['.'], {
                    relativeTo: this.activatedRoute,
                    queryParams: { success: response.message },
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                });
            });
    }
}
