import { Component, Input, OnInit } from '@angular/core';
import {
    ApiRequest,
    ApiRequestStatus,
    ApiRequestStatusEnum,
    ApiRequestTypeEnum,
} from '../../models/api-request';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Series } from '../time-series/time-series.component';
import { csvToJson, isJsonString } from '../../utils/utils';
import { ApiRequestsService } from '../../services/api-requests.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'app-api-request-details',
    templateUrl: './api-request-details.component.html',
    styleUrls: ['./api-request-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ApiRequestDetailsComponent implements OnInit {
    /**
     * List of available statuses
     */
    ApiRequestStatusInterface = ApiRequestStatusEnum;

    /**
     * List of types
     */
    ApiRequestTypeInterface = ApiRequestTypeEnum;

    /**
     * Boolean value to display mat-spinner when the refresh api request status button is used.
     */
    refreshApiRequestStatus = false;

    /**
     * Object with the status of the api request
     */
    apiRequestStatus: ApiRequestStatus;

    /**
     * API Request data.
     */
    @Input() public request: ApiRequest;

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = ['filename', 'download'];

    /**
     * Material table data source.
     * @type {MatTableDataSource<any>}
     */
    previewChartdataSource = new MatTableDataSource([]);

    /**
     * Object that contains chart data and metadata
     */
    previewChartData = {};

    /**
     * Data series to be displayed on the graph.
     * @type {Array<Series>}
     */
    public data: Array<Series> = [];

    /**
     * CanvasJS object
     * @type {undefined}
     */
    public chart: any = undefined;

    /**
     * CanvasJS chart configuration options
     */
    public canvasjsOptions: any;

    public expandedElement = null;

    constructor(private apiRequestsService: ApiRequestsService) {}

    ngOnInit() {
        this.getApiRequestStatus();
    }

    /**
     * Get the status of the current api request
     */
    getApiRequestStatus() {
        this.refreshApiRequestStatus = true;
        this.apiRequestsService
            .getApiRequestStatus(this.request.uuid)
            .subscribe((results: ApiRequestStatus) => {
                this.apiRequestStatus = results;
                this.previewChartdataSource.data = this.apiRequestStatus.getFileData();

                if (
                    (this.request.type === this.ApiRequestTypeInterface.PointTimeSeriesRequest ||
                        this.request.type === this.ApiRequestTypeInterface.RoiTimeSeriesRequest) &&
                    this.apiRequestStatus.processingStatus === this.ApiRequestStatusInterface.Ready
                ) {
                    this.displayedColumns = ['filename', 'download', 'preview'];
                    this.apiRequestsService
                        .downloadApiRequestData(
                            this.request.uuid,
                            this.previewChartdataSource.data[0].filename
                        )
                        .subscribe((res: any) => {
                            if (typeof res === 'object') {
                                this.previewChartData = res;
                            } else {
                                const result = isJsonString(res);
                                if (result) {
                                    this.previewChartData['metadata'] = result['metadata'];
                                    const chartData = [];
                                    for (let i = 0; i < result['dates'].length; i++) {
                                        const data = {};
                                        data['date'] = result['dates'][i];
                                        for (let j = 0; j < result['data'].length; j++) {
                                            data[result['data'][j]['product']] =
                                                result['data'][j]['values'][i];
                                        }
                                        chartData.push(data);
                                    }
                                    this.previewChartData['data'] = chartData;
                                } else {
                                    this.previewChartData = csvToJson(res);
                                }
                            }
                        });
                }
                this.refreshApiRequestStatus = false;
            });
    }

    /**
     * Downlaod file button callback
     * @param url
     */
    downloadFile(url) {
        window.open(url);
    }

    /**
     * Expand material table
     * @param element
     * @param expandedElement
     */
    toggleExpandedElement(element) {
        this.onInitCanvasJS();
        this.renderPreviewChart(this.previewChartData['data']);

        this.expandedElement = this.expandedElement === element ? null : element;
    }

    /**
     * CanvasJS initializer method
     */
    private onInitCanvasJS(): void {
        this.canvasjsOptions = {
            zoomEnabled: true,
            zoomType: 'xy',
            animationEnabled: false,
            exportEnabled: false, // we will use the export function of this component
            title: {
                text: '.', // the purpose of this title is to create some room for zoom/pan buttons
                fontColor: 'white',
                fontSize: 10,
            },
            axisX: {
                title: 'Date',
                labelFormatter: this.standardLabelFormat,
            },
            axisY: {
                title: '',
                suffix: '',
            },
            axisY2: null,
            toolTip: {
                shared: true,
                cornerRadius: 5,
                contentFormatter: this.canvasJSTooltipCustomLinear,
            },
            legend: {
                cursor: 'pointer',
                verticalAlign: 'top',
                horizontalAlign: 'center',
                dockInsidePlotArea: false,
                fontSize: 15,
                itemclick: (e) => {
                    if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
                        e.dataSeries.visible = false;
                    } else {
                        e.dataSeries.visible = true;
                    }
                    this.chart.render();
                },
            },
            data: [],
        };

        const unit = this.previewChartData['metadata']['Unit']
            ? this.previewChartData['metadata']['Unit']
            : '';
        const axis = {};
        axis['suffix'] = ' ' + unit;
        axis['title'] = this.request.productApiName;

        this.canvasjsOptions['axisY'] = axis;
        this.chart = new CanvasJS.Chart('chartContainer', this.canvasjsOptions);
    }

    /**
     * Set the right format to date label
     * @param e
     */
    private standardLabelFormat = (e): string => {
        return moment.utc(e.value).format('YYYY-MM-DD');
    };

    /**
     * Set custom canvasJS tooltip
     * @param e
     */
    private canvasJSTooltipCustomLinear = (e) => {
        const entries = e.entries.filter((i) => i.dataSeries.visible);
        let content = ' ';

        if (entries.length > 0) {
            content += `<strong>${entries[0].dataPoint.x.format('YYYY-MM-DD')}</strong><br/>`;

            for (const entry of entries) {
                let unit;
                if (entry.dataSeries.name.includes(' COV')) {
                    unit = '%';
                } else {
                    unit = this.previewChartData['metadata']['Unit']
                        ? this.previewChartData['metadata']['Unit']
                        : '';
                }
                content +=
                    "<span style='color:" +
                    entry.dataSeries.color +
                    "'>" +
                    entry.dataSeries.name +
                    '</span> ' +
                    entry.dataPoint.y +
                    ' ' +
                    unit;
                content += '<br/>';
            }
        }

        return content;
    };

    /**
     * Creates a new series object or clears the existing one
     *
     * @param name {string} series name
     * @param data {array} data array where to create the series
     * @param seriesOptions {object} Series custom options, such us color.
     * @param visible {boolean} show/hide series
     * @param axisYType {string} axis Y type
     * @param axisYIndex {number} axis Y index
     *
     * @return new series object.
     */
    private createSeries(
        name: string,
        data,
        seriesOptions?,
        visible = true,
        axisYType = 'primary',
        axisYIndex = 0
    ): Series {
        const dataArray = [];

        let series = this.findSeries(name, data);

        if (series === undefined) {
            series = {
                name: name,
                dataPoints: dataArray,
                type: 'line',
                axisYType: axisYType,
                axisYIndex: axisYIndex,
                showInLegend: true,
                markerSize: 1,
                connectNullData: true,
                visible: visible,
                isYear: false,
            } as Series;

            data.push(series);
        }

        // Add custom options when a new series is created
        if (seriesOptions) {
            Object.assign(series, seriesOptions);
        }

        return series;
    }

    /**
     * Append data to series
     * @param name
     * @param obj
     * @param data
     * @param year
     */
    public appendToSeries(name: string, obj: Object, data = this.data, year?: number) {
        const series = this.findSeries(name, data);

        if (series !== undefined && !Number.isNaN(obj['y'])) {
            if (series.isYear) {
                series.dataPoints[obj['x'] - 1].y = obj['y'];
            } else {
                series.dataPoints.push(obj);
            }
        }
    }

    /**
     * Find created series
     * @param name
     * @param data
     */
    protected findSeries(name: string, data = this.data): Series {
        return data.find((element) => {
            return element.name === name;
        });
    }

    /**
     * Create series to render the preview chart
     */
    renderPreviewChart(data) {
        this.data = [];
        if (data.length > 0) {
            const headers = Object.keys(data[0]);
            for (let i = 0; i < headers.length; i++) {
                if (headers[i] !== 'date') {
                    let options = {};
                    let axisYType = 'primary';
                    if (headers[i].includes(' CLIM')) {
                        options = { color: 'black' };
                    }
                    if (headers[i].includes(' COV')) {
                        options = { color: 'green' };
                        axisYType = 'secondary';
                        this.canvasjsOptions['axisY2'] = {
                            suffix: ' %',
                            title: 'Coverage',
                            minimum: 0,
                            maximum: 100,
                        };
                    }
                    this.createSeries(headers[i], this.data, options, undefined, axisYType);
                }
            }

            for (let i = 0; i < data.length; i++) {
                const xVal = moment.utc(data[i]['date']);

                for (let j = 0; j < headers.length; j++) {
                    const valueObj = {
                        x: xVal,
                        y: data[i][headers[j]] ? Number(data[i][headers[j]]) : '',
                    };
                    this.appendToSeries(headers[j], valueObj, this.data);
                }
            }

            if (this.chart) {
                this.chart.options.data = this.data;
                this.chart.render();
            }
        }
    }
}
