import { Component, EventEmitter, Input, Output } from '@angular/core';
import { forkJoin, Observable, of as observableOf } from 'rxjs';
import { catchError, map } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    getISODateString,
    isNumberInRange,
    pointInsideAreaAllowed,
    createBinaryString,
    CoordinateSystem,
} from '../../utils/utils';
import { parseCoordinates } from '../../utils/coordinates-utils';
import { LayersService } from '../../services/layers.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { UserSettingsInfo } from '../../services/user.service';
import { ProductService } from '../../services/product.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { GeoJSON } from 'geojson';
import { getAreaRepr, getAreaUnitDisp } from '../../utils/roi.utils';

export enum ClickType {
    TimeSeries,
    Dashboard,
}

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-map-popup',
    templateUrl: './map-popup.component.html',
    styleUrls: ['./map-popup.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MapPopupComponent {
    /**
     * Popup metadata: Position, Lat/Lng, Product...
     */
    @Input() data: any;

    @Input() allowedArea: GeoJSON | null;

    @Input() userSettings: UserSettingsInfo;

    /**
     * Button click event.
     */
    @Output() click: EventEmitter<any> = new EventEmitter();

    /**
     * Popup visibility.
     * @type {boolean}
     */
    show = false;

    /**
     * Popup position X.
     */
    posX: number;

    /**
     * Popup position Y.
     */
    posY: number;

    /**
     * Popup position X offset.
     */
    posXOffset = 0;

    /**
     * Popup width in pixels.
     * @type {number}
     */
    width = 450;

    /**
     * Default popup height in pixels.
     * @type {number}
     */
    readonly defaultPopUpHeight = 325;

    /**
     * Popup height in pixels.
     * @type {number}
     */
    height = this.defaultPopUpHeight;

    /**
     * Popup height in pixels for view dashboard only case.
     * @type {number}
     */
    readonly viewDashboardOnlyHeight = 140;

    /** Popup width in pixels for point info only case. */
    readonly pointInfoOnlyHeight = 65;

    /** Adjust the top margin to show the popup upside down. */
    heightAdjust = 40;

    /** Popup product table columns. */
    get displayedColumns(): string[] {
        return this.authService.hasPermission('can-create-point-time-series-request')
            ? ['name', 'date', 'time-series', 'value']
            : ['name', 'date', 'value'];
    }

    /**
     * Flag value table columns.
     */
    displayedBitMaskColumns = ['bit', 'meaning', 'binary'];

    /**
     * Popup product table data.
     * @type {any[]}
     */
    dataSource = [];

    /**
     * Text of the button to open the ROI Information dashboard
     */
    roiInformationDashboardText = environment.roiInformationDashboardButtonText;

    /**
     * String that defines the current coordinate system for a user
     */
    get coordinateSystem(): CoordinateSystem {
        return this.userSettings?.settings?.coordinateSystem ?? 'EPSG:4326';
    }

    expandedElement = null;

    constructor(
        private layersService: LayersService,
        private authService: AuthService,
        private productService: ProductService,
        private snackbar: CustomSnackbarService,
        private clipboard: ClipboardService
    ) {}

    /**
     * Return the string to show in the value column.
     */
    private getValueStr(data, product, legend): string {
        if (data.error !== undefined) {
            return 'Error';
        }
        if (data.interpretation && data.interpretation.length > 0) {
            return data.value;
        }
        if (product.isAreaUnit()) {
            if (data.value !== null) {
                return getAreaRepr(data.value, this.userSettings?.settings?.areaUnit);
            } else {
                return `- ${getAreaUnitDisp(this.userSettings?.settings?.areaUnit)}`;
            }
        }

        let valueStr;
        if (legend && legend.type === 'categorical') {
            valueStr = '-';
            if (data.value !== undefined && data.value !== null) {
                const scaled_value =
                    (data.value - product.minVal) / (product.maxVal - product.minVal);
                const category = legend.categories.find((cat) =>
                    isNumberInRange(cat.interval[0], cat.interval[1], scaled_value)
                );
                if (category) {
                    valueStr = category.label;
                }
            }
            return valueStr;
        } else {
            valueStr = data.value === null ? '-' : data.value;
            const unitStr = product.unit === null ? '' : product.unit;
            return `${valueStr} ${unitStr}`;
        }
    }

    /**
     * Show the popup.
     */
    open() {
        if (!this.authService.hasPermission('view-point-value')) {
            this.height = this.data.insideRoi
                ? this.viewDashboardOnlyHeight
                : this.pointInfoOnlyHeight;
            this.computePopupPosition();
            this.show = true; // Set the popup visibility
            return;
        }
        this.computePopupPosition();
        this.show = true; // Set the popup visibility

        this.getLayers();
        // Retrieve layers data
        this.getProductLayerValues(this.data.lat, this.data.lng).subscribe((result: any) => {
            this.dataSource.forEach((layer) => {
                layer.data = result[layer.index];
                if (layer.data.interpretation && layer.data.interpretation.length > 0) {
                    layer.interpretation = [];
                    layer.bitMask = createBinaryString(layer.data.value);

                    for (const flag of layer.data.interpretation) {
                        layer.interpretation.push({
                            bit: flag['bit'],
                            meaning: flag['meaning'],
                            binary: this.getBitPos(Number(flag['bit'])),
                        });
                    }
                }
                layer.value = this.getValueStr(layer.data, layer.product, layer.legend);
            });

            this.dataSource = this.dataSource.filter(function (layer) {
                return layer.data.error !== 403;
            });

            if (this.dataSource.length === 0) {
                this.height = this.data.insideRoi
                    ? this.viewDashboardOnlyHeight
                    : this.pointInfoOnlyHeight;
            } else {
                this.height = this.defaultPopUpHeight;
            }
        });
    }

    /**
     * Compute the popup position
     */
    private computePopupPosition() {
        // TODO: See guided tour component and update this old manual calcs

        const windowWidth = this.data.width;
        const popuphalfWidth = this.width / 2;

        this.posX = this.data.x - popuphalfWidth; // - 25 // 25 and 22 because of mat-card padding
        this.posY = this.data.y - this.height - 22;

        // Keep it always inside the window
        if (this.data.x - popuphalfWidth < 0) {
            this.posXOffset = popuphalfWidth - this.data.x + 20; // Triangle adjustment
            this.posX = 0;
        } else if (this.data.x + popuphalfWidth > windowWidth) {
            this.posXOffset = windowWidth - (this.data.x + popuphalfWidth) + 20;
            this.posX = windowWidth - this.width;
        } else {
            this.posXOffset = 20;
        }

        const h = this.height + this.heightAdjust;
        if (this.data.y - h < 0) {
            this.posY = h - (h - this.data.y) + 25;
        }
    }

    /**
     * Return true if the popup should be displayed upside-down.
     * @returns {boolean}
     */
    popupUpsidedown() {
        return this.data.y < this.height + this.heightAdjust;
    }

    /**
     * Round the number of decimals.
     * @param n float
     * @param decimals number of decimals
     * @returns {number}
     */
    roundNumber(n, decimals) {
        return n.toFixed(decimals);
    }

    /**
     * Hide the popup.
     */
    close() {
        this.show = false;
    }

    /**
     * Button click event.
     */
    emit(event) {
        this.click.emit(event);
    }

    /**
     * Button click event.
     */
    clickTimeSeries(layerKey) {
        const layer = this.layersService.get(layerKey);
        const event = {
            type: ClickType.TimeSeries,
            lat: this.data.lat,
            lng: this.data.lng,
            product: layer.product,
            date: layer.getDate(),
        };

        this.emit(event);
    }

    /**
     * Button click event.
     */
    clickDashboard() {
        const event = {
            type: ClickType.Dashboard,
            roi: this.data.roi,
        };

        this.emit(event);
    }

    /**
     * Retrieve each product layer values. Returns an observable of all the requests.
     */
    getProductLayerValues(lat, lng) {
        const promises: Observable<any>[] = [];
        const allowedArea = JSON.parse(JSON.stringify(this.allowedArea));
        for (const layer of this.layersService.getLayers().values()) {
            if (layer.product) {
                if (
                    (!layer.product.areaAllowed &&
                        !pointInsideAreaAllowed(lng, lat, allowedArea)) ||
                    (layer.product.areaAllowed &&
                        !pointInsideAreaAllowed(lng, lat, layer.product.areaAllowed))
                ) {
                    promises.push(observableOf({ error: 403 }));
                } else {
                    const date = layer.getDate();
                    const pointData = {
                        lat: lat,
                        lon: lng,
                        date: getISODateString(date),
                    };
                    const stream = this.productService
                        .getTimeSeriesPointValue(layer.product.apiName, pointData)
                        .pipe(
                            map((data) => {
                                if (data['value'] === 'None') {
                                    data['value'] = null;
                                }
                                return data;
                            }),
                            catchError((err) => {
                                return observableOf({ error: err.status });
                            })
                        );
                    promises.push(stream);
                }
            }
        }

        return forkJoin(promises);
    }

    /**
     * Copy coordinates to the clipboard
     */
    copyCoords() {
        this.clipboard.copyFromContent(this.getCoordsString());
        this.snackbar.present('Coordinates copied to the clipboard.');
    }

    /**
     * Generate a string with the coordinates in the right format
     */
    getCoordsString() {
        return parseCoordinates(
            this.data.lng,
            this.data.lat,
            this.coordinateSystem,
            this.data.degreeMinuteSecond
        );
    }

    /**
     * Retrieve all product layers.
     */
    private getLayers() {
        this.dataSource = [];
        let index = 0;
        this.layersService.getLayers().forEach((layer, key) => {
            if (layer.product) {
                let removeLayer = false;
                if (layer.product.areaAllowed) {
                    if (
                        !pointInsideAreaAllowed(
                            this.data.lng,
                            this.data.lat,
                            layer.product.areaAllowed
                        )
                    ) {
                        removeLayer = true;
                    }
                }

                const layerObject = {
                    product: layer.product,
                    legend: layer.legend,
                    data: null,
                    name: layer.product.name,
                    date: getISODateString(layer.getDate()),
                    value: null,
                    key: key,
                    index: index,
                };

                if (!removeLayer) {
                    this.dataSource.push(layerObject);
                }
                index += 1;
            }
        });
    }

    /**
     * Check if the product has flags to display the bitmask table
     * @param element
     * @param expandedElement
     */
    toggleExpandedElement(element) {
        if (element.interpretation && element.interpretation.length > 0) {
            this.expandedElement = this.expandedElement === element ? null : element;
        }
    }

    /**
     * Return 16 bit binary string with a bit in the given position
     * @param position
     */
    getBitPos(position) {
        const bitString = [];
        for (let i = 1; i < 17; i++) {
            if (i === position) {
                bitString.unshift('<strong>1</strong>');
            } else {
                bitString.unshift('0');
            }
        }
        bitString.splice(8, 0, ' ');
        return bitString.join('');
    }
}
