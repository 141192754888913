import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, take } from 'rxjs';
import { throwError } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { DialogService } from '../dialog/dialog.service';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

let changePasswordDialogRef: ReturnType<DialogService['openComponent']>;

/**
 * Checks if the current url is for a demo domain, and if that's the case it does login as demo user
 */
function demoAutoLogin(isDomainDemo: boolean, auth: AuthService) {
    if (document.location.pathname === '/demo' && environment.defaultDemoUser) {
        return auth.login(environment.defaultDemoUser);
    }

    if (isDomainDemo && environment.defaultDemoUser) {
        const demoCredentials =
            environment.demoUsers?.[document.location.host] ?? environment.defaultDemoUser;

        return auth.login(demoCredentials);
    }
}

/**
 * Returns if the logged user has none of the excluded roles for the guard to return true
 */
function userIsAuthorized(excludedRoles: string[], auth: AuthService) {
    return !(excludedRoles && auth.hasRole(excludedRoles));
}

/**
 * Guard to be used in routes definition, field 'canActivate'
 */
export const authGuard: CanActivateFn = (route) => {
    const auth = inject(AuthService);
    const router = inject(Router);
    const dialogService = inject(DialogService);
    const snackBar = inject(CustomSnackbarService);

    const isDomainDemo = environment.demoDomains?.includes(document.location.host) ?? false;

    if (
        (isDomainDemo || document.location.pathname === '/demo') &&
        environment.defaultDemoUser &&
        (!auth.loggedIn() || !auth.hasRole('demo'))
    ) {
        return demoAutoLogin(isDomainDemo, auth).pipe(
            map(() => {
                if (!isDomainDemo) {
                    return router.parseUrl('/demo');
                }
                return true;
            })
        );
    }

    if (auth.loggedIn()) {
        return auth.loadUserData().pipe(
            take(1),
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    auth.logout();
                    window.location.reload();
                }
                return throwError(() => err);
            }),
            map(() => {
                if (!auth.hasPermission('can-access-viewer')) {
                    console.debug("This user can't access the viewer");
                    auth.logout();
                    snackBar.present(
                        'The Planetary Variables viewer is not enabled for your account',
                        'warning'
                    );
                    return router.parseUrl('/login');
                }
                console.debug('This user can access the viewer');
                if (auth.hasAccessCookie() && userIsAuthorized(route.data.excludedRoles, auth)) {
                    if (auth.needPasswordChange() && changePasswordDialogRef === undefined) {
                        changePasswordDialogRef = dialogService.openComponent(
                            PasswordChangeComponent,
                            {
                                closeOnNavigation: false,
                                disableClose: true,
                                data: {
                                    forceChange: true,
                                },
                            }
                        );

                        changePasswordDialogRef.afterClosed().subscribe(() => {
                            changePasswordDialogRef = undefined;
                        });
                    }
                    return true;
                }

                if (auth.hasRole('minimal-user') && !route.routeConfig.path.includes('simple')) {
                    return router.parseUrl('/simple');
                }
                return false;
            })
        );
    }
    console.debug('This user is not logged in');
    auth.logout();
    auth.setRouteAfterLogin(document.location.pathname, route.queryParams);
    return router.parseUrl('/login');
};
