import proj4 from 'proj4';
import { getDegreeMinuteSecond } from './utils';

// https://spatialreference.org/ref/epsg/amersfoort-rd-new/proj4js/

const projections = {
    'EPSG:4326': new proj4.Proj('EPSG:4326'),
    'EPSG:28992': new proj4.Proj(
        '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs'
    ),
};

const EPSG4326 = 'EPSG:4326';
const EPSG28992 = 'EPSG:28992';

/**
 * Converts coordinates from one projection to another
 * @param lon
 * @param lat
 * @param fromProjection
 * @param toProjection
 */
export function transformCoordinates(lon, lat, fromProjection, toProjection) {
    const fromProj = projections[fromProjection];
    const toProj = projections[toProjection];
    return proj4(fromProj, toProj, [parseFloat(lon), parseFloat(lat)]);
}

export function parseCoordinates(lng, lat, coordinateSystem, degreeMinuteSecond?) {
    switch (coordinateSystem) {
        case 'EPSG:28992':
            const coordValue = transformCoordinates(lng, lat, EPSG4326, EPSG28992);
            return `X ${coordValue[0].toFixed(4)} Y ${coordValue[1].toFixed(4)}`;
            break;
        default:
            let lngVal, latVal;
            if (degreeMinuteSecond) {
                lngVal = getDegreeMinuteSecond(lng);
                latVal = getDegreeMinuteSecond(lat);
            } else {
                lngVal = lng.toFixed(4) + '°';
                latVal = lat.toFixed(4) + '°';
            }
            return latVal + ' N ' + lngVal + ' E';
    }
}
