<div class="flex justify-between items-center">
    <span
        [matTooltip]="showFullInfo ? data.hideTooltipText : data.showTooltipText"
        (click)="displayErrorInfo()"
        class="custom-snackbar"
        ><i [ngClass]="data.icon"></i>{{ data.message }}</span
    >
    <button mat-icon-button (click)="close()">
        <i class="fa fa-times"></i>
    </button>
</div>

<div *ngIf="showFullInfo && data.request && data.status" class="flex flex-col">
    <span>Error code: {{ data.status }}</span>
    <span>Request url: {{ data.request }}</span>
</div>
