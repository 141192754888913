import {
    Component,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    SimpleChanges,
    HostListener,
} from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';

import { TimelineConfig } from './timeline-config';
import { findNearestDate, getGroupProductDate } from '../../utils/utils';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

/**
 * Selector, template and styling for the time line component
 */
@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})

/**
 * Time line component
 * based on: https://github.com/pmlrsg/GISportal
 */
export class TimelineComponent implements OnChanges {
    /**
     * Timelines to display
     * @type {TimelineConfig}
     */
    @Input() config: TimelineConfig;

    @Input() groupProductTimeLine = false;

    /**
     * The selected date
     * @type {Date}
     */
    public selectedDate: Moment;

    /**
     *  New date zoom event.
     * @type {EventEmitter<any>}
     */
    @Output() onDateZoom = new EventEmitter<any>(true);

    /**
     *  New date selected event.
     * @type {EventEmitter<any>}
     */
    @Output() onDateSelected = new EventEmitter<any>(true);

    /**
     *  New date dragged event.
     * @type {EventEmitter<any>}
     */
    @Output() onDateDragged = new EventEmitter<any>(true);

    /**
     * D3 object referencing host dom object
     */
    private host;

    /**
     * SVG in which we will print our chart
     */
    private chart;

    /**
     * Space between the svg borders and the actual chart graphic {top, bottom, left, right}
     */
    private margin: object;

    /**
     * Timeline bar margin
     */
    private barMargin: number;

    /**
     * Label area width
     */
    private labelWidth: number;

    /**
     * Width of component's siblings
     */
    private siblingsWidth: number;

    /**
     * Component width
     */
    private width: number;

    /**
     * Chart width
     */
    private chartWidth: number;

    /**
     * Component height
     */
    private height: number;

    /**
     * Bar height
     */
    private barHeight: number;

    /**
     * Chart height
     */
    private chartHeight: number;

    /**
     * Line height
     */
    private laneHeight: number;

    /**
     * Chart horizontal translation: make room for label area
     */
    private mainXShift: number;

    /**
     * Axis height
     * @type {number}
     */
    readonly axisHeight = 20;

    /**
     * D3 scale in X
     */
    private xScale;

    /**
     * D3 scale in Y
     */
    private yScale;

    /**
     * D3 X Axis
     */
    private xAxis;

    /**
     * D3 Y Axis
     */
    private yAxis;

    /**
     * Host HTMLElement
     */
    private readonly htmlElement;

    /**
     * Minimum date for the slider
     */
    private minDate: Moment;

    /**
     * Maximum date for the slider
     */
    private maxDate: Moment;

    /**
     * Dragged date
     */
    private draggedDate: Moment;

    /**
     * Current date
     */
    private now: Moment;

    /**
     * Displayed time bars
     */
    private timeBars: object[];

    /**
     * Colors configuration
     */
    private colours: any;

    /**
     * Flag that determines if the handle is being dragged
     */
    private isDragging: boolean;

    /**
     * Zoom level
     */
    private zoom: any;

    /**
     * Main graphical drawing area for the widget
     */
    private main: any;

    /**
     * Label drawing area for the widget
     */
    private labelArea: any;

    /**
     * Time bar area
     */
    private barArea: any;

    /**
     * Date details area
     */
    private dateDetailArea: any;

    /**
     * Line that marks the current date
     */
    private nowLine: any;

    /**
     * Line that marks the selected date
     */
    private selectedDateLine: any;

    /**
     * Component constructor
     * @param {ElementRef} element
     */
    constructor(
        private element: ElementRef,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        // We request angular for the element reference and then we create a D3 Wrapper for our host element
        this.htmlElement = this.element.nativeElement;
        this.host = d3.select(this.element.nativeElement);
    }

    /**
     * Will Update on every @Input change
     */
    ngOnChanges(changes: SimpleChanges): void {
        const changeConfig =
            changes.config && changes.config.previousValue !== changes.config.currentValue;

        if (this.config === undefined) {
            return;
        }

        if (changeConfig) {
            this.setup();
            // Draw the graphical elements
            this.redraw();
        }
    }

    /**
     * Direct config set and redraw
     *
     * @param {TimelineConfig} config
     */
    public setConfig(config: TimelineConfig): void {
        this.config = config;
        this.setup();
        // Draw the graphical elements
        this.redraw();
        if (this.groupProductTimeLine) {
            if (
                this.config &&
                this.config.zoomStartDate !== undefined &&
                this.config.zoomEndDate !== undefined
            ) {
                this.zoomDate(
                    new Date(this.config.zoomStartDate.toISOString()),
                    new Date(this.config.zoomEndDate.toISOString()),
                    true
                );
            }
        }
    }

    /**
     * Get time-line config
     * @returns {TimelineConfig}
     */
    public getConfig(): TimelineConfig {
        return this.config;
    }

    /**
     * Will setup the chart container
     */
    private setup(): void {
        // Load and setup the options
        const defaults = <TimelineConfig>{
            comment: 'Sample timeline data',
            selectedDate: moment(),
            chartMargins: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            labelWidth: 100,
            siblingsWidth: 0,
            barHeight: 5,
            barMargin: 4,
            timeBars: [],
        };

        const options = Object.assign(defaults, this.config);

        // Initialise the fixed TimeLine widget properties from the JSON options file
        this.now = moment();

        // To lazy to go and rename everything "this.options.xxx"
        this.timeBars = options['timeBars'];

        this.barHeight = options['barHeight'];
        this.barMargin = options['barMargin'];
        this.labelWidth = options['labelWidth'];
        this.siblingsWidth = options['siblingsWidth'];

        this.selectedDate = moment(options['selectedDate']);
        this.margin = options['chartMargins'];
        this.laneHeight = this.barHeight + this.barMargin * 2 + 1;
        this.colours = d3.scale.category10(); // d3 colour categories scale with 10 contrasting colours

        // Set up initial dynamic dimensions
        this.reHeight();
        this.reWidth();

        // Set initial x scale
        this.minDate = d3.min(this.timeBars, (d) => {
            return d['startDate'];
        });
        this.maxDate = d3.max(this.timeBars, (d) => {
            return d['endDate'];
        });

        // Set some default max and min dates if no initial timebars (6 months either side of selected date)
        if (this.minDate === undefined || this.minDate === null) {
            this.minDate = this.selectedDate.subtract(6, 'months');
        }
        if (this.maxDate === undefined || this.maxDate === null) {
            this.maxDate = this.selectedDate.add(6, 'months');
        }

        // Set initial X scale
        this.xScale = d3.time.scale
            .utc()
            .domain([this.minDate.toDate(), this.maxDate.toDate()])
            .range([0, this.width]);

        // Set initial Y scale
        this.yScale = d3.scale.linear().domain([0, this.timeBars.length]).range([0, this.height]);

        // Used to prevent clickDate setting the date when dragging the date selector line or the whole timebar
        this.isDragging = false;

        // Set up the SVG chart area within the specified div; handle mouse zooming with a callback.
        this.zoom = d3.behavior
            .zoom()
            .x(this.xScale)
            .on('zoom', () => {
                this.isDragging = true;
                this.redraw();
            })
            .on('zoomend', () => {
                // Send event for collaboration
                const startDate = this.xScale.invert(0);
                const endDate = this.xScale.invert(this.width);
                const params = {
                    startDate: startDate,
                    endDate: endDate,
                    noPadding: true,
                };
                // emit date.zoom event
                this.onDateZoom.emit(params);
            });

        this.host.html(''); // clear the svg before adding elements
        // Append the svg and add a class before attaching both events.
        this.chart = this.host
            .append('svg')
            .attr('class', 'timeline')
            .call(this.zoom)
            .on('click', this.clickDate)
            .on('mousedown', () => {
                this.isDragging = false;
            })
            .on('touchend', this.clickDate)
            .on('touchstart', () => {
                this.isDragging = false;
                if (d3.event instanceof Event) {
                    d3.event.preventDefault();
                }
            });

        // Create the graphical drawing area for the widget (main)
        this.main = this.chart
            .append('svg:g')
            .attr('transform', `translate(${this.margin['left']}, ${this.margin['top']})`)
            .attr('class', 'main');

        // Initialise the area to hold the range bars as horizontal timelines
        this.barArea = this.main.append('svg:g');

        // Initialise the fine-grained date-time detail bar area
        this.dateDetailArea = this.main.append('svg:g');

        // Initialise a vertical line through all timelines for today's date
        this.nowLine = this.main.append('svg:line').attr('class', 'nowLine');

        // Set up callback functions to handle dragging of a selected date-time marker
        this.draggedDate = this.selectedDate;

        // Initialise the selected date-time marker and handle dragging via a callback
        this.selectedDateLine = this.main
            .append('svg:rect')
            .attr('cursor', 'e-resize')
            .attr('class', 'selectedDateLine')
            .call(
                d3.behavior
                    .drag()
                    .origin(Object)
                    .on('drag', this.dragDate)
                    .on('dragend', this.dragDateEnd)
            )
            .on('mousedown', () => {
                if (d3.event instanceof Event) {
                    d3.event.stopPropagation();
                }
            });

        // X-axis initialisation
        this.xAxis = d3.svg
            .axis()
            .scale(this.xScale)
            .orient('bottom')
            .innerTickSize(6)
            .outerTickSize(0);
        this.main
            .append('svg:g')
            .attr('transform', `translate(0, ${d3.round(this.height + 0.5)})`)
            .attr('class', 'axis');

        // Setup xAxis time tick formats
        // Chooses the first format that returns a non-0 (true) value
        const customTimeFormat = d3.time.format.utc.multi([
            [
                '%H:%M:%S.%L',
                (d) => {
                    // HH:mm:ss.sss
                    return d.getUTCMilliseconds();
                },
            ],
            [
                '%H:%M:%S',
                (d) => {
                    // HH:mm:ss
                    return d.getUTCSeconds();
                },
            ],
            [
                '%H:%M',
                (d) => {
                    // HH:mm
                    return d.getUTCMinutes();
                },
            ],
            [
                '%H:%M',
                (d) => {
                    // HH:mm
                    return d.getUTCHours();
                },
            ],
            [
                '%Y-%m-%d',
                (d) => {
                    // YYYY-MM-DD
                    return d.getUTCDate() !== 1;
                },
            ],
            [
                '%b %Y',
                () => {
                    // 3 letter month YYYY
                    return true;
                },
            ],
        ]);

        this.xAxis.tickFormat(customTimeFormat);

        // Y-axis setup - Label area
        this.labelArea = this.chart
            .append('svg:g')
            .attr('transform', `translate(0, 0)`)
            .attr('class', 'y-axis');

        // chart horizontal translation: to make room for label area
        this.mainXShift = this.timeBars.length > 1 ? this.labelWidth : 0;
    }

    /**
     * Handle browser window resize event to dynamically scale the timeline chart
     *
     * @param event
     */
    @HostListener('window:resize', ['$event'])
    private onResize(event): void {
        if (event.target === window) {
            if (this.config !== undefined) {
                this.redraw();
            }
        }
    }

    /**
     * Drag date start action method
     */
    private dragDate = () => {
        this.isDragging = true;
        const dx = d3.event['dx'];

        if (dx === undefined) {
            return;
        }

        let x = this.xScale(this.draggedDate) + dx;

        // Prevent dragging the selector off-scale
        x = x > this.xScale.range()[0] && x < this.xScale.range()[1] ? x : x - dx;

        // Now update the date based on the new value of x
        this.draggedDate = this.xScale.invert(x);

        // Move the graphical marker
        this.selectedDateLine.attr('x', (d) => {
            return d3.round(this.xScale(this.draggedDate) - 1.5);
        });

        // emit dragged date
        this.onDateDragged.emit({ date: moment.utc(this.draggedDate) });
    };

    /**
     * Drag date end action method
     */
    private dragDateEnd = () => {
        this.setDate(this.draggedDate);
    };

    /**
     * Click date action method
     * @param d
     * @param i
     */
    private clickDate = (d, i) => {
        if (this.isDragging) {
            // Return if the date selector is being dragged
            return;
        }

        let x = d3.mouse(this.htmlElement)[0] - this.mainXShift;
        // Prevent dragging the selector off-scale
        x = x > this.xScale.range()[0] && x < this.xScale.range()[1] ? x : x - d3.event['layerX'];

        // Now update the date based on the new value of x
        this.draggedDate = this.xScale.invert(x);

        // Move the graphical marker
        this.setDate(this.draggedDate);
        this.googleAnalyticsService.eventEmitter(
            'timeline_date_changed',
            'timeline',
            'timeline_date_changed',
            this.draggedDate.toString()
        );
    };

    /**
     * Get the date a number of time slices before or after the selected date.
     *
     * @param  {number} increment The number of time slices to move by
     * @return {Date}           The date to move to
     */
    public getNextPreviousDate(increment: number): Moment {
        increment = increment || 0;
        let newDate = null;
        let dateTimes = null;
        const layerIntervals = [];

        // Calculate the average interval for each layer on the timebar
        for (let i = 0; i < this.timeBars.length; i++) {
            dateTimes = this.timeBars[i]['dateTimes'];
            const startDate = this.timeBars[i]['startDate'];
            const endDate = this.timeBars[i]['endDate'];
            if (startDate && endDate) {
                const interval =
                    (endDate.toDate().getTime() - startDate.toDate().getTime()) / dateTimes.length;
                layerIntervals.push({
                    layer: i,
                    interval: interval,
                });
            }
        }

        // Sort the layers by their intervals
        layerIntervals.sort((a, b) => {
            return a.interval - b.interval;
        });

        // Find the best layer to use
        for (let i = 0; i < layerIntervals.length; i++) {
            const layerIndex = layerIntervals[i].layer;
            dateTimes = this.timeBars[layerIndex]['dateTimes'];

            // Find the dateTimes index for the selected date
            const dateIndex = this.findLayerDateIndex(layerIndex, this.selectedDate, dateTimes);
            if (
                dateIndex !== -1 &&
                dateIndex + increment >= 0 &&
                dateIndex + increment < dateTimes.length &&
                dateTimes[dateIndex + increment] >= this.timeBars[layerIndex]['startDate'] &&
                dateTimes[dateIndex + increment] <= this.timeBars[layerIndex]['endDate']
            ) {
                // If a date index was found, and incrementing it doesn't go out of bounds of dateTimes,
                // and it doesn't go past the saved startDate or endDate for the layer (which can be different to the ends of dateTimes)
                const tempNewDate = dateTimes[dateIndex + increment];
                if (i > 0) {
                    // If this isn't the most regular layer
                    // Check if incrementing this layer will overlap with a more regular layer, and if so,
                    // then pick the start or end date of that layer
                    for (let j = i - 1; j >= 0; j--) {
                        const jLayerIndex = layerIntervals[j].layer;
                        const jStartDate = this.timeBars[jLayerIndex]['startDate'];
                        const jEndDate = this.timeBars[jLayerIndex]['endDate'];

                        if (jStartDate <= tempNewDate && tempNewDate <= jEndDate) {
                            if (increment < 0) {
                                if (!newDate || jEndDate > newDate) {
                                    newDate = jEndDate;
                                }
                            } else {
                                if (!newDate || jEndDate < newDate) {
                                    newDate = jStartDate;
                                }
                            }
                        }
                    }
                    if (!newDate) {
                        newDate = tempNewDate;
                    }
                    break;
                } else {
                    newDate = tempNewDate;
                    break;
                }
            }
        }
        return newDate;
    }

    /**
     * Find the index for a date on a timebar layer
     * @param  {number} layer        The index of the layer on the timebar
     * @param  {date}   selectedDate The date to find
     * @param  {array}  dateTimes    (optional) DateTimes to search in
     * @return {number}              The index for the provided date
     */
    private findLayerDateIndex(layer: number, selectedDate: Moment, dateTimes): number {
        let layerDateIndex = -1;
        dateTimes = dateTimes || this.timeBars[layer]['dateTimes'];
        const startDate = this.timeBars[layer]['startDate'];
        const endDate = this.timeBars[layer]['endDate'];
        if (startDate <= selectedDate && selectedDate <= endDate) {
            if (!this.groupProductTimeLine) {
                layerDateIndex = findNearestDate(selectedDate, dateTimes)[0];
            } else {
                layerDateIndex = dateTimes.findIndex((d) =>
                    d.isSame(getGroupProductDate(selectedDate, dateTimes))
                );
            }
        }
        return layerDateIndex;
    }

    /**
     * Handle browser window resize event to dynamically scale the timeline chart along the x-axis
     */
    private redraw(): void {
        // Recalculate the x and y scales before redraw
        this.reHeight();
        this.reWidth();
        this.xScale.range([0, this.width - this.mainXShift]);
        this.yScale.domain([0, this.timeBars.length]).range([0, this.height]);

        // Scale the chart and main drawing areas
        this.chart.attr('width', this.chartWidth).attr('height', this.chartHeight);
        this.main.attr('width', this.width - this.mainXShift).attr('height', this.height);

        const centerShift = this.chartHeight * 0.5 - (this.height + this.axisHeight) * 0.5;
        this.main.attr(
            'transform',
            `translate(${this.mainXShift}, ${d3.round(centerShift + 0.5)})`
        );
        this.labelArea.attr('transform', `translate(0, ${d3.round(centerShift + 0.5)})`);

        // Set the SVG clipping area to prevent drawing outside the bounds of the widget chart area
        this.chart.style(
            'clip',
            `rect( 0px, ${this.width + this.margin['left']}px, ${this.chartHeight}px, ${
                this.margin['left']
            }px)`
        );

        if (this.timeBars.length === 0) {
            // if there is no data stop drawing after the first steps of chart resizing
            return;
        } else if (this.timeBars.every((e) => e['dateTimes'].length === 0)) {
            // draw 'no data' label
            this.main.html('');
            this.main
                .append('svg:text')
                .attr('x', 0)
                .attr('y', 20)
                .attr('class', 'y-axis-label')
                .attr('font-size', '16px')
                .text('No data available');
            return;
        }

        // Scale the x-axis and define the x-scale label format
        this.main
            .selectAll('.axis')
            .attr('transform', `translate(0, ${d3.round(this.height + 0.5)})`)
            .call(this.xAxis);

        // Draw the time bars
        if (
            this.config.timeBars[0]['dateTimes'] &&
            this.config.timeBars[0]['dateTimes'].length > 1
        ) {
            const bars = this.barArea.selectAll('rect').data(this.timeBars);
            bars.enter()
                .append('svg:rect')
                .attr('y', (d1, i1) => {
                    return d3.round(this.yScale(i1) + this.barMargin + 0.5);
                })
                .transition()
                .duration(500)
                .attr('height', d3.round(this.barHeight + 0.5))
                .attr('stroke', (d1, i1) => {
                    return d1.colour || this.colours(i1);
                })
                .attr('class', 'timeRange');

            // Time bar removal
            bars.exit().remove();
            // Re-scale the x values and widths of ALL the time bars
            bars.attr('x', (d) => {
                if (d.startDate) {
                    return d3.round(this.xScale(new Date(d.startDate)) + 0.5);
                } else {
                    return 0;
                }
            }).attr('width', (d) => {
                if (d.endDate) {
                    return d3.round(
                        this.xScale(new Date(d.endDate)) - this.xScale(new Date(d.startDate))
                    );
                } else {
                    return 0;
                }
            });
        }

        // Position the date time detail lines (if available) for each time bar
        const dateDetails = this.dateDetailArea.selectAll('g').data(this.timeBars);

        // Add new required g elements
        dateDetails
            .enter()
            .append('svg:g')
            .attr('class', (d) => {
                return `line-${d.name}`;
            });

        // Remove unneeded g elements
        dateDetails.exit().remove();

        // Add lines to dateDetails g elements
        let i = 0;
        for (const timeBar of this.timeBars) {
            const takenSpaces = {};
            const dateTimes = timeBar['dateTimes'].filter((date) => {
                const x = d3.round(this.xScale(new Date(date.toISOString())) + 0.5);
                if (takenSpaces[x] === true) {
                    return false;
                }
                takenSpaces[x] = true;
                return 0 < x && x < this.width;
            });
            const g = this.dateDetailArea.select(`.line-${timeBar['name']}`);
            g.html('');
            for (const d of dateTimes) {
                g.append('svg:line')
                    .attr('y1', d3.round(this.yScale(i) + this.barMargin + 1.5))
                    .attr('y2', d3.round(this.yScale(i) + this.laneHeight - this.barMargin + 0.5))
                    .attr('x1', d3.round(this.xScale(new Date(d.toISOString())) + 0.5))
                    .attr('x2', d3.round(this.xScale(new Date(d.toISOString())) + 0.5))
                    .attr('class', 'detailLine');
            }
            i++;
        }

        // Draw the current date-time line
        this.nowLine
            .attr('x1', d3.round(this.xScale(this.now) + 0.5))
            .attr('y1', 0)
            .attr('x2', d3.round(this.xScale(this.now) + 0.5))
            .attr('y2', this.height);

        // Draw the selected date-time line
        this.selectedDateLine
            .attr('x', (d) => {
                return d3.round(this.xScale(this.selectedDate) - 1.5);
            })
            .attr('y', 2)
            .attr('width', 10)
            .attr('height', this.height - 2);

        // Draw labels in y-axis area
        if (this.timeBars.length > 1) {
            i = 0;
            this.labelArea.html('');
            for (const timeBar of this.timeBars) {
                this.labelArea
                    .append('svg:text')
                    .attr('x', 0)
                    .attr('y', d3.round(this.yScale(i + 1) - this.barMargin - 1))
                    .attr('class', 'y-axis-label')
                    .attr('font-size', this.laneHeight - this.barMargin * 2 - 1 + 'px')
                    .text(timeBar['label']);
                i++;
            }
        }

        this.host
            .select('.axis')
            .selectAll('.tick')
            .on('click', (d) => {
                if (d3.event instanceof Event) {
                    d3.event.stopPropagation();
                }
                this.setDate(d);
            });

        if (!this.groupProductTimeLine) {
            if (this.timeBars.length > 0) {
                // If there is at least one timebar and loading from state isn't in progress
                // Move the selected date within the min and max date if it isn't
                if (this.getDate() < moment.utc(this.minDate).startOf('day')) {
                    this.setDate(this.minDate);
                }
                if (this.getDate() > moment.utc(this.maxDate).startOf('day')) {
                    this.setDate(this.maxDate);
                }
            }
        }
    }

    /**
     * Re-calculate the dynamic widget height
     */
    private reHeight(): void {
        this.chartHeight = this.htmlElement.offsetHeight - 4; // -4px to avoid vertical scroll bar
        const maxHeight =
            this.chartHeight - this.margin['top'] - this.margin['bottom'] - this.axisHeight;
        this.height = this.laneHeight * this.timeBars.length; // adjust main height to the number of lanes

        if (this.height > maxHeight && this.timeBars.length > 1) {
            // if there are multiple time-lines we make the svg larger than the html host, a scroll bar will appear
            this.chartHeight =
                this.height + this.margin['top'] + this.margin['bottom'] + this.axisHeight;
        } else if (this.height > maxHeight) {
            this.height = maxHeight;
        }
    }

    /**
     * Re-calculate the dynamic widget width
     */
    private reWidth(): void {
        // calculate width relative to parent element:
        // - siblingsWidth (date picker and prev/next buttons) -35px to avoid horizontal scroll bar
        this.chartWidth = this.htmlElement.parentElement.offsetWidth - this.siblingsWidth - 35;
        this.width = this.chartWidth - this.margin['right'] - this.margin['left'];
    }

    /**
     * Reset the timeline to its original data extents
     */
    private reset(): void {
        this.zoom.translate([0, 0]).scale(1);
        this.reHeight();
        this.reWidth();
        this.redraw();
        this.updateMinMaxDate();
    }

    /**
     * Zoom the timebar to a date range.
     * To only update it in one direction, just startDate or endDate can be provided with the other
     * set to null.
     * @param  {Date} startDate The start date
     * @param  {Date} endDate   The end date
     * @param  {boolean} noPadding (optional) True to not add padding at each end
     */
    private zoomDate(startDate: Date, endDate: Date, noPadding = false): void {
        let newStartDate;
        let newEndDate;

        if (startDate === null) {
            newStartDate = this.xScale.invert(0);
        } else {
            newStartDate = new Date(startDate);
        }
        if (endDate === null) {
            newEndDate = this.xScale.invert(this.width);
        } else {
            newEndDate = new Date(endDate);
        }

        // Set padding to equal 5% of the time difference between the startDate and endDate
        const padding = (newEndDate.getTime() - newStartDate.getTime()) * 0.05;

        if (!noPadding) {
            // Add the padding to both ends or just one if only extending in one direction
            if (startDate !== null) {
                newStartDate = newStartDate.getTime() - padding;
            }
            if (endDate !== null) {
                newEndDate = newEndDate.getTime() + padding;
            }
        }

        if (!this.groupProductTimeLine) {
            this.xScale.domain([newStartDate, newEndDate]).range([0, this.width]);
            this.zoom.x(this.xScale); // This is absolutely required to programatically zoom and retrigger internals of zoom
            this.redraw();
        }

        const params = {
            startDate: startDate,
            endDate: endDate,
            noPadding: noPadding,
        };
        // date.zoom event
        this.onDateZoom.emit(params);
    }

    /**
     * Add a new time bar using detailed parameters
     *
     * @param name
     * @param id
     * @param label
     * @param startDate
     * @param endDate
     * @param dateTimes
     */
    private addTimeBar(name, id, label, startDate, endDate, dateTimes): void {
        const newTimebar = {
            name: name,
            id: id,
            label: label,
            startDate: startDate,
            endDate: endDate,
            dateTimes: dateTimes,
            hidden: false,
            colour: '',
        };

        this.timeBars.push(newTimebar);

        this.updateMinMaxDate();

        this.reHeight();
        this.redraw();
    }

    /**
     * Helper method that checks if a string is found within the timebar's names
     *
     * @param name
     * @returns {boolean}
     */
    private has(name): boolean {
        const nameLo = name.toLowerCase();

        const has = this.timeBars.find((d) => {
            return d['name'].toLowerCase() === nameLo;
        });

        return has !== undefined;
    }

    /**
     * Dom helper to remove the time bar using the ID
     *
     * @param id
     */
    private removeTimeBarById(id): void {
        if (this.has(id)) {
            this.removeTimeBarByName(id);
        }
    }

    /**
     * Remove a time bar by name (if found)
     *
     * @param name
     */
    private removeTimeBarByName(name): void {
        const removeByName = (anArray) => {
            for (let j = 0; j < anArray.length; j++) {
                if (anArray[j].name.toLowerCase() === name.toLowerCase()) {
                    const removedBar = anArray[j];
                    anArray.splice(j, 1);
                    return removedBar;
                }
            }
        };

        const bar = removeByName(this.timeBars);

        const temp = this.timeBars;
        // Kludge to clear out the display
        this.timeBars = [];
        this.reHeight();
        this.redraw();
        // Now re-instate the newly altered array and redraw
        this.timeBars = temp;
        this.reHeight();
        this.updateMinMaxDate();
        // this.updatePickerBounds();
        this.redraw();
        const rect = this.host.select('.timeline-container').node().getBoundingClientRect();
        const h = rect.height + 10; // +10 for the padding
        if (this.timeBars.length <= 0) {
            this.host.select('.timeline-container').style('bottom', '-1000px');
        }
    }

    /**
     * Set the currently selected date and animated the transition
     *
     * @param date
     * @returns {boolean}
     */
    private setDate(date) {
        if (this.getDate().toISOString() === date.toISOString()) {
            return false;
        }

        if (this.timeBars && this.timeBars.length > 0) {
            if (date < this.minDate) {
                date = this.minDate;
            } else if (date > this.maxDate) {
                date = this.maxDate;
            }
        }

        this.selectedDate = moment(date);
        date = this.getNextPreviousDate(0);
        if (date === null) {
            return false;
        }
        this.selectedDate = this.draggedDate = moment(date);

        // Move the selected date-time line
        // ADD_CONFIG: Animation may not be wanted
        if (this.timeBars.length > 0) {
            if (date < this.xScale.invert(0)) {
                this.zoomDate(date, null, null);
            } else if (this.xScale.invert(this.width) < date) {
                this.zoomDate(null, date, null);
            }
            this.selectedDateLine
                .transition()
                .duration(500)
                .attr('x', (d) => {
                    return d3.round(this.xScale(this.selectedDate) - 1.5);
                });
        }
        // self.selectedDateLine.attr('x', (d) => { return d3.round(self.xScale(self.draggedDate) - 1.5); });

        // emit set date event
        this.onDateSelected.emit({
            date: date,
            groupProductTimeline: this.groupProductTimeLine,
        });
        return true;
    }

    /**
     * Set selected date
     *
     * @param {Date} date
     */
    public setSelectedDate(date) {
        if (this.setDate(date)) {
            this.redraw();
        }
    }

    /**
     * Get the currently selected date
     * @returns {Date}
     */
    private getDate(): Moment {
        return this.selectedDate;
    }

    /**
     * Calculate and update the minDate and maxDate
     */
    private updateMinMaxDate(): void {
        const dates = this.timeBars
            .map((bar) => {
                return [bar['startDate'], bar['endDate']];
            })
            .reduce((d1, d2) => {
                return d1.concat(d2);
            }, []);

        const extent = d3.extent(dates);

        this.minDate = moment.utc(extent[0]);
        this.maxDate = moment.utc(extent[1]);
    }
}
