import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs';

import { Deserialize } from 'cerialize';
import { environment } from '../environments/environment';
import { Label } from '../models/label';
import { Region } from '../models/region';
import { chunksOf } from '../utils/utils';

const ROIS_QUERYPARAM_MAX_LENGTH = 3900;

export interface RoisResponse {
    rois: Region[];
}

/** Region Of Interest REST Service */
@Injectable()
export class RoiService {
    /** API prefix. */
    protected apiURL = environment.apiV2;

    /** API endpoint with service path. */
    protected route = `${this.apiURL}/rois`;

    private http = inject(HttpClient);

    /**
     * Region object to Region class casting.
     */
    private regionCasting(response: any): RoisResponse {
        response.rois = response.rois.map((r) => new Region(r));
        return response;
    }

    /**
     * Upload a Region object.
     */
    public create(regions: Region[]): Observable<RoisResponse> {
        return this.http.post(`${this.route}/`, { rois: regions }).pipe(map(this.regionCasting));
    }

    public list(withGeojson = true, regionsIds?: number[]): Observable<RoisResponse> {
        let params = 'rois{}';
        if (!withGeojson) {
            params = 'rois{id, name, description, display, labels}';
        }

        const headers = new HttpHeaders({ 'X-Fields': params });

        let obs: Observable<any>;
        if (regionsIds) {
            const chunks = chunksOf(
                ROIS_QUERYPARAM_MAX_LENGTH,
                regionsIds,
                (id) => id.toString().length + 1 // take into account the commas when joining
            );

            obs = forkJoin(
                chunks.map((ids) =>
                    this.http.get<{ rois: any[] }>(`${this.route}/`, {
                        headers,
                        params: { ids: ids.join(',') },
                    })
                )
            ).pipe(
                map((results) => {
                    return results.reduce((previous, current) => ({
                        rois: [...previous.rois, ...current.rois],
                    }));
                })
            );
        } else {
            obs = this.http.get(`${this.route}/`, { headers });
        }
        return obs.pipe(map(this.regionCasting));
    }

    public update(region: Region): Observable<any> {
        return this.http.put(`${this.route}/${region.id}`, region);
    }

    public del(region: Region): Observable<any> {
        return this.http.delete(`${this.route}/${region.id}`, {});
    }

    /**
     * Convert a shapefile into a GeoJSON.
     * Supported extensions: 'zip' or 'json'.
     *
     * @param file
     * @returns {Observable<Response>}
     */
    public convertShapefileToGeoJSON(file: File) {
        const payload = new FormData();
        payload.append('data', file, file.name);
        return this.http.post(this.route + '/convert', payload);
    }

    /**
     * Show/hide all the regions.
     *
     * @param show: Boolean
     * @param: roisIds
     *      given when action has to be applied to the filtered ROI list only
     */
    public showHideAll(show: boolean, roisIds: number[]): Observable<any> {
        const params = { show: show };
        if (roisIds && roisIds.length > 0) {
            params['ids'] = roisIds.join(',');
        }
        return this.http.post(this.route + '/show-hide-all', params);
    }

    public getRegion(roi_id: number) {
        return this.http.get(this.route + `/${roi_id}`);
    }

    /**
     * Retrieves a list with all the current user labels
     */
    getAllUserRoiLabels(): Observable<Label[]> {
        return this.http
            .get(this.route + '/labels', {})
            .pipe(map((data: any) => data.labels.map((label: any) => Deserialize(label, Label))));
    }

    /**
     * Creates a new label for the current user
     * @param labelObj
     */
    createUserRoiLabel(labelObj): Observable<Label> {
        return this.http
            .post(this.route + '/labels', labelObj)
            .pipe(map((res: any) => Deserialize(res, Label)));
    }

    /**
     * Removes a label for a user (and all its apparitions in that user's regions)
     * @param labelId
     */
    deleteUserRoiLabel(labelId) {
        return this.http.delete(this.route + `/labels/${labelId}`, {});
    }

    /**
     * Updates a label for a user (and all its apparitions in that user's regions)
     * @param label
     */
    updateUserRoiLabel(label: Label): Observable<Label> {
        return this.http
            .put(this.route + `/labels/${label.id}`, label)
            .pipe(map((res: any) => Deserialize(res, Label)));
    }
}
