<div class="layers flex-col">
    <div class="flex" *ngIf="!embed">
        <button
            mat-mini-fab
            (click)="toggleAddProduct()"
            class="add-product"
            color=""
            matTooltip="New layer"
            [matTooltipPosition]="'right'"
        >
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </div>

    <div
        class="flex flex-col justify-center items-center w-full gap-2"
        [class.drop-zone]="layersOrder.length === 0 || dragging"
        cdkDropList
        [cdkDropListDisabled]="embed"
        #dragLayers="cdkDropList"
        [cdkDropListData]="layersOrder"
        [cdkDropListConnectedTo]="[compareLayer]"
        (cdkDropListDropped)="drop($event)"
        (dragstart)="dragStart()"
        (dragend)="dragging = false"
    >
        <app-layer
            *ngFor="let layerKey of layersOrder"
            [embed]="embed"
            [layer]="layersService.get(layerKey)"
            [attr.key]="layerKey"
            cdkDrag
            (cdkDragStarted)="dragStart()"
            (cdkDragEnded)="dragging = false"
        >
            <div
                *ngIf="!embed"
                slot="drag-handle"
                class="flex flex-col justify-center items-center cursor-move"
                cdkDragHandle
            >
                <i class="fa fa-bars"></i>
            </div>
        </app-layer>

        <span *ngIf="layersOrder.length === 0">No product layers.</span>
    </div>
</div>

<div
    class="layers-compare flex flex-col"
    [ngClass]="{ 'opacity-0 pointer-events-none': layersCompareOrder.length === 0 && !dragging }"
>
    <div class="flex justify-end">
        <div class="flex" *ngIf="layersService.getLayersCompareMode() === 'spy'">
            <button
                mat-mini-fab
                (click)="setSpyModeSize('increase')"
                class="compare-mode-buttons"
                color=""
                matTooltip="Increase spy mode size"
                [matTooltipPosition]="'above'"
                [disabled]="layersService.getSpyModeSize() > maximumSpyModeSize"
            >
                <i class="fa fa-plus"></i>
            </button>
            <button
                mat-mini-fab
                (click)="setSpyModeSize('decrease')"
                class="compare-mode-buttons"
                color=""
                matTooltip="Decrease spy mode size"
                [matTooltipPosition]="'above'"
                [disabled]="layersService.getSpyModeSize() < 128"
            >
                <i class="fa fa-minus"></i>
            </button>
        </div>
        <div class="flex" *ngIf="!embed">
            <button
                mat-mini-fab
                (click)="toggleCompareLayersMode()"
                class="compare-mode-buttons"
                color=""
                [matTooltip]="
                    layersService.getLayersCompareMode() === 'sideBySide'
                        ? 'Set spy mode'
                        : 'Set side by side mode'
                "
                [matTooltipPosition]="'above'"
            >
                <i
                    [class]="
                        layersService.getLayersCompareMode() === 'sideBySide'
                            ? 'fa fa-user-secret'
                            : 'fa fa-arrows-h'
                    "
                    aria-hidden="true"
                ></i>
            </button>
        </div>
    </div>

    <div
        class="flex flex-col justify-center items-center gap-2"
        [class.drop-zone]="layersCompareOrder.length === 0 || dragging"
        cdkDropList
        [cdkDropListDisabled]="embed"
        #compareLayer="cdkDropList"
        [cdkDropListData]="layersCompareOrder"
        [cdkDropListConnectedTo]="[dragLayers]"
        (cdkDropListDropped)="drop($event)"
    >
        <app-layer
            *ngFor="let layerKey of layersCompareOrder"
            [embed]="embed"
            [layer]="layersService.get(layerKey)"
            [attr.key]="layerKey"
            cdkDrag
            (cdkDragStarted)="dragStart()"
            (cdkDragEnded)="dragging = false"
        >
            <div
                *ngIf="!embed"
                slot="drag-handle"
                class="flex flex-col justify-center items-center cursor-move"
                cdkDragHandle
            >
                <i class="fa fa-bars"></i>
            </div>
        </app-layer>

        <span *ngIf="layersCompareOrder.length === 0"
            >Drop the layer here to compare the products.</span
        >
    </div>
</div>
