<div class="api-access-management-wrapper flex flex-col">
    <div class="title-wrapper flex gap-4 justify-around items-center">
        <div
            class="flex flex-col justify-center items-center max-w-[80%] lg:flex-grow lg:flex-shrink lg:basis-0"
        >
            <span class="title"><i class="icon-w-text fa fa-film"></i>Animation preview</span>
        </div>
        <div class="flex justify-end items-center gap-4">
            <button
                mat-raised-button
                (click)="handleAnimationApiDialog()"
                matTooltip="Create API request"
            >
                <i class="fa fa-file-video-o" aria-hidden="true"></i>
            </button>
            <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>

    <div class="selectors flex flex-wrap justify-around items-baseline gap-4 mb-2">
        <div class="flex flex-col justify-center items-center">
            <mat-form-field
                [matTooltip]="loadedLayers ? 'Animation must be stopped to request new data' : ''"
                class="map-base-layer-selector"
                class="expand-button"
            >
                <input
                    type="text"
                    placeholder="Product name"
                    aria-label="Product name"
                    matInput
                    [formControl]="searchControl"
                    [matAutocomplete]="auto"
                    tabindex="-1"
                />
                <button
                    [disabled]="loadedLayers"
                    mat-button
                    *ngIf="searchControl.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearSelection()"
                >
                    <mat-icon><i class="fa fa-times"></i></mat-icon>
                </button>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onProductChange($event.option.value)"
                    [displayWith]="displayProduct"
                >
                    <mat-option *ngFor="let prod of filteredProducts$ | async" [value]="prod">
                        {{ prod.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div [formGroup]="animationApiRequestForm">
            <mat-form-field class="map-base-layer-selector" class="expand-button">
                <mat-select
                    placeholder="Base layer"
                    formControlName="base_layer"
                    (selectionChange)="setBaseLayer($event.value)"
                >
                    <mat-option *ngFor="let layer of baseLayers | keys" [value]="layer"
                        >{{ baseLayers[layer].label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field
            class="flex flex-col justify-center items-center"
            [matTooltip]="loadedLayers ? 'Animation must be stopped to request new data' : ''"
        >
            <input
                [disabled]="loadedLayers"
                (dateChange)="setDatesRange()"
                matInput
                placeholder="Start date"
                [(ngModel)]="startDate"
                [matDatepicker]="rangeDatepickerStart"
            />
            <mat-datepicker-toggle matSuffix [for]="rangeDatepickerStart"></mat-datepicker-toggle>
            <mat-datepicker #rangeDatepickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field
            class="flex flex-col justify-center items-center"
            [matTooltip]="loadedLayers ? 'Animation must be stopped to request new data' : ''"
        >
            <input
                [disabled]="loadedLayers"
                (dateChange)="setDatesRange()"
                matInput
                placeholder="End date"
                [(ngModel)]="endDate"
                [matDatepicker]="rangeDatepickerEnd"
            />
            <mat-datepicker-toggle matSuffix [for]="rangeDatepickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
            <mat-hint class="error-hint" *ngIf="!validateDates()"
                >End date can't be before start date.</mat-hint
            >
        </mat-form-field>

        <mat-form-field class="flex justify-center items-center">
            <input
                [(ngModel)]="animationSeconds"
                [disabled]="loadedLayers"
                (change)="setIntervalValue()"
                placeholder="Animation duration in seconds"
                matInput
                step="1"
                min="1"
                type="number"
            />
        </mat-form-field>

        <div class="flex flex-row justify-center items-center gap-1">
            <button
                *ngIf="!pauseButton"
                [disabled]="!validateDates()"
                mat-raised-button
                class="control-buttons"
                color="primary"
                [matTooltip]="'Start animation'"
                (click)="playButton()"
            >
                <i class="fa fa-play"></i>
            </button>
            <button
                *ngIf="pauseButton"
                mat-raised-button
                class="control-buttons"
                color="primary"
                [matTooltip]="'Pause animation'"
                (click)="buttonClick({ count: false })"
            >
                <i class="fa fa-pause"></i>
            </button>
            <button
                mat-raised-button
                class="control-buttons"
                color="warn"
                [matTooltip]="'Stop animation to preview more dates'"
                (click)="resetAnimation()"
            >
                <i class="fa fa-stop"></i>
            </button>
        </div>
    </div>
</div>

<div class="map-box mx-auto">
    <div class="simple-wrapper">
        <div
            class="map-canvas"
            leaflet
            [leafletOptions]="options"
            (leafletMapReady)="onMapReady($event)"
        ></div>
        <div class="bg-white">
            <span>Current date: {{ datesRange[layerIndex - 1] | date: 'yyyy-MM-dd' }}</span>
        </div>
    </div>
</div>
