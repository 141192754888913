import { AfterViewInit, Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { from as observableFrom } from 'rxjs';
import moment from 'moment';
import { Moment } from 'moment';
import * as L from 'leaflet';
import { GeoJsonObject } from 'geojson';

import { Region } from '../../models/region';
import { RoiService } from '../../services/roi.service';
import { environment } from '../../environments/environment';

/**
 * Layers styles.
 */
const roiStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0.25,
};

export type UploadStatus = 'success' | 'fail' | 'pending' | 'timeout';

/**
 * Constant to define the success status
 * @type {string}
 */
const STATUS_SUCCESS: UploadStatus = 'success';

/**
 * Constant to define the fail status
 * @type {string}
 */
const STATUS_FAIL: UploadStatus = 'fail';

/**
 * Constant to define the pending status
 * @type {string}
 */
const STATUS_PENDING: UploadStatus = 'pending';

/**
 * Constant to define the pending status
 * @type {string}
 */
const STATUS_TIMEOT: UploadStatus = 'timeout';

/**
 * Constant to define the default error message for ROI upload
 * @type {string}
 */
const DEFAULT_UPLOAD_ERROR_MESSAGE = 'Server timed out. Please retry.';

/**
 * Upload result interface
 */
export interface RoiUpload {
    roi: Region;
    start?: Moment;
    end?: Moment;
    status: UploadStatus;
    error?: string;
    retryingUpload?: boolean;
}

/**
 * Dialog result interface.
 */
export interface RoiUploadResult {
    failed?: Array<Region>;
    uploaded?: Array<Region>;
    uploads?: Array<RoiUpload>;
    region?: Region;
}

@Component({
    selector: 'app-upload-roi-progress',
    templateUrl: './upload-roi-progress.component.html',
    styleUrls: ['./upload-roi-progress.component.scss'],
})
export class UploadRoiProgressComponent implements OnInit, AfterViewInit {
    /**
     * Auto close dialog after upload completion
     * @type {boolean}
     */
    readonly autoClose: boolean = false;

    /**
     * Auto close dialog timeout
     * @type {boolean}
     */
    readonly autoCloseTimeout: number = 2000;

    /**
     * Regions to upload
     */
    public rois: Region[];

    /**
     * Regions to upload
     */
    public uploads: RoiUpload[];

    /**
     * Array of regions which failed on its upload
     */
    public failedUploads: Region[];

    /**
     * Array of regions which were uploaded and created correctly
     */
    public correctUploads: Region[];

    /**
     * Dialog result
     */
    public dialogResult: RoiUploadResult;

    /**
     * File to upload
     */
    public file: File;

    /**
     * Component constructor
     *
     * @param roiService
     * @param {MatDialogRef<UploadRoiProgressComponent>} dialogRef
     * @param data
     */
    constructor(
        private roiService: RoiService,
        public dialogRef: MatDialogRef<UploadRoiProgressComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data['rois'] !== undefined) {
            this.rois = data.rois;
        } else if (data['file'] !== undefined) {
            this.file = data.file;
        }

        if (data['autoClose'] === true) {
            this.autoClose = true;
        }

        if (data['autoCloseTimeout'] !== undefined) {
            this.autoCloseTimeout = data.autoCloseTimeout;
        }
    }

    ngOnInit() {
        this.dialogResult = undefined;
    }

    ngAfterViewInit(): void {
        if (this.rois) {
            setTimeout(() => {
                this.uploadRois();
            });
        } else if (this.file) {
            setTimeout(() => {
                this.uploadZip();
            });
        }
    }

    /**
     * Upload rois one by one and report progress
     */
    private uploadRois() {
        const statusArray = [STATUS_SUCCESS, STATUS_PENDING, STATUS_TIMEOT, STATUS_FAIL];
        this.failedUploads = [];
        this.correctUploads = [];
        this.uploads = this.rois.map((r) => {
            return { roi: r, status: STATUS_PENDING, retryingUpload: false };
        });

        // create observable from array of rois
        const uploads$ = observableFrom(this.uploads);
        uploads$.subscribe((upload) => {
            upload.start = moment();
            this.roiService.create([upload.roi]).subscribe(
                (response) => {
                    upload.end = moment();
                    upload.status = STATUS_SUCCESS;
                    for (const uploadedRoi of response.rois) {
                        this.correctUploads.push(uploadedRoi);
                    }
                },
                (err) => {
                    upload.end = moment();
                    upload.status = err.status === 403 ? STATUS_FAIL : STATUS_TIMEOT;
                    if (err.message.startsWith('Unexpected token E in JSON at position 0')) {
                        upload.error = 'Unexpected error';
                    } else {
                        upload.error = err.error.message;
                    }

                    if (statusArray.indexOf(upload.status) === -1) {
                        upload.status = STATUS_TIMEOT;
                    }
                    if (!upload.error) {
                        upload.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
                    }

                    // add roi to list of fails
                    this.failedUploads.push(upload.roi);
                },
                () => {
                    this.dialogResult = {
                        failed: this.failedUploads,
                        uploaded: this.correctUploads,
                        uploads: this.uploads,
                    };
                }
            );
        });
    }

    /**
     * Add the region as a layer to the preview map.
     * @param {Map} map
     * @param region
     */
    setRegion(map: L.Map, region) {
        const regionLayer = L.geoJSON(<GeoJsonObject>region.geojson);
        regionLayer.setStyle(roiStyle);
        regionLayer.addTo(map);

        setTimeout(() => {
            map.invalidateSize();
            map.fitBounds(regionLayer.getBounds());
        }, 200);
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close(this.dialogResult);
    }

    /**
     * Close the dialog after a timeout if this.autoClose is true
     */
    private _autoClose() {
        if (this.autoClose === true) {
            setTimeout(() => {
                this.close();
            }, this.autoCloseTimeout);
        }
    }

    /**
     * Upload zip shapefile
     */
    private uploadZip() {
        this.roiService.convertShapefileToGeoJSON(this.file).subscribe(
            (response: { geojson: any }) => {
                const region = new Region(response);
                this.dialogResult = { region: region };
                this._autoClose();
            },
            (err) => {
                this.dialogResult = { region: null };
                this._autoClose();
            }
        );
    }

    /**
     * Retry the upload of a give ROI after it fails by timeout
     * @param roi
     */
    retry(roi) {
        const statusArray = [STATUS_SUCCESS, STATUS_PENDING, STATUS_TIMEOT, STATUS_FAIL];
        const roiUploadsFiltered = this.uploads.filter((r) => {
            return r.roi === roi;
        });

        if (roiUploadsFiltered && roiUploadsFiltered.length > 0) {
            const currentRoiUpload = roiUploadsFiltered[0];
            currentRoiUpload['retryingUpload'] = true;

            this.roiService.create([currentRoiUpload.roi]).subscribe(
                (response) => {
                    currentRoiUpload['retryingUpload'] = false;
                    currentRoiUpload.end = moment();
                    currentRoiUpload.status = STATUS_SUCCESS;
                    this.correctUploads.push(response.rois[0]);
                    // After correctly uploaded, the roi has to be removed from failedUploads
                    if (this.failedUploads.indexOf(roi) > -1) {
                        this.failedUploads.splice(this.failedUploads.indexOf(roi), 1);
                    }
                },
                (err) => {
                    currentRoiUpload['retryingUpload'] = false;
                    currentRoiUpload.end = moment();
                    currentRoiUpload.status = err.status === 403 ? STATUS_FAIL : STATUS_TIMEOT;
                    if (err.message.startsWith('Unexpected token E in JSON at position 0')) {
                        currentRoiUpload.error = 'Unexpected error';
                    } else {
                        currentRoiUpload.error = err.error.message;
                    }

                    if (statusArray.indexOf(currentRoiUpload.status) === -1) {
                        currentRoiUpload.status = STATUS_TIMEOT;
                    }
                    if (!currentRoiUpload.error) {
                        currentRoiUpload.error = DEFAULT_UPLOAD_ERROR_MESSAGE;
                    }
                },
                () => {
                    this.dialogResult = {
                        failed: this.failedUploads,
                        uploaded: this.correctUploads,
                        uploads: this.uploads,
                    };
                }
            );
        }
    }

    /**
     * Checks if there are any ROI uploads with the given status
     */
    checkAnyUploadStatus(statusValue: UploadStatus) {
        return this.uploads.find((up) => up.status === statusValue);
    }

    /**
     * Obtains RoiUploads with the given UploadStatus value
     * @param statusValue
     */
    obtainUploadsByStatus(statusValue: UploadStatus) {
        return this.uploads.filter((up) => up.status === statusValue);
    }

    /**
     * Retries the upload for those that failed due to timeout, code 501 only
     */
    retryFailedUploads() {
        this.uploads
            .filter((r) => r.status === STATUS_TIMEOT)
            .forEach((up) => {
                up.status = STATUS_PENDING;
                this.retry(up.roi);
            });
    }

    /**
     * Downloads a JSON file containing the ROIs which upload failed
     */
    downloadFailedRegions() {
        if (this.failedUploads && this.failedUploads.length > 0) {
            const geosJson = this.failedUploads.map((fr) => fr.geojson);
            const failedRoisJson =
                '{\n' +
                '    "type": "FeatureCollection",\n' +
                '    "features": \n' +
                JSON.stringify(geosJson, null, 4) +
                '\n}';

            const blob = new Blob([failedRoisJson], {
                type: 'application/json',
            });
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = 'failed-rois.geojson';
            link.click();
        }
    }
}
