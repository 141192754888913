import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';

@Component({
    selector: 'app-account-re-activate',
    templateUrl: './account-re-activate.component.html',
    styleUrls: ['./account-re-activate.component.scss'],
})
export class AccountReActivateComponent implements OnInit {
    /**
     * Forgot password form.
     */
    public resendActivationForm: UntypedFormGroup;

    /**
     * Background image
     */
    public backgroundImage = 'url("/assets/img/blurred_world.jpg")';

    constructor(
        private router: Router,
        private userService: UserService,
        private snackbar: CustomSnackbarService
    ) {}

    ngOnInit() {
        this.resendActivationForm = new UntypedFormGroup({
            email: new UntypedFormControl('', Validators.required),
        });
    }

    /**
     * Navigate to login page.
     */
    goToLogin() {
        this.router.navigateByUrl('/login');
    }

    /**
     * Re send activation email
     */
    resendActivation() {
        const email = this.resendActivationForm.value.email;
        this.userService.resendActivationEmail(email).subscribe((response: any) => {
            this.snackbar.present('Check your email to activate your account.');
            this.goToLogin();
        });
    }
}
