/**
 * Product CRUD class
 */
import { autoserialize, autoserializeAs } from 'cerialize';

export class Group {
    /**
     * Group type identifier
     */
    @autoserializeAs('group_type') groupType: string;

    /**
     * Group name
     */
    @autoserializeAs('group_name') groupName: string;

    /**
     * Group value
     */
    @autoserializeAs('group_value') groupValue: string;

    /**
     * Products list
     */
    @autoserializeAs('products') products: any[];

    /**
     * Main product of the group
     */
    @autoserializeAs('main_product') mainProduct: any;

    /**
     * Group member name
     */
    @autoserializeAs('member_name') memberName: string;

    /**
     * Group member value
     */
    @autoserializeAs('member_value') memberValue: string;

    /**
     * Selected product
     */
    @autoserialize product: any;

    /**
     * If is the group main member
     */
    @autoserializeAs('is_main_member') isMainMember?: boolean;

    constructor(
        groupType: string,
        groupName: string,
        products: any[],
        mainProduct: any,
        product: any,
        isMainMember?: boolean
    ) {
        this.groupType = groupType;
        this.groupName = groupName;
        this.products = products;
        this.mainProduct = mainProduct;
        this.product = product;
        this.isMainMember = isMainMember;
    }

    /**
     * Shallow product copy.
     * @return {Group}
     */
    public clone(): Group {
        return new Group(
            this.groupType,
            this.groupName,
            this.products,
            this.mainProduct,
            this.product,
            this.isMainMember
        );
    }
}
