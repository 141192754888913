<div class="api-access-management-wrapper flex flex-col">
    <div class="title-wrapper flex justify-around items-center gap-4">
        <div class="flex flex-col justify-center items-center flex-grow">
            <span class="title"><i class="icon-w-text fa fa-cogs"></i>API Access management</span>
        </div>
        <div class="flex gap-4 justify-end items-center">
            <button
                class="!min-w-[50px]"
                mat-raised-button
                [disabled]="reloadingApiRequestList"
                (click)="applyRequestFilter(true)"
                matTooltip="Reload api request list"
            >
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>

    <div class="content flex flex-col gap-4">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z2">
                <mat-expansion-panel-header>
                    <mat-panel-title> Filter options </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="flex gap-5">
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <mat-select
                                [(value)]="productFilter"
                                (selectionChange)="applyRequestFilter(true)"
                                placeholder="Product"
                            >
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let product of productsList"
                                    [value]="product.apiName"
                                >
                                    {{ product.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <mat-select
                                [(value)]="requestTypeFilter"
                                (selectionChange)="applyRequestFilter(true)"
                                placeholder="Request type"
                            >
                                <mat-option></mat-option>
                                <mat-option *ngFor="let type of requestType" [value]="type.value">
                                    {{ type.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <mat-select
                                [(value)]="requestStatusFilter"
                                (selectionChange)="applyRequestFilter(true)"
                                placeholder="Request status"
                            >
                                <mat-option></mat-option>
                                <mat-option
                                    *ngFor="let status of requestStatus"
                                    [value]="status.value"
                                >
                                    {{ status.viewValue }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <mat-select
                                [(value)]="formatFilter"
                                (selectionChange)="applyRequestFilter(true)"
                                placeholder="Format"
                            >
                                <mat-option></mat-option>
                                <ng-container *ngFor="let format of requestFormat">
                                    <mat-option
                                        *ngIf="!requestTypeFilter || format[requestTypeFilter]"
                                        [value]="format.value"
                                    >
                                        {{ format.viewValue }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex gap-5">
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="requestTypeFilter && requestTypeFilter !== 'gridded'"
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Minimum latitude"
                                type="number"
                                [(ngModel)]="minimumLatitudeFilter"
                                step="0.00001"
                                min="-180"
                                max="180"
                            />
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="requestTypeFilter && requestTypeFilter !== 'gridded'"
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Minimum longitude"
                                type="number"
                                [(ngModel)]="minimumLongitudeFilter"
                                step="0.00001"
                                min="-180"
                                max="180"
                            />
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="requestTypeFilter && requestTypeFilter !== 'gridded'"
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Maximum latitude"
                                type="number"
                                [(ngModel)]="maximumLatitudeFilter"
                                step="0.00001"
                                min="-180"
                                max="180"
                            />
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="requestTypeFilter && requestTypeFilter !== 'gridded'"
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Maximum longitude"
                                type="number"
                                [(ngModel)]="maximumLongitudeFilter"
                                step="0.00001"
                                min="-180"
                                max="180"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex gap-5">
                    <div class="date-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                (dateChange)="applyRequestFilter(true)"
                                [(ngModel)]="startDateFilter"
                                [matDatepicker]="startDate"
                                placeholder="Start date"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="startDate"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="date-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                (dateChange)="applyRequestFilter(true)"
                                [(ngModel)]="endDateFilter"
                                [matDatepicker]="endDate"
                                placeholder="End date"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="endDate"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="
                                    requestTypeFilter && requestTypeFilter !== 'time-series'
                                "
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Latitude"
                                type="number"
                                [(ngModel)]="latitudeFilter"
                                step="0.00001"
                                min="-90"
                                max="90"
                            />
                        </mat-form-field>
                    </div>
                    <div class="selected-filter flex">
                        <mat-form-field>
                            <input
                                matInput
                                [disabled]="
                                    requestTypeFilter && requestTypeFilter !== 'time-series'
                                "
                                (ngModelChange)="debounceApplyFilter($event)"
                                placeholder="Longitude"
                                type="number"
                                [(ngModel)]="longitudeFilter"
                                step="0.00001"
                                min="-180"
                                max="180"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-form-field style="width: 80%">
            <input
                #filterBox
                matInput
                (keyup)="applyFilter(filterBox.value)"
                placeholder="Filter table data"
            />
        </mat-form-field>

        <div class="loading-spinner" *ngIf="reloadingApiRequestList">
            <mat-spinner></mat-spinner>
        </div>
        <table
            [hidden]="reloadingApiRequestList"
            mat-table
            [dataSource]="dataSource"
            multiTemplateDataRows
            matSort
        >
            <ng-container matColumnDef="uuid">
                <th mat-header-cell *matHeaderCellDef>UUID</th>
                <td mat-cell *matCellDef="let element">{{ element.uuid }}</td>
            </ng-container>
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.productName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date | date: 'yyyy-MM-dd' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.type | removeunderscore }}
                </td>
            </ng-container>
            <ng-container matColumnDef="format">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Format</th>
                <td mat-cell *matCellDef="let element">{{ element.format }}</td>
            </ng-container>
            <ng-container matColumnDef="notify">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Notify</th>
                <td mat-cell *matCellDef="let element">{{ element.notify }}</td>
            </ng-container>
            <ng-container matColumnDef="zipped">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Zipped</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.zipped | booltoyesno }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.processingStatus }}
                </td>
            </ng-container>

            <ng-container matColumnDef="information">
                <th mat-header-cell *matHeaderCellDef>Info</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button>
                        <i
                            [class]="element == expandedElement ? 'fa fa-eye-slash' : 'fa fa-eye'"
                            aria-hidden="true"
                        ></i>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div
                        class="element-detail"
                        *ngIf="selectedApiRequest"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                    >
                        <div class="processing-status">
                            <div
                                class="download-title-wrapper flex justify-start items-center gap-4"
                            >
                                <div class="flex flex-col justify-start items-start">
                                    <span class="title"
                                        >Details for API request:
                                        {{ selectedApiRequest.uuid }}</span
                                    >
                                </div>
                            </div>
                            <div
                                class="download-title-wrapper"
                                *ngIf="element && element === expandedElement"
                            >
                                <app-api-request-details
                                    [request]="selectedApiRequest"
                                ></app-api-request-details>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let element; columns: displayedColumns"
                class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="
                    expandedElement = expandedElement === element ? null : element;
                    selectApiRequest(element)
                "
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>

        <div class="flex flex-col">
            <mat-paginator
                [length]="resultsLength"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [pageIndex]="pageIndex"
                (page)="setPage($event)"
                showFirstLastButtons
            >
            </mat-paginator>
        </div>
    </div>
</div>
