import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService, AccessInput } from '../auth/auth.service';

@Directive({
    selector: '[appAccess]',
})
export class AccessDirective {
    private hasChanged = false;

    @Input() set appAccess(permissions: AccessInput) {
        if (!this.authService.hasPermission(permissions)) {
            this.el.nativeElement.style.display = 'none';
            this.el.nativeElement.classList.add('app-hide');
            this.hasChanged = true;
        } else if (this.hasChanged) {
            this.el.nativeElement.style.removeProperty('display');
            this.el.nativeElement.classList.remove('app-hide');
            this.hasChanged = false;
        }
    }

    constructor(
        private el: ElementRef,
        private authService: AuthService
    ) {}
}
