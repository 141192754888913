import { toCssHexColor } from '../utils/utils';

/**
 * Legend CRUD class
 */
export class Legend {
    /**
     * Show flag.
     */
    show: boolean;

    /**
     * Legend data
     */
    data: any = {};

    /**
     * Legend name
     */
    name: string;

    /**
     * Linear gradient user selected range
     */
    currentRange: [number, number];

    /**
     * Default constructor
     * @param jsonData
     */
    constructor(jsonData?: any) {
        Object.assign(this, jsonData);
    }

    /**
     * Get linear gradient string
     * @returns {string}
     */
    linearGradient() {
        const gradient = [];

        for (const d of this.data.listed_colormap) {
            gradient.push(`#${toCssHexColor(d)}`);
        }

        return gradient.join(', ');
    }
}
