import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NvD3Component } from './ng2-nvd3.component';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_MOMENT_DATE_FORMATS,
    MatMomentDateModule,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ClipboardModule } from 'ngx-clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { CompareMapComponent } from './compare-map/compare-map.component';
import { TimeSeriesDialog } from './time-series-dialog/time-series-dialog.component';
import { DamService } from '../services/dam.service';
import { TimeSeries } from './time-series/time-series.component';
import { KeysPipe, ValuesPipe } from '../pipes/object-accessors.pipe';
import { BoolToYesNoPipe } from '../pipes/bool-yes-no.pipe';
import { RemoveUnderScorePipe } from '../pipes/remove_underscore.pipe';
import { ShowLegendPipe, VisibleProductLegendsPipe } from '../pipes/legend-filter.pipe';
import { RoiPreviewDialogComponent } from './roi-preview-dialog/roi-preview-dialog.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MapPreviewComponent } from './map-preview/map-preview.component';
import { MapPreviewSmallComponent } from './map-preview/map-preview-small.component';
import { RoiSidenavComponent } from './roi-sidenav/roi-sidenav.component';
import { LayersControlComponent } from './layers-control/layers-control.component';
import { LegendComponent } from './legend/legend.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { KpiService } from '../services/kpi.service';
import { MapPopupComponent } from './map-popup/map-popup.component';
import { SettingsSidenavComponent } from './settings-sidenav/settings-sidenav.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UnitAppenderPipe } from '../pipes/unit-appender.pipe';
import { ReversePipe } from '../pipes/reverse.pipe';
import { CategoricalLegendComponent } from './categorical-legend/categorical-legend.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { UserService } from '../services/user.service';
import { LayersAddComponent } from './layers-add/layers-add.component';
import { ProductService } from '../services/product.service';
import { RoiInfoDialogComponent } from './roi-info-dialog/roi-info-dialog.component';
import { ProductInfoDialogComponent } from './product-info-dialog/product-info-dialog.component';
import { RoiService } from '../services/roi.service';
import { KpiConfigDialogComponent } from './kpi-config-dialog/kpi-config-dialog.component';
import { KpiComponent } from './kpi/kpi.component';
import { KpiGridComponent } from './kpi-grid/kpi-grid.component';
import { RoiHelpComponent } from './roi-help/roi-help.component';
import { RoiMaxComponent } from './roi-max/roi-max.component';
import { AuthService } from './auth/auth.service';
import { LayersService } from '../services/layers.service';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { LayerComponent } from './layer/layer.component';
import { RoiManagementComponent } from './roi-management/roi-management.component';
import { DialogModule } from './dialog/dialog.module';
import { UserSelectDialogComponent } from './user-select-dialog/user-select-dialog.component';
import { UserForApiInterceptor } from '../interceptors/user-for-api.interceptor';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ErrorInterceptor } from '../interceptors/error.interceptor';
import { CustomSnackbarModule } from './custom-snackbar/custom-snackbar.module';
import { AppRoutes } from './app.routes';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AppDirectivesModule } from './app-directives/app-directives.module';
import { AreaPipe } from '../pipes/roi.pipe';
import { CoordinatePipe } from '../pipes/coordinates.pipe';
import { MonthDaySelectorComponent } from './month-day-selector/month-day-selector.component';
import { RoiUploadFileComponent } from './roi-upload-file/roi-upload-file.component';
import { RegisterComponent } from './register/register.component';
import { AccountActivateComponent } from './account-activate/account-activate.component';
import { AccountReActivateComponent } from './account-re-activate/account-re-activate.component';
import { UploadRoiProgressComponent } from './upload-roi-progress/upload-roi-progress.component';
import { AddOriginInterceptor } from '../interceptors/add-origin.interceptor';
import { EmbedComponent } from './embed/embed.component';
import { LandingLogoComponent } from './landing-logo/landing-logo.component';
import { CustomDateAdapter } from '../utils/custom-date-adapter';
import { ApiAccessComponent } from './api-access/api-access.component';
import { ApiAccessManagementComponent } from './api-access-management/api-access-management.component';
import { ApiAccessInfoDialogComponent } from './api-access-info-dialog/api-access-info-dialog.component';
import { UserProductMultiSelectComponent } from './user-product-multi-select/user-product-multi-select.component';
import { EmbedSidenavComponent } from './embed-sidenav/embed-sidenav.component';
import { RegionPreviewDialogComponent } from './region-preview-dialog/region-preview-dialog.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { ApiRequestParamsDialogComponent } from './api-request-params-dialog/api-request-params-dialog.component';
import { TimeSeriesApiDialogComponent } from './time-series-api-dialog/time-series-api-dialog.component';
import { SimpleComponent } from './simple/simple.component';
import { ShareSimpleDialogComponent } from './share-simple-dialog/share-simple-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HeaderComponent } from './header/header.component';
import { DemoComponent } from './demo/demo.component';
import { SimpleInfoDialogComponent } from './simple-info-dialog/simple-info-dialog.component';
import { LabelManagementComponent } from './label-management/label-management.component';
import { ColorGithubModule } from 'ngx-color/github';
import { LabelEditDialogComponent } from './label-edit-dialog/label-edit-dialog.component';
import { GroupSelectorComponent } from './group-selector/group-selector.component';
import { AnimationPreviewComponent } from './animation-preview/animation-preview.component';
import { AnimationSidenavComponent } from './animation-sidenav/animation-sidenav.component';
import { AnimationApiRequestDialogComponent } from './animation-api-request-dialog/animation-api-request-dialog.component';
import { TimeSeriesInfoDialogComponent } from './time-series-info-dialog/time-series-info-dialog.component';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { NewReleasePopupComponent } from './new-release-popup/new-release-popup.component';
import { NewReleaseInfoPopupComponent } from './new-release-info-popup/new-release-info-popup.component';
import { ImpersonateDialogComponent } from './impersonate-dialog/impersonate-dialog.component';
import { ApiRequestDetailsComponent } from './api-request-details/api-request-details.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import 'nvd3';
import { getJwtToken } from '../utils/utils';
import { googlePlacesFactory, GOOGLE_PLACES } from '../utils/google-maps-places';
import { AuthConfigModule } from './auth/auth-config.module';
import { GradientDirective } from './gradient.directive';
import { Router } from '@angular/router';

@NgModule({
    declarations: [
        AppComponent,
        CompareMapComponent,
        KeysPipe,
        BoolToYesNoPipe,
        RemoveUnderScorePipe,
        LayersControlComponent,
        MapComponent,
        MapPreviewComponent,
        TimeSeries,
        TimeSeriesDialog,
        RoiPreviewDialogComponent,
        ShowLegendPipe,
        VisibleProductLegendsPipe,
        LoginComponent,
        HomeComponent,
        RoiSidenavComponent,
        ValuesPipe,
        LegendComponent,
        DashboardComponent,
        ProgressBarComponent,
        TimelineComponent,
        MapPopupComponent,
        SettingsSidenavComponent,
        UnitAppenderPipe,
        ReversePipe,
        CategoricalLegendComponent,
        LayersAddComponent,
        SearchBarComponent,
        RoiInfoDialogComponent,
        ProductInfoDialogComponent,
        RoiHelpComponent,
        RoiMaxComponent,
        ShareDialogComponent,
        LayerComponent,
        RoiManagementComponent,
        UserSelectDialogComponent,
        PasswordChangeComponent,
        PasswordResetComponent,
        AreaPipe,
        CoordinatePipe,
        RoiUploadFileComponent,
        MonthDaySelectorComponent,
        RegisterComponent,
        AccountActivateComponent,
        AccountReActivateComponent,
        EmbedComponent,
        AccountReActivateComponent,
        UploadRoiProgressComponent,
        MapPreviewSmallComponent,
        LandingLogoComponent,
        UserProductMultiSelectComponent,
        ApiAccessComponent,
        ApiAccessManagementComponent,
        ApiAccessInfoDialogComponent,
        EmbedSidenavComponent,
        RegionPreviewDialogComponent,
        LoadingDialogComponent,
        ApiRequestParamsDialogComponent,
        TimeSeriesApiDialogComponent,
        SimpleComponent,
        ShareSimpleDialogComponent,
        HeaderComponent,
        DemoComponent,
        SimpleInfoDialogComponent,
        LabelManagementComponent,
        LabelEditDialogComponent,
        GroupSelectorComponent,
        SimpleInfoDialogComponent,
        AnimationPreviewComponent,
        AnimationSidenavComponent,
        AnimationApiRequestDialogComponent,
        TimeSeriesInfoDialogComponent,
        NewReleasePopupComponent,
        NewReleaseInfoPopupComponent,
        ImpersonateDialogComponent,
        ApiRequestDetailsComponent,
        GradientDirective,
    ],
    imports: [
        AppDirectivesModule,
        AppRoutes,
        BrowserAnimationsModule,
        BrowserModule,
        ClipboardModule,
        CustomSnackbarModule,
        DialogModule,
        ScrollingModule,

        FormsModule,
        HttpClientModule,
        LeafletModule,
        JwtModule.forRoot(<JwtModuleOptions>{
            config: {
                tokenGetter: getJwtToken,
                // October 2022: while documented as required, it seems
                // this may not be used due to relative paths?
                // See https://github.com/auth0/angular2-jwt/blob/main/API.md
                whitelistedDomains: environment.domains,
            },
        }),
        DragDropModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSortModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MatInputModule,
        MatMomentDateModule,
        MatSelectModule,
        MatFormFieldModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatDividerModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatMenuModule,
        NgxMatSelectSearchModule,
        ColorGithubModule,
        AuthConfigModule,

        KpiConfigDialogComponent,
        KpiGridComponent,
        KpiComponent,
        NvD3Component,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },

        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        AuthService,
        CookieService,
        DamService,
        KpiService,
        LayersService,
        CoordinatePipe,
        ProductService,
        RoiService,
        UserService,
        GoogleAnalyticsService,
        { provide: GOOGLE_PLACES, useFactory: googlePlacesFactory },
        { provide: MAT_DATE_LOCALE, useValue: 'en' },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: 'moment', useValue: moment },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserForApiInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddOriginInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
