import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { UserService } from '../services/user.service';
import { getAreaRepr } from '../utils/roi.utils';

/**
 * Pipe that retrieves the region area according to the user area unit settings.
 */
@Pipe({ name: 'area' })
export class AreaPipe implements PipeTransform {
    constructor(private userService: UserService) {}

    transform(area: number, args?: string[]): Observable<string> {
        return this.userService.userSettings$.pipe(
            map((userSettings) => getAreaRepr(area, userSettings?.settings?.areaUnit))
        );
    }
}
