import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { interval, NEVER, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, scan, startWith, switchMap, tap } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as L from 'leaflet';
import { GeoJSON } from 'geojson';
import { environment } from '../../environments/environment';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { ProductAvailability } from '../../models/product-availability';
import { LayersService } from '../../services/layers.service';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import {
    AnimationApiRequestDialogComponent,
    AnimationApiRequestDialogData,
    AnimationApiRequestDialogResult,
} from '../animation-api-request-dialog/animation-api-request-dialog.component';
import { getISODateString } from '../../utils/utils';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import 'leaflet-boundary-canvas';
import moment from 'moment';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

const embedAreaStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0,
};

export interface AnimationPreviewData {
    geojson: GeoJSON;
    baseLayer: string;
    notificationEmail: string;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    duration?: number | undefined;
    product?: Product | string | undefined;
}

export interface AnimationPreviewResult {}

@Component({
    selector: 'app-animation-preview',
    templateUrl: './animation-preview.component.html',
    styleUrls: ['./animation-preview.component.scss'],
})
export class AnimationPreviewComponent implements OnInit {
    /**
     *  New region added event.
     * @type {EventEmitter<any>}
     */
    @Output() onRequestCreated = new EventEmitter<any>(true);

    /**
     * Leaflet map reference
     */
    map: L.Map;

    /**
     * User products list
     */
    products: Product[];

    /**
     * Start date selector value
     */
    startDate: moment.Moment | undefined;

    /**
     * End date selector value
     */
    endDate: moment.Moment | undefined;

    /**
     * Range of dates to obtain the tiles
     */
    datesRange = [];

    /**
     * Observable for user list query
     */
    filteredProducts$: Observable<Product[]>;
    /**
     * Form control for input field
     * @type {FormControl}
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Product selected model.
     */
    productSelected: Product;

    /**
     * Date selected model.
     */
    dateSelected;

    /**
     * Product availability list for the current product selected.
     * @type {ProductAvailability}
     */
    productAvailability = new ProductAvailability([]);

    /**
     * Index of layers that will be displayed on the map
     */
    layerIndex = 0;

    /**
     * Subject to detect the clicks on the start, play, pause and interval buttons
     */
    private clickStream = new Subject();

    /**
     * Variable to store the frames per second
     */
    interval;

    /**
     * Animation time in seconds
     */
    animationSeconds: number;

    /**
     * Flag to detect if the layers are loaded
     */
    loadedLayers = false;

    /**
     * Flag to enable/disable pause button
     */
    pauseButton = false;

    /**
     * Animation tile layers
     */
    tileLayers = [];

    /**
     * Selected area layer
     */
    selectedAreaLayer;

    /**
     * Layer group
     */
    private layerGroup: L.LayerGroup = new L.LayerGroup();

    /**
     * Reference to the loading dialog shown while processing a new api request
     */
    loadingDialogRef;

    /**
     * Reference of the animation layers html element
     */
    animationLayershtmlElement;

    /**
     * Legend start value
     */
    legendStart;

    /**
     * Legend end value
     */
    legendEnd;

    /**
     * String containing the attribution when using Open Street Maps base layers
     */
    private osmAttribution =
        '<span>© <a href="https://www.openstreetmap.org/copyright"  target="_blank">OpenStreetMap</a> contributors</span>';

    /**
     * Base layers included
     */
    public baseLayers = {
        'open-street-map': {
            label: 'OpenStreetMap',
            layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: this.osmAttribution,
            }),
            openStreetMapBaseLayer: true,
        },
    } as const;

    /**
     * Variable to store the current baseLayer used for the animation
     */
    public currentBaseLayer;

    /**
     * Animation API request form.
     */
    public animationApiRequestForm: UntypedFormGroup;

    /**
     * Map preview options
     */
    public options = {
        zoom: 7,
        center: L.latLng([50.5, 25.5]),
        zoomControl: false,
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: AnimationPreviewData,
        private productService: ProductService,
        private layersService: LayersService,
        private dialogRef: MatDialogRef<AnimationPreviewComponent, AnimationPreviewResult>,
        public matDialog: MatDialog,
        private snackBar: CustomSnackbarService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.startDate = data.startDate ? moment(data.startDate) : undefined;
        this.endDate = data.endDate ? moment(data.endDate) : undefined;
        this.animationSeconds = data.duration;
        this.animationApiRequestForm = new UntypedFormGroup({
            base_layer: new UntypedFormControl(
                { value: this.currentBaseLayer },
                Validators.required
            ),
        });
    }

    ngOnInit() {
        const selectedLayer = this.layersService.getSelectedLayer();
        if (selectedLayer) {
            this.onProductChange(selectedLayer.product);
            this.legendStart = selectedLayer.range[0];
            this.legendEnd = selectedLayer.range[1];
        }

        this.productService.list(true).subscribe((data: any) => {
            this.products = data;

            this.filteredProducts$ = this.searchControl.valueChanges.pipe(
                startWith(''),
                debounceTime(100),
                distinctUntilChanged(),
                map((query) => this.filterProducts(query))
            );

            if (this.data.product) {
                const product = this.filterProducts(this.data.product);
                this.onProductChange(product[0]);
                this.productSelected = product[0];
            }
        });
        const stopWatch$ = this.clickStream.pipe(
            startWith({
                count: false,
                speed: 1000,
                value: 0,
                countup: true,
                increase: 1,
            }),
            scan((state: any, curr: any) => ({ ...state, ...curr }), {}),
            switchMap((state) =>
                state.count ? interval(state.speed).pipe(tap((_) => this.transition())) : NEVER
            )
        );
        stopWatch$.subscribe();
    }

    /**
     * Detect click on the play/pause buttons and interval bar
     * @param event
     */
    buttonClick(event) {
        if (event.count === false) {
            this.pauseButton = false;
        }
        this.googleAnalyticsService.eventEmitter(
            'animation_preview_pause',
            'animation',
            'pause_animation',
            'animation_preview_paused'
        );
        this.clickStream.next(event);
    }

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;
        this.layerGroup.addTo(this.map);
        this.map.doubleClickZoom.disable();
        this.map.dragging.disable();
        this.map.scrollWheelZoom.disable();
        this.map.touchZoom.disable();
        this.map.boxZoom.disable();
        this.map.keyboard.disable();

        // Queue update after angular lifecycle finish
        setTimeout(() => {
            this.createSelectedArea();
        }, 0);
        this.currentBaseLayer =
            this.data.baseLayer in this.baseLayers ? this.data.baseLayer : 'open-street-map';
        this.setBaseLayer(this.currentBaseLayer);
    }

    /**
     * Add selected area to the map
     */
    createSelectedArea() {
        this.selectedAreaLayer = L.geoJSON(this.data.geojson);
        this.selectedAreaLayer.setStyle(embedAreaStyle);
        this.selectedAreaLayer.addTo(this.layerGroup);

        this.map.invalidateSize();
        this.map.fitBounds(this.selectedAreaLayer.getBounds());
    }

    /**
     * Filter product list
     *
     * @param query
     */
    private filterProducts(query): Product[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof Product) {
            query = query.name;
        }
        const filterValue = query.toLowerCase();
        return this.products.filter((prod) => {
            return prod.name && prod.name.toLowerCase().includes(filterValue);
        });
    }

    /**
     * Callback for product select change
     * @param product selected Product
     */
    onProductChange(product: Product) {
        if (product === undefined) {
            return;
        }
        this.productSelected = product;
        this.searchControl.setValue(product);
        this.productService.getAvailability(product.apiName).subscribe((obj) => {
            this.productAvailability = obj;
            // Set date-picker to the most recent date available
            if (obj.availability.length > 0) {
                this.dateSelected = obj.availability[obj.availability.length - 1];
                this.endDate = obj.availability[obj.availability.length - 1];
                this.startDate = obj.availability[obj.availability.length - 7];
                this.setDatesRange();
            }
            return obj;
        });
    }

    /**
     * Clear current selection
     */
    clearSelection() {
        this.productSelected = undefined;
        this.dateSelected = undefined;
        this.productAvailability = new ProductAvailability([]);
        this.searchControl.setValue('');
    }

    /**
     * Returns product name for display
     */
    displayProduct(prod: Product): string {
        return prod ? `${prod.name}` : undefined;
    }

    /**
     * Creates the animation layers
     */
    playButton() {
        if (!this.productSelected) {
            this.snackBar.present('Please, select a valid product and date.');
            return;
        }
        this.pauseButton = true;
        if (!this.loadedLayers) {
            for (const layer of this.tileLayers) {
                this.map.removeLayer(layer);
            }

            this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
                width: '25%',
                height: 'auto',
                data: { evolutionLayers: true },
            });
            this.setDatesRange();
            this.tileLayers = [];

            for (let i = 0; i < this.datesRange.length; i++) {
                this.tileLayers.push(
                    this.layersService.addAnimationLayer(
                        this.productSelected,
                        this.productAvailability,
                        this.datesRange[i],
                        this.data.geojson,
                        this.legendStart,
                        this.legendEnd
                    )
                );
            }
            const layersToLoad = this.tileLayers.length;
            let layersLoaded = 0;
            for (const layer of this.tileLayers) {
                layer.addTo(this.map);
                layer.on('load', () => {
                    layersLoaded++;
                    if (layersLoaded === layersToLoad) {
                        if (this.loadingDialogRef) {
                            this.loadingDialogRef.close();
                        }
                        this.loadedLayers = true;
                        this.searchControl.disable();
                        if (!this.animationSeconds || this.animationSeconds < 0) {
                            this.animationSeconds = this.datesRange.length;
                        }
                        this.setIntervalValue();
                        this.clickStream.next({
                            count: true,
                            speed: this.interval,
                        });
                    }
                });
            }
            this.animationLayershtmlElement = document.getElementsByClassName('animationLayer');

            for (let i = 0; i < this.animationLayershtmlElement.length; i++) {
                (this.animationLayershtmlElement[i] as HTMLElement).style.display = 'none';
            }
        } else {
            this.clickStream.next({ count: true, speed: this.interval });
        }
        this.googleAnalyticsService.eventEmitter(
            'animation_preview_play',
            'animation',
            'play_animation',
            'animation_preview_played'
        );
    }

    /**
     * Start the transition of the animation layers
     */
    transition() {
        if (this.layerIndex === this.animationLayershtmlElement.length) {
            (this.animationLayershtmlElement[this.layerIndex - 1] as HTMLElement).style.display =
                'none';
            this.layerIndex = 0;
        }
        if (this.layerIndex === 0) {
            (this.animationLayershtmlElement[this.layerIndex] as HTMLElement).style.display =
                'block';
            this.layerIndex++;
        } else {
            (this.animationLayershtmlElement[this.layerIndex - 1] as HTMLElement).style.display =
                'none';
            (this.animationLayershtmlElement[this.layerIndex] as HTMLElement).style.display =
                'block';
            this.layerIndex++;
        }
    }

    /**
     * Stop the animation layer animation
     */
    resetAnimation() {
        if (this.animationLayershtmlElement[this.layerIndex - 1]) {
            (this.animationLayershtmlElement[this.layerIndex - 1] as HTMLElement).style.display =
                'none';
        }
        this.layerIndex = 0;
        this.loadedLayers = false;
        this.searchControl.enable();
        this.pauseButton = false;
        this.clickStream.next({ count: false });
        this.googleAnalyticsService.eventEmitter(
            'animation_preview_stop',
            'animation',
            'stop_animation',
            'animation_preview_stopped'
        );
    }

    /**
     * Set the base layer of the animation layer component
     * @param baseLayer
     */
    setBaseLayer(baseLayer) {
        if (baseLayer in this.baseLayers) {
            if (
                this.currentBaseLayer &&
                this.map.hasLayer(this.baseLayers[this.currentBaseLayer].layer)
            ) {
                this.map.removeLayer(this.baseLayers[this.currentBaseLayer].layer);
            }
            this.currentBaseLayer = baseLayer;
            this.animationApiRequestForm.setValue({ base_layer: baseLayer });
            this.map.addLayer(this.baseLayers[baseLayer].layer);
        }
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Operation related to AnimationApiDialogComponent
     */
    handleAnimationApiDialog() {
        const dialogRef = this.matDialog.open<
            AnimationApiRequestDialogComponent,
            AnimationApiRequestDialogData,
            AnimationApiRequestDialogResult
        >(AnimationApiRequestDialogComponent, {
            data: {
                dateStart: this.startDate,
                dateEnd: this.endDate,
                duration: this.animationSeconds,
                product: this.productSelected,
                baseLayer: this.currentBaseLayer,
                notificationEmail: this.data.notificationEmail,
            },
            panelClass: 'animation-api-dialog',
        });

        dialogRef.afterClosed().subscribe((params: any) => {
            if (params) {
                this.createApiRequest(params);
            }
        });
    }

    /**
     * Create API Request and return status and uuid value
     * @param apiRequestParams
     */
    createApiRequest(apiRequestParams) {
        const geojson = {
            type: 'Polygon',
            coordinates: [
                [
                    [
                        this.selectedAreaLayer.getBounds().getNorthWest().lng,
                        this.selectedAreaLayer.getBounds().getNorthWest().lat,
                    ],
                    [
                        this.selectedAreaLayer.getBounds().getNorthEast().lng,
                        this.selectedAreaLayer.getBounds().getNorthEast().lat,
                    ],
                    [
                        this.selectedAreaLayer.getBounds().getSouthEast().lng,
                        this.selectedAreaLayer.getBounds().getSouthEast().lat,
                    ],
                    [
                        this.selectedAreaLayer.getBounds().getSouthWest().lng,
                        this.selectedAreaLayer.getBounds().getSouthWest().lat,
                    ],
                    [
                        this.selectedAreaLayer.getBounds().getNorthWest().lng,
                        this.selectedAreaLayer.getBounds().getNorthWest().lat,
                    ],
                ],
            ],
        };
        const selectedLayer = this.layersService.getSelectedLayer();
        const params = {
            geojson: JSON.stringify(geojson),
            startDate: apiRequestParams['start_date']
                ? getISODateString(apiRequestParams['start_date'])
                : getISODateString(selectedLayer.getDate()),
            endDate: apiRequestParams['end_date']
                ? getISODateString(apiRequestParams['end_date'])
                : getISODateString(selectedLayer.getDate()),
            duration: apiRequestParams['duration'],
            notify: apiRequestParams['notify'] ? apiRequestParams['notify'] : '',
            zoomLevel: this.map.getZoom(),
            legendStart: this.legendStart,
            legendEnd: this.legendEnd,
            baseLayer: apiRequestParams['base_layer']
                ? apiRequestParams['base_layer']
                : this.currentBaseLayer,
        };
        this.showProcessingApiRequestDialog();
        this.productService
            .getAnimationApiRequest(apiRequestParams.product.apiName, params)
            .subscribe(
                (result: any) => {
                    if (result.message === 'Download request received') {
                        this.snackBar.present(
                            `API request ${result.uuid} has been created. You can check the status of the request` +
                                ' in the API access menu.',
                            'success'
                        );
                        this.checkProcessingApiRequest();
                        this.onRequestCreated.emit(result);
                    }
                },
                () => {
                    this.checkProcessingApiRequest();
                }
            );
    }

    /**
     * Method to show a dialog with a processing api request message
     */
    showProcessingApiRequestDialog() {
        this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            data: {
                loadingMessages: { 'api-request': 'Submitting API request...' },
            },
        });
    }

    /**
     * Method that closes the processing api request dialog
     */
    checkProcessingApiRequest() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }

    /**
     * Sets the duration for how long each image will be shown in the animation
     */
    setIntervalValue() {
        this.interval = 1000 / (this.datesRange.length / this.animationSeconds);
    }

    /**
     * Sets the diff of days between start date and end date
     */
    setDatesRange() {
        this.datesRange = [];
        if (this.validateDates()) {
            const current = this.startDate.clone();
            while (current.isBefore(this.endDate, 'days') || current.isSame(this.endDate, 'days')) {
                this.datesRange.push(current.clone());
                current.add(1, 'days');
            }
        }
    }

    /**
     * Method to check that endDate is greater (in days) than  startDate
     */
    validateDates() {
        if (this.startDate && this.endDate) {
            return this.startDate.isBefore(moment(this.endDate).add(1, 'day'), 'days');
        } else {
            return false;
        }
    }
}
