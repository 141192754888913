import { Component } from '@angular/core';
import moment from 'moment';
import { forkJoin, Observable, of as observableOf, of } from 'rxjs';
import { EmbedLinkDates, EmbedLinkMetadata } from '../../models/embed-link';
import { LayerKey } from '../../models/layer-data';
import { Product } from '../../models/product';
import { ProductAvailability } from '../../models/product-availability';
import { EmbedService } from '../../services/embed.service';
import { LayersService } from '../../services/layers.service';
import { binarySearch, cmpMoment, linkParamsAreValid } from '../../utils/utils';

interface EmbedLinkParams {
    products: string;
    dates: string;
    ranges: string;
    hidden: string;
    compare: string;
    selected: string;
    pinned: string;
    x: string;
    y: string;
    z: string;
    opacities: string;
    compareMode?: string;
    baseLayer?: string;
}

/**
 * Load the map status from the embed link.
 * @param params URL Path (?products=...&...)
 * @param productList
 * @param datesList
 * @param layersService
 * @param hash
 * @param embedService
 * @return null if the link is not valid, {x:, y:, z:} otherwise.
 */
export function loadEmbedLink(
    params: EmbedLinkParams,
    productList: Product[] | undefined,
    datesList: EmbedLinkDates[],
    layersService: LayersService,
    hash: string | undefined,
    embedService: EmbedService
) {
    // Get raw params
    const _x = params.x;
    const _y = params.y;
    const _z = params.z;
    const _products = params.products;
    const _dates = params.dates;
    const _selectedLayer = params.selected;
    const _compareLayers = params.compare;
    const _compareMode = params.compareMode;
    const _hiddenLayers = params.hidden;
    const _pinnedLayers = params.pinned;
    const _ranges = params.ranges;
    const _baseLayer = params.baseLayer;
    const _opacities = params.opacities;

    // Check using RegExp that the parameters are valid
    if (
        !linkParamsAreValid(
            _x,
            _y,
            _z,
            _products,
            _dates,
            _selectedLayer,
            _compareLayers,
            _compareMode,
            _opacities,
            _ranges,
            _pinnedLayers,
            _hiddenLayers,
            _baseLayer
        ).valid
    ) {
        return null;
    }

    // Parse the input
    const parseInput = (str: string) => {
        return str.split(',').map((n) => parseInt(n));
    };
    const selectedLayer: LayerKey = _selectedLayer === '' ? 0 : parseInt(_selectedLayer);
    const compareLayers = _compareLayers === '' ? [] : parseInput(_compareLayers);
    const compareMode = _compareMode === null ? '' : _compareMode;
    const products = _products.split(',');
    const hidden = _hiddenLayers === '' ? [] : parseInput(_hiddenLayers);
    const pinned = _pinnedLayers === '' ? [] : parseInput(_pinnedLayers);
    const ranges = _ranges.split(';').map((r) => {
        const groups = r.match(/(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)/);
        return [parseFloat(groups[1]), parseFloat(groups[3])];
    });

    let dates: moment.Moment[];
    const opacities = _opacities.split(',');

    if (_dates !== undefined) {
        dates = _dates.split(',').map((d) => moment.utc(d));
    } else {
        dates = [];
    }

    const specialLayers = layersService.getSpecialLayers();

    // Load layers
    const layerList = layersService.getProductGroupsList(productList);

    const availabilityData: Observable<unknown>[] = [];
    const specialLayersData = new Map<string, (typeof availabilityData)[number]>();
    for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if (specialLayers[product] !== undefined) {
            let specialLayerData = specialLayersData.get(product);
            if (!specialLayerData) {
                specialLayerData = layersService.getSpecialLayerData(product);
                specialLayersData.set(product, specialLayerData);
            }
            availabilityData.push(specialLayerData);
        } else {
            availabilityData.push(observableOf(new ProductAvailability(datesList[i].dates)));
        }
    }

    forkJoin(availabilityData).subscribe((result: any) => {
        for (let i = 0; i < result.length; i++) {
            const addOptions = {
                select: false,
                compare: false,
                emit: false,
            };

            let layer;
            let layerDate;
            const layerOpacity = opacities[i] ? opacities[i] : 100;
            const layerProduct = layerList.find((p) =>
                p.groupType
                    ? p.products.find((pr) => pr.apiName === products[i])
                    : p.apiName == products[i]
            );
            addOptions.compare = compareLayers.indexOf(i) > -1;

            if (result[i].type) {
                layer = layersService.addSpecialLayer(
                    products[i],
                    result[i],
                    undefined,
                    addOptions
                );
            } else {
                if (
                    dates[i] !== undefined &&
                    // dates[i]._i !== 'latest' &&
                    binarySearch(result[i].availability, dates[i], cmpMoment) > -1
                ) {
                    layerDate = dates[i];
                } else {
                    layerDate = result[i].availability[result[i].availability.length - 1];
                }
                layer = layersService.addProductLayer(
                    layerProduct,
                    result[i],
                    layerDate,
                    addOptions,
                    { range: ranges[i] },
                    layerOpacity,
                    hash
                );
                if (layer.group) {
                    layer.product = layer.group.products.find((pr) => pr.apiName === products[i]);
                    layersService.updateTilelayer(layer.key, hash);
                }
            }

            if (i === selectedLayer) {
                layersService.selectLayer(layer.key);
            }

            if (hidden.findIndex((e) => e == i) > -1) {
                layer.display = false;
            }

            if (pinned.findIndex((e) => e == i) > -1) {
                layer.pinned = true;
            }
        }
        embedService.emitShareLinkUpdated();
        layersService.emitLayersUpdated();
    });

    if (compareMode === 'spy' || compareMode === 'sideBySide') {
        layersService.setLayersCompareMode(compareMode);
    }

    return {
        y: parseFloat(_y),
        x: parseFloat(_x),
        z: parseInt(_z),
        baseLayer: _baseLayer,
    };
}

/**
 * Decode metadata_link into a proper format to generate embed view
 * @param {EmbedLinkMetadata} metadataLink
 */
export function decodeEmbedLinkParams(metadataLink: EmbedLinkMetadata) {
    const products: string[] = [];
    const dates: string[] = [];
    const ranges: string[] = [];
    const hidden: number[] = [];
    const compare: number[] = [];
    const selected: number[] = [];
    const pinned: number[] = [];
    const opacities: number[] = [];
    for (let i = 0; i < metadataLink.layers.length; i++) {
        const layer = metadataLink.layers[i];
        if (layer.product) {
            products.push(layer.product);
        } else {
            products.push(layer['specialLayer']);
        }
        dates.push(layer.date);
        ranges.push(layer.range);
        if (layer.opacity) {
            opacities.push(layer.opacity);
        }
        if (layer.compare) {
            compare.push(i);
        }
        if (layer.hidden) {
            hidden.push(i);
        }
        if (layer.pinned) {
            pinned.push(i);
        }
        if (layer.selected) {
            selected.push(i);
        }
    }

    const params: EmbedLinkParams = {
        products: products.join(','),
        dates: dates.join(','),
        opacities: opacities.join(','),
        ranges: ranges.join(';'),
        hidden: hidden.join(','),
        compare: compare.join(','),
        selected: selected.join(','),
        pinned: pinned.join(','),
        x: metadataLink.x,
        y: metadataLink.y,
        z: metadataLink.z,
    };
    if (metadataLink.compareMode) {
        params.compareMode = metadataLink.compareMode;
    }
    if (metadataLink.baseLayer) {
        params.baseLayer = metadataLink.baseLayer;
    }

    return params;
}

@Component({
    selector: 'app-embed',
    templateUrl: './embed.component.html',
    styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent {}
