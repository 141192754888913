<div class="animation-management-wrapper flex flex-col gap-4">
    <div class="title-wrapper flex items-center justify-around gap-4">
        <div class="flex flex-col w-10/12 lg:flex-1 lg:w-auto items-center justify-center">
            <span class="title">Animation API request parameters</span>
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="closeDialog()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="content flex flex-col gap-4">
        <form [formGroup]="animationApiRequestForm" class="grid grid-cols-2 grid-rows-3 gap-2">
            <mat-form-field>
                <input
                    matInput
                    [matDatepicker]="rangeDatepickerStart"
                    formControlName="start_date"
                    placeholder="Start date"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="rangeDatepickerStart"
                ></mat-datepicker-toggle>
                <mat-datepicker #rangeDatepickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    [matDatepicker]="rangeDatepickerEnd"
                    formControlName="end_date"
                    placeholder="End date"
                />
                <mat-datepicker-toggle matSuffix [for]="rangeDatepickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
                <mat-hint
                    class="error-hint"
                    *ngIf="animationApiRequestForm?.errors?.endBeforeStart"
                >
                    End date can't be before start date.
                </mat-hint>
            </mat-form-field>
            <mat-form-field>
                <input
                    formControlName="duration"
                    placeholder="Animation duration in seconds"
                    matInput
                    step="1"
                    min="1"
                    type="number"
                />
            </mat-form-field>
            <mat-form-field class="map-base-layer-selector" class="expand-button">
                <input
                    type="text"
                    placeholder="Product name"
                    aria-label="Product name"
                    matInput
                    formControlName="product"
                    [matAutocomplete]="auto"
                    tabindex="-1"
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProduct">
                    <mat-option *ngFor="let prod of filteredProducts$ | async" [value]="prod">
                        {{ prod.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
                <input matInput formControlName="notify" placeholder="Email to notify" />
            </mat-form-field>

            <mat-form-field>
                <mat-select formControlName="base_layer" placeholder="Base layer">
                    <mat-option *ngFor="let layer of baseLayers | keys" [value]="layer"
                        >{{ baseLayers[layer].label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div class="flex justify-end items-center gap-2">
            <button
                [disabled]="animationApiRequestForm.invalid"
                mat-raised-button
                (click)="save()"
                color="accent"
            >
                <div class="flex justify-center items-center"><i class="fa fa-check"></i> Save</div>
            </button>
        </div>
    </div>
</div>
