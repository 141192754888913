/// <reference types="@types/google.maps" />
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    NgZone,
    Output,
    ViewChild,
} from '@angular/core';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { GOOGLE_PLACES } from '../../utils/google-maps-places';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements AfterViewInit {
    @Output() placeSelected = new EventEmitter<google.maps.places.PlaceResult>();

    /**
     * Reference to input text element that will be manipulated by google
     */
    @ViewChild('search')
    public searchElementRef: ElementRef;

    /**
     * instance of autocomplete service
     */
    private autocomplete: google.maps.places.AutocompleteService;

    /**
     * instance of place service
     */
    private placeService: google.maps.places.PlacesService;

    constructor(
        private ngZone: NgZone,
        private googleAnalyticsService: GoogleAnalyticsService,
        @Inject(GOOGLE_PLACES) private places: typeof google.maps.places
    ) {}

    /**
     * Basic fields for an autocomplete request
     */
    private autocompleteBasicFields = [
        'address_component',
        'adr_address',
        'formatted_address',
        'geometry',
        'icon',
        'name',
        'business_status',
        'photo',
        'type',
        'url',
        'utc_offset_minutes',
        'vicinity',
    ];

    /**
     * on init method
     */
    ngAfterViewInit(): void {
        // load Places Autocomplete
        this.autocomplete = new this.places.AutocompleteService();
        this.placeService = new this.places.PlacesService(this.searchElementRef.nativeElement);
        const autocomplete = new this.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ['(regions)'],
        });
        autocomplete.setFields(this.autocompleteBasicFields);
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                // get the place result
                const place = autocomplete.getPlace();
                this.emitPlace(place);
            });
        });
    }

    /**
     * Callback for enter key press on search input text field
     * @param event
     */
    onSearchEnter(event: any) {
        if (event.keyCode === 13 && event.target.value) {
            // if enter is pressed and there is data
            const request = <google.maps.places.AutocompletionRequest>{
                input: event.target.value,
                types: ['(regions)'],
            };

            this.autocomplete.getPlacePredictions(request, (results, status) => {
                if (status === this.places.PlacesServiceStatus.OK && results.length > 0) {
                    this.placeService.getDetails(
                        { placeId: results[0].place_id },
                        (place, status2) => {
                            if (status2 === this.places.PlacesServiceStatus.OK) {
                                this.emitPlace(place);
                            }
                        }
                    );
                }
            });
            this.googleAnalyticsService.eventEmitter(
                'place_searched',
                'map_controls',
                'search_location',
                event.target.value
            );
        }
    }

    /**
     * Emits the place selected event
     *
     * @param {google.maps.places.PlaceResult} place
     */
    private emitPlace(place: google.maps.places.PlaceResult) {
        // verify result
        if (place.geometry === undefined || place.geometry === null) {
            return;
        }
        // emit the place selected
        this.placeSelected.emit(place);
    }
}
