import { Component, effect, Inject, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, map } from 'rxjs';

import { Kpi } from '../../models/kpi';
import { KpiService } from '../../services/kpi.service';
import { KpiGridComponent } from '../kpi-grid/kpi-grid.component';

/** Move a kpi from src to dst signal */
function moveKpi(src: WritableSignal<Kpi[]>, dst: WritableSignal<Kpi[]>, kpi: Kpi) {
    src.update((src) => src.filter((srcKpi) => srcKpi.name !== kpi.name));
    dst.mutate((kpis) => {
        kpis.push(kpi);
    });
}

@Component({
    selector: 'app-kpi-config-dialog',
    templateUrl: './kpi-config-dialog.component.html',
    styleUrls: ['./kpi-config-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, KpiGridComponent],
})
export class KpiConfigDialogComponent {
    private kpis = toSignal(
        forkJoin([this.kpiService.getSelectedKpis(), this.kpiService.getAvailableKpis()]).pipe(
            map(([selected, available]): [Kpi[], Kpi[]] => {
                const av = available.filter((kpi) => !kpi.inArray(selected));
                for (const k of selected) {
                    k.setZero();
                }
                for (const k of av) {
                    k.setZero();
                }
                return [selected, av];
            })
        )
    );

    selected = signal<Kpi[] | undefined>(undefined);
    available = signal<Kpi[] | undefined>(undefined);

    /**
     * Default constructor
     * @param {KpiService} kpiService
     * @param {MatDialogRef<KpiConfigDialogComponent>} dialogRef
     * @param data
     */
    constructor(
        private kpiService: KpiService,
        public dialogRef: MatDialogRef<KpiConfigDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        effect(
            () => {
                const kpis = this.kpis();
                if (kpis === undefined) {
                    return;
                }
                const [selected, available] = kpis;
                this.selected.set(selected);
                this.available.set(available);
            },
            { allowSignalWrites: true }
        );
    }

    /** Closes the dialog and returns the list of selected kpis */
    closeDialog() {
        this.dialogRef.close(this.selected());
    }

    /** kpi deleted event */
    onKpiDeleted(kpi: Kpi) {
        // move the kpi from selected to available list
        moveKpi(this.selected, this.available, kpi);
    }

    /** kpi added event */
    onKpiAdded(kpi: Kpi) {
        // move the kpi from available to selected list
        moveKpi(this.available, this.selected, kpi);
    }
}
