<div class="api-access-wrapper flex flex-col gap-4">
    <section class="flex flex-col">
        <div class="api-access-header flex justify-start items-center">
            <h2 class="api-access-title primary-color font-medium text-xl">API Access</h2>
            <button
                mat-raised-button
                (click)="close()"
                matTooltip="Close dashboard"
                matTooltipPosition="left"
                class="close-sidenav-button"
                color="warn"
            >
                <i class="fa fa-times"></i>
            </button>
        </div>
    </section>

    <mat-divider></mat-divider>

    <section class="flex flex-col justify-start items-start gap-2">
        <h2 class="primary-color font-medium text-lg">Recent API requests</h2>
        <div *ngIf="!loadingList" class="flex justify-center items-center w-11/12 mx-auto">
            <mat-table #table [dataSource]="dataSource" class="w-full">
                <ng-container matColumnDef="uuid">
                    <mat-header-cell *matHeaderCellDef style="flex: 30%">UUID</mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex: 30%">
                        <span>{{ element.uuid }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="product">
                    <mat-header-cell *matHeaderCellDef style="flex: 25%">Product</mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex: 25%">
                        <span>{{ element.productName }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef style="flex: 20%"
                        >Request status</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex: 20%">
                        <span>{{ element.processingStatus }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="info">
                    <mat-header-cell *matHeaderCellDef>Info</mat-header-cell>
                    <mat-cell
                        class="info-button"
                        (click)="openApiAccessDialog(element)"
                        *matCellDef="let element"
                    >
                        <i style="margin: 0 auto" class="fa fa-info"></i>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        <div *ngIf="loadingList" class="loading-spinner">
            <mat-spinner></mat-spinner>
        </div>
    </section>

    <section class="flex flex-col justify-start items-start gap-2">
        <h2 class="primary-color font-medium text-lg">API Request management</h2>
        <div class="flex justify-center items-center" style="width: 100%">
            <button
                mat-raised-button
                class="button-fab button-fab-master button-fab-region expand-button"
                (click)="showApiAccessManagement()"
            >
                <i class="fa fa-cogs" aria-hidden="true"></i>
                API Request management
            </button>
        </div>
    </section>

    <section
        class="flex flex-col justify-start items-start gap-2"
        appAccess="can-create-gridded-data-request"
    >
        <h2 class="primary-color font-medium text-lg">Exports</h2>

        <div
            class="flex justify-center items-center gap-4 w-full"
            *ngIf="editionModes['captureRegionMode']"
        >
            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="showParamsDialog()"
            >
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
            </button>

            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="cancelAreaSelect()"
            >
                <i class="fa fa-times" aria-hidden="true"></i>
                Cancel
            </button>
        </div>

        <div class="flex justify-center items-center w-full">
            <button
                mat-raised-button
                class="button-fab button-fab-master button-fab-region expand-button"
                [disabled]="editionModes['shapeEditMode']"
                (click)="showAreaSelect()"
                matTooltip="Export area as image"
                matTooltipPosition="right"
                *ngIf="!editionModes['captureRegionMode']"
            >
                <mat-icon class="!inline-flex items-center">
                    <img src="/assets/img/image-download-icon.svg" aria-hidden="true" />
                </mat-icon>
                Download map
            </button>
        </div>
    </section>
</div>
