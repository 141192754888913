import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-simple-snackbar',
    templateUrl: './simple-snackbar.component.html',
    styleUrls: ['./simple-snackbar.component.scss'],
})
/**
 * Custom snackbar: icon, message and close button.
 */
export class SimpleSnackbarComponent implements OnInit {
    /**
     * Snackbar icon and message.
     * @type {{icon: string; message: string}}
     */
    data = {
        icon: '',
        message: '',
        status: undefined,
        request: undefined,
        hideTooltipText: '',
        showTooltipText: '',
    };

    /**
     * Boolean to whether display info about the error or not
     */
    showFullInfo = false;

    /**
     * Default constructor.
     * @param {MatSnackBarRef<SimpleSnackbarComponent>} snackbarRef
     */
    constructor(private snackbarRef: MatSnackBarRef<SimpleSnackbarComponent>) {
        this.data = snackbarRef.containerInstance.snackBarConfig.data;
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {}

    /**
     * Dismiss the snackbar.
     */
    close() {
        this.snackbarRef.dismiss();
    }

    /**
     * Show/hide info about the error
     */
    displayErrorInfo() {
        this.showFullInfo = !this.showFullInfo;
    }
}
