<div class="flex flex-col">
    <div class="title-wrapper flex justify-between items-center">
        <div>
            <span class="title"><i class="fa fa-file icon-w-text"></i>Upload file</span>
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times icon-close"></i>
        </button>
    </div>

    <div class="upload-file-dialog-wrapper flex flex-col w-full h-full">
        <div class="file-data flex flex-col">
            <span>Supported file types: </span>
            <ul>
                <li><span>One GeoJSON (.geojson or .json file).</span></li>
                <li>
                    <span>
                        One .zip containing the shapefile (.shp, .shx, .dbf, .prj and optional .cpg
                        files).
                    </span>
                </li>
            </ul>
            <span>Maximum file size: 50MB.</span>

            <div class="flex justify-end items-center">
                <label
                    class="mat-raised-button mat-accent flex justify-start items-center"
                    for="map-upload-geojson-dialog"
                >
                    <i class="fa fa-upload" style="margin-right: 5px" aria-hidden="true"></i>
                    <span>Upload file </span>
                </label>
                <input
                    id="map-upload-geojson-dialog"
                    type="file"
                    style="display: none"
                    (change)="importRegion($event)"
                />
            </div>
        </div>
    </div>
</div>
