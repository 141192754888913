<div class="label-management-wrapper flex flex-col">
    <div class="dialog-header flex justify-between items-center">
        <div>
            <span class="title">Label Management</span>
        </div>
        <div class="flex justify-end items-center">
            <button
                class="dismiss-button !min-w-[50px]"
                mat-raised-button
                color="warn"
                (click)="close()"
            >
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
    <div class="content flex flex-col">
        <div class="labels-data flex flex-col gap-4">
            <h4>Your Labels</h4>
            <span *ngIf="labelPool && labelPool.length === 0">You don't have any label yet.</span>
            <mat-chip-listbox [selectable]="false" class="chip-list">
                <mat-chip-option
                    class="label justify-center items-center"
                    *ngFor="let label of labelPool"
                    [ngStyle]="{
                        color: label.color,
                        backgroundColor: label.color
                    }"
                    (click)="openEditLabel($event, label)"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="deleteUserLabel(label)"
                    [matTooltip]="label.description"
                >
                    <span>{{ label.title }}</span>
                    <span>
                        <mat-icon class="fa fa-pencil edit-label-icon"></mat-icon>
                        <mat-icon matChipRemove class="fa fa-times"></mat-icon>
                    </span>
                </mat-chip-option>
            </mat-chip-listbox>
        </div>
        <div class="new-label-form flex flex-col">
            <h4>New Label</h4>
            <form [formGroup]="newLabelForm">
                <mat-form-field>
                    <input
                        matInput
                        [matTooltip]="'Title'"
                        placeholder="Title"
                        formControlName="title"
                    />
                    <mat-error>A title for the label is required.</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        [matTooltip]="'Description'"
                        placeholder="Description"
                        formControlName="description"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        [matTooltip]="'Color'"
                        placeholder="Color"
                        formControlName="color"
                    />
                </mat-form-field>
                <color-github
                    class="color-picker"
                    color="newLabelForm['color']"
                    (onChangeComplete)="colorChanged($event)"
                ></color-github>
            </form>
        </div>
        <div class="dialog-actions flex justify-end items-end">
            <button
                class="roi-accept"
                mat-raised-button
                (click)="saveLabel()"
                color="accent"
                [disabled]="newLabelForm.invalid"
            >
                <div class="flex justify-center items-center">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;Add label
                </div>
            </button>
        </div>
    </div>
</div>
