import { round } from './utils';

/**
 * Square meters to hectares.
 * @param n: number
 */
export function squareMetersToHectares(n: number) {
    return n / 10000;
}

/**
 * Square meters to acres.
 * @param n: number
 */
export function squareMetersToAcres(n: number) {
    return n * 0.00024710538146717;
}

export function getAreaRepr(area: number, unit: 'acres' | string | undefined): string {
    const areaUnit = getAreaUnitDisp(unit);
    const newArea = getAreaValue(area, areaUnit);
    return `${round(newArea, 2)}${areaUnit}`;
}

/**
 * Get transformed value in user's area unit
 *
 * @param area in m2 to transform
 * @param unit: unit as stored in DB or as returned by getAreaUnitDisp
 */
export function getAreaValue(area: number, unitDisp: string): number {
    if (unitDisp.startsWith('ac')) {
        return squareMetersToAcres(area);
    }
    return squareMetersToHectares(area);
}

/**
 * Get area unit displayed
 */
export function getAreaUnitDisp(areaUnit: 'acres' | string | undefined): 'ac' | 'ha' {
    return areaUnit === 'acres' ? 'ac' : 'ha';
}
