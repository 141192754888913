import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { Product } from '../../models/product';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';
import { ProductService } from '../../services/product.service';
import * as L from 'leaflet';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Moment } from 'moment';

export interface AnimationApiRequestDialogData {
    product: Product | string | undefined;
    dateStart: Moment;
    dateEnd: Moment;
    duration: number;
    notificationEmail: string;
    baseLayer: string;
}

export type AnimationApiRequestDialogResult = AnimationApiRequestDialogFormResult | undefined;

export interface AnimationApiRequestDialogFormResult {}

@Component({
    selector: 'app-animation-api-request-dialog',
    templateUrl: './animation-api-request-dialog.component.html',
    styleUrls: ['./animation-api-request-dialog.component.scss'],
})
export class AnimationApiRequestDialogComponent implements OnInit {
    /**
     * Observable for user list query
     */
    filteredProducts$: Observable<Product[]>;

    /**
     * User products list
     */
    products: Product[];

    /**
     * Variable to store the current base layer to be used for the animation
     */
    currentBaseLayer = 'open-street-map';

    /**
     * Base layers included
     */
    public baseLayers: any = {
        'open-street-map': {
            label: 'OpenStreetMap',
            layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: '...',
            }),
            openStreetMapBaseLayer: true,
        },
    };

    /**
     * Time series API request form.
     */
    public animationApiRequestForm: UntypedFormGroup;

    /**
     * Perform the component initial configuration and load the products.
     */
    constructor(
        public dialogRef: MatDialogRef<
            AnimationApiRequestDialogComponent,
            AnimationApiRequestDialogResult
        >,
        @Inject(MAT_DIALOG_DATA) public data: AnimationApiRequestDialogData,
        private productService: ProductService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    ngOnInit() {
        const product = this.data.product ? this.data.product : undefined;
        this.animationApiRequestForm = new UntypedFormGroup(
            {
                start_date: new UntypedFormControl(this.data.dateStart, Validators.required),
                end_date: new UntypedFormControl(this.data.dateEnd, Validators.required),
                duration: new UntypedFormControl(this.data.duration, Validators.required),
                product: new UntypedFormControl(product, Validators.required),
                notify: new UntypedFormControl(this.data.notificationEmail),
                base_layer: new UntypedFormControl(this.data.baseLayer, Validators.required),
            },
            {
                validators: [this.startBeforeEnd('start_date', 'end_date', 'endBeforeStart')],
            }
        );
        this.productService.list(true).subscribe((data: any) => {
            this.products = data;

            this.filteredProducts$ = this.animationApiRequestForm.get('product').valueChanges.pipe(
                startWith(''),
                debounceTime(100),
                distinctUntilChanged(),
                map((query) => this.filterProducts(query))
            );
        });
    }

    /**
     * Close the dialog.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Close the dialog and emit params
     */
    save() {
        this.googleAnalyticsService.eventEmitter(
            'new_animation_request',
            'animation',
            'animation_request_submit',
            'animation_request_submitted'
        );
        this.dialogRef.close(this.animationApiRequestForm.value);
    }

    /**
     * Filter product list
     *
     * @param query
     */
    private filterProducts(query): Product[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof Product) {
            query = query.name;
        }
        const filterValue = query.toLowerCase();
        return this.products.filter((prod) => {
            return prod.name && prod.name.toLowerCase().includes(filterValue);
        });
    }

    /**
     * Returns product name for display
     */
    displayProduct(prod: Product): string {
        return prod ? `${prod.name}` : undefined;
    }

    /**
     * Check if end date is greater than start date
     * @param startKey
     * @param endKey
     * @param errorKey
     */
    startBeforeEnd(startKey: string, endKey: string, errorKey: string): ValidatorFn {
        return (control: UntypedFormControl): { [key: string]: boolean } | null => {
            if (!control) {
                return null;
            }

            const start = control.get(startKey);
            const end = control.get(endKey);
            if (!start.value || !end.value) {
                return null;
            }

            const startDate = new Date(start.value);
            const endDate = new Date(end.value);

            if (!this.isValidDate(startDate) || !this.isValidDate(endDate)) {
                return null;
            } else if (startDate > endDate) {
                const obj = {};
                obj[errorKey] = true;
                return obj;
            }
            return null;
        };
    }

    /**
     * Check if date is valid
     * @param date
     */
    private isValidDate(date: any): boolean {
        return date instanceof Date && !isNaN(Number(date));
    }
}
