<div class="reset-password-wrapper flex flex-col justify-center items-center">
    <mat-card appearance="outlined" class="p-8 flex flex-col justify-center items-center">
        <app-landing-logo></app-landing-logo>
        <ng-container *ngIf="success$ | async; let success; else: initialForm">
            <mat-card-content>
                <p>{{ success }}</p>
                <p>You can now close this window.</p>
            </mat-card-content>
        </ng-container>
    </mat-card>
</div>

<!--
     Ideally, this would be implemented with ngSwitch, but type
     narrowing doesn't work: https://github.com/angular/angular/issues/20780
     Apart from that, type narrowing doesn't work on status?.type either,
     so we do status && status.type === '<status type>'
-->
<ng-template #initialForm>
    <ng-container *ngIf="status && status.type === 'no-token'">
        <mat-card-subtitle>Set password</mat-card-subtitle>
        <mat-card-content>
            <form
                novalidate
                (ngSubmit)="restorePassword(status.usernameControl.value)"
                class="flex flex-col justify-center items-center gap-4"
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Username"
                        type="text"
                        [formControl]="status.usernameControl"
                        name="username"
                    />
                </mat-form-field>
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="status.usernameControl.invalid || sending"
                    color="accent"
                    class="w-100"
                >
                    Next
                </button>
            </form>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="status && status.type === 'invalid-token'">
        <mat-card-subtitle> This link is invalid. You can request another one. </mat-card-subtitle>
        <mat-card-content>
            <form
                novalidate
                (ngSubmit)="restorePassword(status.usernameControl.value)"
                class="flex flex-col justify-center items-center gap-4"
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Username"
                        type="text"
                        [formControl]="status.usernameControl"
                        name="username"
                    />
                </mat-form-field>
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="status.usernameControl.invalid || sending"
                    color="accent"
                    class="w-100"
                >
                    Next
                </button>
            </form>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="status && status.type === 'expired-token'">
        <mat-card-subtitle>
            This link has expired, resend a link to change the password?
        </mat-card-subtitle>
        <mat-card-content>
            <form
                novalidate
                (ngSubmit)="restorePassword(status.usernameControl.value)"
                class="flex flex-col justify-center items-center gap-4"
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Username"
                        type="text"
                        [formControl]="status.usernameControl"
                        name="username"
                        autocomplete="username"
                    />
                </mat-form-field>
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="status.usernameControl.invalid || sending"
                    color="accent"
                    class="w-100"
                >
                    Next
                </button>
            </form>
        </mat-card-content>
    </ng-container>
    <ng-container *ngIf="status && status.type === 'valid-token'">
        <mat-card-subtitle>Set password</mat-card-subtitle>
        <mat-card-content>
            <form
                novalidate
                [formGroup]="status.setPasswordForm"
                (ngSubmit)="resetPassword(status)"
                class="flex flex-col justify-center items-center gap-4"
            >
                <mat-form-field>
                    <input
                        matInput
                        placeholder="New password"
                        type="password"
                        formControlName="password"
                        name="password"
                        autocomplete="new-password"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Repeat password"
                        type="password"
                        formControlName="repeatPassword"
                        name="repeatPassword"
                        autocomplete="new-password"
                    />
                </mat-form-field>
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="status.setPasswordForm.invalid || sending"
                    color="accent"
                    class="w-100"
                >
                    Set password
                </button>
            </form>
        </mat-card-content>
        <mat-card-footer
            [ngStyle]="{
                opacity: status.setPasswordForm.errors?.matchPassword ? 1 : 0
            }"
            class="err-text"
        >
            Passwords don't match.
        </mat-card-footer>
    </ng-container>
</ng-template>
