import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'app-month-day-selector',
    templateUrl: './month-day-selector.component.html',
    styleUrls: ['./month-day-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MonthDaySelectorComponent,
            multi: true,
        },
    ],
})
export class MonthDaySelectorComponent implements ControlValueAccessor {
    /**
     * Selected month
     */
    public monthSelected: number;

    /**
     * Disabled state
     */
    public isDisabled = false;

    /**
     * List of months {id, name} to display in the selector
     */
    public months: Array<object>;

    /**
     * Registered function for change event
     */
    private registeredChange: any;

    /**
     * Default constructor
     */
    constructor() {
        this.months = [];
        // create list of months {id, name}
        for (let i = 0; i < 12; i++) {
            this.months.push({
                id: i,
                name: moment([2016, i, 1]).format('MMM'),
            });
        }
    }

    /**
     * Implementation of ControlValueAccessor interface
     * @param {number} value: the day of year [1, 366]
     */
    writeValue(value: number): void {
        if (value) {
            if (value >= 1 && value <= 366) {
                this.monthSelected = this.dayOfYearToMonth(value);
                this.onChange(this.monthSelected);
            }
        }
    }

    /**
     * Select value change event
     *
     * @param {number} event: the selected month id
     */
    public onChange(event) {
        const day = this.monthToDayOfYear(event);
        this.registeredChange(day); // call the registered function
    }

    /**
     * Implementation of ControlValueAccessor interface
     * @param fn
     */
    registerOnChange(fn: any): void {
        this.registeredChange = fn; // copy the received function
    }

    /**
     * Implementation of ControlValueAccessor interface
     * @param fn
     */
    registerOnTouched(fn: any): void {
        // nothing to do here
    }

    /**
     * Implements set disabled state
     * @param {boolean} isDisabled
     */
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Convert day of year to month
     *
     * @param {number} day [1, 366]
     */
    private dayOfYearToMonth(day: number): number {
        return moment([2016, 0, 1]).dayOfYear(day).month();
    }

    /**
     * Convert month to day of year
     *
     * @param {number} month [0, 11]
     */
    private monthToDayOfYear(month: number): number {
        return moment([2016, 0, 1]).month(month).dayOfYear();
    }
}
