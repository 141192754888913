import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { SimpleSnackbarComponent } from './simple-snackbar/simple-snackbar.component';
import { ReleaseSnackbarComponent } from './release-snackbar/release-snackbar.component';

/**
 * Snackbar message type.
 */
type SnackbarType = 'error' | 'success' | 'warning' | 'info';

/**
 * Shape files Service
 *
 * This service retrieves the region files from the API
 */
@Injectable()
export class CustomSnackbarService extends MatSnackBar {
    /**
     * Method that presents the snackbar, with a static duration
     * @param {string} message Message to show
     * @param {SnackbarType} type Message type.
     * @returns {MatSnackBarRef<SimpleSnackbarComponent>} Reference to the snackbar
     */
    public present(
        message: string,
        type: SnackbarType = 'success',
        status?: string,
        request?: string,
        config: MatSnackBarConfig = {}
    ): MatSnackBarRef<SimpleSnackbarComponent> {
        const defaultConfig = <MatSnackBarConfig>{
            data: {
                message: message,
                status: status,
                request: request,
            },
        };

        if (type === 'success') {
            defaultConfig.panelClass = 'custom-snackbar-success';
            defaultConfig.duration = 2000;
            defaultConfig.data.icon = 'fa fa-check';
            defaultConfig.data.showTooltipText = 'Show info';
            defaultConfig.data.hideTooltipText = 'Hide info';
        } else if (type === 'error') {
            defaultConfig.panelClass = 'custom-snackbar-error';
            defaultConfig.data.icon = 'fa fa-exclamation-triangle';
            defaultConfig.data.showTooltipText = 'Show error info';
            defaultConfig.data.hideTooltipText = 'Hide error info';
        } else if (type === 'warning') {
            defaultConfig.panelClass = 'custom-snackbar-warning';
            defaultConfig.data.icon = 'fa fa-exclamation-triangle';
            defaultConfig.duration = 10000;
            defaultConfig.data.showTooltipText = 'Show warning info';
            defaultConfig.data.hideTooltipText = 'Hide warning info';
        } else if (type === 'info') {
            defaultConfig.panelClass = 'custom-snackbar-info';
            defaultConfig.data.icon = 'fa fa-info-circle';
            defaultConfig.duration = 2000;
            defaultConfig.data.showTooltipText = 'Show info';
            defaultConfig.data.hideTooltipText = 'Hide info';
        }

        return this.openFromComponent(SimpleSnackbarComponent, {
            ...defaultConfig,
            ...config,
            data: { ...defaultConfig.data, ...config.data },
        });
    }

    public release(): MatSnackBarRef<ReleaseSnackbarComponent> {
        const matSnackbarOptions = <MatSnackBarConfig>{};
        matSnackbarOptions.panelClass = 'custom-snackbar-release';
        matSnackbarOptions.duration = 10000;

        return this.openFromComponent(ReleaseSnackbarComponent, matSnackbarOptions);
    }
}
