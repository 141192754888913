import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeunderscore' })
export class RemoveUnderScorePipe implements PipeTransform {
    transform(value: string) {
        if (value) {
            return value.replace(/_/g, ' ');
        } else {
            return value;
        }
    }
}
