<mat-card appearance="outlined" class="!flex flex-col">
    <!-- KPI title -->
    <mat-card-header class="!flex justify-between leading-4 max-h-12 items-center">
        <mat-card-title class="!flex justify-between gap-1">
            <div class="display-name">
                {{ kpi ? kpi.display_name : '' }}
            </div>
        </mat-card-title>

        <div *ngIf="edit">
            <button
                *ngIf="mode === 'delete'"
                mat-icon-button
                (click)="onDelete()"
                matTooltip="Delete"
                matTooltipPosition="above"
            >
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>

            <button
                *ngIf="mode === 'add'"
                mat-icon-button
                (click)="onAdd()"
                matTooltip="Add"
                matTooltipPosition="above"
            >
                <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    </mat-card-header>

    <!-- KPI content depends on kpi.display_type -->

    <div *ngIf="kpi && kpi.display_type === 'gauge'" class="p-0 flex justify-center items-center">
        <nvd3 #chartgauge [options]="chartGauge.options"></nvd3>
    </div>

    <div
        *ngIf="kpi && kpi.display_type === 'gauge_needle'"
        class="p-0 flex justify-center items-center"
    >
        <nvd3 #chartgauge [options]="chartRelativeAnomalyGauge.options"></nvd3>
    </div>

    <mat-card-content
        *ngIf="kpi && kpi.display_type === 'numerical'"
        class="flex justify-center items-center"
    >
        <div class="flex flex-col grow justify-center items-center">
            <div class="kpi flex justify-center items-center">
                <span class="kpi-value">{{ kpi.value['value'] }}</span>
                <span class="kpi-unit">{{ kpi.value['unit'] }}</span>
            </div>
        </div>
    </mat-card-content>

    <mat-card-content
        *ngIf="kpi && kpi.display_type === 'numerical_change'"
        class="flex justify-center items-center"
    >
        <div class="flex flex-col grow justify-center items-center">
            <div class="variation flex justify-center items-center gap-1" [hidden]="!kpi.value">
                <i class="fa fa-arrow-down" [hidden]="kpi.value['value'] > 0"></i>
                <i class="fa fa-arrow-up" [hidden]="kpi.value['value'] < 0"></i>
                <span>{{ kpi.value['value'] }}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>
