<div class="flex flex-col h-16 w-64 items-center">
    <mat-slider
        [appGradient]="legend.linearGradient()"
        class="my-legend-slider flex !w-56"
        [min]="product.getValueRange()[0]"
        [max]="product.getValueRange()[1]"
        [step]="(product.getValueRange()[1] - product.getValueRange()[0]) / 20"
        showTickMarks
        [formGroup]="range"
    >
        <input formControlName="min" matSliderStartThumb />
        <input formControlName="max" matSliderEndThumb />
    </mat-slider>
    <div class="flex justify-between items-center text-gray-500 text-xs leading-none gap-1 w-full">
        <div class="text-end" [ngStyle]="{ 'flex-grow': range.getRawValue().min - getMin() }">
            {{ range.getRawValue().min.toFixed(2) }} {{ product.unit }}
        </div>
        <div
            class="text-center"
            [ngStyle]="{ 'flex-grow': range.getRawValue().max - range.getRawValue().min }"
        ></div>
        <div class="text-start" [ngStyle]="{ 'flex-grow': getMax() - range.getRawValue().max }">
            {{ range.getRawValue().max.toFixed(2) }} {{ product.unit }}
        </div>
    </div>
</div>
