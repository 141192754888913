import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as L from 'leaflet';

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-map-preview',
    templateUrl: './map-preview.component.html',
    styleUrls: ['./map-preview.component.css'],
})

/**
 * Map preview Component
 */
export class MapPreviewComponent implements OnInit {
    /**
     * Event emitter that will trigger an event when the map is loaded.
     * @type {EventEmitter<Map>}
     */
    @Output() mapReady: EventEmitter<L.Map> = new EventEmitter<L.Map>();

    /**
     * Map height CSS.
     * @type {string}
     */
    @Input() height = '300px';

    /**
     * Map width CSS.
     * @type {string}
     */
    @Input() width = '500px';

    /**
     * Dom reference to the Leaflet map preview.
     */
    private map: L.Map; // Map DOM reference

    /**
     * Map preview options
     */
    public options = {
        zoom: 7,
        center: L.latLng([50.5, 25.5]),
    };

    /**
     * Base layers included
     */
    public baseLayers = {
        OpenStreetMap: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: '...',
        }),
    };

    /**
     * Angular default initializer
     */
    ngOnInit() {}

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;
        // Disable double click zoom -> show time series
        map.doubleClickZoom.disable();
        this.map.addLayer(this.baseLayers['OpenStreetMap']);
        this.mapReady.emit(map);
    }
}
