import { Component, NgZone, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import moment from 'moment';
import { Observable, of as observableOf, forkJoin, ReplaySubject } from 'rxjs';
import { throttleTime } from 'rxjs';

import { TimelineConfig } from '../timeline/timeline-config';
import { ProductAvailability } from '../../models/product-availability';
import { Product } from '../../models/product';
import { TimelineComponent } from '../timeline/timeline.component';
import { ViewportService } from '../../services/viewport.service';
import { ViewportData } from '../../models/viewport-data';
import { LayersService } from '../../services/layers.service';
import { ProductService } from '../../services/product.service';
import { getBrowser, getUTCDateWithoutConversion } from '../../utils/utils';
import { environment } from '../../environments/environment';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';

/**
 * Home component with the header and router-outlet for views
 */
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Selected date on timeline
     * @type {Date}
     */
    public selectedDate: Moment;

    /**
     * Selected product on timeline
     * @type {Product}
     */
    public selectedProduct: Product;

    /**
     * Reference to timeline component
     */
    @ViewChild('timeline', { static: true })
    private timelineComponent: TimelineComponent;

    /**
     * Reference to group product timeline component
     */
    @ViewChild('groupProductTimeline', { static: true })
    private groupProductsTimelineComponent: TimelineComponent;

    /**
     * Global product availability for selected product
     * @type {ProductAvailability}
     */
    private productAvailability: ProductAvailability = new ProductAvailability([]);

    /**
     * Product availability for selected product could be global or viewport based
     * @type {ProductAvailability}
     */
    public productAvailDisplayed: ProductAvailability = new ProductAvailability([]);

    /**
     * if there is availability data
     */
    public hasAvailabilityData: boolean;

    /**
     * viewport availability zoom level threshold
     */
    private readonly VIEWPORT_AVAIL_THR = 5;

    /**
     * RxJs DateShift click emitter.
     */
    public dateShift = new ReplaySubject<number>();

    public showHeader = false;

    /**
     * Cached product availability for selected product
     * @type {ProductAvailability}
     */
    productAvailabilityCache: ProductAvailability;

    /**
     * Flag to show/hide the progress bar when loading time line
     */
    loadingTimeline = false;

    /**
     * Selected layer
     */
    selectedLayer;

    /**
     * Browser data to show/hide unsupported browser message
     */
    public browserData;

    /**
     * Constructor
     */
    constructor(
        private productService: ProductService,
        private layersService: LayersService,
        private viewportService: ViewportService,
        private ngZone: NgZone,
        private snackBar: CustomSnackbarService
    ) {
        if (environment.headerConfig && environment.headerConfig.headerText) {
            this.showHeader = true;
        }
    }

    /**
     * OnInit
     */
    ngOnInit() {
        // When some layers get updated, check the selected date
        this.layersService.layers$.subscribe((layerStatus) => {
            if (layerStatus.emit) {
                if (layerStatus.selected === undefined) {
                    this.selectedLayer = undefined;
                    // no product selected (empty layer list)
                    this.selectedProduct = undefined;
                    if (this.timelineComponent) {
                        this.timelineComponent.setConfig(undefined);
                    }
                    return;
                }

                if (layerStatus.showLoadingTimeLineBar) {
                    this.loadingTimeline = true;
                }

                const layer = this.layersService.getSelectedLayer();

                this.getGroupAvailability(layer);
                let linkLayerKey;

                if (layerStatus.linked !== undefined || layerStatus.unlinked !== undefined) {
                    // get the key of the layer that has been linked/unlinked
                    linkLayerKey = layerStatus.linked
                        ? layerStatus.linked.key
                        : layerStatus.unlinked.key;
                }

                if (
                    this.layersService.isInLinkedLayers(layer.key) ||
                    (linkLayerKey !== undefined && linkLayerKey === layer.key)
                ) {
                    // if the selected layer is linked to others should update the time-lines displayed
                    return this.buildTimelineConfig({
                        product: layer.group ? layer.group.mainProduct : layer.product,
                        date: layer.getDate(),
                        availability: layer.availability,
                        layerKey: layer.key,
                    });
                }
                if (
                    this.selectedProduct &&
                    this.selectedProduct.apiName === layer.product.apiName &&
                    this.selectedDate.isSame(layer.getDate(), 'day')
                ) {
                    // if there is no data or we are receiving the same product/date
                    this.loadingTimeline = false;
                    return;
                }

                if (
                    this.selectedProduct &&
                    this.selectedProduct.apiName === layer.product.apiName
                ) {
                    // we are receiving the same product but different date so don't rebuild the time-line again
                    this.setDate(layer.getDate(), false); // just change date
                    return;
                }
                this.buildTimelineConfig({
                    product:
                        layer.group && layer.group.groupType === 'time_ordered_groups'
                            ? layer.group.mainProduct
                            : layer.product,
                    date: layer.getDate(),
                    availability: layer.availability,
                    layerKey: layer.key,
                });
            }
        });

        this.viewportService.watch().subscribe((data) => {
            if (data && this.selectedProduct !== undefined) {
                this.buildTimelineConfig({
                    product: this.selectedProduct,
                    date: this.selectedDate,
                    availability: this.productAvailability,
                    layerKey: this.layersService.getSelectedLayerKey(),
                });
            }
        });

        this.dateShift.pipe(throttleTime(200)).subscribe((increment: number) => {
            this.shiftSelectedDate(increment);
        });
    }

    ngAfterViewInit() {
        // Enable unsupported browser message
        this.browserData = getBrowser();
        if (!this.browserData.supported) {
            setTimeout(() =>
                this.snackBar.present(
                    this.browserData.name +
                        ' browser is not fully supported. Please use Chrome/Firefox for an optimal experience.',
                    'warning'
                )
            );
        }
    }

    /**
     * OnDestroy
     */
    ngOnDestroy(): void {
        this.viewportService.resetOutputViewport();
    }

    /**
     * Receive date selected event from timeline
     *
     * @param data
     */
    public timelineDateSelected(data: any): void {
        const date = data.date;
        if (typeof data.groupProductTimeline === 'boolean' && !data.groupProductTimeline) {
            if (!this.selectedDate.isSame(date, 'day')) {
                this.setDate(date);
            }
        }
    }

    /**
     * Return formatted forecast product value
     */
    getFormattedForecast() {
        let duration;
        if (this.layersService.isInLinkedLayers(this.selectedLayer.key)) {
            const linkedLayers = this.layersService.getLinkedLayers();
            const groupProductLayer = linkedLayers.find(
                (layer) => layer.product.productGroupValue !== undefined
            );
            if (groupProductLayer) {
                duration = moment.duration(groupProductLayer.product.productGroupValue).asHours();
            }
        } else {
            duration = moment.duration(this.selectedLayer.product.productGroupValue).asHours();
        }

        if (duration < 0 && this.selectedLayer.product.productGroupValue) {
            return ` ${duration}H`;
        } else if (duration > 0) {
            return ` +${duration}H`;
        } else {
            return '';
        }
    }

    updateTileLayer(layer, updateEmittedLayers = true) {
        this.layersService.updateTilelayer(layer.key);
        this.layersService.emitLayersUpdated(undefined, updateEmittedLayers);
    }

    /**
     * Set next date in the group product timeline
     * @param value
     */
    setNextGroupProduct(value) {
        const layerDate = this.groupProductsTimelineComponent.getNextPreviousDate(value);
        if (layerDate) {
            if (this.groupProductsTimelineComponent.config) {
                this.groupProductsTimelineComponent.setSelectedDate(layerDate);
            }
        }
    }

    /**
     * Receive date selected event from group product timeline
     *
     * @param event
     */
    groupProductTimelineDateSelected(event) {
        if (event.groupProductTimeline) {
            const layer = this.layersService.getSelectedLayer();
            const date = event.date;
            if (layer.linked) {
                for (const linkedLayer of this.layersService.getLinkedLayers()) {
                    this.setGroupLayerDate(linkedLayer, date);
                }
            } else {
                this.setGroupLayerDate(layer, date);
            }
        }
    }

    /**
     * Set date to the group product and update the availability
     * @param layer
     * @param date
     */
    setGroupLayerDate(layer, date) {
        const duration = date.diff(this.selectedDate, 'hours');
        if (layer.group) {
            const productIndex = layer.group.products.findIndex(
                (p) => moment.duration(p.productGroupValue).asHours() === duration
            );
            if (productIndex !== -1) {
                layer.product = layer.group.products[productIndex];
                layer.available = true;
            } else {
                layer.available = false;
            }
            if (this.groupProductsTimelineComponent.config) {
                this.groupProductsTimelineComponent.setSelectedDate(date);
            }
            this.updateTileLayer(layer, false);
        }
    }

    /**
     * Shift the selected date by the given increment
     *
     * @param {number} increment (positive or negative) in days
     */
    public shiftSelectedDate(increment: number): void {
        if (this.selectedDate === undefined) {
            return;
        }

        const selectedUTCDate = getUTCDateWithoutConversion(this.selectedDate.clone());

        const availability = this.productAvailDisplayed;
        let date = availability.getOffsetBound(selectedUTCDate, increment);

        if (date === null) {
            const bound = increment > 0 ? 'upper' : 'lower';
            date = availability.findNearestDateBound(selectedUTCDate, bound);
        }

        this.setDate(date);
    }

    /**
     * Build time-line config object
     *
     * @param {object} prodSelectData
     */
    private buildTimelineConfig(prodSelectData) {
        const product = prodSelectData.product;
        const selectedDate = prodSelectData.date;
        const availability = prodSelectData.availability;
        const layerKey = prodSelectData.layerKey;

        // request product availability based on viewport and zoom level
        let viewport = this.viewportService.get();
        if (viewport !== undefined) {
            viewport = viewport.zoom >= this.VIEWPORT_AVAIL_THR ? viewport : undefined;
        }

        let products = [];
        let availObs: Observable<Array<ProductAvailability>>;
        let cachedAvailabilityArray = [];
        if (this.layersService.isInLinkedLayers(layerKey)) {
            products = this.uniqueProducts(
                this.layersService
                    .getLinkedLayers()
                    .map((l) => (l.group ? l.group.mainProduct : l.product))
            );
            availObs = this.getAvailabilities(products, viewport);
            cachedAvailabilityArray = this.getCachedAvailabilities(products);
        } else {
            if (product !== undefined) {
                products.push(product);
            }
            if (viewport === undefined) {
                availObs = observableOf([availability]);
            } else {
                availObs = this.getAvailabilities(products, viewport);
            }
            cachedAvailabilityArray = this.getCachedAvailabilities(products);
        }
        const layer = this.layersService.get(layerKey);
        this.productAvailabilityCache = this.productService.getProductAvailabilityCache(
            layer.product.apiName
        );
        if (this.productAvailabilityCache) {
            this.selectedDate = layer.date;
        }

        // The time bar is first set with the cached data
        this.setTimeBar(
            product,
            availability,
            products,
            cachedAvailabilityArray,
            layerKey,
            viewport,
            selectedDate,
            false
        );

        // Once the request is finished, the time bar is set with the real data
        availObs.subscribe((availabilities) => {
            this.setTimeBar(
                product,
                availability,
                products,
                availabilities,
                layerKey,
                viewport,
                selectedDate,
                true
            );
        });
    }

    /**
     * Get product availabilities
     * @param {Array<Product>} products
     * @param {ViewportData} viewport
     * @returns {Observable<Array<ProductAvailability>>}
     */
    private getAvailabilities(
        products: Array<Product>,
        viewport: ViewportData
    ): Observable<Array<ProductAvailability>> {
        const promises: Observable<ProductAvailability>[] = [];

        products.forEach((p) => {
            promises.push(this.productService.getAvailability(p.apiName, viewport));
        });

        return forkJoin(promises);
    }

    /**
     * Get cached product availabilities
     * @param {Array<Product>} products
     * @returns {<Array<ProductAvailability>>}
     */
    private getCachedAvailabilities(products: Array<Product>): Array<ProductAvailability> {
        const productsAvailability = [];
        products.forEach((p) => {
            const productAvailability = this.productService.getProductAvailabilityCache(p.apiName);
            if (productAvailability) {
                productsAvailability.push(productAvailability);
            }
        });

        return productsAvailability;
    }

    /**
     * remove duplicates from products array
     */
    private uniqueProducts(products: Array<Product>) {
        const product_names = {};
        return products.filter((p) => {
            return product_names.hasOwnProperty(p.name) ? false : (product_names[p.name] = true);
        });
    }

    /**
     * Build time-bars for the given products and availabilities
     * @param {Array<Product>} products
     * @param {Array<ProductAvailability>} availabilities
     * @param {Moment} startDate
     * @returns {Array<object>}
     */
    private buildTimeBars(
        products: Array<Product>,
        availabilities: Array<ProductAvailability>,
        startDate?: Moment
    ): Array<object> {
        return products.map((p, i) => {
            const e = { prod: p, avail: availabilities[i] }; // join product and availabilities
            const dateTimes = e.avail.availability; // the time-line component uses the array of moment
            let timeBarStartDate;
            if (dateTimes.length > 0) {
                if (dateTimes.length === 1) {
                    const startDateProduct = dateTimes[0].clone();
                    timeBarStartDate = startDateProduct.add(-0.1, 'hours');
                } else {
                    timeBarStartDate = dateTimes[0];
                }
            } else {
                timeBarStartDate = startDate ? startDate : undefined;
            }
            return {
                name: this.safeTimebarName(e.prod.name),
                label: e.prod.apiName,
                startDate: timeBarStartDate,
                endDate: dateTimes.length > 0 ? dateTimes[dateTimes.length - 1] : undefined,
                dateTimes: dateTimes,
            };
        });
    }

    /**
     * Event listener for timeline datepicker
     *
     * @param newDate
     */
    public onDateSelected(newDate: MatDatepickerInputEvent<Moment>) {
        this.setDate(newDate.value);
    }

    /**
     * Set app date.
     * @param date
     * @param emit
     */
    public setDate(date: Moment, emit = true) {
        this.selectedDate = date;
        if (this.timelineComponent.config) {
            this.timelineComponent.setSelectedDate(date);
        }

        if (emit) {
            this.layersService.setSelectedDate(date);
        }
    }

    /**
     * Receive zoom changed event from time-line
     *
     * @param data
     */
    public timelineZoomChanged(data: any) {
        this.layersService.setZoomDate(data.startDate, data.endDate);
    }

    /**
     * Generate a safe timebar name for timeline component
     * @param name
     */
    private safeTimebarName(name: string): string {
        return name.replace(/[\s\\[\]\\(\\)!"#$%&'*+,.\\/:;<>=?@^|{}~`]/g, '_');
    }

    /**
     * Draw the timeline
     * @param product, selected product
     * @param productAvailability, selected product availability
     * @param products, list of unique products that have a layer
     * @param availabilities, list of product availabilities
     * @param layerKey, selected layer key
     * @param viewport, current viewport
     * @param selectedDate, selected date
     * @param apiData, if the data comes from cache or api
     */
    setTimeBar(
        product,
        productAvailability,
        products,
        availabilities,
        layerKey,
        viewport,
        selectedDate,
        apiData
    ) {
        const cachedMinBarHeight = 10;
        const cachedBaseBarHeight = 25;
        const productNames = {};
        products.forEach((p) => (productNames[p.name] = true));

        const currentConfig = this.timelineComponent
            ? this.timelineComponent.getConfig()
            : undefined;

        // variable that stores whether the current config has the same products
        const sameProducts =
            currentConfig !== undefined &&
            currentConfig.timeBars.length === products.length &&
            currentConfig.timeBars.every((e) => productNames.hasOwnProperty(e['name']));

        if (
            viewport === undefined && // not viewport based availability
            currentConfig !== undefined && // component config initialization not set
            currentConfig.tag !== 'viewport' && // the current config is for a global availability
            sameProducts === true
        ) {
            return; // the time-line config doesn't change -> skip unnecessary redraw
        }

        const layer = this.layersService.get(layerKey);

        const timelineConfig = <TimelineConfig>{
            comment: 'product timeline',
            selectedDate: selectedDate,
            chartMargins: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
            },
            labelWidth: 120,
            siblingsWidth: 0,
            barHeight:
                cachedBaseBarHeight / products.length > cachedMinBarHeight
                    ? cachedBaseBarHeight / products.length
                    : cachedMinBarHeight,
            barMargin: 2,
            tag: viewport === undefined ? '' : 'viewport',
            zoomStartDate: layer.zoomStartDate,
            zoomEndDate: layer.zoomEndDate,
            timeBars: this.buildTimeBars(products, availabilities),
        };

        const prodIndex = products.findIndex((p) => p.product_id === product.product_id);
        this.productAvailability = productAvailability;

        this.ngZone.run(
            // Change detection outside angular
            () => {
                this.hasAvailabilityData = timelineConfig.timeBars.some(
                    (e) => e['dateTimes'].length > 0
                );
                // set global availability or viewport based availability for selected product
                this.productAvailDisplayed =
                    viewport === undefined ? this.productAvailability : availabilities[prodIndex];
                if (this.productAvailabilityCache) {
                    timelineConfig.selectedDate = this.selectedDate;
                } else {
                    this.selectedDate = selectedDate;
                }

                this.selectedProduct = product;
            }
        );
        if (apiData) {
            this.loadingTimeline = false;
        }
        if (this.timelineComponent !== undefined) {
            this.timelineComponent.setConfig(timelineConfig);
        }
    }

    private buildGroupProductsTimelineConfig(prodSelectData) {
        const product = prodSelectData.product;
        const selectedDate = prodSelectData.date;
        const availability = prodSelectData.availability;
        let availabilities = [];
        const layerKey = prodSelectData.layerKey;

        // request product availability based on viewport and zoom level
        let viewport = this.viewportService.get();
        if (viewport !== undefined) {
            viewport = viewport.zoom >= this.VIEWPORT_AVAIL_THR ? viewport : undefined;
        }

        const products = [];
        if (this.layersService.isInLinkedLayers(layerKey)) {
            const layers = this.layersService.getLinkedLayers();
            const layerGroup = {};
            for (const layerT of layers) {
                if (layerT.group && layerT.group.groupType === 'time_ordered_groups') {
                    if (!layerGroup[layerT.group.groupName]) {
                        layerGroup[layerT.group.groupName] = true;
                        availabilities.push(new ProductAvailability(availability));
                    }
                    products.push(layerT.product);
                }
            }
        } else {
            products.push(product);
            availabilities = [availability];
        }
        const layer = this.layersService.get(layerKey);
        this.selectedDate = layer.date;
        this.loadingTimeline = false;
        // The time bar is first set with the cached data
        this.setGroupProductsTimeBar(
            product,
            availability,
            products,
            availabilities,
            layerKey,
            viewport,
            selectedDate,
            false
        );
    }

    setGroupProductsTimeBar(
        product,
        productAvailability,
        products,
        availabilities,
        layerKey,
        viewport,
        selectedDate,
        apiData
    ) {
        const cachedMinBarHeight = 10;
        const cachedBaseBarHeight = 25;
        const productNames = {};
        products.forEach((p) => (productNames[p.name] = true));

        const layer = this.layersService.get(layerKey);
        const layerDate = layer.getDate();
        const timelineConfig = <TimelineConfig>{
            comment: 'product timeline',
            selectedDate: selectedDate,
            chartMargins: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
            },
            labelWidth: 120,
            siblingsWidth: 0,
            barHeight:
                cachedBaseBarHeight / products.length > cachedMinBarHeight
                    ? cachedBaseBarHeight / products.length
                    : cachedMinBarHeight,
            barMargin: 2,
            tag: viewport === undefined ? '' : 'viewport',
            zoomStartDate: layer.zoomStartDate,
            zoomEndDate: layer.zoomEndDate,
            timeBars: this.buildTimeBars(products, availabilities, layerDate),
        };

        if (this.groupProductsTimelineComponent !== undefined) {
            this.groupProductsTimelineComponent.setConfig(timelineConfig);
        }
    }

    setForecastGroupAvailability(productGroup) {
        const promises: Observable<any>[] = [];
        for (const product of productGroup.group.products) {
            const product_date = productGroup.getDate().clone();
            const forecast_date = moment.duration(product.productGroupValue);
            const forecast_availability = getUTCDateWithoutConversion(
                product_date.clone().add(forecast_date)
            );
            promises.push(
                this.productService.checkProductAvailabilitySelectedDate(
                    product.apiName,
                    product_date,
                    forecast_availability
                )
            );
        }

        return forkJoin(promises);
    }

    /**
     * For a group product, get the availability of all the products
     * @param layer
     */
    getProductAvailabilities(layer) {
        return this.setForecastGroupAvailability(layer);
    }

    /**
     * Return true if the selected layer or a linked layer is a `time_orderder_groups` layer.
     */
    displayGroupProductTimeline() {
        if (
            this.selectedLayer &&
            this.selectedLayer.group &&
            this.selectedLayer.group.groupType === 'time_ordered_groups'
        ) {
            return true;
        } else if (
            this.selectedLayer &&
            this.layersService.isInLinkedLayers(this.selectedLayer.key)
        ) {
            const layers = this.layersService.getLinkedLayers();
            for (const layer of layers) {
                if (layer.group && layer.group.groupType === 'time_ordered_groups') {
                    return true;
                }
            }
        }
    }

    /**
     * Set groupAvailability and build the group products timelineConfig
     */
    setGroupAvailability(layer, productAvailabilities) {
        let groupAvailability = [];
        for (const productAvailability of productAvailabilities) {
            if (productAvailability['availability'].length > 0) {
                groupAvailability.push(productAvailability['forecastDate']);
            }
            groupAvailability = groupAvailability.sort((a, b) => a.valueOf() - b.valueOf());
            const timeLineDate = layer
                .getDate()
                .clone()
                .add(moment.duration(layer.product.productGroupValue));
            this.buildGroupProductsTimelineConfig({
                product: layer.product,
                date: timeLineDate,
                availability: new ProductAvailability(groupAvailability),
                layerKey: layer.key,
            });
        }
    }

    /**
     * Get group availabilities
     */
    getGroupAvailability(layer) {
        this.selectedLayer = layer;
        setTimeout(() => {
            if (this.layersService.isInLinkedLayers(layer.key)) {
                const linkedLayers = this.layersService.getLinkedLayers();
                for (const linkedLayer of linkedLayers) {
                    if (
                        linkedLayer.group &&
                        linkedLayer.group.groupType === 'time_ordered_groups'
                    ) {
                        this.getProductAvailabilities(linkedLayer).subscribe((response) => {
                            this.setGroupAvailability(linkedLayer, response);
                        });
                    }
                }
            } else {
                if (layer.group && layer.group.groupType === 'time_ordered_groups') {
                    // const sortedAvailability = this.getProductAvailabilities(layer);
                    this.getProductAvailabilities(layer).subscribe((response) => {
                        this.setGroupAvailability(layer, response);
                    });
                }
            }
        }, 0);
    }
}
