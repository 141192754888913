import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';

@Component({
    selector: 'app-account-activate',
    templateUrl: './account-activate.component.html',
    styleUrls: ['./account-activate.component.scss'],
})
export class AccountActivateComponent implements OnInit {
    /**
     * Token to authenticate the link.
     */
    public token: string;

    /**
     * Background image
     */
    public backgroundImage = 'url("/assets/img/blurred_world.jpg")';

    /**
     * Default constructor.
     * @param userService
     * @param activatedRoute
     * @param snackbar
     * @param router
     */
    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private snackbar: CustomSnackbarService,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.token = params.token;
        });
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.activateAccount();
    }

    /**
     * Activate user account.
     */
    activateAccount() {
        this.userService.activateAccount(this.token).subscribe(
            () => {
                this.snackbar.present('Account activated successfully.');
                setTimeout(() => {
                    this.router.navigate(['/login']);
                }, 500);
            },
            () => {
                this.router.navigate(['/login']);
            }
        );
    }
}
