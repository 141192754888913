import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmComponent } from './confirm/confirm.component';
import { DynamicDialog, DynamicLoaderComponent } from './dynamic-loader/dynamic-loader.component';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    /**
     * Default constructor.
     * @param {MatDialog} dialogService
     */
    constructor(private dialogService: MatDialog) {}

    /**
     * Open a confirm dialog with custom title, message and icon.
     * @param title
     * @param message
     * @param icon
     * @param confirmMessage
     * @param closeMessage
     * @return {MatDialogRef<ConfirmComponent, any>}
     */
    openConfirm(title, message, icon, confirmMessage?, closeMessage?) {
        const data = { title: title, message: message, icon: icon };
        if (confirmMessage) {
            data['confirmMessage'] = confirmMessage;
        }
        if (closeMessage) {
            data['closeMessage'] = closeMessage;
        }
        return this.dialogService.open(ConfirmComponent, {
            data: data,
            panelClass: 'generic-dialog-wrapper',
        });
    }

    /**
     * Open a dialog with a custom component inside.
     * @param componentType
     * @param customConfig
     */
    openComponent<T extends DynamicDialog<T, D, R>, D = any, R = any>(
        componentType: ComponentType<T>,
        customConfig?: { [key: string]: any; data?: D }
    ): MatDialogRef<DynamicLoaderComponent<T, D, R>, R> {
        const config = {
            data: { componentType } as any,
            panelClass: 'generic-dialog-wrapper',
        };

        if (customConfig !== undefined) {
            if (customConfig.data !== undefined) {
                config.data.childData = customConfig.data;
                delete customConfig.data;
            }

            Object.assign(config, customConfig);
        }

        return this.dialogService.open<DynamicLoaderComponent<T, D, R>, D, R>(
            DynamicLoaderComponent,
            config
        );
    }
}
