<div class="dialog-wrapper flex flex-col gap-4">
    <div class="title-wrapper flex flex-col justify-center items-center gap-4">
        <span class="title"> <i [class]="'icon-w-text fa ' + icon"></i>{{ title }}</span>
    </div>

    <div class="content flex flex-col gap-4">
        <div class="flex justify-center items-center">
            {{ message }}
        </div>

        <div class="flex justify-end items-center gap-4">
            <button mat-raised-button color="accent" class="flex items-center" (click)="accept()">
                <i class="icon-w-text fa fa-check"></i>
                <span>{{ confirmMessage }}</span>
            </button>
            <button mat-raised-button color="warn" class="flex items-center" (click)="cancel()">
                <i class="icon-w-text fa fa-times"></i>
                <span>{{ closeMessage }}</span>
            </button>
        </div>
    </div>
</div>
