import {
    Component,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { LayerData, LayerKey } from '../../models/layer-data';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, debounceTime, tap } from 'rxjs';
import { getISODateString } from '../../utils/utils';

import { Product } from '../../models/product';
import { LayersService } from '../../services/layers.service';
import { ProductInfoDialogComponent } from '../product-info-dialog/product-info-dialog.component';
import { Legend } from '../../models/legend';
import { LegendMode } from '../settings-sidenav/settings-sidenav.component';
import { DialogService } from '../dialog/dialog.service';
import { ProductService } from '../../services/product.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import moment from 'moment';
import type { RangeChangeEvent } from '../legend/legend.component';
import { CategoricalLegendComponent } from '../categorical-legend/categorical-legend.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

@Component({
    selector: 'app-layer',
    templateUrl: './layer.component.html',
    styleUrls: ['./layer.component.scss'],
})
export class LayerComponent implements OnInit, OnDestroy, OnChanges {
    /**
     * Layer info.
     */
    @Input() public layer: LayerData;

    /**
     * Bool to display the layers in the normal view or in the embed view
     * @type {boolean}
     */
    @Input() embed = false;

    /**
     * Reference to the Categorical Legend component.
     */
    @ViewChild(CategoricalLegendComponent)
    categoricalLegendComponent: CategoricalLegendComponent;

    /**
     * Layer legend.
     */
    public legend: Legend;

    /**
     * Categorical legend mode.
     */
    public legendMode: LegendMode = 'extended';

    /**
     * Categorical legend mode type.
     */
    categoricalLegendType: string = this.legendMode;

    /**
     * Current selected layer key.
     */
    public selectedLayerKey: LayerKey;

    /**
     * Layers watch observable stream.
     */
    private layersStream;

    /**
     * Subject that emits when the component has been destroyed.
     */
    protected _onDestroy = new Subject<void>();

    /**
     * List of products that belong to a group
     */
    protected productList: Product[];

    /**
     *  control for the selected product
     */
    public productListCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * Control for the MatSelect filter keyword
     */
    public productListFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * List of products filtered by search keyword
     */
    public filteredProductList: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);

    /**
     * Product selected in embed view. Products cannot be changed in this view
     */
    embedProduct: Product;

    protected layerOpacity$ = new Subject<number>();

    /**
     * Default constructor.
     * @param {LayersService} layersService
     * @param productService
     * @param {DialogService} dialogService
     * @param ngZone
     * @param router
     * @param googleAnalyticsService
     * @param {CustomSnackbarService} snackBar Snackbar service
     */
    constructor(
        private layersService: LayersService,
        private productService: ProductService,
        private ngZone: NgZone,
        private dialogService: DialogService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        private snackBar: CustomSnackbarService
    ) {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.selectedLayerKey = this.layersService.getSelectedLayerKey();

        this.layersStream = this.layersService.layers$.subscribe((layerStatus) => {
            this.ngZone.run(() => {
                this.selectedLayerKey = layerStatus.selected;
            });
        });

        if (this.layer.group) {
            this.productList = this.layer.group.products;
            const selectedProductIndex = this.layer.group.products.findIndex(
                (p) => p.apiName === this.layer.product.apiName
            );
            if (this.embed) {
                this.embedProduct = this.layer.group.products[selectedProductIndex];
            }
            this.productListCtrl.setValue(this.productList[selectedProductIndex]);
            this.filteredProductList.next(this.productList.slice());
            this.productListFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterProductList();
                });
        }

        this.layerOpacity$
            .pipe(
                tap((opacity) => {
                    this.layer.opacity = opacity;
                    if (this.layer.tileLayer) {
                        this.layer.tileLayer.setOpacity(opacity / 100);
                    }
                    if (this.layer.layer) {
                        this.layer.layer.setOpacity(opacity / 100);
                    }
                }),
                debounceTime(200)
            )
            .subscribe((opacity) => {
                this.googleAnalyticsService.eventEmitter(
                    'layer_opacity_slider_dragged',
                    'layer',
                    'layer_opacity',
                    (opacity / 100).toString()
                );
            });
    }

    /**
     * Angular lifecycle method.
     */
    ngOnChanges(changes) {
        if (changes.layer !== undefined) {
            this.updateLegend();
        }
    }

    /**
     * Layer click event.
     */
    onClick() {
        const showLoadingTimeLineBar = { showLoadingTimeLineBar: true };
        this.layersService.selectLayer(this.layer.key, showLoadingTimeLineBar);
    }

    /**
     * Check if the layer is loading.
     * @returns {boolean}
     */
    isLoading() {
        return this.layer.loading;
    }

    /**
     * Layer title date string.
     * @returns {string}
     */
    getLayerFormattedDate() {
        return getISODateString(this.layer.getDate());
    }

    /**
     * Enable/disable layer visibility.
     * @param $event
     */
    toggleVisibility($event) {
        $event.stopPropagation();

        this.layer.display = !this.layer.display;
        this.layersService.emitLayersUpdated();
        this.googleAnalyticsService.eventEmitter(
            'layer_eye_icon_clicked',
            'layer',
            'layer_visibility',
            this.layer.display.toString()
        );
    }

    /**
     * Pin the layer (keep it in detailed mode).
     * @param event Button click event
     */
    pinLayer(event) {
        event.stopPropagation(); // Don't select the layer
        this.layer.pinned = !this.layer.pinned;
        this.googleAnalyticsService.eventEmitter(
            'layer_pin_icon_clicked',
            'layer',
            'layer_pinned',
            this.layer.pinned.toString()
        );
    }

    /**
     * Updates the link layer status of the linked group.
     * @param event Button click event
     */
    linkLayer(event) {
        event.stopPropagation(); // Don't select the layer
        this.layer.linked = !this.layer.linked;
        this.layersService.updateLinkedLayers(this.layer);
        this.googleAnalyticsService.eventEmitter(
            'layer_chain_icon_clicked',
            'layer',
            'layer_linked',
            this.layer.linked.toString()
        );
    }

    /**
     * Delete layer.
     */
    deleteLayer() {
        this.layersService.delete(this.layer.key);
        this.googleAnalyticsService.eventEmitter(
            'layer_delete_icon_clicked',
            'layer',
            'layer_deleted',
            this.layer.key.toString()
        );
    }

    /**
     * Show/hide the border of the product allowed area.
     * @param event Button click event
     */
    showBorder(event) {
        event.stopPropagation(); // Don't select the layer
        this.layer.showBorder = !this.layer.showBorder;
        this.layersService.emitLayersUpdated();
    }

    /**
     * Open the product info dialog.
     * @param event
     */
    openProductInfoDialog(event) {
        event.stopPropagation();
        this.dialogService.openComponent(ProductInfoDialogComponent, {
            data: { product: this.layer.product },
        });
    }

    /**
     *  Legend change event handler.
     *  @param event received event, containing the new range
     */
    updateLegendRange(event: RangeChangeEvent) {
        this.layer.range = event;
        const currentUrl = this.router.url;
        const embedParam = currentUrl.split('embed?');
        const hash = embedParam.length > 1 ? embedParam[1] : undefined;
        this.layersService.updateTilelayer(this.layer.key, hash);
        this.layersService.emitLayersUpdated({ showLoadingTimeLineBar: false });
        this.googleAnalyticsService.eventEmitter(
            'layer_legend_range_slider_dragged',
            'layer',
            'layer_legend_slider',
            this.layer.range.toString()
        );
    }

    /**
     * Add a new legend to the legends object from a layer.
     */
    updateLegend() {
        const product = this.layer.product;
        if (product) {
            if (!this.embed) {
                this.productService.getProductLegend(product.apiName).subscribe((legend: any) => {
                    if (legend.data !== null) {
                        // Product without legend
                        this.legend = new Legend({
                            ...legend,
                            show: this.legend !== undefined ? this.legend.show : true,
                            currentRange: this.layer.range.slice(),
                        });
                        this.layersService.updateLayerLegend(this.layer.key, this.legend);
                    }
                });
            } else {
                this.productService
                    .getProductLegendInEmbedView(product.apiName, this.layer.hash)
                    .subscribe((legend: any) => {
                        if (legend.data !== null) {
                            // Product without legend
                            this.legend = new Legend({
                                ...legend,
                                show: this.legend !== undefined ? this.legend.show : true,
                                currentRange: this.layer.range.slice(),
                            });
                        }
                    });
            }
        }
    }

    /**
     * Angular lifecycle method.
     */
    ngOnDestroy() {
        this.layersStream.unsubscribe();
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    productSelected(selectedProduct) {
        const product = selectedProduct;
        if (product !== this.layer.product) {
            this.layer.product = product;
            this.productService
                .getAvailability(this.layer.product.apiName)
                .subscribe((result: any) => {
                    this.layer.availability = result;
                    if (this.layer.availability.availability.length > 0) {
                        const selectedDate = this.layer.getDate().clone();
                        const isDateInAvailability = result.availability.some((date) =>
                            moment(date.clone()).isSame(selectedDate, 'day')
                        );
                        if (!isDateInAvailability) {
                            this.snackBar.present(
                                'Date was changed to the nearest date with data availability',
                                'warning'
                            );
                            this.layer.setDate(result.availability[result.availability.length - 1]);
                        }
                    }

                    this.layersService.updateTilelayer(this.layer.key);
                    this.layersService.emitLayersUpdated();
                    this.updateLegend();
                    this.updateLegendRange(this.layer.product.getValueRange());
                });
        }
    }

    protected filterProductList() {
        if (!this.productList) {
            return;
        }
        // get the search keyword
        let search = this.productListFilterCtrl.value;
        if (!search) {
            this.filteredProductList.next(this.productList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the products
        this.filteredProductList.next(
            this.productList.filter((bank) => bank.name.toLowerCase().indexOf(search) > -1)
        );
    }

    /**
     * Return formatted forecast product value
     */
    getFormattedForecast() {
        const duration = moment.duration(this.layer.product.productGroupValue).asHours();
        if (duration > 0) {
            return `+${duration}H`;
        } else if (this.layer.product.productGroupValue && duration < 0) {
            return `${duration}H`;
        } else {
            return '';
        }
    }

    /**
     * Switch between expanded and collapsed mode in categorical legend
     */
    setCategoricalLegendStyle() {
        if (this.categoricalLegendComponent) {
            this.categoricalLegendComponent.updateCategoricalLegend();
            this.categoricalLegendType = this.categoricalLegendComponent.mode;
        }
    }
}
