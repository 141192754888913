<div class="flex flex-col justify-around items-center">
    <img class="login-img" src="assets/planet.png" />

    <div class="flex flex-col justify-around items-center">
        <h2 i18n [ngClass]="{ 'logo-text-subtitle': logoTextSubtitle !== undefined }">
            {{ logoText }}
        </h2>
        <h6 i18n *ngIf="logoTextSubtitle !== undefined" class="logo-subtitle">
            {{ logoTextSubtitle }}
        </h6>
    </div>
</div>
