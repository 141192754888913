/**
 * KPI class
 */
export class Kpi {
    /**
     * Name that identifies the KPI
     */
    public name: string;

    /**
     * Unit for the KPI values
     */
    public unit: string;

    /**
     * Type of graphic to display the KPI value
     */
    public display_type: string;

    /**
     * Display name for the KPI
     */
    public display_name: string;

    /**
     * Description of the KPI
     */
    public description: string;

    /**
     * Url where to retrieve the KPI
     */
    public url: string;

    /**
     * Value for the kpi
     */
    public value: object;

    /**
     * Default constructor
     * @param {object} kpi_data
     */
    constructor(kpi_data: object) {
        this.name = kpi_data['name'];
        this.unit = kpi_data['unit'];
        this.display_type = kpi_data['display_type'];
        this.display_name = kpi_data['display_name'];
        this.description = kpi_data['description'];
        this.url = kpi_data['url'];
    }

    /**
     * Set the KPI value
     *
     * @param data
     */
    public setValue(data: object[]): void {
        this.value = data[0];
    }

    /**
     * Get the value for the KPI
     */
    public getValue(): object {
        return this.value;
    }

    /**
     * Creates a copy of the Kpi
     *
     * @param value
     */
    public copy(): Kpi {
        const newKpi = new Kpi({
            name: this.name,
            unit: this.unit,
            display_type: this.display_type,
            display_name: this.display_name,
            description: this.description,
            url: this.url,
        });
        return newKpi;
    }

    /**
     * Set a zero kpi value
     */
    public setZero() {
        this.setValue([{ value: 0, name: this.name, unit: this.unit }]);
    }

    /**
     * Checks whether the kpi is included in the given array
     * @param {Array<Kpi>} array
     * @returns {boolean}
     */
    public inArray(array: Array<Kpi>): boolean {
        const index = this.findIndex(array);
        return index > -1;
    }

    /**
     * Find the index of the kpi in the given array
     * @param {Array<Kpi>} array
     * @returns {number}
     */
    public findIndex(array: Array<Kpi>): number {
        return array.findIndex((e) => e.name === this.name);
    }
}
