import { Component, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss'],
    animations: [
        trigger('fadeTransition', [
            state(
                'void',
                style({
                    opacity: 0,
                })
            ),
            transition('void <=> *', animate(1000)),
        ]),
    ],
})
export class LoadingDialogComponent {
    /**
     * Dict object containing pairs "element name" : "message to show if it is still loading"
     * which will be shown
     */
    public loadingMessages: {};

    /**
     * Dict object containing pairs "element name" : "message to show if it is still loading"
     * with the default values
     */
    private defaultLoadingMessages = {
        rois: 'regions',
        map: 'map',
    };

    /**
     * Variable to determine if the string 'Loading...' has to appear in the dialog message
     */
    private includeLoadingWord = true;

    /**
     * Actual string that is shown in the dialog
     */
    public get shownMessage(): string {
        const beginning = this.includeLoadingWord ? 'Loading... ' : '';
        return Object.keys(this.loadingMessages).length > 0
            ? `${beginning}${Object.values(this.loadingMessages).join(', ')}`
            : beginning;
    }

    /**
     * Number of message shown/elements that are being checked - for the current loading message
     */
    public messagesNumber: number;

    /**
     * Loading dialog constructor
     *
     * Establishes the initial configuration of the loading dialog and its message.
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.loadingMessages = this.defaultLoadingMessages;

        if (data.shareLink) {
            this.loadingMessages['link'] = 'share link';
        }

        if (data.evolutionLayers) {
            this.loadingMessages = {};
            this.loadingMessages['evolutionLayers'] = 'layers';
        }

        if (data.downloadEvolutionLayers) {
            this.includeLoadingWord = false;
            this.loadingMessages = {};
            this.loadingMessages['evolutionLayers'] = 'Preparing download';
        }

        if (data.loadingMessages) {
            this.includeLoadingWord = false;
            this.loadingMessages = data.loadingMessages;
        }

        this.messagesNumber = Object.keys(this.defaultLoadingMessages).length;
    }

    /**
     * If the message for the element with the given label is shown, then its removed from messages dictionary,
     * and the string for the dialog rebuilt
     */
    public updateLoadingMessage(elementLabel) {
        if (this.loadingMessages[elementLabel] && this.loadingMessages[elementLabel].length > 0) {
            delete this.loadingMessages[elementLabel];
            this.messagesNumber--;
        }
    }
}
