import { Product } from './product';
import * as L from 'leaflet';
import { Moment } from 'moment';

import { ProductAvailability } from './product-availability';
import { getUTCDateWithoutConversion } from '../utils/utils';

/**
 * Layer Key type.
 */
// export type LayerKey = Branded<number, 'LayerKey'>;
export type LayerKey = number;

/**
 * LayerData class
 */
export class LayerData {
    key: LayerKey;
    display: boolean;
    available: boolean;
    opacity: number;
    product?: Product;
    group?;
    tileLayer?: L.TileLayer;
    protected date: Moment;
    linked: boolean;
    showLegend: boolean;
    range: [number, number];
    loading: boolean;
    pinned: boolean;
    showBorder: boolean;
    availability: ProductAvailability;
    zoomStartDate: Moment;
    zoomEndDate: Moment;
    hash?: string;
    layer?;
    name?;
    legend?;
    layerName?: string;

    constructor(data?: object) {
        if (data !== undefined) {
            Object.assign(this, data);
        }
    }

    /**
     * If the layer should be displayed
     * @returns {boolean}
     */
    isVisible(): boolean {
        return this.display && this.available;
    }

    /**
     * Get the layer date.
     * @returns {moment.Moment}
     */
    getDate(): Moment {
        return this.date;
    }

    /**
     * Set the layer date.
     * @param {moment.Moment} date
     */
    setDate(date: Moment) {
        this.date = getUTCDateWithoutConversion(date);
        this.available = this.availability.isIncluded(this.date);
    }

    /**
     * Set the layer zoom dates
     * @param {moment.Moment} startDate
     * @param {moment.Moment} endDate
     */
    setZoomDate(startDate: Moment, endDate: Moment) {
        this.zoomStartDate = startDate;
        this.zoomEndDate = endDate;
    }
}
