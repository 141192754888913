import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, map } from 'rxjs';

import { Group } from '../../models/group';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

export interface GroupSelectorData {
    groups: Group[];
}

export type GroupSelectorResult = Group | undefined;

@Component({
    selector: 'app-group-selector',
    templateUrl: './group-selector.component.html',
    styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent
    implements
        OnInit,
        DynamicDialog<GroupSelectorComponent, GroupSelectorData, GroupSelectorResult>
{
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<GroupSelectorComponent, GroupSelectorData, GroupSelectorResult>,
        GroupSelectorResult
    >;

    public dialogData: GroupSelectorData;

    /**
     * Form control for input field
     * @type {FormControl}
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Observable for group list query
     */
    filteredGroups$: Observable<Group[]>;

    /**
     * Group selected in autocomplete
     */
    groupSelected: Group;

    constructor() {}

    ngOnInit() {
        this.filteredGroups$ = this.searchControl.valueChanges.pipe(
            startWith(''),
            debounceTime(100),
            distinctUntilChanged(),
            map((query) => this.filterGroups(query))
        );
    }

    /**
     * Closes the dialog and returns the selected user
     */
    closeOk() {
        this.dialogRef.close(this.groupSelected);
    }

    /**
     * Closes the dialog
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Returns user name for display
     */
    displayGroup(group: Group): string {
        return group ? `${group.groupName}` : undefined;
    }

    /**
     * Event for user selection in autocomplete
     *
     * @param {MatAutocompleteSelectedEvent} event
     */
    onGroupSelect(event): void {
        this.groupSelected = event.option.value;
    }

    /**
     * Filter user list
     *
     * @param query
     */
    private filterGroups(query): Group[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof Group) {
            query = query.groupName;
        }
        const filterValue = query.toLowerCase();
        return this.dialogData.groups.filter((group) => {
            return group.groupName.toLowerCase().includes(filterValue);
        });
    }
}
