<div class="flex flex-col h-full">
    <div *ngIf="showHeader" class="flex h-full max-h-20 justify-center items-center">
        <app-header class="header-wrapper"></app-header>
    </div>
    <div class="flex h-full relative">
        <div
            class="map-canvas"
            leaflet
            [leafletOptions]="options"
            [leafletLayers]="layers"
            (leafletMapReady)="onMapReady($event)"
        ></div>
        <div class="w-full h-full overflow-y-auto py-4 flex flex-col justify-start items-center">
            <h2 mat-dialog-title class="primary-color font-medium">Dashboard</h2>

            <h4 *ngIf="userProducts.length > 1" class="settings-title primary-color font-medium">
                Product & Region selection:
            </h4>
            <h4
                *ngIf="userProducts.length === 1"
                class="settings-title primary-color font-semibold"
            >
                Region selection:
            </h4>
            <div class="selector-wrapper flex justify-between items-center">
                <mat-form-field>
                    <mat-label>Region</mat-label>
                    <mat-select
                        class="simple-select"
                        label="Region"
                        [formControl]="roiCtrl"
                        (valueChange)="roiSelected($event)"
                    >
                        <mat-option>
                            <ngx-mat-select-search
                                [placeholderLabel]="'Input region name'"
                                [noEntriesFoundLabel]="'No region found'"
                                [formControl]="roiFilterCtrl"
                            >
                                <mat-icon ngxMatSelectSearchClear
                                    ><i class="fa fa-times"></i
                                ></mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let roi of filteredUserRois | async" [value]="roi">
                            {{ roi.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="userProducts.length > 1">
                    <mat-label>Product</mat-label>
                    <mat-select
                        class="simple-select"
                        label="Product"
                        [formControl]="userProductsCtrl"
                        (valueChange)="productSelected($event)"
                        data-testid="simple-product-select"
                    >
                        <mat-option data-testid="simple-product-search">
                            <ngx-mat-select-search
                                [placeholderLabel]="'Input product name'"
                                [noEntriesFoundLabel]="'No product found'"
                                [formControl]="userProductsFilterCtrl"
                            >
                                <mat-icon ngxMatSelectSearchClear
                                    ><i class="fa fa-times"></i
                                ></mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                            *ngFor="let product of filteredUserProducts | async"
                            [value]="product"
                            data-testid="simple-product-option"
                        >
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="userProducts.length === 1 && selectedProduct">
                    <mat-label>Product</mat-label>
                    <input matInput [disabled]="true" value="{{ selectedProduct.name }}" />
                </mat-form-field>

                <mat-icon class="share-icon" (click)="shareSimpleMap()">
                    <i class="fa fa-share-alt" matTooltip="Share map"></i>
                </mat-icon>
            </div>

            <mat-progress-spinner
                *ngIf="!currentRegionKpis"
                class=""
                [color]="'primary'"
                [mode]="'indeterminate'"
                [diameter]="50"
            >
            </mat-progress-spinner>
            <app-kpi-grid
                *ngIf="currentRegionKpis.length > 0"
                [kpis]="currentRegionKpis"
                [edit]="false"
                class="kpi-info"
            ></app-kpi-grid>

            <div class="time-series-wrapper flex">
                <mat-card appearance="outlined" class="flex flex-col w-full">
                    <mat-card-header class="flex justify-between items-center">
                        <mat-card-title> Time series </mat-card-title>
                        <div class="dashboard-time-series-buttons flex justify-end gap-4">
                            <button
                                mat-raised-button
                                [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                                (click)="exportAsPng()"
                                matTooltip="Export chart as PNG"
                            >
                                <i class="fa fa-image" aria-hidden="true"></i>
                            </button>
                            <button
                                mat-raised-button
                                [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                                (click)="exportAsCsv()"
                                matTooltip="Export series as CSV"
                            >
                                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                            </button>
                            <button
                                mat-raised-button
                                [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                                (click)="setDashboardChart()"
                                matTooltip="Reload time series"
                            >
                                <i class="fa fa-refresh" aria-hidden="true"></i>
                            </button>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="flex flex-col gap-2">
                            <div class="flex justify-around items-center mt-2">
                                <app-month-day-selector
                                    [style.visibility]="
                                        yearMode || yearModeOnly ? 'visible' : 'hidden'
                                    "
                                    [(ngModel)]="yearModeStart"
                                    [disabled]="!timeSeries.isLoaded"
                                ></app-month-day-selector>
                                <mat-slide-toggle
                                    color="accent"
                                    [(ngModel)]="yearMode"
                                    [disabled]="!timeSeries.isLoaded"
                                    *ngIf="!yearModeOnly"
                                >
                                    Year mode
                                </mat-slide-toggle>
                            </div>
                            <app-time-series
                                [withFocusChart]="true"
                                variant="dashboard"
                                [yearMode]="yearMode"
                                [yearModeStart]="yearModeStart"
                                [userSettings]="userSettings"
                            >
                            </app-time-series>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <h4 mat-dialog-title class="settings-title primary-color">
                Regions of interest settings:
            </h4>
            <div
                class="actions flex justify-start items-center gap-4 w-fit"
                appAccess="manage-rois"
            >
                <button
                    mat-raised-button
                    (click)="showRoiManagement()"
                    class="!flex justify-center items-center"
                >
                    <i class="fa fa-cogs"></i>
                    <span>ROI management</span>
                </button>

                <a class="label-button" appAccess="draw-roi" mat-raised-button>
                    <label class="flex justify-around items-center" for="map-upload-geojson">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        <span>Upload file</span>
                    </label>
                    <input
                        id="map-upload-geojson"
                        type="file"
                        class="hidden"
                        (change)="importRegion($event)"
                    />
                </a>
            </div>

            <div
                class="impersonate-column flex flex-col justify-start items-center"
                *ngIf="userForApi"
            >
                <h4 mat-dialog-title class="settings-title primary-color">
                    Impersonation settings:
                </h4>
                <div class="actions flex justify-around items-center gap-4">
                    <div class="flex">
                        <i aria-hidden="true"></i
                        ><span>&nbsp; Impersonating: {{ userForApi }}</span>
                    </div>
                    <button class="impersonate-button" mat-raised-button (click)="forgetUser()">
                        Forget
                    </button>
                </div>
            </div>
        </div>

        <div class="button-container flex flex-col gap-4 justify-start items-end max-w-[50%]">
            <button
                mat-mini-fab
                [matMenuTriggerFor]="menu"
                class="button-fab sidenav-settings-bttn"
                data-testid="simple-open-settings-menu"
            >
                <i class="fa fa-user"></i>
            </button>
            <button
                mat-mini-fab
                (click)="showInfoDialog()"
                *ngIf="infoDialogAvailable"
                class="button-fab sidenav-settings-bttn"
                aria-label="Show info dialog"
            >
                <i class="fa fa-info" aria-hidden="true"></i>
            </button>
            <mat-menu #menu="matMenu" class="settings-menu px-6" xPosition="before">
                <section class="flex flex-col">
                    <h2 mat-dialog-title class="settings-title primary-color font-medium">
                        User settings
                    </h2>
                    <div class="settings-button flex flex-col justify-center items-center">
                        <button mat-raised-button (click)="showPasswordDialog()">
                            <i class="fa fa-user"></i>
                            <span>Change password</span>
                        </button>
                    </div>
                </section>
                <!-- Superadmin - User selection button -->
                <section [appAccess]="['can-impersonate', 'can-impersonate-everybody']">
                    <h2 mat-dialog-title class="settings-title primary-color font-medium">
                        Impersonation
                    </h2>
                    <div class="settings-button flex flex-col justify-center items-center gap-2">
                        <button
                            mat-raised-button
                            matTooltip="Select an user"
                            (click)="showUserSelection()"
                        >
                            <i class="fa fa-sign-out"></i>
                            <span>Impersonate user</span>
                        </button>

                        <button
                            mat-raised-button
                            *ngIf="userForApi !== undefined"
                            (click)="forgetUser()"
                            matTooltip="Forget user"
                        >
                            <i class="fa fa-user-secret"></i>
                            <span>Forget {{ userForApi }}</span>
                        </button>
                    </div>
                </section>
                <section>
                    <h2 mat-dialog-title class="settings-title primary-color font-medium">
                        Logout
                    </h2>
                    <div class="settings-button flex flex-col justify-center items-center">
                        <button mat-raised-button (click)="logout()" data-testid="simple-logout">
                            <i class="fa fa-sign-out"></i>
                            <span>Logout</span>
                        </button>
                    </div>
                </section>
            </mat-menu>
        </div>
        <app-roi-sidenav
            *ngIf="userService.userSettings$ | async; let userSettings"
            [hidden]="true"
            [userSettings]="userSettings"
            (regionsShow)="showRegions($event)"
        >
        </app-roi-sidenav>
    </div>
</div>
