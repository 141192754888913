<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-user icon-w-text"></i>Change password</span>
    </div>
    <button
        *ngIf="!this.forceChange"
        class="!min-w-[50px]"
        mat-raised-button
        color="warn"
        (click)="close()"
    >
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div class="password-wrapper flex flex-col justify-center items-center">
    <form novalidate [formGroup]="passwordForm" class="flex flex-col justify-center items-center">
        <mat-form-field>
            <input
                matInput
                class="form-control"
                placeholder="Current password"
                type="password"
                formControlName="currentPassword"
                name="currentPassword"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                class="form-control"
                placeholder="New password"
                type="password"
                formControlName="password"
                name="password"
                required
            />
        </mat-form-field>
        <mat-form-field>
            <input
                matInput
                class="form-control"
                placeholder="Repeat password"
                type="password"
                formControlName="repeatPassword"
                name="repeatPassword"
                required
            />
        </mat-form-field>
    </form>

    <span class="err-text" [ngClass]="{ 'err-active': passwordForm.errors?.matchPassword }"
        >Passwords don't match.</span
    >

    <div class="flex justify-end items-center gap-4">
        <button mat-raised-button (click)="save()" [disabled]="passwordForm.invalid" color="accent">
            <div class="flex justify-center items-center"><i class="fa fa-save"></i>Save</div>
        </button>
    </div>
</div>
