<div class="dashboard-content flex flex-col justify-start items-center gap-4 pb-4">
    <div class="full-width flex justify-start items-center">
        <div class="grow-0">
            <button
                mat-icon-button
                matTooltip="Manage KPIs"
                matTooltipPosition="right"
                class="kpi-manage"
                (click)="onManageKpis()"
            >
                <i class="fa fa-gear"></i>
            </button>
        </div>

        <div class="region-info w-full flex flex-col justify-center items-center">
            <div class="flex justify-start items-center">
                <span class="product-title">{{ getRegionName() }}</span>
                <button
                    mat-icon-button
                    ngClass="info-button"
                    (click)="openRegionInfo()"
                    matTooltip="Show detailed ROI info"
                    matTooltipPosition="right"
                >
                    <i class="fa fa-info-circle"></i>
                </button>
            </div>

            <span class="product-subtitle">{{ productSelected.name }} - {{ getDate() }}</span>
        </div>

        <button
            mat-raised-button
            color="warn"
            matTooltip="Close dashboard"
            matTooltipPosition="left"
            class="close-sidenav-button"
            (click)="close()"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div *ngIf="!loadingKpis && (kpis?.length ?? -1 > 0)">
        <app-kpi-grid [kpis]="kpis"></app-kpi-grid>
    </div>

    <div *ngIf="loadingKpis" class="loading-spinner">
        <mat-spinner></mat-spinner>
    </div>

    <mat-card
        class="full-width flex flex-col"
        *appStructuralAccess="'can-create-roi-time-series-request'"
    >
        <mat-card-header class="flex justify-between items-center">
            <mat-card-title> Time series </mat-card-title>
            <div class="dashboard-time-series-buttons flex gap-4">
                <button
                    *ngIf="appTimeSeries.params"
                    mat-raised-button
                    (click)="showTimeSeriesParamsDialog()"
                    matTooltip="Parameters used to generate this time series"
                >
                    <i class="fa fa-info-circle"></i>
                </button>
                <button
                    mat-raised-button
                    (click)="handleTimeSeriesDialog()"
                    matTooltip="Create API request"
                >
                    New request
                </button>
                <button
                    mat-raised-button
                    [disabled]="!appTimeSeries.isLoaded"
                    (click)="exportAsPng()"
                    matTooltip="Export chart as PNG"
                >
                    <i class="fa fa-image" aria-hidden="true"></i>
                </button>
                <button
                    mat-raised-button
                    [disabled]="!appTimeSeries.isLoaded"
                    (click)="exportAsCsv()"
                    matTooltip="Export series as CSV"
                >
                    <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </button>
                <button
                    mat-raised-button
                    [disabled]="!appTimeSeries.isLoaded"
                    (click)="update(region)"
                    matTooltip="Reload time series"
                >
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
                <button
                    mat-raised-button
                    [disabled]="!appTimeSeries.isLoaded"
                    (click)="openTimeSeriesFullScreen()"
                    matTooltip="Advanced mode"
                >
                    Advanced
                </button>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div class="flex flex-col gap-2">
                <div class="flex justify-around items-center mt-2">
                    <app-month-day-selector
                        [style.visibility]="yearMode || yearModeOnly ? 'visible' : 'hidden'"
                        [(ngModel)]="yearModeStart"
                        [disabled]="!appTimeSeries.isLoaded"
                    ></app-month-day-selector>
                    <mat-slide-toggle
                        color="accent"
                        [(ngModel)]="yearMode"
                        [disabled]="!appTimeSeries.isLoaded"
                        *ngIf="!yearModeOnly"
                    >
                        Year mode
                    </mat-slide-toggle>
                </div>
                <app-time-series
                    [withFocusChart]="true"
                    variant="dashboard"
                    [yearMode]="yearMode"
                    [yearModeStart]="yearModeStart"
                    [userSettings]="userSettings"
                    #appTimeSeries
                >
                </app-time-series>
            </div>
        </mat-card-content>
    </mat-card>
</div>
