<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-users icon-w-text"></i>Users</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>
<mat-progress-bar
    mode="indeterminate"
    [class.invisible]="filteredUsers$ | async"
></mat-progress-bar>
<div class="flex flex-col gap-4">
    <div class="content flex flex-col">
        <mat-form-field>
            <input
                type="text"
                placeholder="Type user email or name"
                aria-label="User name"
                matInput
                class="search-user"
                [formControl]="searchControl"
                [matAutocomplete]="auto"
                tabindex="-1"
            />
        </mat-form-field>
        <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayUser"
            (optionSelected)="onUserSelect($event)"
        >
            <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                {{ displayUser(user) }}
            </mat-option>
        </mat-autocomplete>
    </div>

    <div class="button-bar flex justify-end items-center gap-4">
        <button
            mat-raised-button
            color="accent"
            class="popup-button-dash"
            (click)="closeOk()"
            [disabled]="!userSelected"
        >
            Select User
        </button>
    </div>
</div>
