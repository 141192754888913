import { Moment } from 'moment';
import moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { GeoJSON, Position } from 'geojson';

import { environment } from '../environments/environment';

// Constant for the precision of the geojson (decimal places for coordinates)
export const PRECISION = 15;

export const TOKEN_NAME = 'access_token';

export function getJwtToken(): string {
    const token = localStorage.getItem(TOKEN_NAME);
    return token && JSON.parse(token);
}

export function setJwtToken(accessToken: any): void {
    localStorage.setItem(TOKEN_NAME, JSON.stringify(accessToken));
}

export function deleteJwtToken(): void {
    localStorage.removeItem(TOKEN_NAME);
}

/** The name of the key that is stored in local storage for user impersonation */
const USER_FOR_API = 'user_for_api';

/** The name of the key that is stored in local storage for store a list of impersonated user permissions */
const USER_FOR_API_PERMISSIONS = 'user_for_api_permissions';

/** Get the user sent in api calls */
export function getUserForApi(): string | undefined {
    const val = localStorage.getItem(USER_FOR_API);
    return val === null || val === undefined ? undefined : val;
}

/** Get a list of impersonated user permissions */
export function getUserForApiPermissions(): string[] {
    const val = localStorage.getItem(USER_FOR_API_PERMISSIONS);
    return val === null || val === undefined ? [] : JSON.parse(val);
}

export function setUserForApi(userForApi: { username: string; permissions: string[] }) {
    localStorage.setItem(USER_FOR_API, userForApi.username);
    localStorage.setItem(USER_FOR_API_PERMISSIONS, JSON.stringify(userForApi.permissions));
}

export function deleteUserForApi() {
    // reset user
    localStorage.removeItem(USER_FOR_API);
    localStorage.removeItem(USER_FOR_API_PERMISSIONS);
}

/**
 * Encode an Object to URL params.
 *
 * E.g.: {'lat': 33, 'lng':-3} => '?lat=33&lng=-3'
 *
 * @param {Object} params
 * @returns {string}
 */
export function encodeObjectToURL(params: object): string {
    return `?${Object.entries(params)
        .map((x) => x.join('='))
        .join('&')}`;
}

/**
 * Converts a date to ISO format (YYYY-MM-DD)
 * @param date_obj
 */
export function getISODateString(date_obj: Moment): string | undefined {
    try {
        return date_obj.format('YYYY-MM-DD');
    } catch (ex) {}
}

/**
 * Converts a date to local format (based on browsers locale)
 * @param date_obj
 */
export function getLocaleDateString(date_obj: Date): string {
    return date_obj.toLocaleDateString();
}

/**
 * Converts stream data received from backend into D3/Chart.js ready data
 *
 * @param data {{date: string, value: string}}
 * @returns {{x: Date, y: number}}
 */
export function parseStreamSeries(data: { value: string; date: moment.MomentInput | undefined }): {
    x: Moment;
    y: number | null;
} {
    const value = Number.parseFloat(data.value);
    return {
        x: moment.utc(data.date),
        y: Number.isNaN(value) ? null : value,
    };
}

/**
 * Binary search
 *
 * @param data Array of elements.
 * @param element Element to find.
 * @param comparison Function to compare element with the array elements. Must return -1, 0 or 1.
 */
export function binarySearch<T>(data: T[], element: T, comparison: (a: T, b: T) => 1 | -1 | 0) {
    let start = 0;
    let end = data.length - 1;
    let found = false;
    let elementIndex = -1;

    while (start <= end && !found) {
        const index = Math.floor(start + Math.floor((end - start) / 2));
        const cmp = comparison(element, data[index]);

        if (cmp < 0) {
            end = index - 1;
        } else if (cmp > 0) {
            start = index + 1;
        } else {
            found = true;
            elementIndex = index;
        }
    }

    return elementIndex;
}

/**
 * MomentJS date comparator.
 */
export function cmpMoment(a: Moment, b: Moment) {
    if (a.isBefore(b)) {
        return -1;
    }
    if (a.isAfter(b)) {
        return 1;
    }
    return 0;
}

/**
 * Calculates the nearest date to target
 * @param {moment} target
 * @param {moment[]} array
 * @returns {[index, moment]}
 */
export function findNearestDate(
    target: Moment,
    array: Array<Moment>
): [number | undefined, Moment | undefined] {
    let minIndex = 0;
    let maxIndex = array.length - 1;
    let mid: number | undefined;

    // Corner cases
    if (target.isBefore(array[0], 'day')) {
        return [0, array[0]];
    }
    if (target.isAfter(array[maxIndex], 'day')) {
        return [maxIndex, array[maxIndex]];
    }

    // Doing binary search
    while (minIndex <= maxIndex) {
        mid = Math.floor((minIndex + maxIndex) / 2);
        const currentElement = array[mid];

        if (target.isSame(currentElement, 'day')) {
            return [mid, currentElement];
        }
        if (target.isBefore(currentElement, 'day')) {
            // If target is greater than previous to current, return closest of two
            if (mid > 0 && target.isAfter(array[mid - 1], 'day')) {
                return getClosest(array[mid - 1], mid - 1, array[mid], mid, target);
            }
            maxIndex = mid - 1;
        } else if (target.isAfter(currentElement, 'day')) {
            if (mid < array.length - 1 && target.isBefore(array[mid + 1], 'day')) {
                return getClosest(array[mid], mid, array[mid + 1], mid + 1, target);
            }
            minIndex = mid + 1;
        }
    }

    // Only single element left after search
    return mid === undefined ? [undefined, undefined] : [mid, array[mid]];
}

/**
 * Find the nearest date to the target, specifying the lower/upper bound. The target is not within the array.
 *
 * Eg. array = [1, 2, 3, 7], target = 5
 *      - bound = 'lower' => output = 3
 *      - bound = 'upper' => output = 7
 */
export function findNearestDateBound(date: Moment, array: Array<Moment>, bound: 'lower' | 'upper') {
    let startIndex = 0;
    let endIndex = array.length - 1;

    // Check base cases
    if (array.length === 0) {
        return null;
    } else if (array.length == 1) {
        return array[0];
    }

    // Binary search without increments, to not to skip the missing date
    while (endIndex - startIndex > 1) {
        const index = Math.floor(startIndex + Math.floor((endIndex - startIndex) / 2));
        const cmp = cmpMoment(date, array[index]);

        if (cmp < 0) {
            endIndex = index;
        } else if (cmp > 0) {
            startIndex = index;
        }
    }

    // Return the desired bound
    if (bound == 'upper') {
        return array[endIndex];
    } else {
        return array[startIndex];
    }
}

/**
 * Get the closest date to target
 * @param {moment} val1
 * @param {number} idx1
 * @param {moment} val2
 * @param {number} idx2
 * @param {moment} target
 * @returns {[number, moment]}
 */
function getClosest(
    val1: Moment,
    idx1: number,
    val2: Moment,
    idx2: number,
    target: Moment
): [number, Moment] {
    const diff1 = Math.abs(target.diff(val1, 'days'));
    const diff2 = Math.abs(target.diff(val2, 'days'));
    return diff1 <= diff2 ? [idx1, val1] : [idx2, val2];
}

/**
 * Create a GMT+0 date from a moment local date, without the conversion of the timezone.
 *
 * With this function:
 * 2018-01-01T00:00:00.0 GTM+2 -> 2018-01-01T00:00:00.0Z
 *
 * With moment.utc(date):
 * 2018-01-01T00:00:00.0 GTM+2 -> 2017-12-31T22:00:00.0Z
 */
export function getUTCDateWithoutConversion(date: Moment): Moment {
    const dateUTC0 = moment(date);
    dateUTC0.utcOffset(0, true);
    return dateUTC0;
}

/**
 * Convert array of normalized colors into css style colors
 *
 * @param color
 */
export function toCssColor(color): string {
    return `(${color.map((v) => v * 255).join(',')})`;
}

/**
 * Password match validator.
 * @return {ValidatorFn}
 */
export function matchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const repeatPassword = control.get('repeatPassword').value;

    if (password === repeatPassword) {
        return null;
    } else {
        return { matchPassword: { value: true } };
    }
}

/**
 * Round a number to a number of decimals.
 *
 * @param n: number
 * @param precision: number of decimals
 */
export function round(n: number, precision: number) {
    const p = Math.pow(10, precision);
    return Math.round(n * p) / p;
}

/**
 * Convert a integer number to hexadecimal string
 *
 * @param {number} rgb value from 0 to 255
 */
function rgbToHex(rgb) {
    let hex = Number(rgb).toString(16);
    if (hex.length < 2) {
        hex = '0' + hex;
    }
    return hex;
}

/**
 * Convert array of normalized colors into css hexadecimal colors
 *
 * @param color
 */
export function toCssHexColor(color): string {
    if (color.length === 3) {
        color.push(1.0); // Add alpha
    }
    return `${[0, 1, 2, 3].map((i) => rgbToHex(Math.floor(color[i] * 255))).join('')}`;
}

/**
 * Get the browser name and returns the name and if the browser is supported/unsupported
 *
 */
export function getBrowser() {
    const output = {
        name: 'This',
        supported: false,
    };

    if (
        (navigator.userAgent.indexOf('OPR') > -1 && navigator.userAgent.indexOf('Safari') > -1) ||
        navigator.userAgent.indexOf('Opera') > -1
    ) {
        output.name = 'Opera';
        output.supported = false;
    } else if (
        navigator.userAgent.indexOf('Chrome') < 0 &&
        navigator.userAgent.indexOf('Safari') > -1
    ) {
        output.name = 'Safari';
        output.supported = false;
    } else if (
        navigator.userAgent.indexOf('Chrome') > -1 &&
        navigator.userAgent.indexOf('Safari') > -1 &&
        navigator.userAgent.indexOf('Vivaldi') < 0 &&
        navigator.userAgent.indexOf('Edge') < 0
    ) {
        output.name = 'Chrome';
        output.supported = true;
    } else if (navigator.userAgent.indexOf('Vivaldi') > -1) {
        output.name = 'Vivaldi';
        output.supported = false;
    } else if (navigator.userAgent.indexOf('Firefox') > -1) {
        output.name = 'Firefox';
        output.supported = true;
    } else if (navigator.userAgent.indexOf('Edge') > -1) {
        output.name = 'Edge';
        output.supported = false;
    } else if (
        navigator.userAgent.indexOf('MSIE') > -1 ||
        navigator.userAgent.indexOf('Trident') > -1
    ) {
        output.name = 'Internet Explorer';
        output.supported = false;
    }

    return output;
}

/**
 * Check share link URL params.
 *
 * @param {string} x
 * @param {string} y
 * @param {string} z
 * @param {string} products
 * @param {string} dates
 * @param {string} linkedLayers
 * @param {string} selectedLayer
 * @param {string} compareLayers
 * @param {string} compareMode
 * @param {string} opacities
 * @param {string} ranges
 * @param {string} pinnedLayers
 * @param {string} hiddenLayers
 * @param {string} baseLayer
 * @param {string} roi
 * @param {string} username
 * @param {string} loaded
 */
export function linkParamsAreValid(
    x,
    y,
    z,
    products,
    dates,
    selectedLayer,
    compareLayers,
    compareMode,
    opacities,
    ranges,
    pinnedLayers,
    hiddenLayers,
    baseLayer,
    linkedLayers?,
    roi?,
    username?,
    loaded?
): { valid: boolean; messages: string[] } {
    const idRE = /^(\d+)(,\d+)*$/;
    const dateRE = /^(\d{4}-\d{2}-\d{2})(,\d{4}-\d{2}-\d{2})*$|(^latest$)*$/;
    const zoomRE = /^\d+$/;
    const productRE = /^[\w\-:_. ]+(,[\w\-:_. ]+)*$/;
    const latlngRE = /^-?\d+(\.\d+)?$/;
    const selectedRE = /^\d+$/;
    const rangeRE = /^(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)(;(-?\d+(\.\d+)?),(-?\d+(\.\d+)?))*$/;
    const wordRE = /^[\w\-_]+$/;
    const opacityRE = /^(\d+(,\d+)*)?$/;

    const results: { valid: boolean; message: string }[] = [
        {
            valid: x !== null && x !== undefined && latlngRE.test(x),
            message: 'Invalid x',
        },
        {
            valid: y !== null && y !== undefined && latlngRE.test(y),
            message: 'Invalid y',
        },
        {
            valid: z !== null && z !== undefined && zoomRE.test(z),
            message: 'Invalid z',
        },
        {
            valid: products !== null && products !== undefined && productRE.test(products),
            message: 'Invalid products',
        },
        { valid: dateRE.test(dates), message: 'Invalid dates' },
        { valid: rangeRE.test(ranges), message: 'Invalid ranges' },
        {
            valid: linkedLayers === null || linkedLayers === undefined || idRE.test(linkedLayers),
            message: 'Invalid linked layers',
        },
        {
            valid: compareLayers === null || idRE.test(compareLayers) || compareLayers === '',
            message: 'Invalid compare layers',
        },
        {
            valid: compareMode === null || productRE.test(compareMode) || compareMode === '',
            message: 'Invalid compare mode',
        },
        {
            valid: pinnedLayers === null || idRE.test(pinnedLayers) || pinnedLayers === '',
            message: 'Invalid pinned layers',
        },
        {
            valid: hiddenLayers === null || idRE.test(hiddenLayers) || hiddenLayers === '',
            message: 'Invalid hidden layers',
        },
        {
            valid: selectedLayer === null || selectedRE.test(selectedLayer) || selectedLayer === '',
            message: 'Invalid selected',
        },
        {
            valid: baseLayer === null || wordRE.test(baseLayer),
            message: 'Invalid base layer',
        },
        {
            valid: roi === null || selectedRE.test(roi) || roi === undefined,
            message: 'Invalid roi',
        },
        {
            valid: opacities ? opacityRE.test(opacities) : true,
            message: 'Invalid opacity',
        },
        {
            valid: loaded === null || loaded === 'true' || loaded === undefined,
            message: 'Invalid loaded',
        },
    ];

    return results.reduce(
        ({ valid, messages }, { valid: currentValid, message: currentMessage }) => ({
            valid: valid && currentValid,
            messages: currentValid ? messages : [...messages, currentMessage],
        }),
        { valid: true, messages: [] }
    );
}

/**
 * Check if a point is inside a polygon
 * Based on: https://github.com/substack/point-in-polygon
 * @param lat, point latitude
 * @param lon, point longitude
 * @param polygon, array of coordinates of the polygon
 */
export function pointInPolygon(lat: number, lon: number, polygon: Position[]) {
    // ray-casting algorithm
    const x = lat;
    const y = lon;

    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0],
            yi = polygon[i][1];
        const xj = polygon[j][0],
            yj = polygon[j][1];

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
}

/**
 * Get the array of coordinates from a given geometry
 * @param {geometry} geometry object
 */
export function getCoordinatesFromGeometry(geometry: GeoJSON): Position[] {
    let coordinates = [];
    if (geometry.type === 'MultiPolygon') {
        coordinates = geometry.coordinates[0][0];
    } else if (geometry.type === 'Polygon') {
        coordinates = geometry.coordinates[0];
    } else if (geometry.type === 'GeometryCollection') {
        coordinates = getCoordinatesFromGeometry(geometry.geometries[0]);
    }
    return coordinates;
}

/**
 * Transform the coordinate from decimal degrees to degrees minutes seconds
 * @param coordinate
 */
export function getDegreeMinuteSecond(coordinate) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return coordinate > 0
        ? `${degrees}° ${minutes}' ${seconds}"`
        : `-${degrees}° ${minutes}' ${seconds}"`;
}

/**
 * Check simple component share link URL params.
 *
 * @param {string} region
 * @param {string} product
 */
export function linkSimpleComponentParamsAreValid(params) {
    const paramRegion = params.get('region');
    const paramProduct = params.get('product');
    const paramUsername = params.get('username');

    const regionRE = /^(\d+)(,\d+)*$/;
    const productRE = /^([\w\-_ ]+)*$/;
    const regionValid = paramRegion !== null && regionRE.test(paramRegion);
    const productValid = paramProduct !== null && productRE.test(paramProduct);
    const usernameValid =
        (paramUsername !== null && productRE.test(paramUsername)) || paramUsername === undefined;

    return (
        paramProduct !== undefined &&
        paramRegion !== undefined &&
        regionValid &&
        productValid &&
        usernameValid
    );
}

/**
 * Check if a point is inside a GEOJson
 * Based on: https://github.com/substack/point-in-polygon
 * @param lng, point longitude
 * @param lat, point latitude
 * @param areaAllowed, GEOJson
 */
export function pointInsideAreaAllowed(lng: number, lat: number, areaAllowed: GeoJSON) {
    if (areaAllowed.type === 'Polygon') {
        return pointInPolygon(lng, lat, areaAllowed.coordinates[0]);
    } else if (areaAllowed.type === 'MultiPolygon') {
        let inside = false;
        let i = 0;
        while (i < areaAllowed.coordinates.length && !inside) {
            if (areaAllowed.coordinates[i].length > 1) {
                let pountInHoles = false;
                let x = 1;
                while (x < areaAllowed.coordinates[i].length && !pountInHoles) {
                    if (pointInPolygon(lng, lat, areaAllowed.coordinates[i][x])) {
                        pountInHoles = true;
                    }
                    x++;
                }
                if (pointInPolygon(lng, lat, areaAllowed.coordinates[i][0]) && !pountInHoles) {
                    inside = true;
                }
            } else {
                if (pointInPolygon(lng, lat, areaAllowed.coordinates[i][0])) {
                    inside = true;
                }
            }
            i++;
        }
        return inside;
    } else {
        return false;
    }
}

/**
 * Converts a CSV string into a JSON object [{columnKeyA: valueA, columnKeyB: value..}, {..}, ...]
 *
 * @param CSV
 */
export function csvToJson(csv: string) {
    const lines = csv.split('\n');

    const dataIndex =
        lines.findIndex((l) => !l.startsWith('#')) > -1
            ? lines.findIndex((l) => !l.startsWith('#'))
            : 0;
    const metaDataList = lines.slice(0, dataIndex);
    const metaData = {};
    for (let i = 0; i < metaDataList.length; i++) {
        const metaDataValue = metaDataList[i].split('# ');
        if (metaDataValue.length > 1) {
            const keyValue = metaDataValue[1].split(' ');
            const keyArray = keyValue.slice(0, keyValue.length - 1);
            const key = keyArray.join(' ');
            const value = keyValue[keyValue.length - 1];
            metaData[key] = value;
        }
    }

    const dataLines = lines.slice(dataIndex);

    const headers = dataLines[0].split(',');

    const data = [];
    for (let i = 1; i < dataLines.length; i++) {
        const obj = {};
        const currentLine = dataLines[i].split(',');
        if (currentLine.length === headers.length) {
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentLine[j];
            }
            data.push(obj);
        }
    }
    return { metadata: metaData, data: data };
}

/**
 * Compare two strings
 *
 * @param {string} a
 * @param {string} b
 */
export function cmpStr(a: string, b: string) {
    if (a.toLowerCase() !== b.toLowerCase()) {
        a = a.toLowerCase();
        b = b.toLowerCase();
    }
    return a.localeCompare(b);
}

/**
 * Check if number is in range
 * @param minValue
 * @param maxValue
 * @param number
 */
export function isNumberInRange(minValue, maxValue, number) {
    return (number - minValue) * (number - maxValue) <= 0;
}

/**
 * Return file extenstion from image path
 * @param imagePath, filesystem path to the image
 */
export function getFileExtension(imagePath) {
    return imagePath.split('\\').pop().split('/').pop().split('.').pop();
}

/**
 * Return the current domain name
 */
export function getHostname() {
    return window.location.hostname;
}

/**
 * Return the domain of the environment if set or the current domain name from the url
 */
export function getDomain() {
    return environment.domain ? environment.domain : getHostname();
}

/**
 * Return the date closest to the selected date from array of dates
 */
export function getGroupProductDate(date, dates): any {
    let dateDiff;
    let closest = null;
    let dateDiffClosest;

    const dateIndex = dates.findIndex((dt) => dt.isSame(date));
    if (dateIndex > -1) {
        return dates[dateIndex];
    }
    if (date.isBefore(dates[0])) {
        return dates[0];
    }
    if (date.isAfter(dates[dates.length - 1])) {
        return dates[dates.length - 1];
    }

    dates.forEach(function (d) {
        if (closest === null) {
            closest = d;
            dateDiffClosest = d.diff(date);
            if (d.diff(date) < 0) {
                dateDiffClosest = date.diff(d);
            }
        }
        dateDiff = d.diff(date);
        if (d.diff(date) < 0) {
            dateDiff = date.diff(d);
        }
        if (dateDiff < dateDiffClosest) {
            closest = d;
            dateDiffClosest = dateDiff;
        } else if (dateDiff > dateDiffClosest) {
            return closest;
        }
    });

    return closest;
}

/**
 * Return a binary string for a given number
 */
export function createBinaryString(nMask) {
    let nShifted = nMask;
    const maskArray = [];
    for (let nFlag = 0; nFlag < 32; nFlag++) {
        const bitValue = nShifted >>> 31;
        maskArray.push(bitValue === 1 ? '<strong>1</strong>' : '0');
        nShifted <<= 1;
    }
    maskArray.splice(24, 0, ' ');
    const bitsArray = maskArray.slice(16, maskArray.length);
    return bitsArray.join('');
}

/**
 * Decode base64 string
 */
export function decodeBase64Unicode(str) {
    return decodeURIComponent(
        atob(str)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
}

/**
 * Checks if a string is a valid json object
 * @param text
 */
export function isJsonString(text) {
    try {
        text = text.replace('NaN', 'null');
        const jsonObject = JSON.parse(text);
        if (jsonObject && typeof jsonObject === 'object') {
            return jsonObject;
        }
    } catch (e) {
        return false;
    }
}

/**
 * Checks if a geojson has a valid CRS: non-specified CRS or CRS EPSG:4326 (any version of the EPSG:4326)
 * @param geojson
 */
export function validGeojsonCrs(geojson) {
    let isValid = true;
    if (!geojson['crs']) {
        return isValid;
    }
    if (geojson['crs'] && geojson['crs']['properties'] && geojson['crs']['properties']['name']) {
        const crs = geojson['crs']['properties']['name'];
        if (crs.indexOf('EPSG') === -1 || crs.indexOf(':4326') === -1) {
            isValid = false;
        }
    }
    return isValid;
}

export function getMonday(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

/**
 * Returns the intersection of a list of Sets
 */
export function intersect<T>(...allSets: Set<T>[]): Set<T> {
    const [firstSet, ...sets] = allSets;
    const count = sets.length;
    const result = new Set(firstSet); // Only create one copy of the set
    firstSet.forEach((item) => {
        let i = count;
        let allHave = true;
        while (i--) {
            allHave = sets[i].has(item);
            if (!allHave) {
                break;
            } // loop only until item fails test
        }
        if (!allHave) {
            result.delete(item); // remove item from set rather than
            // create a whole new set
        }
    });
    return result;
}

type Mask = string[] | { [key: string]: Mask };

export function formatMask(mask: Mask): string {
    if (Array.isArray(mask)) {
        return mask.join(',');
    }

    return Object.entries(mask)
        .map(([key, value]) => `${key}{${formatMask(value)}}`)
        .join(',');
}

export function defaultGauge(_: any) {
    return 1;
}

export function chunksOf<T>(
    size: number,
    items: T[],
    gauge: (item: T) => number = defaultGauge
): T[][] {
    return items
        .reduce<[T[], number][]>(([lastReduced, ...rest], current) => {
            const [previousValues, previousSize] = lastReduced ?? [[], 0];
            const currentSize = gauge(current);
            if (previousSize + currentSize <= size) {
                return [[[...previousValues, current], previousSize + currentSize], ...rest];
            } else {
                return [[[current], currentSize], lastReduced, ...rest];
            }
        }, [])
        .map(([xs, _]) => xs)
        .reverse();
}

export function assertNever(x: never): never {
    throw new Error('Unexpected object: ' + JSON.stringify(x));
}

export type CoordinateSystem = 'EPSG:28992' | string;
