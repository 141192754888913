<div class="regions-dialog-wrapper flex flex-col w-full h-full">
    <div class="dialog-header flex justify-between items-center">
        <div>
            <h3 class="font-bold">{{ title }}</h3>
        </div>
        <div>
            <button class="roi-dismiss" mat-raised-button color="warn" (click)="dismiss()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <div class="roi-dialog-container flex flex-col">
        <div class="roi-data flex flex-col p-4">
            <mat-checkbox [(ngModel)]="separatedRegions" [hidden]="mode !== 'create'">
                <span>Create an ROI for each geometry</span>
            </mat-checkbox>
            <mat-form-field [hidden]="separatedRegions">
                <input matInput placeholder="Name" [(ngModel)]="region.name" />
            </mat-form-field>
            <div class="flex flex-col" *ngIf="mode === 'create'" cdkDropListGroup>
                <div *ngIf="separatedRegions">
                    <div class="example-container">
                        <mat-form-field>
                            <mat-select
                                (selectionChange)="addRoiProperties($event)"
                                placeholder="Select attributes for name generation"
                                [formControl]="roiProperties"
                            >
                                <mat-option *ngFor="let item of roiPropertiesList" [value]="item">{{
                                    item
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="flex justify-start items-center gap-4">
                        <div class="text-block basis-3/4">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Text block for name generation"
                                    [(ngModel)]="textBlock"
                                />
                            </mat-form-field>
                        </div>

                        <button
                            class="text-block-button basis-1/4"
                            mat-raised-button
                            [disabled]="!textBlock.length"
                            (click)="addTextBlock()"
                            color="accent"
                        >
                            Add text block
                        </button>
                    </div>

                    <div *ngIf="roiNameBlocks.length > 0">
                        <span>Drag and drop name blocks to set order</span>
                        <div
                            cdkDropList
                            cdkDropListOrientation="horizontal"
                            class="list"
                            (cdkDropListDropped)="orderRoiName($event)"
                        >
                            <div class="box" *ngFor="let item of roiNameBlocks" cdkDrag>
                                <span class="flex justify-between items-center">
                                    <p>{{ item }}</p>
                                    <i
                                        class="fa fa-trash delete-block-icon basis-1/12"
                                        (click)="deleteRoiNamePreviewBlock(item)"
                                        aria-hidden="true"
                                        [matTooltip]="'Remove name block'"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="field-separator-dropwdown">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder="Field separator"
                                [(ngModel)]="separator"
                                (ngModelChange)="generateOutputExampleList()"
                            />
                        </mat-form-field>
                    </div>

                    <div *ngIf="outputExample && outputExample.length > 0">
                        <span class="select-title">Name preview:</span>
                        <ul>
                            <li *ngFor="let example of outputExample">
                                {{ example }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <mat-form-field>
                <textarea
                    matInput
                    placeholder="Description"
                    [(ngModel)]="region.description"
                ></textarea>
            </mat-form-field>

            <mat-form-field class="chip-list">
                <mat-chip-grid #labelsList>
                    <mat-chip-row
                        class="roi-label"
                        *ngFor="let label of region.labels"
                        [ngStyle]="{
                            color: label.color,
                            backgroundColor: label.color
                        }"
                        [removable]="removableLabels"
                        [disableRipple]="true"
                        (removed)="deleteLabel(label)"
                    >
                        <span>{{ label.title }}</span>
                        <mat-icon
                            matChipRemove
                            *ngIf="removableLabels"
                            class="fa fa-times"
                        ></mat-icon>
                    </mat-chip-row>

                    <input
                        id="titleSearch"
                        [formControl]="searchLabels"
                        [matChipInputFor]="labelsList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        [matAutocomplete]="auto"
                        [placeholder]="'Labels'"
                    />
                </mat-chip-grid>

                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="addLabelFromAutocomplete($event)"
                >
                    <mat-option *ngFor="let userLabel of labelPool" [value]="userLabel">
                        <mat-chip-option
                            (click)="addLabel(userLabel)"
                            [ngStyle]="{
                                color: userLabel.color,
                                backgroundColor: userLabel.color
                            }"
                            class="roi-label"
                        >
                            <span>{{ userLabel.title }}</span>
                        </mat-chip-option>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-checkbox
                [indeterminate]="
                    selectedGeometries.length != 0 && selectedGeometries.length != geometries.length
                "
                [checked]="selectedGeometries.length == geometries.length"
                (change)="selectAll($event)"
                >Select All/None
            </mat-checkbox>
        </div>

        <div class="justify-center items-center">
            <app-map-preview (mapReady)="onMapReady($event)"></app-map-preview>
        </div>

        <div [hidden]="mode !== 'create'" class="flex justify-center items-center">
            <span class="txt-info">You can select the regions by clicking them</span>
        </div>

        <div class="dialog-actions flex justify-between items-center">
            <div>
                <button
                    mat-raised-button
                    [hidden]="mode !== 'edit'"
                    appAccess="delete-roi"
                    (click)="delete()"
                    color="warn"
                    matTooltip="Delete ROI"
                    [matTooltipPosition]="'right'"
                >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </div>

            <div>
                <button
                    class="roi-accept"
                    mat-raised-button
                    (click)="save()"
                    color="accent"
                    [disabled]="mode === 'create' && selectedGeometries.length === 0"
                >
                    <div *ngIf="mode === 'create'" class="flex justify-center items-center">
                        <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;Upload
                    </div>
                    <div *ngIf="mode !== 'create'" class="flex justify-center items-center">
                        <i class="fa fa-save" aria-hidden="true"></i>&nbsp;Save
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
