import { autoserialize, autoserializeAs, deserialize } from 'cerialize';

/**
 * Time Series Dialog modes.
 */
export enum ApiRequestTypeEnum {
    PointTimeSeriesRequest = 'point_time_series_request',
    RoiTimeSeriesRequest = 'roi_time_series_request',
    GriddedDataRequest = 'gridded_data_request',
    AnimationRequest = 'animation_api_request',
}

export enum ApiRequestStatusEnum {
    Ready = 'Ready',
    Processing = 'Processing',
    Scheduled = 'Scheduled',
    Submitted = 'Submitted',
    ReadyNoData = 'No data',
    Error = 'Error',
}

/**
 * API Request class
 */
export class ApiRequest {
    /**
     * Request format
     */
    @autoserialize format: string;

    /**
     * Maximum latitude
     */
    @autoserializeAs('lat_max') latMax?: number;

    /**
     * Minimum latitude
     */
    @autoserializeAs('lat_min') latMin?: number;

    /**
     * Maximum longitude
     */
    @autoserializeAs('lon_max') lonMax?: number;

    /**
     * Minimun longitude
     */
    @autoserializeAs('lon_min') lonMin?: number;

    /**
     * Latitude
     */
    @autoserialize lat?: number;

    /**
     * Longitude
     */
    @autoserialize lon?: number;

    /**
     * Notify email
     */
    @autoserialize notify: string;

    /**
     * API Request processing status
     */
    @autoserializeAs('processing_status') processingStatus: string;

    /**
     * Product API name of the API Request
     */
    @autoserializeAs('product_api_name') productApiName: string;

    /**
     * Product name of the API Request
     */
    @autoserializeAs('product_name') productName: string;

    /**
     * API Request datetime
     */
    @autoserializeAs('request_dt') requestDt: string;

    /**
     * API Request type
     */
    @autoserialize type: string;

    /**
     * UUID of the API Request
     */
    @autoserialize uuid: string;

    /**
     * Boolean flag to indicate whether the API Request is compressed or not
     */
    @autoserialize zipped: boolean;

    /** Boolean flag to indicate whether the API request is related with climatology or not  */
    @autoserialize climatology: boolean;

    /** Boolean flag to indicate if it includes masked data or not */
    @autoserializeAs('include_masked_data') includeMaskedData: boolean;

    /**
     * Start date for the time series API request
     */
    @autoserializeAs('start_date') startDate: Date;

    /**
     * End date for the time series API request
     */
    @autoserializeAs('end_date') endDate: Date;

    /**
     * Duration of the animation API request
     */
    @autoserialize duration?;

    /**
     * Geojson of the API request
     */
    @autoserialize geojson?;

    /**
     * Id of the ROI
     */
    @autoserializeAs('roi_id') roiId?;

    /**
     * API Request datetime
     */
    @autoserializeAs('avg_window_direction') avgWindowDirection: string;

    /**
     * Base layer of the animation API request
     */
    @autoserializeAs('base_layer') baseLayer?;

    constructor(
        format: string,
        notify: string,
        processingStatus: string,
        productApiName: string,
        productName: string,
        requestDt: string,
        type: string,
        uuid: string,
        zipped: boolean,
        includeMaskedData: boolean,
        climatology: boolean,
        startDate: Date,
        endDate: Date,
        geojson: object,
        roiId: number,
        baseLayer: string,
        latMax?: number,
        latMin?: number,
        lonMax?: number,
        lonMin?: number,
        lat?: number,
        lon?: number,
        duration?: number,
        avgWindowDirection?: string
    ) {
        this.format = format;
        this.notify = notify;
        this.processingStatus = processingStatus;
        this.productApiName = productApiName;
        this.productName = productName;
        this.requestDt = requestDt;
        this.type = type;
        this.uuid = uuid;
        this.zipped = zipped;
        this.startDate = startDate;
        this.endDate = endDate;
        this.geojson = geojson;
        this.roiId = roiId;
        this.baseLayer = baseLayer;
        this.latMax = latMax;
        this.latMin = latMin;
        this.lonMax = lonMax;
        this.lonMin = lonMin;
        this.lat = lat;
        this.lon = lon;
        this.duration = duration;
        this.avgWindowDirection = avgWindowDirection;
        this.includeMaskedData = includeMaskedData;
        this.climatology = climatology;
    }
}

export class ApiRequestStatus {
    /**
     * Status percentage
     */
    @autoserialize percentage: number;

    /**
     * API Request processing status
     */
    @autoserializeAs('processing_status') processingStatus: string;

    /**
     * List of files
     */
    @autoserialize data: string[];

    /**
     * Process start date
     */
    @autoserializeAs('started_dt') startedDt: Date;

    /**
     * Process finish date
     */
    @autoserializeAs('finished_dt') finishedDt: Date;

    constructor(
        percentage: number,
        processingStatus: string,
        data: string[] = [],
        startedDt?: Date,
        finishedDt?: Date
    ) {
        this.percentage = percentage;
        this.processingStatus = processingStatus;
        this.data = data;
        this.startedDt = startedDt;
        this.finishedDt = finishedDt;
    }

    getFileData() {
        const fileRows = [];
        for (let i = 0; i < this.data.length; i++) {
            const path = this.data[i].split('/');
            fileRows.push({
                filename: path[path.length - 1],
                path: this.data[i] + '/download',
            });
        }
        return fileRows;
    }
}
