import { Injectable } from '@angular/core';
import { Subject, from as observableFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SimpleService {
    /**
     * Number of services that needs to be loaded before creating the dashboard
     */
    private numberOfServices = 2;

    /**
     * Number of services loaded
     */
    private loadedServices = 0;

    /**
     * RxJs Subject that emits the layers status.
     */
    private outputLoadingOptions = new Subject<void>();

    /**
     * Emit layers order event.
     */
    emitLoadingOptionsUpdated() {
        this._emitLoadingOptionsUpdated();
    }

    /**
     * Emit layers order event.
     */
    private _emitLoadingOptionsUpdated() {
        this.outputLoadingOptions.next();
    }

    /**
     * Update the value of the loaded services and emit a value if all the services are loaded
     */
    public updateLoadingValue() {
        this.loadedServices++;
        if (this.loadedServices === this.numberOfServices) {
            this.emitLoadingOptionsUpdated();
        }
    }

    /**
     * Return an observable from the Subject, that emits the new layers status.
     * @returns {Observable<any>}
     */
    watchLoadingOptionsUpdated() {
        return observableFrom(this.outputLoadingOptions);
    }

    /**
     * Reset the status of the loaded services (this is usually called on onDestroy lifecycle event)
     */
    restartLoadingValues() {
        this.loadedServices = 0;
    }
}
