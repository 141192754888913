import { autoserialize, autoserializeAs } from 'cerialize';
import { Product } from './product';

export class EmbedLink {
    /**
     * Embed ID
     */
    @autoserialize id?: number;

    /**
     * Area of the embed view link
     */
    @autoserializeAs('area_allowed') areaAllowed?: object;

    /**
     * Description of the embed view link
     */
    @autoserialize description?: string;

    /**
     * Embed view link
     */
    @autoserialize link?: string;

    /**
     * List of products of the embed view link
     */
    @autoserialize products?: string[] = [];

    /**
     * Default constructor
     * @param {number} id, embed link id
     * @param {object} areaAllowed, embed link area
     * @param {string} description, embed link description
     * @param {string} link, embed link
     * @param {string[]} products, list of products that are in the embed link
     */
    constructor(
        id?: number,
        areaAllowed?: object,
        description?: string,
        link?: string,
        products?: string[]
    ) {
        this.id = id;
        this.areaAllowed = areaAllowed;
        this.description = description;
        this.link = link;
        this.products = products;
    }

    /**
     * Shallow embed link copy.
     * @return {EmbedLink}
     */
    public clone(): EmbedLink {
        return new EmbedLink(this.id, this.areaAllowed, this.description, this.link, this.products);
    }
}

export class EmbedLayer {
    /**
     * Layer date
     */
    @autoserialize date?: string;

    /**
     * Layer opacity
     */
    @autoserialize opacity?: number;

    /**
     * Layer product
     */
    @autoserialize product?: string;

    /**
     * Layer range
     */
    @autoserialize range?: string;

    /**
     * If layer is in compare mode
     */
    @autoserialize compare?: any;

    /**
     * If layer is hidden
     */
    @autoserialize hidden?: any;

    /**
     * If layer is pinned
     */
    @autoserialize pinned?: any;

    /**
     * If layer is selected
     */
    @autoserialize selected?: boolean;

    constructor(
        date?: string,
        opacity?: number,
        product?: string,
        range?: string,
        selected?: boolean,
        compare?: boolean,
        hidden?: boolean,
        pinned?: boolean
    ) {
        this.date = date;
        this.opacity = opacity;
        this.product = product;
        this.range = range;
        this.selected = selected;
        this.compare = compare;
        this.hidden = hidden;
        this.pinned = pinned;
    }
}

export class EmbedLinkMetadata {
    /**
     * Embed link layers
     */
    @autoserializeAs(EmbedLayer, 'layers') layers: EmbedLayer[];

    /**
     * Embed link base layer
     */
    @autoserializeAs('base-layer') baseLayer?: string;

    /**
     * Embed link compare mode
     */
    @autoserializeAs('compare-mode') compareMode?: string;

    /**
     * Embed link x coordinate
     */
    @autoserialize x: string;

    /**
     * Embed link y coordinate
     */
    @autoserialize y: string;

    /**
     * Embed link z coordinate
     */
    @autoserialize z: string;

    constructor(
        layers: EmbedLayer[],
        x: string,
        y: string,
        z: string,
        baseLayer?: string,
        compareMode?: string
    ) {
        this.layers = layers;
        this.x = x;
        this.y = y;
        this.z = z;
        this.baseLayer = baseLayer;
        this.compareMode = compareMode;
    }
}

export class EmbedLinkDates {
    @autoserialize dates?: string[];

    constructor(dates?: string[]) {
        this.dates = dates;
    }
}

export class EmbedLinkData {
    /**
     * Embed link coordinates
     */
    @autoserialize coordinates?: Array<Array<number>>;

    /**
     * Dates that will be loaded in the embed view
     */
    @autoserialize dates?: EmbedLinkDates[];

    /**
     * Embed link hash
     */
    @autoserialize hash?: string;

    /**
     * EmbedLinkMetadata Object
     */
    @autoserializeAs(EmbedLinkMetadata, 'metadata_link')
    metadataLink?: EmbedLinkMetadata;

    /**
     * EmbedLinkMetadata products
     */
    @autoserializeAs(Product, 'products') products?: Product[];

    constructor(
        coordinates?: Array<Array<number>>,
        dates?: Array<object>,
        hash?: string,
        metadataLink?: EmbedLinkMetadata,
        products?: Product[]
    ) {
        this.coordinates = coordinates;
        this.dates = dates;
        this.hash = hash;
        this.metadataLink = metadataLink;
        this.products = products;
    }
}
