<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-chain icon-w-text"></i>Share map link</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div class="share-wrapper flex flex-col gap-4">
    <div
        *ngIf="shareLink === undefined && embed"
        class="loading-spinner justify-center items-center"
    >
        <mat-progress-spinner class="" [color]="'primary'" [mode]="'indeterminate'" [diameter]="50">
        </mat-progress-spinner>
    </div>
    <span class="share-text" *ngIf="shareLink !== undefined; else error">
        {{ shareLink }}
    </span>
    <ng-template #error>
        <span *ngIf="!embed">Please, add some product layers first.</span>
    </ng-template>

    <div class="flex justify-between items-center">
        <div>
            <mat-checkbox
                *ngIf="!embed && shareLink !== undefined && !this.dialogData.shareLink"
                [(ngModel)]="latestDate"
                (change)="setDateToLatest()"
            >
                Latest date available
            </mat-checkbox>
        </div>
        <div>
            <button mat-raised-button color="accent" (click)="copyLinkToClipboard()">
                <i class="fa fa-files-o"></i>
                <span>Copy link</span>
            </button>
        </div>
    </div>
</div>
