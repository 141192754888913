/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
    dsn: 'https://02a40ed334d7975b7d3be1c4396f93fd@o6855.ingest.sentry.io/4505918993858560',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^\/api\//],
        }),
        // new Sentry.Replay({
        //     maskAllText: false,
        //     blockAllMedia: false,
        //     networkDetailAllowUrls: [`${window.location.origin}/api`],
        //     networkDetailDenyUrls: [`${window.location.origin}/api/v2/login`],
        // }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5 / 100,

    // Capture Replay for 0.5% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.5 / 100,
    // replaysOnErrorSampleRate: 1.0,

    environment: environment.name,
    enabled: environment.useSentry ?? false,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
