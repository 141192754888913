import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Region } from '../../models/region';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

export type RoiInfoDialogData = Region;

export type RoiInfoDialogResult = undefined;

@Component({
    selector: 'app-roi-info-dialog',
    templateUrl: './roi-info-dialog.component.html',
    styleUrls: ['./roi-info-dialog.component.scss'],
})
export class RoiInfoDialogComponent
    implements DynamicDialog<RoiInfoDialogComponent, RoiInfoDialogData, RoiInfoDialogResult>
{
    dialogRef: MatDialogRef<
        DynamicLoaderComponent<
            DynamicDialog<RoiInfoDialogComponent, RoiInfoDialogData, undefined>,
            Region,
            RoiInfoDialogResult
        >,
        RoiInfoDialogResult
    >;

    dialogData: RoiInfoDialogData;

    /**
     * Dialog title.
     */
    get title(): string {
        return `Region: ${this.dialogData.name}`;
    }

    constructor() {}

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }
}
