import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as L from 'leaflet';

import { EmbedLink } from '../../models/embed-link';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { EmbedService } from '../../services/embed.service';
import {
    ShareDialogComponent,
    ShareDialogData,
    ShareDialogResult,
} from '../share-dialog/share-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import {
    RegionPreviewDialogComponent,
    EmbedPreviewResult,
} from '../region-preview-dialog/region-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getCoordinatesFromGeometry } from '../../utils/utils';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { LayersService } from '../../services/layers.service';
import { GeoJsonObject } from 'geojson';

const SCROLL_OPTIONS: ScrollIntoViewOptions = {
    block: 'nearest',
    inline: 'center',
};

@Component({
    selector: 'app-embed-sidenav',
    templateUrl: './embed-sidenav.component.html',
    styleUrls: ['./embed-sidenav.component.scss'],
})
export class EmbedSidenavComponent implements OnInit {
    /**
     * Reference to Map component edition modes object:
     * captureRegionMode: Whether the capture region mode is enabled or not
     */
    @Input() public editionModes: object;

    /**
     * Leaflet map reference.
     */
    @Input() public mapReference: L.Map;

    @Input() public allowedArea: GeoJsonObject | null;

    /**
     * Close sidenav event.
     * @type {EventEmitter<Map>}
     */
    @Output() closeSidenav = new EventEmitter<any>(true);

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<any>(true);

    /**
     * Generate embed link event
     */
    @Output() generateEmbedLink = new EventEmitter<any>(true);

    /**
     * List of embed links
     */
    embedLinksList: EmbedLink[];

    /**
     * Form control for search links input field
     * @type {FormControl}
     */
    searchLinks: UntypedFormControl = new UntypedFormControl('');

    /**
     * List of links that contains the searched word
     */
    foundLinks = [];

    /**
     * Index of the search list
     */
    foundIndex = 0;

    /**
     * Leaflet AreaSelect plugin object.
     * @type {L.AreaSelect}
     */
    private areaSelect: L.AreaSelect;

    constructor(
        private snackBar: CustomSnackbarService,
        private embedService: EmbedService,
        private dialogService: DialogService,
        private matDialogService: MatDialog,
        public layersService: LayersService
    ) {}

    ngOnInit() {
        this.searchLinks.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
            this.clearSearchStyle();
            if (value.length === 0) {
                this.foundLinks = [];
                return;
            }
            this.foundLinks = this.embedLinksList.filter(
                (x) =>
                    (x.description && x.description.toUpperCase().includes(value.toUpperCase())) ||
                    x.id.toString().includes(value)
            );
            if (this.foundLinks.length > 0) {
                this.foundIndex = 0;
                this.foundLinks.forEach((link) => {
                    const linkHtmlElement = document.getElementById(link.id).children;
                    for (let i = 0; i < linkHtmlElement.length; i++) {
                        (linkHtmlElement[i] as HTMLElement).className = 'found-link-list';
                    }
                });
                this.setFoundLink();
            } else {
                this.foundIndex = -1;
            }
        });
    }

    /**
     * Open the sidenav.
     */
    open() {
        this.listEmbedLinks();
    }

    /**
     * Close embed link sidenav
     */
    closeMenu() {
        this.closeSidenav.emit();
    }

    /**
     * Add area select to the map to create embed link.
     */
    generateBoundingBox() {
        if (this.layersService.getLayers()['size'] === 0) {
            this.snackBar.present('No product layers selected.', 'warning');
            return;
        }
        this.editionModes['embedEditMode'] = true;
        this.disableMapClick();

        // Leaflet Area Select
        this.areaSelect = L.areaSelect({ width: 100, height: 100 });
        this.areaSelect.addTo(this.mapReference);
    }

    /**
     * Open the share dialog with the embed link
     */
    confirmBoundingBox() {
        const allowedArea = JSON.parse(JSON.stringify(this.allowedArea));
        let userAreaAllowedCoordinates;
        if (allowedArea) {
            userAreaAllowedCoordinates = getCoordinatesFromGeometry(allowedArea);
        }
        if (userAreaAllowedCoordinates) {
            const coordinates = [];
            for (let i = 0; i < userAreaAllowedCoordinates.length; i++) {
                coordinates.push(userAreaAllowedCoordinates[i].reverse());
            }
            const polygon = L.polygon(coordinates);
            if (polygon.getBounds().contains(this.areaSelect.getBounds())) {
                const bounds = this.areaSelect.getBounds();
                this.previewEmbedLink(bounds);
            } else {
                this.snackBar.present('The bounding box is outside of the allowed area.', 'error');
            }
        } else {
            this.snackBar.present('Error retrieving the allowed area.', 'error');
        }
    }

    /**
     * Cancel the creation of the embed content
     */
    cancelBoundingBox() {
        this.editionModes['embedEditMode'] = false;
        // Leaflet Area Select
        this.areaSelect.remove();

        // Enable text select
        document.body.classList.remove('disable-select');
        this.enableMapClick();
    }

    /**
     * Disable the map click event.
     */
    disableMapClick() {
        this.mapReference.off('click');
    }

    /**
     * Restore the map click event.
     */
    enableMapClick() {
        this.mapClick.emit(true);
    }

    /**
     * Open share dialog with the embed link
     * @param token
     */
    generateShareableEmbedLink(token) {
        const embedLink = `${window.location.protocol}//${window.location.host}/embed?${token}`;
        this.dialogService.openComponent<ShareDialogComponent, ShareDialogData, ShareDialogResult>(
            ShareDialogComponent,
            {
                data: { shareLink: embedLink },
            }
        );
    }

    /**
     * Embed link preview
     *
     * @param {Embed} embedLink.
     */
    previewEmbedLink(embedLink) {
        // Embed link preview
        const data = { regionType: 'embed' };
        if (embedLink.link) {
            data['embedLink'] = new EmbedLink(
                embedLink.id,
                embedLink.areaAllowed,
                embedLink.description,
                embedLink.link,
                embedLink.products
            );
        } else {
            data['bounds'] = embedLink;
        }
        const previewDialogRef = this.matDialogService.open(RegionPreviewDialogComponent, {
            data: data,
            panelClass: 'embedLink-preview-dialog',
        });

        previewDialogRef.afterClosed().subscribe((dialogResult: EmbedPreviewResult) => {
            if (dialogResult === undefined) {
                return;
            }

            if (dialogResult.delete === true) {
                this.deleteEmbedLink(embedLink);
            } else if (dialogResult.mode === 'create') {
                this.cancelBoundingBox();
                const bounds = dialogResult.bounds;
                const description = dialogResult.description;
                this.generateEmbedLink.emit({
                    embed: true,
                    bounds,
                    description,
                });
            } else if (dialogResult.mode === 'edit') {
                const updatedEmbed = dialogResult.embedLink;
                const embedIndex = this.embedLinksList.findIndex((r) => r.id === updatedEmbed.id);
                this.embedLinksList[embedIndex] = updatedEmbed;
            }
        });
    }

    /**
     * Delete a embed link.
     *
     * @param {Embed} embedLink
     **/
    deleteEmbedLink(embedLink: EmbedLink) {
        // HTTP Request
        this.embedService.del(embedLink).subscribe(() => {
            const embedIndex = this.embedLinksList.map((x) => x.id).indexOf(embedLink.id);
            this.embedLinksList.splice(embedIndex, 1);
        });

        this.snackBar.present('Embed link deleted successfully.');
    }

    /**
     * Get a list of embed links
     */
    listEmbedLinks() {
        this.embedService.list().subscribe((result: EmbedLink[]) => {
            this.embedLinksList = result;
        });
    }

    /**
     * Update the value of the foundIndex and set the correct style to the found links
     * @param value, increase or decrease the value of the foundIndex
     */
    selectFoundLink(value) {
        if (this.foundLinks.length > 0) {
            const previousLink = this.foundIndex;
            this.foundIndex += value;
            if (this.foundIndex < 0) {
                this.foundIndex = this.foundLinks.length - 1;
            }
            if (this.foundIndex > this.foundLinks.length - 1) {
                this.foundIndex = 0;
            }
            this.setFoundLink(previousLink);
        }
    }

    /**
     * Set style to all the found links
     * @param previousLink, id of the previous selected link used to set correct style
     */
    setFoundLink(previousLink?) {
        if (this.foundIndex > -1 && this.foundLinks.length > 0) {
            const foundLinkHtmlElement = document.getElementById(
                this.foundLinks[this.foundIndex].id
            );
            foundLinkHtmlElement.scrollIntoView(SCROLL_OPTIONS);
            const foundLinkHtmlElementChildren = foundLinkHtmlElement.children;
            for (let i = 0; i < foundLinkHtmlElementChildren.length; i++) {
                (foundLinkHtmlElementChildren[i] as HTMLElement).className = 'selected-found-link';
            }
            if (previousLink >= 0 && previousLink !== this.foundIndex) {
                const oldFoundLinkHtmlElement = document.getElementById(
                    this.foundLinks[previousLink].id
                ).children;
                for (let i = 0; i < oldFoundLinkHtmlElement.length; i++) {
                    (oldFoundLinkHtmlElement[i] as HTMLElement).className = 'found-link-list';
                }
            }
        }
    }

    /**
     * Clear the searchLinks form comntrol value and the found links list
     */
    clearSearch() {
        this.searchLinks.setValue('');
        this.foundLinks = [];
    }

    /**
     * Remove style of the found links
     */
    clearSearchStyle() {
        this.embedLinksList.forEach((link: any) => {
            const linkElementChildren = document.getElementById(link.id).children;
            for (let i = 0; i < linkElementChildren.length; i++) {
                (linkElementChildren[i] as HTMLElement).className = '';
            }
        });
    }
}
