<mat-card
    appearance="outlined"
    (click)="onClick()"
    class="layer-wrapper flex flex-col"
    [ngClass]="layer.key == selectedLayerKey ? 'layer-selected' : 'layer-regular'"
>
    <div class="flex flex-col">
        <div matLine class="flex gap-4 justify-start items-center">
            <ng-content select="[slot=drag-handle]"> </ng-content>

            <div class="w-full flex flex-col min-w-0">
                <div class="flex justify-start items-center">
                    <div *ngIf="layer.product && !layer.group" class="max-w-full flex items-center">
                        <span class="layer-name overflow-hidden break-words">{{
                            layer.product.name
                        }}</span>
                        <button
                            mat-icon-button
                            *ngIf="!embed"
                            class="action-button"
                            (click)="openProductInfoDialog($event)"
                            matTooltip="Product information"
                            [matTooltipPosition]="'after'"
                        >
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div *ngIf="layer.group" class="flex flex-col">
                        <div>
                            <span>Group: </span
                            ><span class="layer-name">{{ layer.group.groupName }}</span>
                        </div>
                        <div *ngIf="layer.group.groupType !== 'time_ordered_groups'">
                            <span>Product: </span>
                            <mat-form-field
                                *ngIf="!embed"
                                style="width: 64%"
                                subscriptSizing="dynamic"
                            >
                                <mat-select
                                    (valueChange)="productSelected($event)"
                                    [formControl]="productListCtrl"
                                >
                                    <mat-option>
                                        <ngx-mat-select-search
                                            [placeholderLabel]="'Input product name'"
                                            [noEntriesFoundLabel]="'No product found'"
                                            [formControl]="productListFilterCtrl"
                                        >
                                            <mat-icon ngxMatSelectSearchClear
                                                ><i class="fa fa-times"></i
                                            ></mat-icon>
                                        </ngx-mat-select-search>
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let product of filteredProductList | async"
                                        [value]="product"
                                    >
                                        {{ product.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span *ngIf="embed">
                                {{ layer.product.name }}
                            </span>
                            <button
                                mat-icon-button
                                *ngIf="!embed"
                                class="action-button"
                                (click)="openProductInfoDialog($event)"
                                matTooltip="Product information"
                                [matTooltipPosition]="'after'"
                            >
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="layer.name">
                        <span class="layer-name">{{ layer.name }}</span>
                    </div>
                </div>

                <div class="layer-actions-wrapper flex justify-between items-center">
                    <div *ngIf="layer.product">
                        <span>{{ getLayerFormattedDate() }} </span>
                        <span
                            *ngIf="layer.group && layer.group.groupType === 'time_ordered_groups'"
                        >
                            {{ getFormattedForecast() }}
                        </span>
                    </div>

                    <div class="layer-actions flex">
                        <div [class]="!layer.display ? 'action-selected' : ''">
                            <button
                                mat-icon-button
                                class="action-button"
                                (click)="toggleVisibility($event)"
                                [matTooltip]="layer.display ? 'Hide layer' : 'Show layer'"
                                [matTooltipPosition]="'above'"
                            >
                                <i
                                    [class]="layer.display ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>

                        <div [class]="layer.pinned ? 'action-selected' : ''">
                            <button
                                mat-icon-button
                                *ngIf="layer.product && !embed"
                                class="action-button"
                                (click)="pinLayer($event)"
                                [matTooltip]="layer.pinned ? 'Unpin layer' : 'Pin layer'"
                                [matTooltipPosition]="'above'"
                            >
                                <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div *ngIf="layer.product" [class]="layer.linked ? 'action-selected' : ''">
                            <button
                                mat-icon-button
                                *ngIf="!embed"
                                class="action-button"
                                (click)="linkLayer($event)"
                                [matTooltip]="layer.linked ? 'Unlink layer' : 'Link layer'"
                                [matTooltipPosition]="'above'"
                            >
                                <i
                                    [class]="layer.linked ? 'fa fa-link' : 'fa fa-chain-broken'"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                        <button
                            mat-icon-button
                            *ngIf="!embed"
                            class="action-button"
                            (click)="deleteLayer()"
                            matTooltip="Delete layer"
                            [matTooltipPosition]="'above'"
                        >
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                        <div *ngIf="layer.product">
                            <button
                                mat-icon-button
                                *ngIf="selectedLayerKey == layer.key && layer.product.areaAllowed"
                                class="action-button"
                                (click)="showBorder($event)"
                                [matTooltip]="layer.showBorder ? 'Hide border' : 'Show border'"
                                [matTooltipPosition]="'above'"
                            >
                                <i class="fa fa-crop" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            [ngClass]="
                legend.data.type === 'categorical' && categoricalLegendType === 'extended'
                    ? ['justify-start', 'items-start']
                    : ['justify-center', 'items-center']
            "
            class="legend-wrapper flex"
            *ngIf="legend && (layer.key === selectedLayerKey || layer.pinned)"
        >
            <app-legend
                #legendComponent
                [hidden]="legend.data.type === 'categorical'"
                [product]="
                    layer.group && layer.group.groupType === 'time_ordered_groups'
                        ? layer.group.mainProduct
                        : layer.product
                "
                [legend]="legend"
                (rangeChange)="updateLegendRange($event)"
            >
            </app-legend>
            <button
                (click)="setCategoricalLegendStyle()"
                *ngIf="legend.data.type === 'categorical'"
                [matTooltip]="
                    categoricalLegendType === 'extended' ? 'Collapse legend' : 'Expand legend'
                "
                mat-button
                class="button-categorical-legend"
            >
                <mat-icon class="icon-categorical-legend">
                    <i
                        [class]="
                            categoricalLegendType === 'extended'
                                ? 'fa fa-angle-right'
                                : 'fa fa-angle-down'
                        "
                    ></i>
                </mat-icon>
            </button>
            <app-categorical-legend
                [class]="categoricalLegendType === 'extended' ? 'extended' : ''"
                *ngIf="legend.data.type === 'categorical'"
                [mode]="legendMode"
                [legend]="legend"
            >
            </app-categorical-legend>
        </div>

        <div
            class="opacity-slider flex justify-center items-center"
            *ngIf="selectedLayerKey == layer.key || layer.pinned"
        >
            <span class="opacity-tooltip pr-1.5">Opacity</span>
            <mat-slider step="1" color="accent">
                <input
                    matSliderThumb
                    [(ngModel)]="layer.opacity"
                    (input)="layerOpacity$.next(ngSliderThumb.value)"
                    #ngSliderThumb="matSliderThumb"
                />
            </mat-slider>
        </div>

        <mat-progress-bar
            mode="indeterminate"
            color="accent"
            [ngStyle]="{ opacity: isLoading() ? 100 : 0 }"
        ></mat-progress-bar>
    </div>
</mat-card>
