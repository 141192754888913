<div class="embed-dialog-wrapper flex flex-col w-full h-full">
    <div class="dialog-header justify-between items-center flex">
        <div>
            <h3 class="font-bold">{{ title }}</h3>
        </div>
        <div>
            <button class="embed-dismiss" mat-raised-button color="warn" (click)="dismiss()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <div class="overflow-y-auto">
        <div class="embed-data gap-4 flex flex-col">
            <div class="flex flex-col">
                <mat-form-field>
                    <textarea
                        matInput
                        placeholder="Description"
                        [(ngModel)]="embedLink.description"
                    ></textarea>
                </mat-form-field>
                <div *ngIf="shareLink">
                    <h4>Share link</h4>
                    <span class="share-text flex">
                        {{ shareLink }}
                    </span>
                </div>
            </div>
        </div>

        <div class="map-preview gap-5 justify-center items-center flex flex-col">
            <app-map-preview (mapReady)="onMapReady($event)"></app-map-preview>
        </div>

        <div class="dialog-actions justify-between items-center flex">
            <div>
                <button
                    mat-raised-button
                    [hidden]="mode !== 'edit'"
                    (click)="delete()"
                    color="warn"
                    matTooltip="Delete embed link"
                    [matTooltipPosition]="'right'"
                >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </div>

            <div>
                <button class="embed-accept" mat-raised-button (click)="save()" color="accent">
                    <div class="flex justify-center items-center">
                        <i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;Save
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
