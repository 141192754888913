<div class="roi-management-wrapper flex flex-col">
    <div class="title-wrapper flex flex-row justify-around items-center gap-2">
        <div class="flex flex-col grow shrink basis-0 justify-center items-center">
            <span class="title"><i class="icon-w-text fa fa-cogs"></i>ROI Management</span>
            <div class="flex flex-row gap-4 text-white">
                <div>Regions: {{ userRegions }}</div>
                <div>-</div>
                <div>Area: {{ userArea | area | async }}</div>
            </div>
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="content flex flex-col gap-4">
        <mat-form-field class="filter-rois">
            <mat-label>Filter</mat-label>
            <input
                #filterRoisInput
                matInput
                (keyup)="applyFilter(filterRoisInput.value)"
                placeholder="Filter"
            />
        </mat-form-field>

        <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="delete">
                <mat-header-cell *matHeaderCellDef appAccess="delete-roi" style="flex: 10%"
                    >Delete</mat-header-cell
                >
                <mat-cell *matCellDef="let element" appAccess="delete-roi" style="flex: 10%">
                    <button mat-icon-button (click)="askDelete(element)">
                        <i class="fa fa-trash"></i>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit">
                <mat-header-cell *matHeaderCellDef style="flex: 15%">Edit</mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: 15%">
                    <button mat-icon-button (click)="edit(element)" *ngIf="!element.edit">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button mat-icon-button (click)="confirmEdit(element)" *ngIf="element.edit">
                        <i class="fa fa-check"></i>
                    </button>
                    <button mat-icon-button (click)="cancelEdit(element)" *ngIf="element.edit">
                        <i class="fa fa-times"></i>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 10%"
                    >ID</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 10%">
                    <span>{{ element.id }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 20%"
                    >Name</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 20%">
                    <div class="flex flex-row" *ngIf="!element.edit">
                        <span *ngIf="element.name">{{ element.name }}</span>
                        <span *ngIf="!element.name">ROI {{ element.id }}</span>
                    </div>

                    <form class="flex flex-col" *ngIf="element.edit">
                        <mat-form-field>
                            <textarea
                                matInput
                                placeholder="Name"
                                [(ngModel)]="element.editName"
                                name="roirow-name"
                            >
                            </textarea>
                        </mat-form-field>
                    </form>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 25%"
                    >Description</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 25%; padding-left: 5px">
                    <span *ngIf="element.description && !element.edit">{{
                        element.description
                    }}</span>

                    <form class="flex flex-col" *ngIf="element.edit">
                        <mat-form-field>
                            <textarea
                                matInput
                                placeholder="Description"
                                [(ngModel)]="element.editDescription"
                                name="roirow-description"
                            ></textarea>
                        </mat-form-field>
                    </form>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 15%"
                    >Area</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 15%">
                    {{ element.area | area | async }}</mat-cell
                >
            </ng-container>

            <ng-container matColumnDef="display">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 10%"
                    >Display</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 10%">
                    {{ element.display }}</mat-cell
                >
            </ng-container>

            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 15%"
                    >Created at</mat-header-cell
                >
                <mat-cell *matCellDef="let element" style="flex: 15%">
                    {{ element.created_at }}</mat-cell
                >
            </ng-container>

            <ng-container matColumnDef="labels">
                <mat-header-cell *matHeaderCellDef style="flex: 25%">Labels</mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: 25%">
                    <div class="chip-list">
                        <div *ngIf="!element.edit">
                            <mat-chip-listbox>
                                <mat-chip-option
                                    class="roi-label"
                                    *ngFor="let label of element.labels"
                                    [ngStyle]="{
                                        color: label.color,
                                        backgroundColor: label.color
                                    }"
                                    [removable]="element.edit"
                                    (removed)="deleteLabel(element, label)"
                                >
                                    <span>{{ label.title }}</span>
                                    <mat-icon
                                        matChipRemove
                                        *ngIf="element.edit"
                                        class="fa fa-times"
                                    ></mat-icon>
                                </mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                        <div *ngIf="element.edit">
                            <mat-form-field>
                                <mat-chip-grid #labelsList>
                                    <mat-chip-row
                                        class="roi-label"
                                        *ngFor="let label of element.editLabels"
                                        [ngStyle]="{
                                            color: label.color,
                                            backgroundColor: label.color
                                        }"
                                        [removable]="element.edit"
                                        (removed)="deleteLabel(element, label)"
                                    >
                                        <span>{{ label.title }}</span>
                                        <mat-icon
                                            matChipRemove
                                            *ngIf="element.edit"
                                            class="fa fa-times"
                                        ></mat-icon>
                                    </mat-chip-row>
                                    <input
                                        id="labelSearch"
                                        [formControl]="searchLabels"
                                        [matChipInputFor]="labelsList"
                                        [matChipInputAddOnBlur]="addOnBlur"
                                        [matAutocomplete]="auto"
                                        (focus)="updateLabelsPool(element)"
                                    />
                                </mat-chip-grid>
                                <mat-autocomplete
                                    #auto="matAutocomplete"
                                    (optionSelected)="addLabelFromAutocomplete($event, element)"
                                >
                                    <mat-option
                                        *ngFor="let userLabel of labelPool"
                                        [value]="userLabel"
                                    >
                                        <mat-chip-option
                                            (click)="addLabel(element, userLabel)"
                                            [ngStyle]="{
                                                color: userLabel.color,
                                                backgroundColor: userLabel.color
                                            }"
                                            class="roi-label"
                                        >
                                            <span>{{ userLabel.title }}</span>
                                        </mat-chip-option>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="map">
                <mat-header-cell *matHeaderCellDef style="flex: 30%">Map</mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: 30%">
                    <app-map-preview
                        (mapReady)="setRegion($event, element)"
                        [width]="'150px'"
                        [height]="'150px'"
                    ></app-map-preview>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="pageSize" showFirstLastButtons></mat-paginator>
    </div>
</div>
