<div>
    <div class="title-wrapper simple-info-header flex justify-between items-center">
        <div>
            <span class="title">{{ headerText }}</span>
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times icon-close"></i>
        </button>
    </div>
    <p class="simple-info-text">{{ mainText }}</p>
    <div class="justify-between items-center flex flex-col">
        <p class="txt-info">{{ attribution }}</p>
        <p class="version">{{ version }}</p>
    </div>
</div>
