<div class="overflow-x-hidden flex flex-col flex-grow w-full h-full">
    <div *ngIf="showHeader" class="flex h-full max-h-20 justify-center items-center">
        <app-header class="header-wrapper"></app-header>
    </div>
    <div class="flex h-full">
        <app-map></app-map>
    </div>
    <mat-progress-bar
        *ngIf="loadingTimeline"
        mode="indeterminate"
        color="accent"
    ></mat-progress-bar>
    <div
        [hidden]="!displayGroupProductTimeline()"
        class="flex justify-center items-center h-full max-h-20"
    >
        <div class="timeline-forecast">
            <span>Forecast time</span>
        </div>
        <div class="h-20 overflow-y-auto ml-1 flex-grow !flex">
            <app-timeline
                #groupProductTimeline
                class="flex ml-1 flex-grow flex-shrink basis-0"
                [groupProductTimeLine]="true"
                (onDateSelected)="groupProductTimelineDateSelected($event)"
            >
            </app-timeline>
        </div>

        <div class="timeline-tools-wrapper flex flex-col justify-center items-center basis-48">
            <div class="timeline-tools-buttons flex justify-around items-center gap-1">
                <button
                    mat-mini-fab
                    (click)="setNextGroupProduct(-1)"
                    matTooltip="Set previous group product"
                    matTooltipPosition="above"
                    class="button-timeline"
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-left"></i></mat-icon>
                </button>
                <div class="text-xs" *ngIf="selectedLayer">
                    <span>{{ selectedLayer.getDate() | date: 'yyyy-MM-dd' }}</span>
                    <span>{{ getFormattedForecast() }}</span>
                </div>
                <button
                    mat-mini-fab
                    (click)="setNextGroupProduct(1)"
                    matTooltip="set next group product"
                    matTooltipPosition="above"
                    class="button-timeline"
                    color=""
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-right"></i></mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div
        class="touch-none overflow-y-hidden max-h-[100px] flex justify-end items-center flex-grow w-full"
    >
        <!-- Timeline component and control buttons -->
        <div class="h-[100px] overflow-y-auto flex flex-grow ml-5">
            <app-timeline
                #timeline
                class="flex-grow ml-5"
                (onDateSelected)="timelineDateSelected($event)"
                (onDateZoom)="timelineZoomChanged($event)"
            >
            </app-timeline>
        </div>

        <div class="timeline-tools-wrapper flex flex-col justify-center items-center">
            <div class="timeline-tools-buttons flex justify-around items-center gap-2">
                <button
                    mat-mini-fab
                    (click)="dateShift.next(-10)"
                    matTooltip="- 10 days"
                    matTooltipPosition="above"
                    class="button-timeline"
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-double-left"></i></mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="dateShift.next(-1)"
                    matTooltip="- 1 day"
                    matTooltipPosition="above"
                    class="button-timeline"
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-left"></i></mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="dateShift.next(1)"
                    matTooltip="+ 1 day"
                    matTooltipPosition="above"
                    class="button-timeline"
                    color=""
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-right"></i></mat-icon>
                </button>
                <button
                    mat-mini-fab
                    (click)="dateShift.next(10)"
                    matTooltip="+ 10 days"
                    matTooltipPosition="above"
                    class="button-timeline"
                    color=""
                    [disabled]="selectedProduct === undefined || hasAvailabilityData !== true"
                >
                    <mat-icon><i class="fa fa-angle-double-right"></i></mat-icon>
                </button>
            </div>

            <mat-form-field
                matTooltip="Set date"
                matTooltipPosition="above"
                class="timelineDatepicker the-dense-zone"
                subscriptSizing="dynamic"
            >
                <input
                    matInput
                    [(ngModel)]="selectedDate"
                    (dateChange)="onDateSelected($event)"
                    [matDatepicker]="timelineDatepicker"
                    [disabled]="
                        productAvailDisplayed.isEmpty() ||
                        selectedProduct === undefined ||
                        hasAvailabilityData !== true
                    "
                    [matDatepickerFilter]="productAvailDisplayed.isIncluded"
                />
                <mat-datepicker-toggle matSuffix [for]="timelineDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #timelineDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
        <!-- End of Timeline component and control buttons -->
    </div>
</div>
