import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';
import * as L from 'leaflet';
import { GeoJSON } from 'geojson';

import { DialogService } from '../dialog/dialog.service';
import { RoiService } from '../../services/roi.service';
import { environment } from '../../environments/environment';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs';
import { Label } from '../../models/label';

/**
 * Model for each row of the Region table.
 */
interface RegionRow {
    id: number;
    name: string;
    description: string;
    area: number;
    display: string;
    created_at: string;
    geojson: any;
    edit: boolean;
    editName?: string;
    editDescription?: string;
    labels: any;
    editLabels?: any;
}

/**
 * Layers styles.
 */
export const roiStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0.25,
};

@Component({
    selector: 'app-roi-management',
    templateUrl: './roi-management.component.html',
    styleUrls: ['./roi-management.component.scss'],
})
export class RoiManagementComponent implements OnInit {
    /**
     * Material table paginator.
     */
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * Material table sortable.
     */
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    /**
     * Delete region event.
     */
    @Output() deleteRegion = new EventEmitter<any>();

    /**
     * Update region event.
     */
    @Output() updateRegion = new EventEmitter<any>();

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = [
        'delete',
        'edit',
        'id',
        'name',
        'description',
        'area',
        'display',
        'created_at',
        'labels',
        'map',
    ];

    /**
     * Material table data source.
     * @type {MatTableDataSource<any>}
     */
    dataSource = new MatTableDataSource([]);

    /**
     * Number of regions that belongs to the user.
     * @type {number}
     */
    userRegions = 0;

    /**
     * Sum of the areas of each user's region.
     * @type {number}
     */
    userArea = 0;

    /**
     * List of rows with the region info.
     * @type {any[]}
     */
    regionRows: RegionRow[] = [];

    /**
     * Mat paginator available page sizes
     * @type {number[]}
     */
    pageSize = [3, 1, 2];

    /**
     * Whether or not the chipEnd event will be emitted when the input is blurred
     */
    public addOnBlur = true;

    /**
     *The list of key codes that will trigger a chipEnd event
     */
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    /**
     * List of all the user labels
     */
    private userLabels: any;

    /**
     * List of labels that can still be linked to this.region (not linked already)
     */
    public labelPool: any;

    /**
     * Search labels form control used while adding a new label to a ROI
     */
    searchLabels: UntypedFormControl = new UntypedFormControl('');

    /**
     * Default constructor.
     * @param {MatDialogRef<RoiManagementComponent>} dialogRef
     * @param data
     * @param dialogService
     * @param roiService
     */
    constructor(
        private dialogRef: MatDialogRef<RoiManagementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogService: DialogService,
        private roiService: RoiService
    ) {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.setRegionList(this.data.regions);
    }

    /**
     * Add the region as a layer to the preview map.
     * @param {Map} map
     * @param region
     */
    setRegion(map: L.Map, region) {
        const regionLayer = L.geoJSON(<GeoJSON>region.geojson);
        regionLayer.setStyle(roiStyle);
        regionLayer.addTo(map);

        setTimeout(() => {
            map.invalidateSize();
            map.fitBounds(regionLayer.getBounds());
        }, 200);
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Set edit mode.
     * @param roi
     */
    edit(roi: RegionRow) {
        roi.edit = true;
        roi.editName = roi.name;
        roi.editDescription = roi.description;
        roi.editLabels = Array.from(roi.labels);
        this.labelPool = this.userLabels;
        this.updateLabelsPool(roi);
        this.searchLabels.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
            if (this.userLabels && value && value.length > 0) {
                this.labelPool = this.userLabels.filter(
                    (l) =>
                        roi.editLabels.map((rl) => rl.id).indexOf(l.id) === -1 &&
                        l.title.toUpperCase().indexOf(value.toUpperCase()) > -1
                );
            } else {
                this.updateLabelsPool(roi);
            }
        });
    }

    /**
     * Confirm edit mode.
     * @param {RegionRow} roi
     */
    confirmEdit(roi: RegionRow) {
        roi.name = roi.editName;
        roi.description = roi.editDescription;
        roi.labels = roi.editLabels;
        roi.edit = false;
        this.updateRegion.emit({
            id: roi.id,
            name: roi.name,
            description: roi.description,
            labels: roi.labels,
        });
    }

    /**
     * Cancel edit mode.
     * @param {RegionRow} roi
     */
    cancelEdit(roi: RegionRow) {
        roi.edit = false;
    }

    /**
     * Show delete confirmation.
     * @param roi
     */
    askDelete(roi: RegionRow) {
        const deleteDialogRef = this.dialogService.openConfirm(
            'Delete region',
            'Are you sure?',
            'fa-trash'
        );

        deleteDialogRef.afterClosed().subscribe((confirm) => {
            if (confirm) {
                this.delete(roi);
            }
        });
    }

    /**
     * Delete the ROI.
     * @param roi
     */
    delete(roi: RegionRow) {
        this.deleteRegion.emit(roi.id);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filterPredicate = (data: RegionRow, filter: string) => {
            return this.filterRegionTable(data, filter);
        };
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    filterRegionTable(data: RegionRow, filterValue: string) {
        for (const dataKey in data) {
            if (data[dataKey] && data[dataKey].toString().toLowerCase().indexOf(filterValue) > -1) {
                return true;
            }
        }

        if (
            data.labels &&
            data.labels
                .map((l) => l.title.trim().toLowerCase())
                .some((t) => t.indexOf(filterValue) > -1)
        ) {
            return true;
        }
        return false;
    }

    /**
     * Retrieves all the labels of the user
     */
    private setLabelsPool() {
        this.roiService.getAllUserRoiLabels().subscribe((result: Label[]) => {
            this.userLabels = result;
            this.labelPool = this.userLabels;
        });
    }

    /**
     * Retrieves all the labels of the user which are not linked to this.region
     */
    updateLabelsPool(roi: RegionRow) {
        if (!this.searchLabels.value) {
            this.labelPool = this.userLabels.filter(
                (l) => roi.editLabels.map((rl) => Number(rl.id)).indexOf(Number(l.id)) === -1
            );
        }
    }

    /**
     * Vinculates the given label with this.region
     * @param label
     */
    addLabel(roi: RegionRow, label) {
        // this.searchLabels.setValue(''); // Not working for mat-chips, it's a known issue
        // As seen in: https://github.com/angular/components/issues/10968
        document.getElementById('labelSearch')['value'] = '';
        if (label && label.id) {
            roi.editLabels.push(label);
            const index = this.labelPool.indexOf(label);
            this.labelPool.splice(index, 1);
            return;
        }
    }

    /**
     * Removes the link for the given lavel with this.region
     * @param label
     */
    deleteLabel(roi: RegionRow, label): void {
        const index = roi.editLabels.indexOf(label);
        if (index > -1) {
            roi.editLabels.splice(index, 1);
            this.labelPool.push(label);
        }
    }

    /**
     * Captures the matAutocomplete event to vinculate the user's picked label with this.region
     * @param event
     */
    addLabelFromAutocomplete(event: MatAutocompleteSelectedEvent, roi: RegionRow): void {
        const value = event.option.value;
        this.addLabel(roi, value);
    }

    /**
     * Fill the datasource with the regions info
     * @param regions
     */
    setRegionList(regions) {
        let totalArea = 0;
        this.regionRows = [];
        for (const region of regions) {
            const regionArea = region.getArea();
            this.regionRows.push({
                id: region.id,
                name: region.name,
                description: region.description,
                created_at: moment(region.created_at).fromNow(),
                display: region.display ? 'Yes' : 'No',
                geojson: region.geojson,
                area: regionArea,
                edit: false,
                labels: region.labels,
            });
            totalArea += regionArea;
        }

        this.userArea = totalArea;
        this.userRegions = this.data.regions.length;
        this.dataSource.data = this.regionRows;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setLabelsPool();
    }
}
