import { Injectable } from '@angular/core';
import { Observable, Subject, from as observableFrom } from 'rxjs';
import { ViewportData } from '../models/viewport-data';

@Injectable({
    providedIn: 'root',
})
export class ViewportService {
    /**
     * RxJs Subject that produces the viewport update data.
     * @type {Subject<any>}
     */
    outputViewport = new Subject<ViewportData>();

    /**
     * current viewport data
     */
    viewport: ViewportData;

    /**
     * Default constructor
     */
    constructor() {}

    /**
     * Emit the current viewport.
     */
    private emitViewport() {
        this.outputViewport.next(this.get());
    }

    /**
     * Get current viewport
     * @returns {ViewportData}
     */
    get(): ViewportData {
        return this.viewport;
    }

    /**
     * Set current viewport
     * @param {ViewportData} data
     */
    set(data: ViewportData) {
        this.viewport = data;
        this.emitViewport();
    }

    /**
     * Return an observable from the Subject, that emits the viewport updates.
     * @returns {Observable<any>}
     */
    watch(): Observable<ViewportData> {
        return observableFrom(this.outputViewport);
    }

    /**
     * Reset the subject to avoid multiples observables to be stored
     */
    resetOutputViewport() {
        this.outputViewport = new Subject<ViewportData>();
    }
}
