<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-info-circle icon-w-text"></i>{{ title }}</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div class="roi-info-wrapper flex flex-col gap-4">
    <div class="flex">
        <span class="roi-key">Area:</span>
        <span class="roi-value">{{ dialogData.area | area | async }}</span>
    </div>
</div>
