import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as turf from '@turf/turf';
import { Units } from '@turf/helpers';
import { Region } from '../../models/region';
import { GeoJsonObject } from 'geojson';
import { environment } from '../../environments/environment';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import * as L from 'leaflet';
import { RoiService } from '../../services/roi.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Label } from '../../models/label';
import { intersect } from '../../utils/utils';

/**
 * Dialog modes type.
 */
export type RoiMode = 'edit' | 'create';

/**
 * Dialog result interface.
 */
export interface RoiPreviewResult {
    mode?: RoiMode;
    regions?: Array<Region>;
    delete?: boolean;
}

/**
 * Constant to define the edit mode
 * @type {string}
 */
const EDIT_MODE: RoiMode = 'edit';

/**
 * Constant to define the create mode
 * @type {string}
 */
const CREATE_MODE: RoiMode = 'create';

/**
 * Default style for ROIs on the map
 * @type {string}
 */
const roiEditStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0.25,
};
const roiCreateStyle = <L.PathOptions>{ color: '#BBBBBB', fillOpacity: 0.25 };
const roiOverStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0.25,
};

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-roi-preview-dialog',
    templateUrl: './roi-preview-dialog.component.html',
    styleUrls: ['./roi-preview-dialog.component.scss'],
})

/**
 * Regions dialog component
 */
export class RoiPreviewDialogComponent implements OnInit {
    /**
     * Preview mode.
     */
    public mode: RoiMode;

    /**
     * Dialog title.
     */
    public title = '';

    /**
     * Dom reference to the Leaflet map preview.
     */
    private map: L.Map; // Map DOM reference

    /**
     * Geometries collection
     */
    public geometries: Object[] = [];

    /**
     * Temporary active editing region
     */
    public region: Region = new Region();

    /**
     * Fields to add to each geometry name in case of multipolygon upload.
     *
     * {field: [list of values]}
     */
    public fieldNames: any = undefined;

    /**
     * Selected region name field.
     */
    public fieldSelected: string = undefined;

    /**
     * Whether to store a shapefile in different regions
     */
    public separatedRegions: boolean;

    /**
     * Currently selected geometries to import
     * @type {any[]}
     */
    public selectedGeometries: Array<number> = [];

    /**
     * Layer group for the geometries
     * @type {LayerGroup<any>}
     */
    private layerGroup: L.LayerGroup = new L.LayerGroup();

    /**
     * The new layer that will be added
     * @type {any}
     */
    public newLayer: any;

    /**
     * Boolean to allow the label chips to be removed
     */
    public removableLabels = true;

    /**
     * Whether or not the chipEnd event will be emitted when the input is blurred
     */
    public addOnBlur = true;

    /**
     *The list of key codes that will trigger a chipEnd event
     */
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    /**
     * List of all the user labels
     */
    private userLabels: Label[] = [];

    /**
     * List of labels that can still be linked to this.region (not linked already)
     */
    public labelPool: Label[] = [];

    /**
     * Search labels form control used while adding a new label to a ROI
     */
    searchLabels: UntypedFormControl = new UntypedFormControl('');

    /**
     * ROI name separator
     */
    public separator = '-';

    /**
     * ROI properties list
     */
    roiPropertiesList = [];

    /**
     * Array with the properties to generate ROI names
     */
    roiNameBlocks = [];

    /**
     * Array with the deleted properties (needed to use CDK, but it does not have any purpose)
     */
    deletedRoiNameBlocks = [];

    /**
     * Text to include in the ROI name
     */
    textBlock = '';

    /**
     * List of examples for the ROI name generation
     */
    outputExample = [];

    /**
     * List with ROI properties to generate the examples
     */
    exampleProperties = [];

    /**
     * FormControl used to add ROI properties
     */
    roiProperties = new UntypedFormControl();

    // -----------------------------------------------------------------------------------------------------------------

    /**
     * Component constructor
     *
     * @param {MatDialogRef<RoiPreviewDialogComponent>} dialogRef Reference to the ROI preview dialog component
     * @param data Dialog data, containing the region
     */
    constructor(
        public dialogRef: MatDialogRef<RoiPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public roiService: RoiService,
        private userService: UserService
    ) {
        this.region = data.region;

        if (data.region.labels) {
            this.region.labels = [...data.region.labels];
        }

        if (this.region.geojson.features !== undefined && this.region.geojson.features.length > 0) {
            // Feature collection
            const propertiesSet: Set<string>[] = this.region.geojson.features.map(
                (feature) => new Set(Object.keys(feature.properties))
            );
            const properties = intersect(...propertiesSet);

            this.exampleProperties = [];
            let i = 0;
            while (i < 3 && i < this.region.geojson.features.length) {
                this.exampleProperties.push(this.region.geojson.features[i].properties);
                i++;
            }
            // Extract field names
            this.fieldNames = {};

            properties.forEach((key) => {
                this.fieldNames[key.toString()] = this.region.geojson.features.map(
                    (feature) => feature.properties[key.toString()]
                );
            });

            this.roiPropertiesList = Object.keys(this.fieldNames);
        }
    }

    /**
     * Default Angular init method
     */
    ngOnInit() {
        this.setTitle();
        this.setLabelsPool();
        this.searchLabels.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
            if (this.userLabels && value && value.length > 0 && this.region.labels) {
                this.labelPool = this.userLabels.filter(
                    (l) =>
                        this.region.labels.map((rl) => rl.id).indexOf(l.id) === -1 &&
                        l.title.toUpperCase().indexOf(value.toUpperCase()) > -1
                );
            } else {
                this.updateLabelsPool();
            }
        });
    }

    setTitle() {
        if (this.mode === CREATE_MODE) {
            this.title = 'Upload ROIs';
        } else {
            this.title = 'Edit ROI';
        }
    }

    /**
     *  Save the region that is in the preview and add it to the map.
     */
    save() {
        const dialogResult: RoiPreviewResult = {
            mode: this.mode,
        };

        if (this.mode == CREATE_MODE) {
            dialogResult.regions = this.generateRegions();
        } else {
            dialogResult.regions = [this.region];
        }

        this.dialogRef.close(dialogResult);
    }

    /**
     * Delete the region.
     */
    delete() {
        if (confirm(`Are you sure to delete ${this.region.name}?`)) {
            const dialogResult: RoiPreviewResult = {
                delete: true,
            };

            this.dialogRef.close(dialogResult);
        }
    }

    /**
     * Dismiss the changes.
     */
    dismiss() {
        this.dialogRef.close();
    }

    /**
     * Preview the GeoJSON passed as argument
     */
    previewRegion() {
        const totalLayer = L.geoJSON(<GeoJsonObject>this.region.geojson);

        if (this.region.id) {
            this.mode = EDIT_MODE;
            this.setTitle();
            totalLayer.setStyle(roiEditStyle);
            totalLayer.addTo(this.layerGroup);
        } else {
            this.mode = CREATE_MODE;
            this.setTitle();
            this.geometries = this.extractGeometriesFromGeoJSON(this.region.geojson);
            this.selectedGeometries = [];

            for (let index = 0; index < this.geometries.length; index++) {
                // Selected by default
                this.selectedGeometries.push(index);

                if (
                    this.geometries[index]['type'] === 'Point' &&
                    this.geometries[index]['radius']
                ) {
                    // unit of the radius
                    const unit: Units = 'meters';
                    // steps: number of points of the polygon that represents the circle
                    const options = { steps: 64, units: unit };
                    const turfCircle = turf.circle(
                        this.region.geojson.geometries[0].coordinates,
                        this.geometries[index]['radius'],
                        options
                    );
                    this.geometries[index] = {
                        type: 'Polygon',
                        coordinates: [turfCircle.geometry.coordinates[0]],
                    };
                }
                this.newLayer = L.geoJSON(<GeoJsonObject>this.geometries[index]);

                this.newLayer.setStyle(roiOverStyle);
                this.newLayer.options['index'] = index;
                this.newLayer.addTo(this.layerGroup);

                // Layer events
                this.newLayer.on('mouseover', (event: L.LeafletEvent) => {
                    if (this.selectedGeometries.indexOf(event.target.options['index']) == -1) {
                        event.target.setStyle(roiOverStyle);
                    }
                });
                this.newLayer.on('mouseout', (event: L.LeafletEvent) => {
                    if (this.selectedGeometries.indexOf(event.target.options['index']) == -1) {
                        event.target.setStyle(roiCreateStyle);
                    }
                });

                this.newLayer.on('mousedown', (event: L.LeafletEvent) => {
                    let roiStyle;

                    const index = this.selectedGeometries.indexOf(event.target.options['index']);
                    if (index !== -1) {
                        this.selectedGeometries.splice(index, 1);
                        roiStyle = roiCreateStyle;
                    } else {
                        this.selectedGeometries.push(event.target.options['index']);
                        roiStyle = roiOverStyle;
                    }

                    event.target.setStyle(roiStyle);
                });
            }
        }

        this.map.invalidateSize();
        // In leaflet a circle is a point with radius, if we fit the bounds to the `totalLayer`, it will display the center point of the
        // circle, so we have to check if the new region is a circle to fit the map properly
        if (
            this.region.geojson.geometries &&
            this.region.geojson.geometries[0].type === 'Point' &&
            this.region.geojson.geometries[0].radius
        ) {
            this.map.fitBounds(this.newLayer.getBounds());
        } else {
            this.map.fitBounds(totalLayer.getBounds());
        }
    }

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;
        this.layerGroup.addTo(this.map);

        // Queue update after angular lifecycle finish
        setTimeout(() => {
            this.previewRegion();
        }, 0);
    }

    /**
     * Parse a GeoJSON object in order to extract the list of geometries.
     *
     * @param {Object} geoJSON
     * @returns {Object[]}
     */
    private extractGeometriesFromGeoJSON(geoJSON: Object): Object[] {
        const geometries: Object[] = [];

        // Check the structure kind
        if (geoJSON['type'] === 'FeatureCollection') {
            for (const feature of geoJSON['features']) {
                // Allow a second level => Feature of features.
                if ('geometry' in feature) {
                    geometries.push(feature['geometry']);
                } else {
                    geometries.push(feature);
                }
            }
        } else if (geoJSON['type'] === 'GeometryCollection') {
            for (const g of geoJSON['geometries']) {
                geometries.push(g);
            }
        } else {
            geometries.push(geoJSON);
        }

        return geometries;
    }

    /**
     * Generate the dialog preview result regions.
     */
    generateRegions() {
        const regions = [];

        if (this.separatedRegions) {
            for (let index = 0; index < this.geometries.length; index++) {
                if (this.selectedGeometries.indexOf(index) !== -1) {
                    let name = '';
                    if (this.roiNameBlocks.length) {
                        for (let i = 0; i < this.roiNameBlocks.length; i++) {
                            if (this.fieldNames[this.roiNameBlocks[i]]) {
                                name = name + this.fieldNames[this.roiNameBlocks[i]][index];
                            } else {
                                name = name + this.roiNameBlocks[i];
                            }
                            if (i < this.roiNameBlocks.length - 1) {
                                name = name + this.separator;
                            }
                        }
                    } else {
                        if (this.region.name !== undefined) {
                            name =
                                this.fieldSelected === undefined
                                    ? this.region.name
                                    : `${this.region.name}_${
                                          this.fieldNames[this.fieldSelected][index]
                                      }`;
                        } else {
                            name =
                                this.fieldSelected === undefined
                                    ? this.region.fileName
                                    : `${this.fieldNames[this.fieldSelected][index]}`;
                        }
                    }
                    const region = new Region({
                        name: name,
                        description: this.region.description,
                        geojson: this.geometries[index],
                        display: true,
                        labels: this.region.labels,
                    });
                    regions.push(region);
                }
            }
        } else {
            const geometries = [];
            for (let index = 0; index < this.geometries.length; index++) {
                if (this.selectedGeometries.indexOf(index) !== -1) {
                    geometries.push(this.geometries[index]);
                }
            }

            this.region.geojson = {
                type: 'GeometryCollection',
                geometries: geometries,
            };
            this.region.display = true;
            regions.push(this.region);
        }

        return regions;
    }

    /**
     * Selects all the geometries previewed in the dialog
     * @param $event.
     */
    public selectAll($event) {
        this.selectedGeometries = [];
        if ($event.checked) {
            for (let index = 0; index < this.geometries.length; index++) {
                this.selectedGeometries.push(index);
            }
        }
        this.layerGroup.eachLayer((layer: L.GeoJSON) => {
            layer.setStyle($event.checked ? roiOverStyle : roiCreateStyle);
        });
    }

    /**
     * Retrieves all the labels of the user
     */
    private setLabelsPool() {
        if (!this.region.labels) {
            this.region.labels = [];
        }

        this.roiService.getAllUserRoiLabels().subscribe((result: Label[]) => {
            this.userLabels = result;
            this.labelPool = this.userLabels;
            if (this.region.labels) {
                this.labelPool = this.userLabels.filter(
                    (l) => this.region.labels.map((rl) => rl.id).indexOf(l.id) === -1
                );
            }
        });
    }

    /**
     * Retrieves all the labels of the user which are not linked to this.region
     */
    private updateLabelsPool() {
        if (this.region.labels) {
            this.labelPool = this.userLabels.filter(
                (l) => this.region.labels.map((rl) => rl.id).indexOf(l.id) === -1
            );
        } else {
            this.labelPool = [];
        }
    }

    /**
     * Vinculates the given label with this.region
     * @param label
     */
    addLabel(label) {
        // this.searchLabels.setValue(''); // Not working for mat-chips, it's a known issue
        // As seen in: https://github.com/angular/components/issues/10968
        document.getElementById('titleSearch')['value'] = '';
        if (label && label.id && !this.region.labels.find((l) => l.id === label.id)) {
            this.region.labels.push(label);
            this.searchLabels.reset();
            this.updateLabelsPool();
            return;
        }
    }

    /**
     * Removes the link for the given lavel with this.region
     * @param label
     */
    deleteLabel(label): void {
        const index = this.region.labels.indexOf(label);
        if (index > -1) {
            this.labelPool.push(this.region.labels[index]);
            this.region.labels.splice(index, 1);
        }
    }

    /**
     * Captures the matAutocomplete event to vinculate the user's picked label with this.region
     * @param event
     */
    addLabelFromAutocomplete(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        this.addLabel(value);
    }

    /**
     * Generate a list with example of ROI names
     */
    generateOutputExampleList() {
        this.outputExample = [];
        for (const exampleProperty of this.exampleProperties) {
            let output = '';
            for (let i = 0; i < this.roiNameBlocks.length; i++) {
                if (exampleProperty[this.roiNameBlocks[i]]) {
                    output = output + exampleProperty[this.roiNameBlocks[i]];
                } else {
                    output = output + this.roiNameBlocks[i];
                }
                if (i !== this.roiNameBlocks.length - 1) {
                    output = output + this.separator;
                }
            }

            if (output !== '') {
                this.outputExample.push(output);
            }
        }
    }

    /**
     * Drag and drop function to set order
     */
    orderRoiName(event) {
        moveItemInArray(this.roiNameBlocks, event.previousIndex, event.currentIndex);
        this.generateOutputExampleList();
    }

    /**
     * Add block of text to ROI name
     */
    addTextBlock() {
        this.roiNameBlocks.push(this.textBlock);
        this.textBlock = '';
        this.generateOutputExampleList();
    }

    /**
     * Add ROI property to ROI name
     * @param event
     */
    addRoiProperties(event) {
        if (event) {
            this.roiNameBlocks.push(event.value);
            this.generateOutputExampleList();
        }
        this.roiProperties.setValue('');
    }

    /**
     * Delete value from ROI name
     * @param event
     */
    deleteRoiNamePreviewBlock(event) {
        const valueIndex = this.roiNameBlocks.findIndex((r) => r === event);
        this.roiNameBlocks.splice(valueIndex, 1);
        this.generateOutputExampleList();
    }

    /**
     * Delete stringvalue from ROI name
     * @param textBlockValue
     */
    removeTextBlock(textBlockValue) {
        const index = this.roiNameBlocks.indexOf(textBlockValue);
        if (index) {
            this.roiNameBlocks.splice(index, 1);
            this.generateOutputExampleList();
        }
    }
}
