import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
    /**
     * Dialog title.
     */
    title: string;

    /**
     * Dialog message.
     */
    message: string;

    /**
     * Dialog title icon.
     */
    icon: string;

    /**
     * Dialog confirm message.
     */
    confirmMessage: string;

    /**
     * Dialog close message.
     */
    closeMessage: string;

    /**
     * Default constructor.
     * @param {MatDialogRef<ConfirmComponent>} dialogRef
     * @param data
     */
    constructor(
        private dialogRef: MatDialogRef<ConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title;
        this.message = data.message;
        this.icon = data.icon;
        this.confirmMessage = data.confirmMessage ? data.confirmMessage : 'Confirm';
        this.closeMessage = data.closeMessage ? data.closeMessage : 'Close';
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {}

    /**
     * Confirm action.
     */
    accept() {
        this.dialogRef.close(true);
    }

    /**
     * Cancel action.
     */
    cancel() {
        this.dialogRef.close(false);
    }
}
