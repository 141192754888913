import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import moment from 'moment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayersService } from '../../services/layers.service';

export interface ApiRequestParamsDialogData {
    notificationEmail: string | undefined;
}

@Component({
    selector: 'app-api-request-params-dialog',
    templateUrl: './api-request-params-dialog.component.html',
    styleUrls: ['./api-request-params-dialog.component.scss'],
})
export class ApiRequestParamsDialogComponent implements OnInit {
    /**
     * Value from Date Picker input in dialog for StartDate
     */
    public startDateApiRequest: Moment;

    /**
     * Value from Date Picker input in dialog for EndDate
     */
    public endDateApiRequest: Moment;

    /**
     * Value from input in dialog for Format
     */
    public formatApiRequest: string;

    /**
     * Value from input in dialog for Zipped
     */
    public zippedApiRequest: boolean;

    /**
     * FormControl for email input validation
     **/
    public emailControlApiRequest = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
    ]);

    /**
     * ApiRequestParamsDialogComponent constructor
     *
     */
    constructor(
        public dialogRef: MatDialogRef<ApiRequestParamsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ApiRequestParamsDialogData,
        private layersService: LayersService
    ) {}

    ngOnInit() {
        this.setDefaultAreaSelectParams();
    }

    /**
     * Get the values introduced in the dialog input fields
     * Then closes the dialog, emiting the event
     */
    save() {
        const paramsDict = this.getApiRequestParamValues();
        this.dialogRef.close(paramsDict);
    }

    /**
     * Closes the dialog, emiting the event
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Establishes de default values for the imput fields
     */
    private setDefaultAreaSelectParams() {
        const selectedLayer = this.layersService.getSelectedLayer();
        this.startDateApiRequest = selectedLayer ? selectedLayer.getDate() : moment();
        this.endDateApiRequest = selectedLayer ? selectedLayer.getDate() : moment();
        this.formatApiRequest = 'gtiff';
        this.zippedApiRequest = false;
        if (this.data.notificationEmail) {
            this.emailControlApiRequest.setValue(this.data.notificationEmail);
        } else {
            this.emailControlApiRequest.setValue('');
        }
    }

    /**
     * Returns a dict object containing the current values for the input fields in the dialog
     */
    public getApiRequestParamValues() {
        return {
            startDate: this.startDateApiRequest,
            endDate: this.endDateApiRequest,
            format: this.formatApiRequest,
            zipped: this.zippedApiRequest,
            notify: this.emailControlApiRequest.value,
        };
    }
}
