import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    /**
     * Method to send an event trackable in Google Analytics webapp
     *
     * @param eventAction: string
     * @param eventCategory: string
     * @param eventLabel: string
     * @param eventValue: number
     */
    public eventEmitter(
        eventAction: string,
        eventCategory: string,
        eventLabel: string = null,
        eventValue: string = null
    ) {
        // Added try catch for cases it can fail,
        // for example: the GAnalytics script doesn't get added in the index head
        try {
            gtag('event', eventAction, {
                event_category: eventCategory,
                event_label: eventLabel,
                event_value: eventValue,
            });
        } catch (e) {
            return;
        }
    }
}
