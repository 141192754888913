import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import {
    deleteJwtToken,
    deleteUserForApi,
    getDomain,
    getJwtToken,
    getUserForApi,
    getUserForApiPermissions,
    setJwtToken,
    setUserForApi,
    TOKEN_NAME,
} from '../../utils/utils';

import { environment } from '../../environments/environment';
import { User } from '../../models/user';
import moment from 'moment';
import { OidcSecurityService, LoginResponse as OidcLoginResponse } from 'angular-auth-oidc-client';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';

const AUTH_MESSAGE_KEY = 'auth-message-key';

/**
 * API /login response.
 */
interface LoginResponse {
    access_token: string;
    need_password_change: boolean;
    message?: string;
}

/**
 * Interface for a router redirect after login.
 */
export interface RouteAfterLogin {
    path: string;
    params?: any;
}

export type AccessInput = string | string[];

export function getAllowed(permissions: AccessInput): string[] {
    if (typeof permissions === 'string') {
        return [permissions];
    }
    return permissions;
}

interface AuthMessage {
    message: string;
    status: string;
    url: string;
}

/**
 * Authentication service using JWT
 */
@Injectable()
export class AuthService {
    /**
     * The name the token will have in local storage
     * @type {string}
     */
    public tokenName = 'access_token';

    /** The raw access token */
    private accessToken: string;

    /**
     * JWT Token decoded.
     */
    private tokenData: any;

    /**
     * Private copy of the environment.
     */
    private environment: any;

    /**
     * Route to redirect after a successful login.
     */
    private routeAfterLogin: RouteAfterLogin;

    /**
     * Domain in which to set cookies
     */
    private domain = getDomain();

    private userPermissions: Set<string> | null = null;
    private userRoles: Set<string> | null = null;

    private oidcAuthenticated: boolean | null = null;
    private planetToken: string | null = null;

    constructor(
        private http: HttpClient,
        private jwtHelper: JwtHelperService,
        private cookieService: CookieService,
        private oidcSecurityService: OidcSecurityService,
        private snackbar: CustomSnackbarService
    ) {
        this.environment = environment;
        this.environment.apiUrl = this.environment.apiV1;
        this.readToken();
    }

    public checkAuth({ isAuthenticated, accessToken }: OidcLoginResponse) {
        this.oidcAuthenticated = isAuthenticated;
        this.planetToken = accessToken;

        if (isAuthenticated) {
            this.setPlanetToken(accessToken);
        } else {
            // When the token is not valid, remove it, so we don't send the same token again
            this.oidcSecurityService.logoffLocal();
        }
    }

    public loadUserData(): Observable<any> {
        if (this.userPermissions && this.userRoles) {
            return observableOf(null);
        }
        return this.http
            .get<{ permission_names: string[]; roles: string[] }>(`${environment.apiV2}/users/me`, {
                headers: { 'X-Fields': '{permission_names,roles}' },
            })
            .pipe(
                catchError((err: any, caught) => {
                    if (
                        err instanceof HttpErrorResponse &&
                        (err.status === 403 || err.status === 401)
                    ) {
                        const message = {
                            message: err.error.message ?? "Couldn't check user authentication.",
                            status: err.status.toString(),
                            url: err.url,
                        };
                        localStorage.setItem(AUTH_MESSAGE_KEY, JSON.stringify(message));
                        const ref = this.showAuthMessage(message);
                        ref.afterDismissed().subscribe((x) => {
                            if (x.dismissedByAction) {
                                localStorage.removeItem(AUTH_MESSAGE_KEY);
                            }
                        });
                        // local: true for preventing the browser to refresh when contacting the auth server
                        this.logout({ local: false });
                    }
                    return throwError(err);
                }),
                tap((user) => {
                    this.userPermissions = new Set(user.permission_names);
                    this.userRoles = new Set(user.roles);
                })
            );
    }

    public checkAuthMessage(): void {
        // Check if we were showing a message before the app reloaded (because of a logout)
        const rawMessage = localStorage.getItem(AUTH_MESSAGE_KEY);
        const message = rawMessage && JSON.parse(rawMessage);
        if (message) {
            this.showAuthMessage(message);
            localStorage.removeItem(AUTH_MESSAGE_KEY);
        }
    }

    private showAuthMessage(message: AuthMessage): MatSnackBarRef<any> {
        return this.snackbar.present(message.message, 'error', message.status, message.url);
    }

    /**
     * This method stores the access_token retrieved from the backend in the local storage
     * @param {Object} credentials An object with the keys 'username' and 'password'
     * @returns {Observable<string>} The data object with the access_token or an error
     */
    public login(credentials: any): Observable<RouteAfterLogin> {
        return this.http.post<LoginResponse>(this.environment.apiLoginUrl, credentials).pipe(
            switchMap((data) => {
                let output: RouteAfterLogin = null;

                if (data.access_token) {
                    this.setAccessToken(data.access_token);
                    output = this.routeAfterLogin;
                    this.routeAfterLogin = undefined;
                }
                if (data.message) {
                    this.snackbar.present(data.message, 'warning');
                }

                return this.loadUserData().pipe(
                    take(1),
                    map(() => output)
                );
            })
        );
    }

    /**
     * Set access token.
     */
    public setAccessToken(access_token) {
        console.debug(
            'set the access token in the cookies for the xyztiles to work',
            this.domain,
            access_token
        );
        setJwtToken(access_token);
        // This cookie is needed for xyztiles to work, as it uses a/b/c subdomains
        //   (i.e. a.maps.vandersat.com).
        this.cookieService.set(TOKEN_NAME, access_token, undefined, '/', this.domain, true);
        this.readToken();
    }

    public setPlanetToken(token: string) {
        console.debug(
            'set the planet access token in the cookies for the xyztiles to work',
            this.domain,
            token
        );
        // This cookie is needed for xyztiles to work, as it uses a/b/c subdomains
        //   (i.e. a.maps.vandersat.com).
        this.cookieService.set(TOKEN_NAME, token, undefined, '/', this.domain, true);
    }

    /**
     * Read, decode and store the token.
     */
    private readToken() {
        this.tokenData = this.jwtHelper.decodeToken(getJwtToken());
    }

    /**
     * Whether the user has a valid token or not
     * @returns {boolean}
     */
    public loggedIn(): boolean {
        if (!this.jwtHelper.isTokenExpired(getJwtToken())) {
            return true;
        }
        console.debug('Checking if oidc authenticated', this.oidcAuthenticated);
        if (this.oidcAuthenticated === null) {
            console.warn(
                'Race condition: Checked if authenticated with OpenIDConnect before loading the token'
            );
            return false;
        }
        return this.oidcAuthenticated;
    }

    /**
     * Remove the token from local storage and redirects the user to login page
     */
    public logout(opts?: { local: boolean }): void {
        // If there is one app running in the base domain (E.g: vds-dev.com) and other one running in a subdomain (staging.vds-dev.com)
        // login will not work in you switch between them. To avoid this case, we have to remove the cookie from the base domain.
        if (!environment.domain && window.location.hostname.split('.').length > 2) {
            const topLevelDomain = window.location.hostname.slice(
                window.location.hostname.indexOf('.') + 1,
                window.location.hostname.length
            );
            this.cookieService.delete(TOKEN_NAME, undefined, topLevelDomain);
        }
        this.cookieService.delete(TOKEN_NAME);
        // This cookie is needed for xyztiles to work, as it uses a/b/c subdomains
        //   (i.e. a.maps.vandersat.com).
        this.cookieService.delete(TOKEN_NAME, undefined, this.domain);
        this.setUserForApi(undefined);
        deleteJwtToken();
        this.userPermissions = null;
        this.userRoles = null;
        if (this.oidcAuthenticated) {
            if (opts?.local ?? false) {
                this.oidcSecurityService.logoffLocal();
            } else {
                this.oidcSecurityService.logoff().subscribe(() => {
                    console.log('logged out');
                });
            }
        }
    }

    /**
     * Method that retrieves the user data from the JWT
     */
    public getUserData() {
        return this.tokenData?.user_claims;
    }

    public getExpirationDate(): moment.Moment | undefined {
        const expirationDate = this.jwtHelper.getTokenExpirationDate(getJwtToken());
        return expirationDate ? moment.utc(expirationDate) : undefined;
    }

    /**
     * Checks whether the user has the given role
     * @param role
     */
    public hasRole(roleOrRoles: AccessInput): boolean {
        if (this.userRoles) {
            return getAllowed(roleOrRoles).some((r) => this.userRoles.has(r));
        }
        console.warn('Tried to check roles before loading them.');
        return false;
    }

    /**
     * Checks whether the user has the given permission
     * @param permission
     */
    public hasPermission(permissionOrPermissions: AccessInput): boolean {
        const permissions = getAllowed(permissionOrPermissions);
        const userForApi = getUserForApi();
        if (userForApi) {
            const userForApiPermissions = getUserForApiPermissions();
            return permissions.some((p) => userForApiPermissions.includes(p));
        }
        if (this.userPermissions) {
            return permissions.some((p) => this.userPermissions.has(p));
        }

        console.warn('Tried to check permissions before loading them.');
        return false;
    }

    /**
     * Set the user to impersonate.
     *
     * @param {User} user to impersonate or undefined for resetting
     * @param permissions: list of permissions of the user to impersonate
     */
    public setUserForApi(user: User | undefined, permissions: string[] = []): void {
        if (user === undefined) {
            deleteUserForApi();
            // delete cookie
            this.cookieService.delete(environment.userForApi, undefined, this.domain);
            this.userPermissions = null;
            this.userRoles = null;
        } else {
            // check role 'superadmin'
            if (
                !this.hasPermission('can-impersonate-everybody') &&
                !this.hasPermission('can-impersonate')
            ) {
                return;
            }
            if (user.email !== undefined) {
                setUserForApi({ username: user.username, permissions });
                // set cookie
                this.cookieService.set(
                    environment.userForApi,
                    user.username,
                    undefined,
                    undefined,
                    this.domain,
                    true
                );
            }
        }
    }

    /**
     * Set the route to which the user will be redirected after a successful login.
     * @param path
     * @param params
     */
    setRouteAfterLogin(path: string, params: any) {
        this.routeAfterLogin = {
            path: path,
            params: params,
        };
    }

    /**
     * Check if the user needs to change the password.
     */
    needPasswordChange() {
        const data = this.getUserData();
        return data?.need_password_change;
    }

    /**
     * Check if the user has the access token cookie
     */
    hasAccessCookie() {
        const localStorageToken = getJwtToken();

        const accessCookie = this.cookieService.get(this.tokenName);

        if (!accessCookie) {
            return false;
        }
        if (accessCookie === localStorageToken) {
            return true;
        }
        if (this.planetToken === null) {
            console.warn('Tried to get planet token before loading it.');
            return false;
        }
        return accessCookie === this.planetToken;
    }

    getToken() {
        const localStorageToken = getJwtToken();
        if (localStorageToken) {
            return localStorageToken;
        }
        if (this.planetToken === null) {
            console.warn('Tried to get planet token before loading it.');
        }
        return this.planetToken;
    }
}
