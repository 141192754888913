<div class="new-release-popup">
    <div class="popup-header logo flex justify-between items-center gap-1">
        <img src="assets/planet-primarymark.png" width="30px" height="30px" />
        <h3 class="font-bold">Planetary Variables viewer</h3>
        <button class="roi-dismiss" mat-raised-button color="warn" (click)="onClick()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="padding-release">
        <app-new-release-info-popup></app-new-release-info-popup>
    </div>
</div>
