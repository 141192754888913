import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { getISODateString } from '../../utils/utils';
import { startWith, switchMap, takeUntil } from 'rxjs';
import { interval, Subject } from 'rxjs';

import { UserSettingsInfo } from '../../services/user.service';
import { LayersService } from '../../services/layers.service';
import { ProductService } from '../../services/product.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { ApiRequestsService } from '../../services/api-requests.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApiAccessManagementComponent } from '../api-access-management/api-access-management.component';
import { DialogService } from '../dialog/dialog.service';
import {
    ApiAccessInfoDialogComponent,
    ApiAccessInfoDialogData,
} from '../api-access-info-dialog/api-access-info-dialog.component';
import { ApiRequestParamsDialogComponent } from '../api-request-params-dialog/api-request-params-dialog.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ApiRequest } from '../../models/api-request';
import { ApiRequestsListInterface } from '../../services/api-requests.service';

/**
 * Time in milliseconds between get processing api request list
 */
export const timeBetweenRequest = 10000;

/**
 * Number of recent API request to display.
 */
export const recentRequestLimit = 5;

@Component({
    selector: 'app-api-access',
    templateUrl: './api-access.component.html',
    styleUrls: ['./api-access.component.scss'],
})
export class ApiAccessComponent implements OnInit {
    /**
     * Base layers map.
     */
    @Input() public baseLayers: object = {};

    /**
     * Identifier of the current base layer.
     */
    @Input() public currentLayer: object;

    /**
     * Reference to Map component edition modes object:
     * captureRegionMode: Whether the capture region mode is enabled or not
     */
    @Input() public editionModes: object;

    /**
     * Leaflet map reference.
     */
    @Input() public mapReference: L.Map;

    @Input() public userSettings: UserSettingsInfo | undefined;

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<any>(true);

    /**
     * Close sidenav event.
     * @type {EventEmitter<Map>}
     */
    @Output() closeSidenav = new EventEmitter<any>(true);

    /**
     * Material table paginator.
     */
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * Leaflet AreaSelect plugin object.
     * @type {L.AreaSelect}
     */
    private areaSelect: L.AreaSelect;

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = ['uuid', 'product', 'status', 'info'];

    /**
     * Material table data source.
     */
    dataSource = new MatTableDataSource<ApiRequest>([]);

    /**
     * List of rows with the region info.
     * @type {any[]}
     */
    apiAccessRows: ApiRequest[] = [];

    /**
     * Flag to show a spinner when loading api request list
     */
    public loadingList = true;

    /**
     * Subject to remove the observable that makes request every 10 seconds when closing sidenav
     */
    protected _onDestroy = new Subject<void>();

    /**
     * Reference to the loading dialog shown while processing a new api request
     */
    private loadingDialogRef: MatDialogRef<LoadingDialogComponent>;

    constructor(
        private layersService: LayersService,
        private snackBar: CustomSnackbarService,
        private apiRequestService: ApiRequestsService,
        private matDialogService: MatDialog,
        private dialogService: DialogService,
        private productService: ProductService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    ngOnInit() {}

    /**
     * Open the sidenav
     */
    open() {
        this._onDestroy = new Subject<void>();
        this.setDataSource();
    }

    /**
     * Close settings sidenav
     */
    close() {
        this.apiAccessRows = [];
        this._onDestroy.next();
        this._onDestroy.complete();
        this.googleAnalyticsService.eventEmitter(
            'api_request_sidenav_close',
            'sidenavs',
            'close_sidenav',
            'api_request_sidenav_closed'
        );
        this.closeSidenav.emit();
    }

    /**
     * Add the AreaSelect object to the map.
     */
    showAreaSelect() {
        this.editionModes['captureRegionMode'] = true;

        this.disableMapClick();

        // Disable text select
        document.body.classList.add('disable-select');

        // Leaflet Area Select
        this.areaSelect = L.areaSelect({ width: 100, height: 100 });
        this.areaSelect.addTo(this.mapReference);
        this.googleAnalyticsService.eventEmitter(
            'api_request_download_map_button_clicked',
            'api_request_sidenav',
            'api_requests',
            'download_map_functionality_run'
        );
    }

    /**
     * Disable the map click event.
     */
    disableMapClick() {
        this.mapReference.off('click');
    }

    /**
     * Restore the map click event.
     */
    enableMapClick() {
        this.mapClick.emit(true);
    }

    /**
     * Removes the AreaSelect object from the map.
     */
    cancelAreaSelect() {
        this.editionModes['captureRegionMode'] = false;
        // Leaflet Area Select
        this.areaSelect.remove();

        // Enable text select
        document.body.classList.remove('disable-select');

        this.enableMapClick();
    }

    /**
     * Open dialog to set specific parameter values for the gridded data api request
     */
    showParamsDialog() {
        const dialogRef = this.matDialogService.open<ApiRequestParamsDialogComponent>(
            ApiRequestParamsDialogComponent,
            {
                width: '500px',
                height: 'auto',
                panelClass: 'api-params-dialog',
                data: {
                    notificationEmail: this.userSettings?.settings?.notificationEmail,
                },
            }
        );
        dialogRef.afterClosed().subscribe((paramsFromDialog: any) => {
            if (paramsFromDialog) {
                this.getAreaSelect(paramsFromDialog);
            } else {
                this.cancelAreaSelect();
            }
        });
    }

    /**
     * Download the tile, as a TIFF, for the current product/date, that is inside the AreaSelect bounds.
     */
    getAreaSelect(areaSelectParams) {
        const bounds = this.areaSelect.getBounds();
        const selectedLayer = this.layersService.getSelectedLayer();

        if (selectedLayer !== undefined) {
            const north = bounds.getNorth();
            const south = bounds.getSouth();
            const west = bounds.getWest();
            const east = bounds.getEast();

            const params = {
                latMax: north,
                latMin: south,
                lonMax: east,
                lonMin: west,
                startDate: areaSelectParams['startDate']
                    ? getISODateString(areaSelectParams['startDate'])
                    : getISODateString(selectedLayer.getDate()),
                endDate: areaSelectParams['endDate']
                    ? getISODateString(areaSelectParams['endDate'])
                    : getISODateString(selectedLayer.getDate()),
                format: areaSelectParams['format'] ? areaSelectParams['format'] : 'gtiff',
                zipped: areaSelectParams['zipped'] ? areaSelectParams['zipped'] : false,
                notify: areaSelectParams['notify'] ? areaSelectParams['notify'] : '',
            };

            this.showProcessingApiRequestDialog();
            this.productService
                .getProductGriddedData(selectedLayer.product.apiName, params)
                .subscribe(
                    (result: any) => {
                        if (result.message === 'Download request received') {
                            this.snackBar.present(
                                `API request ${result.uuid} has been created. You can check the status of the request` +
                                    ' in the API access menu.',
                                'success'
                            );
                            this.setDataSource();
                            this.checkProcessingApiRequest();
                        }
                    },
                    (error) => {
                        this.checkProcessingApiRequest();
                    }
                );
        } else {
            this.snackBar.present('Please, select a valid product and date.');
        }

        this.cancelAreaSelect();
    }

    /**
     * Open the API Access management dialog.
     */
    showApiAccessManagement() {
        const dialogRef = this.matDialogService.open(ApiAccessManagementComponent, {
            width: '80%',
            panelClass: 'api-access-management-dialog',
        });
        this.googleAnalyticsService.eventEmitter(
            'api_request_management_button_clicked',
            'api_request_sidenav',
            'api_requests',
            'api_request_management_dialog_open'
        );
    }

    /**
     * Open the API Access info dialog.
     * @param apiAccess
     */
    openApiAccessDialog(apiRequest: ApiRequest) {
        this.apiRequestService
            .getApiRequestInfo(apiRequest.uuid)
            .subscribe((apiRequestObj: ApiRequest) => {
                this.dialogService.openComponent<
                    ApiAccessInfoDialogComponent,
                    ApiAccessInfoDialogData
                >(ApiAccessInfoDialogComponent, {
                    data: {
                        request: apiRequestObj,
                        userSettings: this.userSettings,
                    },
                });
            });
    }

    /**
     * Set the data source with the data of the recent api requests
     */
    setDataSource() {
        const httpParams = {
            limit: recentRequestLimit.toString(),
        };

        const getApiRequestListInterval = interval(timeBetweenRequest);

        getApiRequestListInterval
            .pipe(
                takeUntil(this._onDestroy),
                startWith(0),
                switchMap(() => this.apiRequestService.list(httpParams))
            )
            .subscribe((results: ApiRequestsListInterface) => {
                this.apiAccessRows = results['requests'];
                this.dataSource.data = this.apiAccessRows;
                this.dataSource.paginator = this.paginator;
                this.loadingList = false;
            });
    }

    /**
     * Method to show a dialog with a processing api request message
     */
    showProcessingApiRequestDialog() {
        this.loadingDialogRef = this.matDialogService.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            data: {
                loadingMessages: {
                    'api-request': 'Submitting API request...',
                },
            },
        });
    }

    /**
     * Method that closes the processing api request dialog
     */
    checkProcessingApiRequest() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }
}
