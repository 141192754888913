import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, RouteAfterLogin } from '../auth/auth.service';
import { UserService } from '../../services/user.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { environment } from '../../environments/environment';
import { getBrowser } from '../../utils/utils';
import { Moment } from 'moment';
import { combineLatest, interval, Observable } from 'rxjs';
import moment from 'moment';
import { map } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Selector, template and styles for login component
 */
@Component({
    selector: 'login-root',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {
    /**
     * Login form
     */
    public loginForm = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
    });

    /**
     * Forgot password form.
     */
    public forgotForm: UntypedFormGroup;

    /**
     * Forgot password view.
     */
    public forgotPassword = false;

    /**
     * Self-registration capability, disabled by default
     */
    public register = false;

    /**
     * Browser data to show/hide unsupported browser message
     */
    public browserData;

    /**
     * Background image
     */
    public backgroundImage = 'url("/assets/img/blurred_world.jpg")';

    timeToWait: { [username: string]: { nextAttempt: number; expirationPenalty: Moment } } = {};

    timeUntilNextTry$: Observable<{ totalTime: number; timeToWait: number } | null> = combineLatest(
        [this.username.valueChanges, interval(500)]
    ).pipe(
        map(([username, _]) => {
            const timeToWaitUsername = this.timeToWait[username];
            const diffTime = timeToWaitUsername?.expirationPenalty.diff(moment(), 'seconds', true);
            return diffTime > 0
                ? { totalTime: timeToWaitUsername?.nextAttempt, timeToWait: diffTime }
                : null;
        })
    );

    get username() {
        return this.loginForm.get('username');
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private userService: UserService,
        private snackbar: CustomSnackbarService,
        private oidcSecurityService: OidcSecurityService
    ) {}

    /**
     * Navigate to some URL and keep the query parameters.
     * @param {string} url
     * @param params
     */
    navigateTo(url: string, params: any = undefined) {
        this.router.navigate([url], { queryParams: params });
    }

    /**
     * Creates the login form fields and their validations
     */
    ngOnInit() {
        // If already logged go to home
        if (this.authService.loggedIn()) {
            this.navigateTo('/');
        }

        this.forgotForm = new UntypedFormGroup({
            username: new UntypedFormControl('', Validators.required),
        });

        // Enable user self registration
        this.canRegister();

        // Enable unsupported browser message
        this.browserData = getBrowser();
    }

    /**
     * Method called on the event 'onSubmit', if an access_token is received then navigates to home otherwise shows
     * the error
     */
    public login(): void {
        this.authService.login(this.loginForm.value).subscribe({
            next: (redirect: RouteAfterLogin) => {
                if (redirect !== null) {
                    console.debug('Redirecting after login');
                    if (redirect === undefined) {
                        console.debug('Redirect to /');
                        this.navigateTo('/');
                    } else {
                        console.debug('Redirect to', redirect);
                        this.navigateTo(redirect.path, redirect.params);
                    }
                }
                console.debug('redirect: ', redirect);
            },
            error: (err) => {
                console.error('After login there was an error');
                if (err instanceof HttpErrorResponse) {
                    const secondsPenalty = err.error?.next_attempt;
                    if (secondsPenalty) {
                        this.timeToWait[this.username.value] = {
                            nextAttempt: secondsPenalty,
                            expirationPenalty: moment().add(secondsPenalty, 'seconds'),
                        };
                    } else if (err.error?.message) {
                        this.snackbar.present(err.error.message, 'error');
                    }
                }
            },
        });
    }

    public loginWithPlanet() {
        this.oidcSecurityService.authorize();
    }

    /**
     * Show forgot password view.
     */
    public toggleForgotPassword() {
        this.forgotPassword = !this.forgotPassword;
    }

    /**
     * Restore password.
     */
    public restorePassword() {
        const username = this.forgotForm.value.username;
        this.userService.restorePassword(username).subscribe((response: any) => {
            this.snackbar.present(response.message);
            this.forgotPassword = false;
        });
    }

    /**
     * Enable the user self registration.
     */
    private canRegister() {
        const subdomain = location.hostname.split('.')[0];
        if (environment.selfRegistration) {
            this.register = environment.selfRegistration.indexOf(subdomain) > -1;
        }
    }

    /**
     * Go to the register view.
     */
    public createAccount() {
        this.navigateTo('/register');
    }

    /**
     * Go to the re-send activation view.
     */
    public reactivateAccount() {
        this.navigateTo('/account-reactivate');
    }

    /**
     * Convert seconds to hours, minutes and seconds.
     * @param seconds
     */
    public convertSecondsToTime(seconds: number): string {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds / 60) % 60;
        const total_seconds = Math.round(seconds % 60);

        return `${hours}h ${minutes}m ${total_seconds}s`;
    }
}
