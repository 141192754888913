import { Component, Input, OnInit } from '@angular/core';

import { toCssHexColor } from '../../utils/utils';

@Component({
    selector: 'app-categorical-legend',
    templateUrl: './categorical-legend.component.html',
    styleUrls: ['./categorical-legend.component.scss'],
})
export class CategoricalLegendComponent implements OnInit {
    /**
     * Legend data JSON
     */
    @Input() legend: any;

    /**
     * Draw mode: 'full', 'dense', 'collapsed'
     *  full: draw group labels, color boxes and category abbreviation
     *  dense: draw group labels and color boxes
     *  collapsed: draw color boxes only
     */
    @Input() mode: string;

    /**
     * array of legend groups
     * @type {string[]}
     */
    public groups = [];

    /**
     * categories grouped by group name
     * @type {{}}
     */
    public groupsCateg: object = {};

    /**
     * array of legend categories
     * @type {any[]}
     */
    public categories = [];

    constructor() {}

    ngOnInit() {
        if (!this.legend) {
            return; // nothing to do
        }

        if (!this.mode) {
            this.mode = 'dense';
        }

        // get groups of categories
        const data = this.legend.data;
        this.groups = data.groups ? data.groups : ['default'];

        this.groups.forEach((name) => {
            this.groupsCateg[name] = [];
        });

        // assign categories to each group
        let i = 0;
        data.categories.forEach((category) => {
            const group = category['group'] ? category['group'] : 'default';
            this.groupsCateg[group].push(category);
            // set category color
            if (category.color === undefined) {
                category.color = data.listed_colormap[i];
            }

            // create css color field
            category.cssColor = toCssHexColor(category.color);
            i++;
        });

        // fill the array of categories
        this.groups.forEach((name) => {
            this.groupsCateg[name].forEach((category) => {
                this.categories.push(category);
            });
        });
    }

    public updateCategoricalLegend() {
        if (this.mode === 'extended') {
            this.mode = 'collapsed';
        } else {
            this.mode = 'extended';
        }
    }
}
