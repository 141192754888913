<div class="map-wrapper">
    <mat-sidenav-container
        [class]="sidebarType ? 'global-container sidenav-' + sidebarType : 'global-container'"
    >
        <mat-sidenav
            #endSidenav
            class="product-sidenav"
            mode="over"
            position="end"
            (closedStart)="closeSidenav()"
            (openedStart)="openSidenav()"
        >
            <app-settings-sidenav
                *ngIf="!embed"
                [baseLayers]="baseLayers"
                [currentLayer]="currentLayer"
                [hidden]="sidebarType !== 'settings'"
                [supportedGrids]="grids"
                [currentGrid]="currentGrid"
                (baseLayerChanged)="changeBaseLayer($event)"
                (legendDisplayChanged)="changeLegendMode($event)"
                (gridChanged)="changeGrid($event)"
                (mapClick)="setMapClick()"
                (closeSidenav)="closeSidenav()"
            ></app-settings-sidenav>
            <app-roi-sidenav
                #roiSidenavComponent
                *ngIf="!embed"
                (shapeClicked)="shapeClicked($event)"
                (shapeHovered)="highlightRegion($event)"
                [mapReference]="map"
                [mapPopupReference]="mapPopupComponent"
                [editionModes]="editionModes"
                [hidden]="sidebarType !== 'roi'"
                [selectedROI]="roiSelected"
                [userSettings]="userSettings"
                (mapClick)="setMapClick()"
                (closeSidenav)="closeSidenav()"
                (regionAdd)="addRegion($event)"
                (regionsShow)="showRegions($event)"
            ></app-roi-sidenav>
            <app-api-access
                [mapReference]="map"
                [editionModes]="editionModes"
                (closeSidenav)="closeSidenav()"
                (mapClick)="setMapClick()"
                [hidden]="sidebarType !== 'apiAccess'"
                [userSettings]="userSettings"
            ></app-api-access>
            <app-embed-sidenav
                #embedSidenavComponent
                *ngIf="!embed"
                [editionModes]="editionModes"
                [mapReference]="map"
                [hidden]="sidebarType !== 'embed'"
                (mapClick)="setMapClick()"
                (generateEmbedLink)="generateEmbedLink($event)"
                (closeSidenav)="closeSidenav()"
                [allowedArea]="allowedArea"
            ></app-embed-sidenav>
            <app-animation-sidenav
                #animationSidenavComponent
                *ngIf="!embed"
                [editionModes]="editionModes"
                [mapReference]="map"
                [hidden]="sidebarType !== 'evolution'"
                (mapClick)="setMapClick()"
                (closeSidenav)="closeSidenav()"
                [allowedArea]="allowedArea"
                [userSettings]="userSettings"
            ></app-animation-sidenav>
            <div [hidden]="sidebarType !== 'dashboard'">
                <div class="split-pane-content-primary h-full">
                    <div class="upper flex flex-col h-full">
                        <app-dashboard
                            *ngIf="!embed"
                            #dashboardComponent
                            (closeSidenav)="closeSidenav()"
                            [userSettings]="userSettings"
                        >
                        </app-dashboard>
                    </div>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>
            <div
                class="button-container flex flex-col gap-5 justify-start items-end"
                *ngIf="!embed"
                [@lateralMenuAnimation]="lateralMenuStatus"
            >
                <button
                    *ngIf="!sidebarType || sidebarType !== 'settings'"
                    mat-mini-fab
                    (click)="setSidebar($event, 'settings')"
                    [disabled]="
                        editionModes['shapeEditMode'] ||
                        editionModes['embedEditMode'] ||
                        editionModes['captureRegionMode']
                    "
                    matTooltip="Settings"
                    matTooltipPosition="left"
                    [hidden]="sidebarType && sidebarType !== 'settings'"
                    [matTooltipDisabled]="sidebarType === 'settings'"
                    class="button-fab tools-menu-fab"
                    aria-label="Settings"
                >
                    <img src="/assets/img/settings-icon.svg" aria-hidden="true" />
                </button>

                <button
                    mat-mini-fab
                    *ngIf="!sidebarType || sidebarType !== 'roi'"
                    class="button-fab"
                    (click)="setSidebar($event, 'roi')"
                    [disabled]="editionModes['captureRegionMode'] || editionModes['embedEditMode']"
                    appAccess="manage-rois"
                    matTooltip="ROI dashboard"
                    matTooltipPosition="left"
                    [hidden]="sidebarType && sidebarType !== 'roi'"
                    [matTooltipDisabled]="sidebarType === 'roi'"
                    data-testid="map-roi-dashboard"
                    aria-label="ROI dashboard"
                >
                    <img src="/assets/img/placeholder.svg" aria-hidden="true" />
                </button>

                <button
                    mat-mini-fab
                    *ngIf="!sidebarType || sidebarType !== 'apiAccess'"
                    class="button-fab"
                    (click)="setSidebar($event, 'apiAccess')"
                    [disabled]="editionModes['embedEditMode']"
                    matTooltip="API Access"
                    matTooltipPosition="left"
                    [hidden]="sidebarType && sidebarType !== 'apiAccess'"
                    [matTooltipDisabled]="sidebarType === 'apiAccess'"
                    aria-label="API Access"
                >
                    <i class="fa fa-book" aria-hidden="true"></i>
                </button>

                <button
                    mat-mini-fab
                    class="button-fab"
                    (click)="shareMap()"
                    matTooltip="Share map"
                    [matTooltipPosition]="'left'"
                    [disabled]="editionModes['captureRegionMode'] || editionModes['embedEditMode']"
                    *ngIf="lateralMenuStatus === 'closed' || lateralMenuStatus === 'openDashboard'"
                    data-testid="map-share-map"
                    aria-label="Share map"
                >
                    <mat-icon>
                        <i class="fa fa-share-alt"></i>
                    </mat-icon>
                </button>

                <button
                    mat-mini-fab
                    *ngIf="!sidebarType || sidebarType !== 'embed'"
                    (click)="setSidebar($event, 'embed')"
                    appAccess="can-make-embedded-content"
                    [disabled]="editionModes['shapeEditMode'] || editionModes['captureRegionMode']"
                    class="button-fab"
                    [hidden]="sidebarType && sidebarType !== 'embed'"
                    [matTooltipDisabled]="sidebarType === 'embed'"
                    matTooltip="Generate embedded view"
                    [matTooltipPosition]="'left'"
                    aria-label="Generate embedded view"
                >
                    <mat-icon>
                        <i class="fa fa-code"></i>
                    </mat-icon>
                </button>

                <!-- Hide create animation button, as the functionality doesn't work -->
                <!-- <button -->
                <!--     mat-mini-fab -->
                <!--     *ngIf="!sidebarType || sidebarType !== 'evolution'" -->
                <!--     (click)="setSidebar($event, 'evolution')" -->
                <!--     [disabled]="editionModes['shapeEditMode'] || editionModes['captureRegionMode']" -->
                <!--     class="button-fab" -->
                <!--     [hidden]="sidebarType && sidebarType !== 'evolution'" -->
                <!--     appAccess="can-create-animation-request" -->
                <!--     [matTooltipDisabled]="sidebarType === 'evolution'" -->
                <!--     matTooltip="Create animation" -->
                <!--     [matTooltipPosition]="'left'" -->
                <!--     aria-labeld="Create animation" -->
                <!-- > -->
                <!--     <mat-icon> -->
                <!--         <i class="fa fa-film"></i> -->
                <!--     </mat-icon> -->
                <!-- </button> -->

                <!-- <div class="flex gap-1">
                                    <button
                                        appAccess="can-make-embedded-content"
                                        mat-mini-fab class="button-fab share-link-bttn"
                                        (click)="confirmBoundingBox($event)"
                                        matTooltip="Confirm" [matTooltipPosition]="'left'"
                                        color="accent"
                                        *ngIf="!generateEmbed && (lateralMenuStatus === 'closed' || lateralMenuStatus === 'openDashboard')"
                                    >
                                        <mat-icon>
                                            <i class="fa fa-check"></i>
                                        </mat-icon>
                                    </button>
                                    <button
                                        appAccess="can-make-embedded-content"
                                        mat-mini-fab class="button-fab share-link-bttn"
                                        (click)="cancelBoundingBox()"
                                        matTooltip="Cancel" [matTooltipPosition]="'left'"
                                        color="warn"
                                        *ngIf="!generateEmbed && (lateralMenuStatus === 'closed' || lateralMenuStatus === 'openDashboard')"
                                    >
                                        <mat-icon>
                                            <i class="fa fa-times"></i>
                                        </mat-icon>
                                    </button>
                                </div> -->
            </div>

            <app-map-popup
                [data]="mapPopupData"
                (click)="handlePopupClick($event)"
                [allowedArea]="allowedArea"
                [userSettings]="userSettings"
            ></app-map-popup>

            <app-impersonate-dialog
                *ngIf="userForApi"
                [userForApi]="userForApi"
                (forgetUserEvent)="forgetUserForApi()"
            >
            </app-impersonate-dialog>

            <div class="map-wrapper">
                <div
                    class="map-canvas"
                    leaflet
                    [leafletOptions]="options"
                    [leafletLayers]="layers"
                    (leafletMapReady)="onMapReady($event)"
                    [class.map-cursor]="editionModes['shapeEditMode']"
                ></div>
            </div>

            <app-layers-control
                [embed]="embed"
                [hidden]="false"
                [legendMode]="legendMode"
            ></app-layers-control>

            <app-search-bar
                *ngIf="!embed"
                (placeSelected)="onPlaceSelected($event)"
            ></app-search-bar>

            <button
                mat-raised-button
                *ngIf="!embed"
                matTooltip="Area allowed overview"
                matTooltipPosition="right"
                class="button-allowed-area"
                (click)="setAllowedAreaMapView()"
            >
                <mat-icon class="icon-leaflet text-center">
                    <i class="fa fa-home"></i>
                </mat-icon>
            </button>

            <a
                mat-raised-button
                *ngIf="!embed"
                matTooltip="Help"
                matTooltipPosition="right"
                class="button-help"
                href="{{ helpUrl }}"
                target="_blank"
            >
                <mat-icon class="icon-leaflet text-center">
                    <i class="fa fa-question"></i>
                </mat-icon>
            </a>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
