import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RoiService } from '../../services/roi.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService } from '../dialog/dialog.service';
import {
    LabelEditDialogComponent,
    LabelEditDialogData,
    LabelEditDialogResult,
} from '../label-edit-dialog/label-edit-dialog.component';
import { Label } from '../../models/label';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

@Component({
    selector: 'app-label-management',
    templateUrl: './label-management.component.html',
    styleUrls: ['./label-management.component.scss'],
})
export class LabelManagementComponent
    implements OnInit, DynamicDialog<LabelManagementComponent, void, void>
{
    dialogRef: MatDialogRef<
        DynamicLoaderComponent<DynamicDialog<LabelManagementComponent, void, void>, void, void>,
        void
    >;

    dialogData: void;

    /**
     * All labels for the current user
     */
    labelPool: Label[] = [];

    /**
     * New label form control
     */
    newLabelForm: UntypedFormGroup;

    constructor(
        private roiService: RoiService,
        private dialogService: DialogService,
        private snackBarService: CustomSnackbarService
    ) {}

    /**
     * Default Angular init method
     */
    ngOnInit() {
        this.updateUserLabels();
        this.newLabelForm = new UntypedFormGroup({
            title: new UntypedFormControl('', Validators.required),
            description: new UntypedFormControl(''),
            color: new UntypedFormControl('', Validators.required),
        });
    }

    /**
     * Updates the list with the current user labels
     */
    private updateUserLabels() {
        this.roiService.getAllUserRoiLabels().subscribe((result: Label[]) => {
            this.labelPool = result;
        });
    }

    /**
     * Closes the dialog showing the component
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Completly removes an user label (and removes it from the regions with which was associated)
     * @param label
     */
    deleteUserLabel(label) {
        const deleteDialogRef = this.dialogService.openConfirm(
            'Delete label: ' + label.title,
            'Are you sure?',
            'fa-trash'
        );
        deleteDialogRef.afterClosed().subscribe((confirm) => {
            if (confirm) {
                const index = this.labelPool.indexOf(label);
                if (index > -1) {
                    this.roiService.deleteUserRoiLabel(label.id).subscribe((result) => {
                        this.labelPool.splice(index, 1);
                    });
                }
            }
        });
    }

    /**
     * Detects a color choose in the color picker
     * @param event
     */
    colorChanged(event) {
        if (event.color && event.color.hex) {
            this.newLabelForm.get('color').setValue(event.color.hex);
        }
    }

    /**
     * Creates a new label for the user if it does not exist already (same title-same color)
     */
    saveLabel() {
        if (
            this.newLabelForm.get('title').value &&
            !this.labelPool.find((l) => l.title === this.newLabelForm.get('title').value)
        ) {
            const newLabel = new Label();
            newLabel.title = this.newLabelForm.get('title').value;
            newLabel.description = this.newLabelForm.get('description').value;
            newLabel.color = this.newLabelForm.get('color').value;
            this.roiService.createUserRoiLabel(newLabel).subscribe((label: Label) => {
                this.labelPool.push(label);
                this.newLabelForm.reset();
            });
        } else {
            this.snackBarService.present(
                "Can't create the label. Title already used for another label.",
                'error'
            );
        }
    }

    /**
     * Open label edit dialog when clicking on a label in the label management window
     * @param event
     * @param label
     */
    openEditLabel(event, label: Label) {
        const index = this.labelPool.indexOf(label);
        const editDialogRef = this.dialogService.openComponent<
            LabelEditDialogComponent,
            LabelEditDialogData,
            LabelEditDialogResult
        >(LabelEditDialogComponent, { data: label });
        editDialogRef.afterClosed().subscribe((updatedLabel) => {
            if (updatedLabel && index > -1) {
                this.labelPool[index] = updatedLabel;
            }
        });
    }
}
