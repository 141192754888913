<div class="preview-map-wrapper">
    <div
        class="preview-map map-canvas"
        [style.width]="width"
        [style.height]="height"
        leaflet
        [leafletOptions]="options"
        (leafletMapReady)="onMapReady($event)"
    ></div>
</div>
