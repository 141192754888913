import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { AuthService } from '../app/auth/auth.service';

import { CustomSnackbarService } from '../app/custom-snackbar/custom-snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    /**
     * Default constructor.
     * @param {CustomSnackbarService} snackbar
     * @param {AuthService} authService.
     */
    constructor(
        private snackbar: CustomSnackbarService,
        private authService: AuthService
    ) {}

    /**
     * Intercept HttpInterceptor interface.
     * @param {HttpRequest<any>} req
     * @param {HttpHandler} next
     * @return {Observable<HttpEvent<any>>}
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: any, caught) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 && err.error === 'Login required') {
                        this.authService.logout();
                        window.location.reload();
                    } else {
                        this.handleResponseError(err, this.snackbar);
                        return throwError(err);
                    }
                }
            })
        );
    }

    /**
     * Handle the error response and shows some message.
     * @param response
     * @param {CustomSnackbarService} snackbar
     */
    private handleResponseError(response: any, snackbar: CustomSnackbarService) {
        let error: any; // Error should be a dictionary or undefined

        try {
            error = JSON.parse(response.error);
        } catch (e) {
            error = response.error;
        }

        if (error !== undefined && response.url.endsWith('/rois/')) {
            snackbar.present(
                'Something went wrong with the ROI.',
                'error',
                response.status,
                response.url
            );
        } else if (error !== undefined && error.message !== undefined) {
            snackbar.present(error.message, 'error', response.status, response.url);
        } else {
            snackbar.present('Something went wrong.', 'error', response.status, response.url);
        }
    }
}
