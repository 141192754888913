<div
    class="header flex justify-center items-center gap-4"
    [style.background-color]="backgroundColor"
>
    <div *ngIf="headerImg" class="flex justify-end items-center">
        <img class="img" src="{{ headerImg }}" />
    </div>
    <div *ngIf="headerText" class="flex justify-start items-center">
        <span [ngStyle]="{ color: textColor ? textColor : 'white' }" class="title">{{
            headerText
        }}</span>
    </div>
</div>
