import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    /**
     * Text to display in the header
     */
    public headerText;

    /**
     * Image to display in the header
     */
    public headerImg;

    /**
     * Color of the text of the header
     */
    public textColor;

    /**
     * Background color of the header
     */
    public backgroundColor;

    constructor() {
        if (environment.headerConfig) {
            if (environment.headerConfig.headerText) {
                this.headerText = environment.headerConfig.headerText;
            }
            if (environment.headerConfig.textColor) {
                this.textColor = environment.headerConfig.textColor;
            }
            if (environment.headerConfig.backgroundColor) {
                this.backgroundColor = environment.headerConfig.backgroundColor;
            }
        }
    }
}
