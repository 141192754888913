import { RouterModule, Routes } from '@angular/router';

import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { authGuard } from './auth/auth-guard.service';
import { RegisterComponent } from './register/register.component';
import { AccountActivateComponent } from './account-activate/account-activate.component';
import { AccountReActivateComponent } from './account-re-activate/account-re-activate.component';
import { EmbedComponent } from './embed/embed.component';
import { SimpleComponent } from './simple/simple.component';
import { DemoComponent } from './demo/demo.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'embed', component: EmbedComponent },
    { path: 'set-password', component: PasswordResetComponent },
    { path: 'account-activate', component: AccountActivateComponent },
    { path: 'account-reactivate', component: AccountReActivateComponent },
    {
        path: 'account',
        loadChildren: () =>
            import('./account-manager/account-manager.module').then((m) => m.AccountManagerModule),
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    },
    {
        path: 'demo',
        component: DemoComponent,
        canActivate: [authGuard],
        data: { excludedRoles: [] },
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [authGuard],
        data: { excludedRoles: ['minimal-user'] },
    },
    {
        path: 'simple',
        component: SimpleComponent,
        canActivate: [authGuard],
        data: { excludedRoles: [] },
    },
    // {path: 'compare-map', component: CompareMapComponent, canActivate: [authGuard]},
    { path: '**', redirectTo: '/login' },
];

export const AppRoutes = RouterModule.forRoot(routes);
