import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { encodeObjectToURL, getDomain } from '../utils/utils';
import { AuthService } from '../app/auth/auth.service';

export interface ROITimeSeriesParams {
    roiId: number;
    startDate: string;
    endDate: string;
    climatology: boolean;
    avgWindowDays: number;
    format: 'csv';
    avgWindowDirection: 'backward';
    provideCoverage: boolean;
}

export interface TimeSeriesStreamParams {
    lat: number;
    lon: number;
    startDate: string;
    endDate: string;
    avgWindowDirection: 'backward';
}

/**
 * Dam Service
 *
 * This service retrieves the soil moisture data from the API
 */
@Injectable()
export class DamService {
    /**
     * API endpoint path to retrieve the data from
     */
    protected apiURL = `${environment.apiV2}/products`;

    /**
     * Get tile API options.
     */
    private tileOptions =
        '?x={x}&y={y}&z={z}&date={date}&metadata={metadata}' +
        '&file_format={file_format}&as_attachment={as_attachment}&legend_start={legendStart}&legend_end={legendEnd}' +
        '&rois={rois}&FronSat=true';

    /**
     * Environment copy to create unit tests.
     * @type {any}
     */
    public environment = environment;

    private http = inject(HttpClient);
    private cookieService = inject(CookieService);
    private authService = inject(AuthService);

    /**
     * Set the method to get the tile url template, that can be balanced or not.
     */
    public setTileMethod(productApiName, embed = false) {
        if (this.environment.tileServers !== undefined && this.environment.tileURL !== undefined) {
            return this.getTileUrlTemplateBalanced(productApiName, embed);
        }
        return this.getTileUrlTemplateNoBalanced(productApiName, embed);
    }

    /**
     * Base path from where to retrieve map tiles.
     *
     * @returns {string} tiles retrieval API base URL.
     */
    public getAreaPath(): string {
        return this.apiURL + '/get-area';
    }

    /**
     * Base path from where to retrieve map tiles.
     *
     * @returns {string} tiles retrieval API base URL.
     */
    public getTilePath(productApiName, embed = false): string {
        if (embed) {
            return `${this.apiURL}/${productApiName}/embedxyztiles`;
        } else {
            return `${this.apiURL}/${productApiName}/xyztiles`;
        }
    }

    /**
     * Base path from where to retrieve time series data.
     *
     * @returns {string} time series API base URL.
     */
    public getTimeSeriesPath(): string {
        return this.apiURL + '/get-time-series';
    }

    /**
     * Base path from where to retrieve time series data via streaming.
     *
     * @returns {string} time series API base URL.
     */
    private getTimeSeriesStreamPath(productApiName: string): string {
        return `${this.apiURL}/${productApiName}/point-time-series-stream`;
    }

    /**
     * Base path from where to retrieve time series data via streaming for a ROI.
     *
     * @returns {string} time series API base URL.
     */
    public getROITimeSeriesStreamPath(): string {
        return this.apiURL + '/get-roi-time-series-stream';
    }

    /**
     * Base path from where to retrieve time series data for a ROI.
     *
     * @returns {string} time series API base URL.
     */
    public getROITimeSeriesPath(productApiName: string): string {
        return `${this.apiURL}/${productApiName}/roi-time-series-sync`;
    }

    /**
     * URL from where to retrieve the time series from
     *
     * @param {Object} params parameters to configure the time series URL
     * @returns {string} a parameterized time series URL
     */
    public getTimeSeriesUrl(params: object) {
        return this.getTimeSeriesPath() + encodeObjectToURL(params);
    }

    /**
     * Retrieve the time series for the specified point, product and date
     * @param {Object} params time series retrieval configuration parameters
     * @returns {Observable<Response>} requested time series data
     */
    public getTimeSeries(params: object) {
        return this.http.post(this.getTimeSeriesPath(), params, {
            responseType: 'text',
        });
    }

    /**
     * Retrieve the time series for the specified ROI, product and date
     * @param params time series retrieval configuration parameters
     * @param productApiName: product api name
     * @returns requested time series data
     */
    public getROITimeSeries(
        productApiName: string,
        params: ROITimeSeriesParams
    ): Observable<string> {
        return this.http.get(this.getROITimeSeriesPath(productApiName), {
            responseType: 'text',
            params: {
                roi_id: encodeURIComponent(params.roiId),
                start_time: params.startDate,
                end_time: params.endDate,
                climatology: encodeURIComponent(params.climatology),
                avg_window_days: encodeURIComponent(params.avgWindowDays),
                format: params.format,
                avg_window_direction: params.avgWindowDirection,
                provide_coverage: encodeURIComponent(params.provideCoverage),
            },
        });
    }

    /** Generate the oboe config. */
    public getOboeConfig(url: string) {
        const jwtToken = this.authService.getToken();
        const cookieAccessToken = this.cookieService.get('XSRF-TOKEN');

        return {
            url: url,
            method: 'GET',
            cached: false,
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'X-XSRF-TOKEN': cookieAccessToken,
                'X-Fronsat': true,
            },
        };
    }

    /**
     * URL from where to retrieve the time series stream
     *
     * @param {Object} params parameters to configure the time series URL
     * @param {string} productApiName: product api name
     * @returns {string} a parameterized time series URL
     */
    public getTimeSeriesStreamUrl(productApiName: string, params: TimeSeriesStreamParams): string {
        return (
            this.getTimeSeriesStreamPath(productApiName) +
            encodeObjectToURL({
                lat: params.lat,
                lon: params.lon,
                date_init: params.startDate,
                date_end: params.endDate,
                avg_window_direction: params.avgWindowDirection,
            })
        );
    }

    /**
     * URL from where to retrieve the time series stream for a ROI
     *
     * @param {Object} params parameters to configure the time series URL
     * @returns {string} a parameterized time series URL
     */
    public getROITimeSeriesStreamUrl(params: object) {
        return this.getROITimeSeriesStreamPath() + encodeObjectToURL(params);
    }

    /**
     * URL from where to retrieve some tile
     *
     * @param {Object} params parameters to configure the tile URL
     * @returns {string} a parameterized get area URL
     */
    public getAreaUrl(params: object) {
        return this.getAreaPath() + encodeObjectToURL(params);
    }

    /**
     * URL template to get area tiles using XYZ coordinates without subdomains load balancing
     * @returns {string}
     */
    public getTileUrlTemplateNoBalanced(productApiName, embed = false): string {
        return `${this.getTilePath(productApiName, embed)}${this.tileOptions}`;
    }

    /**
     * URL template to get area tiles using XYZ coordinates with subdomain load balancing
     * @returns {string}
     */
    public getTileUrlTemplateBalanced(productApiName, embed = false): string {
        let tileUrlBalance = this.environment.tileURL;
        if (this.environment.tileURL.endsWith('{s}.')) {
            tileUrlBalance = tileUrlBalance + getDomain();
        }
        return `${tileUrlBalance}${this.getTilePath(productApiName, embed)}${this.tileOptions}`;
    }
}
