import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-roi-upload-file',
    templateUrl: './roi-upload-file.component.html',
    styleUrls: ['./roi-upload-file.component.scss'],
})
export class RoiUploadFileComponent implements OnInit {
    /**
     * Upload File event.
     */
    @Output() uploadFileEvent = new EventEmitter<any>();

    constructor(private dialogRef: MatDialogRef<RoiUploadFileComponent>) {}

    ngOnInit() {}

    /**
     * * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Upload a GeoJSON (.json) or Shapefile (.zip with [.dbf, .shp, .shx]) file using the input type='file' change event.
     *
     * @param event ngChange event.
     */
    importRegion(event) {
        this.uploadFileEvent.emit(event);
        this.dialogRef.close();
    }
}
