import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-new-release-popup',
    templateUrl: './new-release-popup.component.html',
    styleUrls: ['./new-release-popup.component.scss'],
})
export class NewReleasePopupComponent {
    constructor(public dialogRef: MatDialogRef<NewReleasePopupComponent>) {}

    onClick(): void {
        this.dialogRef.close();
    }
}
