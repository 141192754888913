import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

@Component({
    selector: 'app-roi-max',
    templateUrl: './roi-max.component.html',
    styleUrls: ['./roi-max.component.scss'],
})
export class RoiMaxComponent implements DynamicDialog<RoiMaxComponent, void, void> {
    dialogRef: MatDialogRef<
        DynamicLoaderComponent<DynamicDialog<RoiMaxComponent, void, void>, void, void>,
        void
    >;

    dialogData: void;

    constructor() {}

    /**
     * Close the popup.
     */
    close() {
        this.dialogRef.close();
    }
}
