import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NvD3Component } from '../ng2-nvd3.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isNumberInRange } from '../../utils/utils';

import { Kpi } from '../../models/kpi';
import { KpiService } from '../../services/kpi.service';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

export type KpiMode = 'delete' | 'add';

@Component({
    selector: 'app-kpi',
    templateUrl: './kpi.component.html',
    styleUrls: ['./kpi.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgClass, MatTooltipModule, NvD3Component, CommonModule],
})
export class KpiComponent implements AfterViewInit {
    /**
     * Reference to the NVD3 donut chart component
     */
    @ViewChild('chartgauge') chartGaugeRef: NvD3Component;

    /**
     * {Kpi} kpi to display
     */
    @Input() kpi: Kpi;

    /**
     * {boolean} edit mode enabled
     */
    @Input() edit = false;

    /**
     * {string} edit mode: 'add', 'delete'
     */
    @Input() mode: KpiMode = 'delete';

    /**
     * Kpi deleted event
     * @type {EventEmitter<Kpi>}
     */
    @Output() kpiDeleted: EventEmitter<Kpi> = new EventEmitter<Kpi>();

    /**
     * Kpi added event
     * @type {EventEmitter<Kpi>}
     */
    @Output() kpiAdded: EventEmitter<Kpi> = new EventEmitter<Kpi>();

    /**
     * Flag to detect if the device is a tablet or desktop
     */
    isDesktopDevice = true;

    /**
     * Chart quality data and options
     */
    public chartGauge = {
        data: [],
        options: {
            chart: {
                type: 'pieChart',
                height: 150,
                donut: true,
                x: function (d) {
                    return d.key;
                },
                y: function (d) {
                    return d.y;
                },
                showLabels: false,
                showLegend: false,
                tooltip: { enabled: false },
                color: (d) => {
                    return d.key === this.kpi.display_name ? '#47c1de' : '#CCCCCC';
                },
                duration: 0,
            },
        },
    };

    /**
     * Chart relative anomaly
     */
    public chartRelativeAnomalyGauge = {
        data: [],
        options: {
            chart: {
                type: 'pieChart',
                height: 200,
                donut: true,
                pie: {
                    startAngle: function (d) {
                        return d.startAngle / 2 - Math.PI / 2;
                    },
                    endAngle: function (d) {
                        return d.endAngle / 2 - Math.PI / 2;
                    },
                },
                x: function (d) {
                    return d.key;
                },
                y: (d) => {
                    return 100 / this.chartRelativeAnomalyGauge.data.length;
                },
                showLabels: false,
                growOnHover: false,
                labelsOutside: true,
                showLegend: false,
                tooltip: { enabled: false },
                color: (d) => {
                    return d.color;
                },
                duration: 0,
            },
        },
    };

    /**
     * Default constructor
     * @param {KpiService} kpiService
     * @param {DeviceDetectorService} deviceService
     */
    constructor(
        private kpiService: KpiService,
        private deviceService: DeviceDetectorService
    ) {
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    /**
     * Update the chart when we have the reference to it.
     */
    ngAfterViewInit() {
        if (this.kpi !== undefined && this.kpi.display_type === 'gauge') {
            this.initGaugeKPIs();
        } else if (this.kpi !== undefined && this.kpi.display_type === 'gauge_needle') {
            this.initRelativeAnomalyKPIs();
        }
    }

    /**
     * Update the KPIs charts.
     */
    private initGaugeKPIs() {
        this.chartGauge.data = [
            {
                key: 'blank',
                y: 100 - this.kpi.value['value'],
            },
            {
                key: this.kpi.display_name,
                y: this.kpi.value['value'],
            },
        ];

        const width = this.kpi.value['value'] < 10 ? 94 : 85;

        this.chartGaugeRef.updateWithData(this.chartGauge.data);
        const lastSVGElement = this.chartGaugeRef.svg[0][0].lastElementChild;
        if (lastSVGElement.tagName === 'text') {
            this.chartGaugeRef.svg[0][0].removeChild(lastSVGElement);
        }
        this.chartGaugeRef.svg
            .append('text')
            .attr('x', width)
            .attr('y', 88)
            .style('fill', '#49BFDC')
            .style('stroke', '#49BFDC')
            .text(`${this.kpi.value['value']}${this.kpi.value['unit']}`);
    }

    /**
     * Update the KPIs charts.
     */
    private initRelativeAnomalyKPIs() {
        this.kpiService.getKpiMetadata(this.kpi).subscribe((result: any) => {
            this.chartRelativeAnomalyGauge.data = result.data;

            this.chartGaugeRef.updateWithData(this.chartRelativeAnomalyGauge.data);
            const lastSVGElement = this.chartGaugeRef.svg[0][0].lastElementChild;
            if (lastSVGElement.tagName === 'text') {
                this.chartGaugeRef.svg[0][0].removeChild(lastSVGElement);
            }

            const message = this.kpi.value['value'] >= 0 ? 'wetter than usual' : 'drier than usual';
            const marginX = Math.abs(this.kpi.value['value']).toString().length > 5 ? 5 : 15;
            this.chartGaugeRef.svg
                .append('text')
                .attr('x', marginX)
                .attr('y', 135)
                .style('fill', '#49BFDC')
                .style('stroke', '#49BFDC')
                .text(`${Math.abs(this.kpi.value['value'])}${this.kpi.value['unit']} ${message}`);
            this.chartGaugeRef.svg
                .append('svg:image')
                .attr('class', 'needle')
                .attr('width', 20)
                .attr('height', 60)
                .attr('x', 90)
                .attr('y', 50)
                .attr('xlink:href', 'assets/img/needle.svg');
            const needle = document.getElementsByClassName('needle');
            (needle[0] as HTMLElement).style.transformOrigin = 'center center';
            (needle[0] as HTMLElement).style.transform = this.getNeedleRotation();

            if (!this.isDesktopDevice) {
                this.chartGaugeRef.el.style.marginTop = '25%';
            }
            this.buildLegend();
        });
    }

    /**
     * Emits an event when delete button in pressed
     */
    public onDelete() {
        this.kpiDeleted.emit(this.kpi);
    }

    public getNeedleRotation() {
        let rotate;
        let rotation;
        let degreeRotation;
        let degreeDiff;

        const pieGrade = 200 / this.chartRelativeAnomalyGauge.data.length;
        const pieHalf = Math.floor(this.chartRelativeAnomalyGauge.data.length / 2);

        if (
            this.kpi.value['value'] < this.chartRelativeAnomalyGauge.data[0].range[0] ||
            this.kpi.value['value'] < -95
        ) {
            degreeRotation = -96;
        } else if (
            this.kpi.value['value'] >
                this.chartRelativeAnomalyGauge.data[this.chartRelativeAnomalyGauge.data.length - 1]
                    .range[1] ||
            this.kpi.value['value'] > 95
        ) {
            degreeRotation = 96;
        } else {
            for (let i = 0; i < this.chartRelativeAnomalyGauge.data.length; i++) {
                if (
                    isNumberInRange(
                        this.chartRelativeAnomalyGauge.data[i].range[0],
                        this.chartRelativeAnomalyGauge.data[i].range[1],
                        this.kpi.value['value']
                    )
                ) {
                    rotation = i - pieHalf;
                    const step =
                        pieGrade /
                        (this.chartRelativeAnomalyGauge.data[i].range[1] -
                            this.chartRelativeAnomalyGauge.data[i].range[0]);
                    const rotationDiff =
                        (this.chartRelativeAnomalyGauge.data[i].range[1] -
                            this.kpi.value['value']) *
                        step;
                    degreeDiff = pieGrade - rotationDiff;
                }
            }
            if (this.chartRelativeAnomalyGauge.data.length % 2 === 0) {
                rotate = pieGrade * rotation + pieGrade / 2;
            } else {
                rotate = pieGrade * rotation;
            }
            rotate = rotate - pieGrade / 2;
            degreeRotation = rotate + degreeDiff;
        }

        return `rotate(${degreeRotation}deg)`;
    }

    /**
     * Emits an event when add button in pressed
     */
    public onAdd() {
        this.kpiAdded.emit(this.kpi);
    }

    public buildLegend() {
        let offsetX = 0;
        let offsetY = 0;
        for (let i = 0; i < this.chartRelativeAnomalyGauge.data.length; i++) {
            offsetX += i > 0 ? 60 : 0;
            if (offsetX >= 180) {
                offsetY = 20;
                offsetX = 0;
            }
            this.chartGaugeRef.svg
                .append('circle')
                .attr('cx', 30 + offsetX)
                .attr('cy', 10 + offsetY)
                .attr('r', 4)
                .style('fill', this.chartRelativeAnomalyGauge.data[i].color);

            this.chartGaugeRef.svg
                .append('text')
                .attr('x', 35 + offsetX)
                .attr('y', 10 + offsetY)
                .attr('dominant-baseline', 'middle')
                .style('font-size', '10px')
                .style('font-family', 'Roboto')
                .text(this.chartRelativeAnomalyGauge.data[i].key);
        }
    }
}
