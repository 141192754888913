<div *ngIf="apiRequestStatus">
    <div class="flex justify-between items-center">
        <div>
            <h3 class="font-bold">API Request status</h3>
        </div>
        <button
            mat-raised-button
            color="accent"
            (click)="getApiRequestStatus()"
            matTooltip="Refresh api request status"
        >
            <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
    </div>

    <div *ngIf="!refreshApiRequestStatus">
        <div class="field flex">
            <p class="field-key">Status:</p>
            <p>{{ apiRequestStatus.processingStatus }}</p>
        </div>
        <div class="field flex">
            <p class="field-key">Progress:</p>
            <p>{{ apiRequestStatus.percentage }} %</p>
        </div>
        <div
            *ngIf="apiRequestStatus.processingStatus === ApiRequestStatusInterface.Ready"
            class="field flex"
        >
            <p class="field-key">Started at:</p>
            <p>{{ apiRequestStatus.startedDt | date }}</p>
        </div>
        <div
            *ngIf="apiRequestStatus.processingStatus === ApiRequestStatusInterface.Ready"
            class="field flex"
        >
            <p class="field-key">Finished at:</p>
            <p>{{ apiRequestStatus.finishedDt | date }}</p>
        </div>
    </div>
    <div class="refresh-api-spinner" *ngIf="refreshApiRequestStatus">
        <mat-spinner></mat-spinner>
    </div>

    <h3 class="font-bold">File details</h3>
    <div *ngIf="apiRequestStatus.processingStatus !== ApiRequestStatusInterface.Ready">
        File details are unavailable
    </div>
    <div *ngIf="apiRequestStatus.processingStatus === ApiRequestStatusInterface.Ready">
        <div class="flex justify-center items-center w-full">
            <table mat-table [dataSource]="previewChartdataSource" multiTemplateDataRows>
                <ng-container matColumnDef="filename">
                    <th mat-header-cell *matHeaderCellDef>Filename</th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.filename }}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef>Download</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="downloadFile(element.path)">
                            <i class="fa fa-arrow-down"></i>
                        </button>
                    </td>
                </ng-container>

                <ng-container
                    *ngIf="
                        request.type === ApiRequestTypeInterface.RoiTimeSeriesRequest ||
                        request.type === ApiRequestTypeInterface.PointTimeSeriesRequest
                    "
                    matColumnDef="preview"
                >
                    <th mat-header-cell *matHeaderCellDef>Preview</th>
                    <td (click)="toggleExpandedElement(element)" mat-cell *matCellDef="let element">
                        <button mat-icon-button>
                            <i
                                [class]="
                                    element == expandedElement ? 'fa fa-eye-slash' : 'fa fa-eye'
                                "
                                aria-hidden="true"
                            ></i>
                        </button>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div
                            class="element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                        >
                            <canvas [id]="'canvas'" height="5"></canvas>
                            <div [id]="'chartContainer'" style="height: 300px; width: 100%"></div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let element; columns: displayedColumns"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="expanded-row"
                ></tr>
            </table>
        </div>
    </div>
</div>
