import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as oboe from 'oboe';
import { Observable, ReplaySubject } from 'rxjs';
import moment from 'moment';
import { Moment } from 'moment';
import { AngularCsv } from 'angular-csv-ext';
import { environment } from '../../environments/environment';
import { bufferTime, throttleTime } from 'rxjs';

import {
    binarySearch,
    cmpMoment,
    csvToJson,
    getISODateString,
    getUTCDateWithoutConversion,
    parseStreamSeries,
} from '../../utils/utils';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import {
    DamService,
    ROITimeSeriesParams,
    TimeSeriesStreamParams,
} from '../../services/dam.service';
import { Region } from '../../models/region';
import { LayersService } from '../../services/layers.service';
import { Product } from '../../models/product';
import { UserSettingsInfo } from '../../services/user.service';
import { ProductAvailability } from '../../models/product-availability';
import { ProductService } from '../../services/product.service';
import { getAreaUnitDisp, getAreaValue } from '../../utils/roi.utils';

/**
 * Time series object interface.
 */
export interface Series {
    name: string;
    dataPoints: Array<any>;
    srcDataPoints: Array<any>;
    type: string;
    showInLegend: boolean;
    markerSize: number;
    connectNullData: boolean;
    isYear: boolean;
    visible: boolean;
    axisYType: string;
    axisYIndex: number;
}

@Component({
    selector: 'app-time-series',
    templateUrl: './time-series.component.html',
    styleUrls: ['./time-series.component.scss'],
})

/**
 * Component that requests some series and displays it in a chart.
 */
export class TimeSeries implements AfterViewInit, OnInit, OnDestroy, OnChanges {
    /**
     * Reference to the ProgressBar child component
     */
    @ViewChild(ProgressBarComponent, { static: true })
    progressBar: ProgressBarComponent;

    /**
     * If a focus chart should be displayed below the main chart
     * @type {boolean}
     */
    @Input() public withFocusChart = false;

    /**
     * Time series variant, can be either 'point' or 'dashboard'
     */
    @Input() public variant = 'point';

    /**
     * Switch time series mode between year by year and linear.
     * @type {boolean}
     */
    @Input() public yearMode = false;

    /**
     * Start day of year for year mode data series
     * @type {number}
     */
    @Input() public yearModeStart = 1;

    @Input() public userSettings: UserSettingsInfo | undefined;

    /**
     * Emitter that will be fired when the time series is done loading
     *
     * @type {EventEmitter<any>}
     */
    @Output() seriesLoaded = new EventEmitter<any>();

    /**
     * Products for which the time series will be displayed
     */
    private products: Product[];

    /**
     * Init date for which the time series will be displayed
     */
    private dateStart: Moment;

    /**
     * End date for which the time series will be displayed
     */
    private dateEnd: Moment;

    /**
     * Number of expected values for the series
     */
    private expectedValues: number;

    /**
     * Latitude of the selected point
     */
    private lat: number;

    /**
     * Longitude of the selected point
     */
    private lng: number;

    /**
     * Highlight the input date.
     * @type {date}
     */
    private dateSelected: Moment;

    /**
     * A ROI
     * @type {Region}
     */
    protected roi: Region;

    /**
     * if the time series has been completely loaded
     * @type {boolean}
     */
    public isLoaded = true;

    /**
     * CanvasJS object
     * @type {undefined}
     */
    public chart: any = undefined;

    /**
     * Data series to be displayed on the graph.
     * @type {Array<Series>}
     */
    public data: Array<Series> = [];

    /**
     * Timeseries streaming observer reference
     */
    private observer;

    /**
     * oboe streaming service reference
     */
    private oboeService;

    /**
     * Helper constant for the timeseries
     * @type {string}
     */
    readonly SERIE_SELECT_DATE = 'Selected date';

    /**
     * Years present in time-series data values
     */
    public years: Set<number>;

    /**
     * Data series by year to be displayed on the graph.
     * @type {Array<Series>}
     */
    public yearData: Array<Series> = undefined;

    /**
     * Actual data series to be displayed on the graph.
     * @type {Array}
     */
    public activeData: Array<object> = undefined;

    /**
     * Climatology series color.
     */
    protected readonly CLIMATOLOGY_OPTIONS = { color: 'black' };

    /**
     * Climatology series name.
     */
    public readonly CLIMATOLOGY_SERIES_NAME = 'Climatology';

    /**
     * Average series name.
     */
    public readonly AVERAGE_SERIES_NAME = 'Average';

    /**
     * Coverage series name.
     */
    public readonly COVERAGE_SERIES_NAME = 'Coverage';

    /**
     * RxJs chart click emitter.
     */
    private chartClick = new ReplaySubject();

    /**
     * CanvasJS chart configuration options
     */
    public canvasjsOptions: any;

    /**
     * Primary unit for products displayed on primary Y axis
     */
    private primaryUnit: string = undefined;

    /**
     * Previous visible status of all the time series
     */
    private previousTimeSeriesVisibleStatus = {};

    public loadingRoiTimeSeries = false;

    /**
     * dictionary where the params used to generate the time series will be kept
     */
    params: ROITimeSeriesParams | TimeSeriesStreamParams | undefined;

    constructor(
        private damService: DamService,
        private layersService: LayersService,
        private productService: ProductService
    ) {
        this.onChartCanvasJSClick = this.onChartCanvasJSClick.bind(this);
        this.canvasjsOptions = {
            zoomEnabled: true,
            zoomType: 'xy',
            animationEnabled: true,
            exportEnabled: false, // we will use the export function of this component
            title: {
                text: '.', // the purpose of this title is to create some room for zoom/pan buttons
                fontColor: 'white',
                fontSize: 10,
            },
            axisX: {
                title: 'Date',
                labelFormatter: this.standardLabelFormat,
            },
            axisY: {
                title: '',
                suffix: '',
            },
            axisY2: [],
            toolTip: {
                shared: true,
                cornerRadius: 5,
                contentFormatter: this.canvasJSTooltipCustomLinear,
            },
            legend: {
                cursor: 'pointer',
                verticalAlign: 'top',
                horizontalAlign: 'center',
                dockInsidePlotArea: false,
                fontSize: 15,
                itemTextFormatter: (e) => {
                    const product = this.getProductFromSeriesName(e.dataSeries.name);
                    if (
                        product &&
                        product.timeSeriesType === 'cumulative_burned_area' &&
                        this.yearMode === true &&
                        this.isLoaded === true
                    ) {
                        // draw year as legend label for this type of series
                        return this.getYearFromName(e.dataSeries.name).toString();
                    }
                    return e.dataSeries.name;
                },
                itemclick: (e) => {
                    if (typeof e.dataSeries.visible === 'undefined' || e.dataSeries.visible) {
                        e.dataSeries.visible = false;
                    } else {
                        e.dataSeries.visible = true;
                    }
                    this.chart.render();
                },
            },
            data: [],
        };
    }

    ngOnInit() {
        // When the layers are updated, check the selected layer date
        this.layersService.layers$.subscribe(() => {
            const layer = this.layersService.getSelectedLayer();
            if (layer !== undefined && !layer.getDate().isSame(this.dateSelected)) {
                this.dateSelected = layer.getDate();
                this.redrawCurrentDate();
            }
        });

        this.chartClick.pipe(throttleTime(500)).subscribe((ev) => {
            this.onChartCanvasJSClick(ev);
        });
    }

    /**
     * Angular lifecycle method.
     */
    ngOnChanges(changes) {
        if (changes.yearMode !== undefined && !changes.yearMode.firstChange) {
            this.toggleYearMode(changes.yearMode.currentValue);
        } else if (changes.yearModeStart !== undefined && !changes.yearModeStart.firstChange) {
            if (this.products.length === 1) {
                // year mode is available for one product series only
                if (this.products[0].timeSeriesType === 'cumulative_burned_area') {
                    this.buildBurnedAreaYearDataSeries();
                    this.activeData = this.yearData;
                } else {
                    this.reorderYearSeries();
                }
                this.redrawChart();
            }
        }
    }

    /**
     * Angular lifecycle method.
     */
    ngOnDestroy() {
        this.clearClickListener();
    }

    /**
     * CanvasJS initializer method
     */
    private onInitCanvasJS(): void {
        this.chart = new CanvasJS.Chart('chartContainer' + this.variant, this.canvasjsOptions);
    }

    /**
     * Update the chart when we have the reference to it.
     */
    ngAfterViewInit() {
        this.onInitCanvasJS();
    }

    /**
     * Get product unit in format for display
     *
     * @param product
     */
    private getProductUnitDisp = (product: Product): string => {
        return product.isAreaUnit()
            ? getAreaUnitDisp(this.userSettings?.settings?.areaUnit)
            : product.unit;
    };

    /**
     * Custom tooltip formatter
     * @param e
     * @returns {string}
     */
    private canvasJSTooltipCustomLinear = (e) => {
        const entries = e.entries.filter((i) => i.dataSeries.visible);
        let content = ' ';

        if (entries.length > 0) {
            content += `<strong>${entries[0].dataPoint.x.format('YYYY-MM-DD')}</strong><br/>`;

            for (const entry of entries) {
                const product = this.getProductFromSeriesName(entry.dataSeries.name);
                const unit =
                    entry.dataSeries.name === `${product.name} ${this.COVERAGE_SERIES_NAME}`
                        ? '%'
                        : this.getProductUnitDisp(product);
                content +=
                    "<span style='color:" +
                    entry.dataSeries.color +
                    "'>" +
                    entry.dataSeries.name +
                    '</span> ' +
                    entry.dataPoint.y +
                    ' ' +
                    unit;
                content += '<br/>';
            }
        }

        return content;
    };

    /**
     * Custom tooltip formatter
     * @param e
     * @returns {string}
     */
    private canvasJSTooltipCustomYear = (e) => {
        const entries = e.entries.filter((i) => i.dataSeries.visible);
        let content = ' ';

        if (entries.length > 0) {
            content += `<strong>${this.dayOfYearToLabel({
                value: entries[0].dataPoint.x,
            })}</strong><br/>`;

            for (const entry of entries) {
                const product = this.getProductFromSeriesName(entry.dataSeries.name);
                content +=
                    "<span style='color:" +
                    entry.dataSeries.color +
                    "'>" +
                    entry.dataSeries.name +
                    '</span> ' +
                    entry.dataPoint.y +
                    ' ' +
                    this.getProductUnitDisp(product);
                content += '<br/>';
            }
        }

        return content;
    };

    /**
     * Set the data for displaying product time series.
     * @param {Product[]} products
     * @param {moment.Moment} dateStart
     * @param {moment.Moment} dateEnd
     * @param {number} lat
     * @param {number} lng
     */
    public setPointData(
        products: Product[],
        dateStart: Moment,
        dateEnd: Moment,
        lat: number,
        lng: number
    ) {
        this.setProducts(products);
        this.roi = undefined;
        this.dateStart = getUTCDateWithoutConversion(dateStart);
        this.dateEnd = getUTCDateWithoutConversion(dateEnd);
        this.lat = lat;
        this.lng = lng;
    }

    /**
     * Set the data for displaying ROI time series.
     * @param {Region} roi
     * @param {Product[]} products
     * @param {moment} dateStart
     * @param {moment} dateEnd
     */
    public setROIData(roi: Region, products: Product[], dateStart?: Moment, dateEnd?: Moment) {
        this.setProducts(products);
        this.roi = roi;
        if (dateStart) {
            this.dateStart = getUTCDateWithoutConversion(dateStart);
        }
        if (dateEnd) {
            this.dateEnd = getUTCDateWithoutConversion(dateEnd);
        }
    }

    /**
     * Set the component selected date.
     * @param {moment.Moment} date
     */
    public setDateSelected(date: Moment) {
        this.dateSelected = date;
    }

    /**
     * Update the component products.
     * @param {Product[]} products
     */
    public setProducts(products: Product[]) {
        this.products = products;
        const units = new Set<string>(this.products.map((p) => p.unit));
        let products1 = this.products;
        this.primaryUnit = undefined;

        if (units.size === 2) {
            // get products for the primary Y axis
            this.primaryUnit = this.products[0].unit;
            products1 = this.products.filter((p) => p.unit === this.primaryUnit);

            // get and configure the secondary Y axis
            const products2 = this.products.filter((p) => p.unit !== this.primaryUnit);
            this.setAxisConfig('axisY2', products2);
        }

        this.setAxisConfig('axisY', products1);
    }

    /**
     * Load the time series (if necessary) and redraw the chart.
     */
    public updateChart() {
        this.abortCurrent();
        this.loadTimeSeries();
    }

    /**
     * Redraw the chart with the current data.
     */
    redrawChart() {
        if (this.chart) {
            this.redrawCurrentDate();
            this.redrawChartCanvasJS();
        }
    }

    /**
     * Method that re-draws the current date line on the chart
     */
    redrawCurrentDate(): void {
        const newStripLines = {
            stripLines: [
                {
                    value: this.dateSelected,
                    thickness: 1,
                    color: '#333',
                },
            ],
        };

        this.chart.options.axisX = Object.assign(this.chart.options.axisX, newStripLines);
        this.redrawChartCanvasJS();
    }

    /**
     * Updates the CanvasJS chart with the current data.
     */
    private redrawChartCanvasJS(): void {
        this.chart.options.data = this.activeData;
        this.chart.render();
        this.setChartCanvasJSClickEvent();
    }

    /**
     * This method listens for a click event on the canvas element for the timeseries chart.
     */
    setChartCanvasJSClickEvent() {
        const canvas = document
            .getElementsByClassName('canvasjs-chart-container')[0]
            .getElementsByTagName('canvas')[1];
        canvas.removeEventListener('dblclick', this.emitChartClick);
        canvas.addEventListener('dblclick', this.emitChartClick, false);
    }

    /**
     * Delete the canvas click listener.
     */
    clearClickListener() {
        const canvas = document
            .getElementsByClassName('canvasjs-chart-container')[0]
            .getElementsByTagName('canvas')[1];
        canvas.removeEventListener('dblclick', this.emitChartClick);
    }

    /**
     * RxJs chart click emitter.
     * @param ev
     */
    private emitChartClick = (ev) => {
        this.chartClick.next(ev);
    };

    /**
     * This method reads the click event on the canvas and translates it to the nearest day, setting the
     * new current date accordingly and redrawing the current date line.
     * @param ev
     */
    private onChartCanvasJSClick(ev) {
        if (!this.isLoaded || this.products.length === 0 || this.yearMode) {
            return;
        }
        const relX = ev.offsetX;
        const xValue = Math.round(this.chart.axisX[0].convertPixelToValue(relX));
        this.dateSelected = moment.utc(xValue).add(12, 'hours').startOf('day');

        const product = this.products[0];
        const productTimeSeries = this.findSeries(product.name);

        let dateSelected = this.dateSelected;
        let dateValue = productTimeSeries.dataPoints.find(function (element) {
            return element.x.isSame(dateSelected);
        });
        let newDateValue;
        if (dateValue.y === null) {
            newDateValue = productTimeSeries.dataPoints.find(function (element) {
                return element.x.isAfter(dateSelected) && element.y !== null;
            });
            this.dateSelected = newDateValue.x;
        }
        this.productService
            .getAvailability(product.apiName)
            .subscribe((productAvailability: ProductAvailability) => {
                let found = false;
                // we search for a date that have a value and is inside product availability
                while (!found) {
                    const currentIndex = binarySearch(
                        productAvailability.availability,
                        this.dateSelected,
                        cmpMoment
                    );
                    if (currentIndex === -1) {
                        this.dateSelected = productAvailability.findNearestDateBound(
                            this.dateSelected,
                            'upper'
                        );
                        dateSelected = this.dateSelected;
                        dateValue = productTimeSeries.dataPoints.find(function (element) {
                            return element.x.isSame(dateSelected);
                        });
                        if (dateValue.y === null) {
                            newDateValue = productTimeSeries.dataPoints.find(function (element) {
                                return element.x.isAfter(dateSelected) && element.y !== null;
                            });
                            this.dateSelected = newDateValue.x;
                        }
                    } else {
                        found = true;
                    }
                }
                this.redrawCurrentDate();
                this.layersService.setSelectedDate(this.dateSelected);
            });
    }

    /**
     * Append a new data value to a series
     *
     * @param name {string} series name
     * @param obj {Object} data to append
     * @param data {array} data array where to append series data
     * @param year {number} year to update in set of years
     */
    public appendToSeries(name: string, obj: Object, data = this.data, year?: number) {
        const series = this.findSeries(name, data);

        if (series !== undefined && !Number.isNaN(obj['y'])) {
            if (series.isYear) {
                series.dataPoints[obj['x'] - 1].y = obj['y'];
            } else {
                series.dataPoints.push(obj);
            }

            if (typeof obj['x'] !== 'number') {
                this.years.add(year === undefined ? obj['x'].year() : year); // update the set of years in data
            }
        }
    }

    /**
     * Creates a new series object or clears the existing one
     *
     * @param name {string} series name
     * @param data {array} data array where to create the series
     * @param seriesOptions {object} Series custom options, such us color.
     * @param visible {boolean} show/hide series
     * @param axisYType {string} axis Y type
     * @param axisYIndex {number} axis Y index
     *
     * @return new series object.
     */
    private createSeries(
        name: string,
        data = this.data,
        seriesOptions?,
        visible = true,
        axisYType = 'primary',
        axisYIndex = 0
    ): Series {
        const dataArray = [];
        let series = this.findSeries(name, data);

        if (series === undefined) {
            series = {
                name: name,
                dataPoints: dataArray,
                type: 'line',
                axisYType: axisYType,
                axisYIndex: axisYIndex,
                showInLegend: true,
                markerSize: 1,
                connectNullData: true,
                visible: visible,
                isYear: false,
            } as Series;

            if (this.primaryUnit !== undefined) {
                const product = this.getProductFromSeriesName(name);
                if (product.unit !== this.primaryUnit) {
                    series['axisYType'] = 'secondary';
                }
            }

            data.push(series);
        }

        // Add custom options when a new series is created
        if (seriesOptions) {
            Object.assign(series, seriesOptions);
        }

        return series;
    }

    /**
     * Creates a new series object for year by year data
     *
     * @param name
     * @param data
     * @param seriesOptions
     */
    private createYearSeries(name: string, data = this.data, seriesOptions?): Series {
        const series = this.createSeries(name, data, seriesOptions);
        series.isYear = true;

        const dataArray = [];
        for (let i = 0; i < 366; i++) {
            dataArray.push({ x: i + 1, y: null });
        }

        series.dataPoints = dataArray;
        series.srcDataPoints = series.dataPoints;

        return series;
    }

    /**
     * Search for a series object in this.data array
     *
     * @param name {string} series name
     * @param data {array} data array where to find the series
     * @returns {Object}
     */
    protected findSeries(name: string, data = this.data): Series {
        return data.find((element) => {
            return element.name === name;
        });
    }

    /**
     * Request time series data and add it to the chart, for the current attribute values.
     */
    loadTimeSeries() {
        for (let i = 0; i < this.data.length; i++) {
            this.previousTimeSeriesVisibleStatus[this.data[i].name] = this.data[i].visible;
        }
        this.data = [];
        this.activeData = this.data;
        this.yearData = undefined;
        this.years = new Set<number>();
        let productRightAxys = false;
        for (const product of this.products) {
            // create series for multiple products
            const seriesName = this.generateSeriesName(product, undefined);
            let settings = {};
            if (this.data.length === 0) {
                settings = { color: environment.timeSeriesLeftYColor };
            }
            if (
                this.primaryUnit !== undefined &&
                product.unit !== this.primaryUnit &&
                !productRightAxys
            ) {
                settings = { color: environment.timeSeriesRightYColor };
                productRightAxys = true;
            }

            const visible = this.getVisibleValue(product.name);
            this.createSeries(
                seriesName,
                this.data,
                product.timeSeriesType === 'cumulative_burned_area'
                    ? {
                          connectNullData: false,
                          markerSize: 5,
                      }
                    : settings,
                visible
            );
        }

        this.isLoaded = false;

        if (this.roi) {
            this.loadingRoiTimeSeries = true;
            if (this.progressBar) {
                this.progressBar.display = false;
            }
            const chartData: number | undefined = this.chart?.data?.length;
            if (chartData) {
                /** Remove the old data series before creating the new ones **/
                for (let i = 0; i < chartData; i++) {
                    this.chart.data[0].remove();
                }
            }
            const params = {
                roiId: this.roi.id,
                startDate: getISODateString(this.dateStart),
                endDate: getISODateString(this.dateEnd),
                climatology: true,
                avgWindowDays: 20,
                format: 'csv',
                avgWindowDirection: 'backward',
                provideCoverage: true,
            } as const;
            this.params = params;
            if (this.products.length > 0) {
                this.products.forEach((p) => {
                    this.damService.getROITimeSeries(p.apiName, params).subscribe(
                        (result) => {
                            const roiDataValues = {};
                            const roiDataValuesAvg = {};
                            const roiDatavaluesClim = {};
                            roiDataValues[p.apiName] = [];
                            roiDataValuesAvg[p.apiName] = [];
                            roiDatavaluesClim[p.apiName] = [];

                            const timeSeriesData = csvToJson(result);
                            const rawJsonData = timeSeriesData['data'];
                            let seriesName: string;
                            let seriesNameAverage: string;
                            let seriesNameClimatology: string;
                            let seriesNameCoverage: string;

                            seriesName = this.generateSeriesName(p, undefined);
                            if (!this.findSeries(seriesName)) {
                                this.createSeries(
                                    seriesName,
                                    undefined,
                                    undefined,
                                    this.getVisibleValue(seriesName)
                                );
                            }
                            seriesNameAverage = this.generateSeriesName(
                                p,
                                this.AVERAGE_SERIES_NAME
                            );
                            if (!this.findSeries(seriesNameAverage)) {
                                this.createSeries(
                                    seriesNameAverage,
                                    undefined,
                                    undefined,
                                    this.getVisibleValue(seriesNameAverage)
                                );
                            }
                            seriesNameClimatology = this.generateSeriesName(
                                p,
                                this.CLIMATOLOGY_SERIES_NAME
                            );
                            if (!this.findSeries(seriesNameClimatology)) {
                                this.createSeries(
                                    seriesNameClimatology,
                                    undefined,
                                    { color: 'black' },
                                    this.getVisibleValue(seriesNameClimatology)
                                );
                            }

                            seriesNameCoverage = this.generateSeriesName(
                                p,
                                this.COVERAGE_SERIES_NAME
                            );
                            this.setCoverageAxis();
                            const axisYIndex = this.primaryUnit ? 1 : 0;
                            if (!this.findSeries(seriesNameCoverage)) {
                                this.createSeries(
                                    seriesNameCoverage,
                                    undefined,
                                    this.getVisibleValue(seriesNameCoverage),
                                    false,
                                    'secondary',
                                    axisYIndex
                                );
                            }

                            for (let i = 0; i < rawJsonData.length; i++) {
                                const xVal = moment.utc(rawJsonData[i]['date']);
                                if (!this.years.has(xVal.year())) {
                                    this.years.add(xVal.year());
                                }
                                const pKey = p.apiName;
                                seriesName = this.generateSeriesName(p, undefined);
                                let valueObj = {
                                    x: xVal,
                                    y: rawJsonData[i][pKey]
                                        ? Number(rawJsonData[i][p.apiName])
                                        : '',
                                };
                                this.appendToSeries(seriesName, valueObj);

                                if (p.timeSeriesType !== 'cumulative_burned_area') {
                                    seriesNameAverage = this.generateSeriesName(
                                        p,
                                        this.AVERAGE_SERIES_NAME
                                    );
                                    valueObj = {
                                        x: xVal,
                                        y: rawJsonData[i][pKey + ' AVG']
                                            ? Number(rawJsonData[i][pKey + ' AVG'])
                                            : '',
                                    };
                                    this.appendToSeries(seriesNameAverage, valueObj);

                                    seriesNameClimatology = this.generateSeriesName(
                                        p,
                                        this.CLIMATOLOGY_SERIES_NAME
                                    );
                                    valueObj = {
                                        x: xVal,
                                        y: rawJsonData[i][pKey + ' CLIM']
                                            ? Number(rawJsonData[i][pKey + ' CLIM'])
                                            : '',
                                    };
                                    this.appendToSeries(seriesNameClimatology, valueObj);

                                    seriesNameCoverage = this.generateSeriesName(
                                        p,
                                        this.COVERAGE_SERIES_NAME
                                    );
                                    valueObj = {
                                        x: xVal,
                                        y: this.setCoverageValue(rawJsonData[i], pKey),
                                    };
                                    this.appendToSeries(seriesNameCoverage, valueObj);
                                }
                            }
                        },
                        () => {
                            this.isLoaded = true;
                            this.loadingRoiTimeSeries = false;
                            this.redrawChart();
                        },
                        () => {
                            this.isLoaded = true;
                            this.loadingRoiTimeSeries = false;
                            this.seriesLoaded.emit();

                            if (this.yearMode) {
                                this.setYearMode();
                            }

                            this.redrawChart();
                        }
                    );
                });
            } else {
                this.isLoaded = true;
                this.loadingRoiTimeSeries = false;
                this.redrawChart();
            }
        } else {
            this.progressBar?.reset();
            if (this.expectedValues) {
                // Set the progress bar info
                this.progressBar?.setTotal(this.expectedValues);
            } else {
                // Get number of days between the two dates
                const start = this.dateStart;
                const end = this.dateEnd;
                const expectedDayCount = (end.diff(start, 'days') + 1) * this.products.length;
                // Set the progress bar info
                this.progressBar?.setTotal(expectedDayCount);
            }

            for (const p of this.products) {
                const params = {
                    lat: this.lat,
                    lon: this.lng,
                    startDate: getISODateString(this.dateStart),
                    endDate: getISODateString(this.dateEnd),
                    avgWindowDirection: 'backward',
                } as const;
                this.params = params;
                const url = this.damService.getTimeSeriesStreamUrl(p.apiName, params);
                const oboeConfig = this.damService.getOboeConfig(url);

                new Observable<{
                    series: string;
                    data: {
                        x: Moment;
                        y: number | null;
                    };
                }>((observer) => {
                    // Load values
                    this.observer = observer;
                    this.oboeService = oboe(oboeConfig);

                    this.oboeService
                        .node({
                            '{date type value}': (thing) => {
                                // console.log("new broker message", thing);
                                const seriesName = this.generateSeriesName(p, undefined);

                                if (thing.type === 'value') {
                                    observer.next({
                                        series: seriesName,
                                        data: parseStreamSeries(thing),
                                    });
                                } else if (
                                    thing.type === 'average' &&
                                    p.timeSeriesType !== 'cumulative_burned_area'
                                ) {
                                    const seriesNameAverage = this.generateSeriesName(
                                        p,
                                        this.AVERAGE_SERIES_NAME
                                    );
                                    if (!this.findSeries(seriesNameAverage)) {
                                        this.createSeries(
                                            seriesNameAverage,
                                            undefined,
                                            undefined,
                                            this.getVisibleValue(seriesNameAverage)
                                        );
                                    }
                                    observer.next({
                                        series: seriesNameAverage,
                                        data: parseStreamSeries(thing),
                                    });
                                } else if (
                                    thing.type === 'climatology' &&
                                    p.timeSeriesType !== 'cumulative_burned_area'
                                ) {
                                    const seriesNameClimatology = this.generateSeriesName(
                                        p,
                                        this.CLIMATOLOGY_SERIES_NAME
                                    );
                                    if (!this.findSeries(seriesNameClimatology)) {
                                        this.createSeries(
                                            seriesNameClimatology,
                                            this.data,
                                            this.CLIMATOLOGY_OPTIONS,
                                            this.getVisibleValue(seriesNameClimatology)
                                        );
                                    }
                                    observer.next({
                                        series: seriesNameClimatology,
                                        data: parseStreamSeries(thing),
                                    });
                                }
                            },
                            '{result}': (thing) => {
                                // console.log("end message received", thing);
                                observer.complete();
                                this.progressBar?.hide();
                            },
                        })
                        .fail((err) => {
                            observer.error(err);
                        });
                })
                    .pipe(bufferTime(100))
                    .subscribe({
                        next: (values) => {
                            let seriesValues = 0;
                            if (values.length > 0) {
                                for (const obj of values) {
                                    const product = this.getProductFromSeriesName(obj.series);
                                    obj.data.y = this.dataTransform(product, obj.data.y);
                                    this.appendToSeries(obj.series, obj.data);
                                    if (this.isValuesSeries(obj.series)) {
                                        // check and count normal values series
                                        seriesValues++;
                                    }
                                }
                            }
                            if (seriesValues > 0) {
                                this.progressBar?.increase(seriesValues);
                                this.redrawChart();
                            }
                        },
                        complete: () => {
                            this.isLoaded = true;
                            this.seriesLoaded.emit();

                            if (this.yearMode) {
                                this.setYearMode();
                            }

                            this.redrawChart();
                        },
                        error: () => {
                            this.isLoaded = true;
                            this.redrawChart();
                            this.progressBar?.hide();
                        },
                    });
            }
        }
    }

    /**
     * Download graph as png
     */
    public saveChartAsPng() {
        this.chart.exportChart({
            fileName: this.generatePngFileName(),
            format: 'png',
        });
    }

    /**
     * Generates a file name for the exported png
     *
     * @returns {string}
     */
    private generatePngFileName(): string {
        let dateStart = this.dateStart ? this.dateStart.format('YYYY-MM-DD') : undefined;
        let dateEnd = this.dateEnd ? this.dateEnd.format('YYYY-MM-DD') : undefined;

        if (this.roi) {
            [dateStart, dateEnd] = this.calcStarEndDates().map((d) =>
                d.toISOString().substr(0, 10)
            );
        }

        return `time-series_${dateStart}_${dateEnd}`;
    }

    /**
     * Get CSV objects from a CSV string
     */
    public _getCsvRowObjects(dateStart: string, dateEnd: string) {
        const rows = [];
        const headers = this._getCsvHeaders();
        const dateFieldName = headers[0].column; // headers[0] is the date field
        const current = moment.utc(dateStart);
        const end = moment.utc(dateEnd);

        // first, create rows for the dates range with empty values
        while (current.isBefore(end, 'days') || current.isSame(end, 'days')) {
            // add a new row
            const row = {};
            for (const h of headers) {
                row[h.column] = ''; // the row must have a value for all columns
            }
            row[dateFieldName] = current.format('YYYY-MM-DD');
            rows.push(row);
            current.add(1, 'days');
        }

        // update the rows with time series data
        const downloadableSeries = this.downloadableSeries();
        for (const series of downloadableSeries) {
            const header = headers.find((h) => h.series === series.name);
            let rowIndex = 0;
            let pointIndex = 0;

            if (header !== undefined) {
                const colName = header.column;
                while (pointIndex < series.dataPoints.length && rowIndex < rows.length) {
                    // find the index of the row object for current series date
                    const value = series.dataPoints[pointIndex];
                    const xDate = moment.utc(value.x);
                    const rowDate = moment.utc(rows[rowIndex][dateFieldName]);

                    if (xDate.isSame(rowDate, 'day')) {
                        // Common case first
                        rows[rowIndex][colName] = value.y ? value.y : '';
                        pointIndex += 1;
                        rowIndex += 1;
                    } else if (xDate.isBefore(rowDate, 'day')) {
                        pointIndex += 1;
                    } else {
                        rowIndex += 1;
                    }
                }
            }
        }

        return rows;
    }

    /**
     * Get headers (column names) for csv file
     * @returns {string[]}
     */
    public _getCsvHeaders() {
        const headers = [
            {
                series: 'date',
                column: 'date',
            },
        ];
        const downloadableSeries = this.downloadableSeries();
        for (const series of downloadableSeries) {
            const header = {
                // This object is for mapping the series name to the CSV column name
                series: '',
                column: '',
            };

            const product = this.getProductFromSeriesName(series.name);

            if (this.isValuesSeries(series.name)) {
                header.series = series.name;
                header.column = product.apiName;
                headers.push(header);
            } else if (series.name.endsWith(this.AVERAGE_SERIES_NAME)) {
                header.series = series.name;
                header.column = product.apiName + '_AVG';
                headers.push(header);
            } else if (series.name.endsWith(this.CLIMATOLOGY_SERIES_NAME)) {
                header.series = series.name;
                header.column = product.apiName + '_CLIMATOLOGY';
                headers.push(header);
            } else if (series.name.endsWith(this.COVERAGE_SERIES_NAME)) {
                header.series = series.name;
                header.column = product.apiName + '_COVERAGE';
                headers.push(header);
            }
        }
        return headers;
    }

    /**
     * Download time series data as CSV
     */
    public saveAsCsv() {
        const dateFormat = 'YYYY-MM-DD';
        if (!this.isLoaded) {
            return;
        }

        let dateStart = this.dateStart ? this.dateStart.format(dateFormat) : undefined;
        let dateEnd = this.dateEnd ? this.dateEnd.format(dateFormat) : undefined;

        if (this.roi) {
            [dateStart, dateEnd] = this.calcStarEndDates().map((d) => d.format(dateFormat));
        }

        const data = this._getCsvRowObjects(dateStart, dateEnd);
        let fileName;
        if (this.roi === undefined) {
            fileName = `series_${this.lat}_${this.lng}_${this.dateStart.format(
                dateFormat
            )}_${this.dateEnd.format(dateFormat)}`;
        } else {
            fileName = `series_${this.roi.name}_${this.roi.id}_${dateStart}_${dateEnd}`;
        }

        const columns = this._getCsvHeaders().map((h) => h.column);
        const a2csv = new AngularCsv(data, fileName, { headers: columns });
    }

    /**
     * Abort current timeseries streaming connection
     */
    public abortCurrent() {
        if (!this.isLoaded && !this.roi) {
            this.oboeService.abort();
            this.observer.error();
        }
    }

    /**
     * Returns an array of downloadable timeseries
     * @return {any[]}
     */
    private downloadableSeries(): Array<Series> {
        return this.data.filter((val) => val.name !== this.SERIE_SELECT_DATE);
    }

    /**
     * Calculates start and end dates from received data series
     * @returns {[Moment,Moment]}
     */
    private calcStarEndDates(): Array<Moment> {
        const starts = this.downloadableSeries().map((series) => series.dataPoints[0].x);
        const ends = this.downloadableSeries().map(
            (series) => series.dataPoints[series.dataPoints.length - 1].x
        );
        const val = [
            starts.reduce((a, b) => {
                return a < b ? a : b;
            }),
            ends.reduce((a, b) => {
                return a > b ? a : b;
            }),
        ];
        return val;
    }

    /**
     * Build data series for year on year graph
     */
    private buildYearDataSeries() {
        if (this.data.length === 0) {
            return; // not yet loaded
        }
        this.yearData = [];
        const years = Array.from(this.years.values()).sort().reverse();
        this.buildYearClimatologyAvg();

        for (const series of this.data) {
            if (series.name.endsWith(this.AVERAGE_SERIES_NAME)) {
                this.buildYearSingleSeries(series, years);
            }
        }
    }

    /**
     * Build data series for year on year graph, for cumulative_burned_area time-series
     */
    private buildBurnedAreaYearDataSeries() {
        if (this.data.length === 0) {
            return; // not yet loaded
        }
        this.yearData = [];
        const years = Array.from(this.years.values()).sort().reverse();

        for (const series of this.data) {
            if (this.isValuesSeries(series.name)) {
                const seriesOptions = {
                    visible: false,
                    connectNullData: false,
                    markerSize: 5,
                };
                this.buildBurnedAreaYearSingleSeries(series, years, seriesOptions);
            }
        }
    }

    /**
     * Generate the year on year series.
     * @param series
     * @param years array of years in descending order
     * @param seriesOptions
     */
    private buildYearSingleSeries(series, years: Array<number>, seriesOptions: any = {}) {
        const VISIBLE_YEARS = 3;
        let visibleCount = 0;
        for (const year of years) {
            // create a series per year
            seriesOptions.visible = visibleCount < VISIBLE_YEARS;
            this.createYearSeries(`${series.name} ${year}`, this.yearData, seriesOptions);

            visibleCount += 1;
        }

        for (const point of series.dataPoints) {
            // append series point to the corresponding year series,
            // Maps day/month based on a leap-year (2016)
            const date2016 = moment.utc(point.x).year(2016);
            const point2016 = { x: date2016.dayOfYear(), y: point.y };
            this.appendToSeries(
                `${series.name} ${point.x.year()}`,
                point2016,
                this.yearData,
                point.x.year()
            );
        }
    }

    /**
     * Generate the year on year series, for cumulative_burned_area time-series
     * @param series
     * @param years array of years in descending order
     * @param seriesOptions
     */
    private buildBurnedAreaYearSingleSeries(series, years: Array<number>, seriesOptions: any = {}) {
        const VISIBLE_YEARS = 3;
        let visibleCount = 0;
        for (const year of years) {
            // create a series per year
            seriesOptions.visible = visibleCount < VISIBLE_YEARS;
            this.createYearSeries(`${series.name} ${year}`, this.yearData, seriesOptions);

            visibleCount += 1;
        }

        if (this.yearModeStart > 1) {
            // add the extra series for points below the yearModeStart day
            const year = years[years.length - 1] - 1;
            seriesOptions.visible = visibleCount < VISIBLE_YEARS;
            this.createYearSeries(`${series.name} ${year}`, this.yearData, seriesOptions);
        }

        for (const point of series.dataPoints) {
            // append series point to the corresponding year series,
            // Maps day/month based on a leap-year (2016)
            const year = point.x.year();
            const date2016 = moment.utc(point.x).year(2016);
            const point2016 = { x: date2016.dayOfYear(), y: point.y };
            const dayOfYear = point2016.x;
            const seasonYear = dayOfYear < this.yearModeStart ? year - 1 : year;

            if (this.yearModeStart > 1) {
                point2016.x =
                    dayOfYear >= this.yearModeStart
                        ? dayOfYear - this.yearModeStart + 1
                        : 366 - this.yearModeStart + dayOfYear + 1;
            }

            this.appendToSeries(
                `${series.name} ${seasonYear}`,
                point2016,
                this.yearData,
                seasonYear === year - 1 ? undefined : year
            );
        }
    }

    /**
     * Compute the climatology average per day [1-366].
     */
    public buildYearClimatologyAvg() {
        const climatologyYearOptions = Object.assign({}, this.CLIMATOLOGY_OPTIONS, {
            lineThickness: 4,
        });
        const climatologySeriesList = this.data.filter((s) =>
            s.name.endsWith(this.CLIMATOLOGY_SERIES_NAME)
        );

        climatologySeriesList.forEach((cs) => {
            const climatologyYearSeries = this.createYearSeries(
                cs.name,
                this.yearData,
                climatologyYearOptions
            );
            const yearValues = {};

            // Initialize days
            for (let i = 1; i <= 366; i++) {
                yearValues[i] = { value: 0, count: 0 };
            }

            // Value average per day in a leap year (2016)
            for (const point of cs.dataPoints) {
                const date2016 = moment.utc(point.x).year(2016);
                const day = date2016.dayOfYear();

                if (point.y !== null) {
                    yearValues[day].value += point.y;
                    yearValues[day].count += 1;
                }
            }

            // Generate series
            for (const day of Object.keys(yearValues)) {
                const data = yearValues[day];
                const val = data.count > 0 ? this.roundClimatology(data.value / data.count) : null;
                climatologyYearSeries.dataPoints[parseInt(day, 10) - 1].y = val;
            }
        });
    }

    /**
     * Round the climatology.
     */
    private roundClimatology(n): number {
        const d = 10000;
        return Math.round(n * d) / d;
    }

    /**
     * Set graph to year mode: each year is a series
     */
    public setYearMode() {
        this.canvasjsOptions.axisX.viewportMinimum = null;
        this.canvasjsOptions.axisX.viewportMaximum = null;
        this.canvasjsOptions.toolTip.contentFormatter = this.canvasJSTooltipCustomYear;
        this.canvasjsOptions.axisX.labelFormatter = this.dayOfYearToLabel;

        if (this.products.length === 1) {
            // year mode is available at the moment for one product only
            if (this.yearData === undefined) {
                if (this.products[0].timeSeriesType === 'cumulative_burned_area') {
                    this.buildBurnedAreaYearDataSeries();
                } else {
                    this.buildYearDataSeries();
                }
            }

            if (this.roi) {
                this.addROIMetadataTrendLines();
            }
        }

        this.activeData = this.yearData;
    }

    /**
     * Set graph to linear mode: from start to end date
     */
    public setLinearMode() {
        this.canvasjsOptions.axisX.viewportMinimum = null;
        this.canvasjsOptions.axisX.viewportMaximum = null;
        this.canvasjsOptions.toolTip.contentFormatter = this.canvasJSTooltipCustomLinear;
        this.canvasjsOptions.axisX.labelFormatter = this.standardLabelFormat;

        if (this.roi) {
            this.removeROIMetadataTrendLines();
        }

        this.activeData = this.data;
    }

    /**
     * Maps a day of year to text label (month - day). Used in year mode
     * @param e
     * @returns {string}
     */
    private dayOfYearToLabel = (e): string => {
        const day = (this.yearModeStart + e.value - 1) % 366;
        return moment.utc([2016, 0, 1]).dayOfYear(day).format('MMM DD');
    };

    /**
     * Standard label formatter
     * @param e
     * @returns {string}
     */
    private standardLabelFormat = (e): string => {
        return moment.utc(e.value).format('YYYY-MM-DD');
    };

    /**
     * Switch time series mode between year by year and linear.
     * @param yearMode
     */
    public toggleYearMode(yearMode) {
        if (yearMode) {
            this.setYearMode();
        } else {
            this.setLinearMode();
        }

        this.redrawChart();
    }

    /**
     * Reordering of year mode time series, makes the start point `this.yearModeStart`
     */
    private reorderYearSeries() {
        const reorder = (dataPoints) => {
            const slicePoint = dataPoints.findIndex((ele) => ele.x === this.yearModeStart);
            const newSeries = [];
            for (let i = 0; i < 366; i++) {
                newSeries.push({
                    x: i + 1,
                    y: dataPoints[(slicePoint + i) % 366].y,
                });
            }
            return newSeries;
        };

        for (const series of this.yearData) {
            series.dataPoints = reorder(series.srcDataPoints);
        }
    }

    /**
     * Add Y axis trend lines, based on ROI metadata
     */
    private addROIMetadataTrendLines() {
        if (this.roi.metadata === undefined || this.products.length !== 1) {
            return; // nothing to do
        }

        // search metadata for the selected product
        const prodMetadata = this.roi.metadata.find(
            (ele) => ele.productApiName === this.products[0].apiName
        );

        if (prodMetadata === undefined) {
            return; // nothing to do
        }

        // get thresholds from prodMetadata.data and create axisY stripLines
        this.canvasjsOptions.axisY.stripLines = [];

        for (const key in prodMetadata.data) {
            if (key.startsWith('max') || key.startsWith('min')) {
                // add min and max lines
                this.canvasjsOptions.axisY.stripLines.push({
                    value: prodMetadata.data[key],
                    color: key.startsWith('max') ? '#F3301C' : '#1CDFF3',
                    label: key,
                    labelFontColor: key.startsWith('max') ? '#F3301C' : '#1CDFF3',
                    labelAlign: 'near',
                    labelFontSize: 10,
                });
            }
        }
    }

    /**
     * Remove the Y axis trend lines, based on ROI metadata
     */
    private removeROIMetadataTrendLines() {
        if (this.roi.metadata === undefined) {
            return; // nothing to do
        }
        this.canvasjsOptions.axisY.stripLines = [];
    }

    /**
     * Apply transformations to the time series values depending on user configuration
     * @param product
     * @param val
     */
    private dataTransform(product: Product, val: number) {
        if (val !== null && product.isAreaUnit()) {
            return getAreaValue(val, product.unit);
        }
        return val;
    }

    /**
     * Extract the year from a series name
     *
     * @param name
     */
    private getYearFromName = (name: string): number => {
        const toks = name.split(' ');
        return parseInt(toks[toks.length - 1], 10);
    };

    /**
     * Get a product given a series name
     *
     * @param seriesName
     */
    private getProductFromSeriesName = (seriesName: string): Product => {
        return this.products.find((p) => seriesName.startsWith(p.name));
    };

    /**
     * Generate series name depending on the product and type (undefined for normal values)
     *
     * @param product
     * @param type
     */
    private generateSeriesName(product: Product, type: string): string {
        if (type === undefined) {
            return product.name;
        }
        return `${product.name} ${type}`;
    }

    /**
     * Checks whether series name belongs to a normal values series
     *
     * @param seriesName
     */
    private isValuesSeries(seriesName: string): boolean {
        const pos = [
            this.AVERAGE_SERIES_NAME,
            this.CLIMATOLOGY_SERIES_NAME,
            this.COVERAGE_SERIES_NAME,
        ].findIndex((suff) => seriesName.endsWith(suff));
        return pos < 0;
    }

    /**
     * Set axis configuration
     *
     * @param {string} axisName: either axisY or axisY2
     * @param {Product[]} products on axis
     */
    private setAxisConfig(axisName: string, products: Product[]) {
        const axis = {};

        if (products.length > 0) {
            axis['suffix'] = ' ' + this.getProductUnitDisp(products[0]);
        }

        // Update chart Y Axis title
        axis['title'] = products.length === 1 ? products[0].name : '';

        // Update chart Y axis min value
        const minValues = products
            .map((p) => (p.minVal ? p.minVal : undefined))
            .filter((v) => v !== undefined);
        if (minValues.length > 0) {
            axis['minimum'] = Math.min(...minValues);
        }

        this.canvasjsOptions[axisName] = [axis];
    }

    /**
     * Set coverage axis configuration
     *
     */
    private setCoverageAxis() {
        const axis = {
            suffix: ' %',
            title: 'Coverage',
            minimum: 0,
            maximum: 100,
        };
        const coverageIndex = this.canvasjsOptions['axisY2'].findIndex(
            (ax) => ax.title === 'Coverage'
        );
        if (coverageIndex > -1) {
            this.canvasjsOptions['axisY2'][coverageIndex] = axis;
        } else {
            this.canvasjsOptions['axisY2'].push(axis);
        }
    }

    /**
     * Show/hide time series of a product
     */
    public updateProductVisibility(event) {
        const productTimeSeriesArray = [
            event.product.name,
            this.generateSeriesName(event.product, this.CLIMATOLOGY_SERIES_NAME),
            this.generateSeriesName(event.product, this.AVERAGE_SERIES_NAME),
            this.generateSeriesName(event.product, this.COVERAGE_SERIES_NAME),
        ];

        for (const productTimeSeries of productTimeSeriesArray) {
            const timeSeries = this.findSeries(productTimeSeries);
            timeSeries.visible = event.show;
        }
        this.chart.render();
    }

    /**
     * Get the visible value of the product to create the time series
     * @param timeSeriesName
     */
    private getVisibleValue(timeSeriesName) {
        let visible = true;
        if (
            this.previousTimeSeriesVisibleStatus &&
            typeof this.previousTimeSeriesVisibleStatus[timeSeriesName] === 'boolean'
        ) {
            visible = this.previousTimeSeriesVisibleStatus[timeSeriesName];
        }
        return visible;
    }

    /**
     * Set coverage based on  value
     * @param rawJsonData
     * @param pKey apiName
     * @private
     */
    private setCoverageValue(rawJsonData, pKey) {
        if (
            (!rawJsonData[pKey] && Number(rawJsonData[pKey + ' COV']) === 0) ||
            !Number(rawJsonData[pKey + ' COV'])
        ) {
            return '';
        }
        return Number(rawJsonData[pKey + ' COV']);
    }
}
