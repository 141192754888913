/**
 * Layer Key type.
 */
export type LayerKey = number;

/**
 * SpecialLayerData class
 */
export class SpecialLayerData {
    name: string;
    key: LayerKey;

    display: boolean;
    opacity: number;
    layer: L.GeoJSON;

    constructor(data?: object) {
        if (data !== undefined) {
            Object.assign(this, data);
        }
    }

    /**
     * If the layer should be displayed
     * @returns {boolean}
     */
    isVisible(): boolean {
        return this.display;
    }
}
