import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Inject,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DynamicDialog<T, D = any, R = any> {
    dialogRef: MatDialogRef<DynamicLoaderComponent<DynamicDialog<T, D, R>, D, R>, R>;
    dialogData: D;
}

@Component({
    selector: 'app-dynamic-loader',
    templateUrl: './dynamic-loader.component.html',
    styleUrls: ['./dynamic-loader.component.scss'],
})
export class DynamicLoaderComponent<T extends DynamicDialog<T, D, R>, D = any, R = any>
    implements AfterContentInit
{
    /**
     * Reference to the host element where the dynamic component will be injected.
     */
    @ViewChild('dynamicHost', { read: ViewContainerRef, static: true })
    dynamicHost: ViewContainerRef;

    /**
     * Reference to the dynamic component.
     */
    private componentReference: ComponentRef<T>;

    /**
     * Dialog injected data.
     */
    private readonly dialogData: D;

    constructor(
        private dialogRef: MatDialogRef<DynamicLoaderComponent<T, D, R>, R>,
        @Inject(MAT_DIALOG_DATA)
        public data: { componentType: Type<T>; childData: D },
        private resolver: ComponentFactoryResolver
    ) {
        this.dialogData = data.childData;
    }

    ngAfterContentInit() {
        // It's important to load the component before the content is checked
        this.loadComponent(this.data.componentType);
    }

    /**
     * Inject component into the view.
     * @param componentType
     */
    loadComponent(componentType: Type<T>) {
        if (componentType !== undefined) {
            const componentFactory = this.resolver.resolveComponentFactory<T>(componentType);
            this.componentReference = this.dynamicHost.createComponent(componentFactory);
            this.componentReference.instance.dialogRef = this.dialogRef;
            this.componentReference.instance.dialogData = this.dialogData;
        }
    }
}
