import { inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { getDomain, getUserForApi } from '../utils/utils';

@Injectable()
export class UserForApiInterceptor implements HttpInterceptor {
    private cookieService = inject(CookieService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const domain = getDomain();
        const cookie = this.cookieService.get(environment.userForApi);

        const userForApi = getUserForApi();
        if (userForApi === undefined) {
            // if the userForApi is undefined we have to check if the cookie exists. If so, we have to delete it to avoid impersonate
            // request to Onesat
            if (cookie) {
                this.cookieService.delete(environment.userForApi, undefined, domain);
                // reload the page to get the own data instead of the data of the impersonate user
                window.location.reload();
            }
            return next.handle(req);
        }
        // if userForApi exists and the cookie doesn't, we create it to make request as the impersonate user
        if (!cookie) {
            this.cookieService.set(
                environment.userForApi,
                userForApi,
                undefined,
                undefined,
                domain,
                true
            );
        }

        const update = { setHeaders: {} };
        update.setHeaders[environment.userForApi] = userForApi;
        const modified = req.clone(update);
        return next.handle(modified);
    }
}
