import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as L from 'leaflet';
import { EmbedLink } from '../../models/embed-link';
import { GeoJsonObject } from 'geojson';
import { environment } from '../../environments/environment';
import { EmbedService } from '../../services/embed.service';
import { Product } from '../../models/product';

/**
 * Dialog modes type.
 */
export type EmbedPreviewMode = 'edit' | 'create';

/**
 * Dialog result interface.
 */
export interface EmbedPreviewResult {
    mode?: EmbedPreviewMode;
    embedLink?: EmbedLink;
    delete?: boolean;
    bounds?: any;
    description?: string;
}

/**
 * Constant to define the edit mode
 * @type {string}
 */
const EDIT_MODE: EmbedPreviewMode = 'edit';

/**
 * Constant to define the create mode
 * @type {string}
 */
const CREATE_MODE: EmbedPreviewMode = 'create';

const embedAreaStyle = <L.PathOptions>{
    color: environment.colorRoi,
    fillOpacity: 0.25,
};

@Component({
    selector: 'app-region-preview-dialog',
    templateUrl: './region-preview-dialog.component.html',
    styleUrls: ['./region-preview-dialog.component.scss'],
})
export class RegionPreviewDialogComponent implements OnInit {
    /**
     * Preview mode.
     */
    public mode: EmbedPreviewMode;

    /**
     * Dom reference to the Leaflet map preview.
     */
    private map: L.Map; // Map DOM reference

    /**
     * Embed link
     */
    public embedLink: EmbedLink;

    /**
     * String with the link of the embed view
     */
    public shareLink: string;

    /**
     * Bounds of the area of the embed view
     */
    private bounds;

    /**
     * Dialog title.
     */
    public title = '';

    /**
     * Selected product
     */
    public selectedProduct: Product;

    /**
     * Type of region to display
     */
    regionType: string;

    /**
     * Layer group for the geometries
     * @type {LayerGroup<any>}
     */
    private layerGroup: L.LayerGroup = new L.LayerGroup();

    constructor(
        public dialogRef: MatDialogRef<RegionPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private embedService: EmbedService
    ) {
        if (data.embedLink) {
            this.mode = EDIT_MODE;
            this.embedLink = data.embedLink;
        } else if (data.bounds) {
            this.mode = CREATE_MODE;
            this.bounds = data.bounds;
            this.embedLink = new EmbedLink();
            this.embedLink.description = '';
        }
        if (data.regionType) {
            this.regionType = data.regionType;
        } else {
            this.regionType = 'embed';
        }

        if (data.product) {
            this.selectedProduct = data.product;
        }
    }

    ngOnInit() {
        if (this.regionType === 'embed') {
            if (this.mode === CREATE_MODE) {
                this.title = 'Create embed link';
            } else {
                this.title = 'Edit embed link';
            }
        } else {
            this.title = 'Preview region';
        }
    }

    /**
     * Dismiss the changes.
     */
    dismiss() {
        this.dialogRef.close();
    }

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;
        this.layerGroup.addTo(this.map);

        // Queue update after angular lifecycle finish
        setTimeout(() => {
            this.previewEmbed();
        }, 0);
    }

    previewEmbed() {
        let totalLayer;
        if (this.embedLink.link) {
            this.mode = EDIT_MODE;
            totalLayer = L.geoJSON(<GeoJsonObject>this.embedLink.areaAllowed);
            this.shareLink = `${window.location.protocol}//${window.location.host}/embed?${this.embedLink.link}`;
            totalLayer.setStyle(embedAreaStyle);
            totalLayer.addTo(this.layerGroup);
        } else if (this.bounds) {
            this.mode = CREATE_MODE;
            const geojson = {
                type: 'Polygon',
                coordinates: [
                    [
                        [this.bounds.getNorthWest().lng, this.bounds.getNorthWest().lat],
                        [this.bounds.getNorthEast().lng, this.bounds.getNorthEast().lat],
                        [this.bounds.getSouthEast().lng, this.bounds.getSouthEast().lat],
                        [this.bounds.getSouthWest().lng, this.bounds.getSouthWest().lat],
                        [this.bounds.getNorthWest().lng, this.bounds.getNorthWest().lat],
                    ],
                ],
            };
            totalLayer = L.geoJSON(<GeoJsonObject>geojson);
            totalLayer.setStyle(embedAreaStyle);
            totalLayer.addTo(this.layerGroup);
        }

        this.map.invalidateSize();
        this.map.fitBounds(totalLayer.getBounds());
    }

    /**
     * Delete the embed link.
     */
    delete() {
        if (confirm(`Are you sure to delete ${this.embedLink.description}?`)) {
            const dialogResult: EmbedPreviewResult = {
                delete: true,
            };

            this.dialogRef.close(dialogResult);
        }
    }

    /**
     *  Save the embed link that is in the preview.
     */
    save() {
        const dialogResult: EmbedPreviewResult = {
            mode: this.mode,
        };

        if (this.mode === CREATE_MODE) {
            dialogResult.bounds = this.bounds;
            dialogResult.description = this.embedLink.description;
        } else {
            this.embedService.update(this.embedLink).subscribe((embedLink: EmbedLink) => {
                dialogResult.embedLink = embedLink;
            });
        }

        this.dialogRef.close(dialogResult);
    }
}
