import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ProductService } from '../../services/product.service';
import { Product, ProductMetadata } from '../../models/product';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';
import { Observable } from 'rxjs';

export interface ProductInfoDialogData {
    product: Product;
}

@Component({
    selector: 'app-product-info-dialog',
    templateUrl: './product-info-dialog.component.html',
    styleUrls: ['./product-info-dialog.component.scss'],
})
export class ProductInfoDialogComponent
    implements OnInit, DynamicDialog<ProductInfoDialogComponent, ProductInfoDialogData>
{
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<ProductInfoDialogComponent, ProductInfoDialogData>
    >;

    public dialogData: ProductInfoDialogData;

    productInfo$: Observable<ProductMetadata>;

    constructor(private productService: ProductService) {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.productInfo$ = this.productService.getProductMetadata(this.dialogData.product.apiName);
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }
}
