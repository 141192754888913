<h1>Most Recent Releases</h1>
<h2>[2.22.2] - 2024-01-29</h2>
<h3>Added</h3>
<ul>
<li>Add labels for input fields in Time Series Request form</li>
</ul>
<h2>[2.21.0] - 2024-01-19</h2>
<h3>Added</h3>
<ul>
<li>Add the option to sign-in with Planet</li>
</ul>
<h2>[2.20.5] - 2022-10-26</h2>
<h3>Fixed</h3>
<ul>
<li>Add warning to be patient while loading point time series (as long as streaming is broken)</li>
</ul>
<h2>[2.20.3] - 2022-10-14</h2>
<h3>Fixed</h3>
<ul>
<li>Redirect to login page after 401 Unauthorized (like for rejected JWT token)</li>
</ul>
<h2>[2.20.2] - 2022-10-05</h2>
<h3>Changed</h3>
<ul>
<li>Move to Planet servers</li>
</ul>
