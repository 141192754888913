import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class AddOriginInterceptor implements HttpInterceptor {
    /**
     * Default constructor.
     */
    constructor() {}

    /**
     * Add custom header to detect requests from FronSat
     *
     * @param request Request to append the header to
     */
    private addOrigin(request) {
        return request.clone({
            setHeaders: {
                [environment.fronsatOriginHeader]: 'true',
            },
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url_blacklist = ['login.planet.com', 'login-next.planet.com'];

        // do not send the X-FronSat header to login provider
        for (var i = 0; i < url_blacklist.length; i++) {
            if (req.url.includes(url_blacklist[i])) {
                return next.handle(req);
            }
        }

        return next.handle(this.addOrigin(req));
    }
}
