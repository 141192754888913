<div class="categorical-legend-wrapper flex" *ngIf="legend">
    <!-- mode full: draw group labels, color boxes and category abbreviation -->
    <!-- mode dense: draw group labels and color boxes -->
    <!-- mode collapsed: draw color boxes only -->

    <div *ngIf="mode === 'full' || mode === 'dense'" class="flex flex-col w-full h-full">
        <!-- Mode full and dense -->
        <div
            *ngFor="let group of groups"
            class="flex"
            [ngClass]="
                mode === 'full'
                    ? 'flex-col justify-between items-start'
                    : 'justify-start items-center'
            "
        >
            <div *ngIf="groups.length > 1">
                <p class="legend-group-name">{{ group }}</p>
            </div>

            <div class="flex flex-wrap gap-1">
                <div
                    class="flex justify-start items-center"
                    *ngFor="let category of groupsCateg[group]"
                >
                    <div
                        class="category-box-color"
                        [ngStyle]="{
                            'background-color': '#' + category['cssColor']
                        }"
                        [matTooltip]="category['label']"
                    ></div>
                    <div *ngIf="mode === 'full'">
                        <p class="legend-short-label">
                            {{ category['shortLabel'] }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode === 'collapsed'" class="flex flex-col w-full h-full">
        <!-- Mode collapsed -->
        <div class="flex flex-wrap gap-1">
            <div class="flex justify-start items-center" *ngFor="let category of categories">
                <div
                    class="category-box-color-collapsed"
                    [ngStyle]="{
                        'background-color': '#' + category['cssColor']
                    }"
                    [matTooltip]="category['label']"
                ></div>
            </div>
        </div>
    </div>

    <div *ngIf="mode === 'extended'" class="flex flex-col w-full h-full">
        <!-- Mode collapsed -->
        <div class="flex flex-col">
            <div
                class="category flex justify-start items-center"
                *ngFor="let category of categories"
            >
                <span
                    class="category-box-color"
                    [ngStyle]="{
                        'background-color': '#' + category['cssColor']
                    }"
                ></span>
                <p class="extended">{{ category['label'] }}</p>
            </div>
        </div>
    </div>
</div>
