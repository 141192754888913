import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs';
import { ApiRequestsService } from '../../services/api-requests.service';
import { ApiRequest } from '../../models/api-request';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getISODateString } from '../../utils/utils';
import { ApiRequestsListInterface } from '../../services/api-requests.service';

/**
 * Model for each row of the Region table.
 */
interface ApiAccessRow {
    uuid: string;
    product: string;
    format: string;
    date: string;
    type: string;
    notify: any;
    zipped: boolean;
    status: string;
}

@Component({
    selector: 'app-api-access-management',
    templateUrl: './api-access-management.component.html',
    styleUrls: ['./api-access-management.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ApiAccessManagementComponent implements OnInit, AfterViewInit {
    /**
     * Material table paginator.
     */
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * Material table sortable.
     */
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = [
        'uuid',
        'product',
        'format',
        'date',
        'type',
        'notify',
        'zipped',
        'status',
        'information',
    ];

    /**
     * Mat paginator available page sizes
     * @type {number[]}
     */
    pageSize = 10;

    pageSizeOptions = [10, 15, 20];

    pageIndex: any = 0;

    public resultsLength = 0;

    public pageEvent: PageEvent;

    /**
     * Material table data source.
     * @type {MatTableDataSource<any>}
     */
    dataSource = new MatTableDataSource([]);

    /**
     * List of rows with the region info.
     * @type {any[]}
     */
    apiAccessRows: ApiAccessRow[] = [];

    /**
     * Selected API Request
     */
    selectedApiRequest: ApiRequest = undefined;

    /**
     * List of user products
     */
    productsList: Product[] = [];

    /**
     * Product filter value
     */
    productFilter = undefined;

    /**
     * Request type filter value
     */
    requestTypeFilter = undefined;

    /**
     * Request status filter value
     */
    requestStatusFilter = undefined;

    /**
     * Request format filter value
     */
    formatFilter = undefined;

    /**
     * Request start date filter value
     */
    startDateFilter = undefined;

    /**
     * Request end date filter value
     */
    endDateFilter = undefined;

    /**
     * Request latitude filter value
     */
    latitudeFilter = undefined;

    /**
     * Request longitude filter value
     */
    longitudeFilter = undefined;

    /**
     * Request minimum latitude filter value
     */
    minimumLatitudeFilter = undefined;

    /**
     * Request minimum longitude filter value
     */
    minimumLongitudeFilter = undefined;

    /**
     * Request maximum latitude filter value
     */
    maximumLatitudeFilter = undefined;

    /**
     * Request maximum longitude filter value
     */
    maximumLongitudeFilter = undefined;

    /**
     * Request status list with name and value
     */
    requestStatus = [
        { value: 'Ready', viewValue: 'Ready' },
        { value: 'Processing', viewValue: 'Processing' },
        { value: 'Scheduled', viewValue: 'Scheduled' },
        { value: 'Submitted', viewValue: 'Submitted' },
        { value: 'Error', viewValue: 'Error' },
    ];

    /**
     * Request type list with name and value
     */
    requestType = [
        { value: 'gridded', viewValue: 'Gridded data request' },
        { value: 'time-series', viewValue: 'Point time series request' },
        { value: 'roi-time-series', viewValue: 'ROI time series request' },
        { value: 'animation', viewValue: 'Animation request' },
    ];

    /**
     * Request format list with name and value
     */
    requestFormat = [
        {
            value: 'csv',
            viewValue: 'csv',
            'time-series': true,
            'roi-time-series': true,
        },
        {
            value: 'json',
            viewValue: 'json',
            'time-series': true,
            'roi-time-series': true,
        },
        { value: 'gtiff', viewValue: 'gtiff', gridded: true },
        { value: 'netcdf4', viewValue: 'netcdf4', gridded: true },
        { value: 'mp4', viewValue: 'mp4', animation: true },
    ];

    /**
     * Selected filter value
     */
    selectedFilter;

    /**
     * Flag to show a spinner when loading api request list
     */
    reloadingApiRequestList = false;

    /**
     * Subject to detect when the numeric inputs have changed
     */
    numericInputsChanged: Subject<string> = new Subject<string>();

    public expandedElement = null;

    constructor(
        private dialogRef: MatDialogRef<ApiAccessManagementComponent>,
        private apiRequestsService: ApiRequestsService,
        private productService: ProductService
    ) {}

    ngOnInit() {
        this.productService.list().subscribe((results: any) => {
            this.productsList = results.products;
        });
        this.numericInputsChanged.pipe(debounceTime(500)).subscribe((data) => {
            this.applyRequestFilter();
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.applyRequestFilter();
        });
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Set the selected api request
     * @param apiRequest
     */
    selectApiRequest(apiRequest) {
        this.selectedApiRequest = apiRequest;
    }

    /**
     * Apply filter to the data source
     * @param filterValue
     */
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Apply filter to the data source
     */
    applyRequestFilter(resetPaginator = false) {
        if (resetPaginator) {
            this.paginator.firstPage();
        }
        this.reloadingApiRequestList = true;
        const httpParams = {};

        if (this.productFilter) {
            httpParams['product'] = this.productFilter;
        }

        if (this.requestTypeFilter) {
            httpParams['api_request_type'] = this.requestTypeFilter;
            if (this.formatFilter) {
                const format = this.requestFormat.find((x) => x.value === this.formatFilter);
                if (format && format[this.requestTypeFilter]) {
                    httpParams['data_format'] = this.formatFilter;
                } else {
                    this.formatFilter = undefined;
                }
            }
        } else {
            if (this.formatFilter) {
                httpParams['data_format'] = this.formatFilter;
            }
        }

        if (this.requestStatusFilter) {
            httpParams['status'] = this.requestStatusFilter;
        }

        if (this.startDateFilter) {
            httpParams['start_date'] = getISODateString(this.startDateFilter);
        }

        if (this.endDateFilter) {
            httpParams['end_date'] = getISODateString(this.endDateFilter);
        }

        if (
            this.latitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'time-series')
        ) {
            httpParams['latitude'] = this.latitudeFilter;
        }

        if (
            this.longitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'time-series')
        ) {
            httpParams['longitude'] = this.longitudeFilter;
        }

        if (
            this.minimumLatitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'gridded')
        ) {
            httpParams['minimum_latitude'] = this.minimumLatitudeFilter;
        }

        if (
            this.minimumLongitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'gridded')
        ) {
            httpParams['minimum_longitude'] = this.minimumLongitudeFilter;
        }

        if (
            this.maximumLatitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'gridded')
        ) {
            httpParams['maximum_latitude'] = this.maximumLatitudeFilter;
        }

        if (
            this.maximumLongitudeFilter &&
            (!this.requestTypeFilter || this.requestTypeFilter === 'gridded')
        ) {
            httpParams['maximum_longitude'] = this.maximumLongitudeFilter;
        }

        httpParams['page'] = this.paginator.pageIndex + 1;
        httpParams['limit'] = this.paginator.pageSize;
        this.apiRequestsService.list(httpParams).subscribe((results: ApiRequestsListInterface) => {
            this.resultsLength = results.total;
            this.setApiAccessRows(results.requests);
        });
    }

    /**
     * Send the input value to the Subject to apply the filters
     * @param query, numeric input value
     */
    debounceApplyFilter(query) {
        this.numericInputsChanged.next(query);
    }

    /**
     * Set the data source with the data of the api requests
     * @param requests
     */
    setApiAccessRows(requests) {
        this.apiAccessRows = requests;
        this.dataSource.data = this.apiAccessRows;
        this.dataSource.sort = this.sort;

        this.reloadingApiRequestList = false;
    }

    setPage(event) {
        this.pageEvent = event;
        this.applyRequestFilter();
    }
}
