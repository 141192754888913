import { Component, ElementRef, Input, OnInit, inject, OnDestroy } from '@angular/core';

import { ProductService } from '../../services/product.service';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { LayersAddComponent } from '../layers-add/layers-add.component';
import { LayersService } from '../../services/layers.service';
import { LayerKey } from '../../models/layer-data';
import { DefaultProduct, Product } from '../../models/product';
import { UserService } from '../../services/user.service';
import { ProductAvailability } from '../../models/product-availability';
import { Group } from '../../models/group';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-layers-control',
    templateUrl: './layers-control.component.html',
    styleUrls: ['./layers-control.component.scss'],
})
export class LayersControlComponent implements OnInit, OnDestroy {
    /** Array of layers keys that keeps the layer order. */
    public layersOrder: Array<LayerKey> = [];

    /** Array of layers keys that keeps the layer order. */
    public layersCompareOrder: Array<LayerKey> = [];

    /** Legend display mode */
    @Input() public legendMode: string;

    /** Bool to display the layers in the normal view or in the embed view */
    @Input() embed = false;

    /** Some layer is being dragged */
    public dragging = false;

    /**
     * Maximum size in pixel of the spy-mode window
     */
    public maximumSpyModeSize: number;

    private breakpoint = inject(BreakpointObserver);

    private destroyed = new Subject<void>();

    constructor(
        private productService: ProductService,
        private userService: UserService,
        private matDialog: MatDialog,
        private elementRef: ElementRef,
        public layersService: LayersService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.breakpoint
            .observe(Breakpoints.Large)
            .pipe(takeUntil(this.destroyed))
            .subscribe((state) => {
                this.maximumSpyModeSize = state.matches ? 512 : 256;
            });
    }

    ngOnInit() {
        this.layersService.layers$.subscribe((layerStatus) => {
            this.layersOrder = layerStatus.order;
            this.layersCompareOrder = layerStatus.compareOrder;
        });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    /** Compute the add layer dialog Y position. */
    private getAddLayerYPos() {
        const top = this.elementRef.nativeElement.getBoundingClientRect().top;
        const leftLayers = this.layersService.getLayersOrder();
        const nLayers = leftLayers.length;
        const dialogHeight = 280;
        const smallLegendHeight = 80;
        const pinnedLegendHeight = 160;
        let nLayersSmall = 0;

        for (const layerKey of leftLayers) {
            const layer = this.layersService.get(layerKey);

            if (!layer.pinned && layer.key !== this.layersService.getSelectedLayerKey()) {
                nLayersSmall += 1;
            }
        }

        const nLayersPinned = nLayers - nLayersSmall;

        return (
            top -
            nLayersSmall * smallLegendHeight -
            nLayersPinned * pinnedLegendHeight -
            dialogHeight
        );
    }

    /**
     * Toggle the add product form fields.
     */
    toggleAddProduct() {
        const dialogRef = this.matDialog.open(LayersAddComponent, {
            position: <DialogPosition>{
                top: `${this.getAddLayerYPos()}px`,
                left: '25px',
            },
            width: '310px',
        });
        this.googleAnalyticsService.eventEmitter(
            'add_product_layer_button_clicked',
            'layer_control',
            'layer_control_add',
            'layer_control_add_product_clicked'
        );

        dialogRef.afterClosed().subscribe((data) => {
            if (data !== undefined) {
                if (data.isLayer) {
                    let layer = data.layer;
                    if (layer.groupType) {
                        layer = new Group(
                            layer.groupType,
                            layer.name,
                            layer.products,
                            layer.mainProduct,
                            layer.mainProduct
                        );
                    } else {
                        layer = new Product(
                            layer.name,
                            layer.apiName,
                            layer.maxZoom,
                            layer.fieldBased,
                            layer.legend,
                            layer.unit,
                            layer.minVal,
                            layer.maxVal,
                            layer.timeSeriesType,
                            layer.areaAllowed,
                            layer.groups,
                            layer.productGroupName,
                            layer.productGroupValue
                        );
                    }
                    const date = data.date;
                    const availability = data.availability;
                    this.layersService.addProductLayer(layer, availability, date, { select: true });
                } else {
                    const layer = data.layer;
                    this.layersService.getSpecialLayerData(layer).subscribe((layerData: any) => {
                        this.layersService.addSpecialLayer(layer, layerData, undefined, {
                            select: true,
                        });
                    });
                }
            }
        });
    }

    /**
     * Get the default user product
     */
    public loadUserDefaultProduct(): void {
        this.userService.getDefaultProduct().subscribe((res: DefaultProduct) => {
            let layerItem;
            if (res.groupProduct) {
                this.productService.list().subscribe((results: Product[]) => {
                    res.product['productGroupName'] = res.groupProduct.memberName;
                    res.product['productGroupValue'] = res.groupProduct.memberValue;
                    const layerList = this.layersService.getProductGroupsList(results);
                    const defaultGroup = layerList.find(
                        (t) => t.name === res.groupProduct.groupName
                    );
                    if (defaultGroup) {
                        defaultGroup['product'] = res.product;
                        this.addDefaultProduct(defaultGroup);
                    } else {
                        this.addDefaultProduct(res.product);
                    }
                });
            } else {
                layerItem = res.product;
                this.addDefaultProduct(layerItem);
            }
        });
    }

    /**
     * Add default product to the map
     * @param layerItem
     */
    public addDefaultProduct(layerItem) {
        const apiName = layerItem.product ? layerItem.product.apiName : layerItem.apiName;
        // No product selected
        if (apiName === null) {
            return;
        }
        this.productService
            .getAvailability(apiName)
            .subscribe((productAvailability: ProductAvailability) => {
                if (
                    layerItem &&
                    productAvailability.availability &&
                    productAvailability.availability.length >= 1
                ) {
                    // We are supposing that intervals are sorted by date,
                    // otherwise we would have to sort them to pick the most recent one
                    const date =
                        productAvailability.availability[
                            productAvailability.availability.length - 1
                        ];
                    this.layersService.addProductLayer(layerItem, productAvailability, date, {
                        select: true,
                    });
                }
            });
    }

    /** Drag event start. */
    dragStart() {
        this.dragging = true;
        this.googleAnalyticsService.eventEmitter(
            'layer_dragged',
            'layer_control',
            'layer_control_drag',
            'layer_dragged'
        );
    }

    /**
     * Update the layers order using the layer service.
     */
    private updateLayersOrder() {
        // --- Layers Compare Order must be set before  layers order change event is fired
        this.layersService.setLayersCompareOrder(this.layersCompareOrder);
        this.layersService.setLayersOrder(this.layersOrder);
        // -----
    }

    /**
     * Set layer compare mode
     */
    toggleCompareLayersMode() {
        const compareMode =
            this.layersService.getLayersCompareMode() === 'sideBySide' ? 'spy' : 'sideBySide';
        this.layersService.setLayersCompareMode(compareMode);
        this.googleAnalyticsService.eventEmitter(
            'layer_compare_spy_mode_button_clicked',
            'layer_control',
            'layer_control_compare_mode',
            compareMode
        );
    }

    /**
     * Set spy mode size
     * @param value, size of the spy mode window
     */
    setSpyModeSize(value: 'increase' | 'decrease') {
        const spyModeSize = this.layersService.getSpyModeSize();
        if (value === 'increase') {
            this.layersService.setSpyModeSize(spyModeSize * 2);
        } else {
            this.layersService.setSpyModeSize(spyModeSize / 2);
        }
    }

    drop(event: CdkDragDrop<LayerKey[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        this.updateLayersOrder();
    }
}
