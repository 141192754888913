import { Environment } from './ienvironment';

export function createConfig(
    partial: Partial<Environment> & {
        name: string;
        planetAuthServer: string;
        planetAuthClientId: string;
    }
): Environment {
    const environment = {
        ...defaults,
        ...partial,
    };

    if (environment.tileServers !== undefined && environment.tileURL === undefined) {
        // Tile servers URL template: Leaflet replaces {s} with environment.tileServers (subdomains).
        environment.tileURL = environment.domain
            ? `${environment.protocol}://{s}.${environment.domain}`
            : `${environment.protocol}://{s}.`;
    }

    return environment;
}

const defaults = {
    production: true,
    apiV1: '/api/v1',
    apiV2: '/api/v2',
    apiLoginUrl: '/api/v2/login',
    apiReports: '/api/reporting/v1/',
    userForApi: 'X-VDS-UserId',
    fronsatOriginHeader: 'X-FronSat',
    protocol: 'https',
    copyrightMap: 'oneSat',
    copyrightText: ['All rights reserved - Planet Labs PBC 2022'],
    colorRoi: '#ff00ff',
    logoText: 'Planetary Variables',
    roiInformationDashboardButtonText: 'View ROI information Dashboard',
    helpPage: 'http://docs.vandersat.com',
    timeSeriesLeftYColor: '#4AA4CA',
    timeSeriesRightYColor: '#FF00FF',
    productAreaAllowedColor: '#4AA4CA',
    specialLayersColor: '#4AA4CA',
    simpleInfoHeader: 'Planet Labs PBC legal information',
    simpleInfoText: [
        'Legal:',
        'Contains modified ESA Copernicus Sentinel data 2015-2018',
        'Contains modified NASA AQUA/AMSRE data 2002-2011',
        'Contains modified JAXA GCOM-W1/AMSR2 data 2012-2018',
        'Contains modified NASA SMAP data 2015-2018',
    ],
    simpleInfoAttribution: 'Copyright: All rights reserved - Planet Labs PBC 2018',
} satisfies Partial<Environment>;
