import { Component } from '@angular/core';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-landing-logo',
    templateUrl: './landing-logo.component.html',
    styleUrls: ['./landing-logo.component.scss'],
})
export class LandingLogoComponent {
    /**
     * Logo text
     */
    public logoText = environment.logoText;

    /**
     * Logo text subtitle
     */
    public logoTextSubtitle = environment.logoTextSubtitle;
}
