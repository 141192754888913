import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicDialog, DynamicLoaderComponent } from '../dynamic-loader/dynamic-loader.component';

export interface DismissData {
    title: string;
    message: string;
    icon: string;
    closeMessage?: string | undefined;
}

export type DismissResult = boolean;

@Component({
    selector: 'app-dismiss-dialog',
    templateUrl: './dismiss.component.html',
    styleUrls: ['./dismiss.component.scss'],
})
export class DismissComponent
    implements DynamicDialog<DismissComponent, DismissData, DismissResult>
{
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<DismissComponent, DismissData, DismissResult>,
        DismissResult
    >;

    public dialogData: DismissData;

    constructor() {}

    /**
     * Confirm action.
     */
    accept() {
        this.dialogRef.close(true);
    }

    /**
     * Cancel action.
     */
    cancel() {
        this.dialogRef.close(false);
    }
}
