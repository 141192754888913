import { Pipe, PipeTransform } from '@angular/core';
import { getDegreeMinuteSecond } from '../utils/utils';
import { transformCoordinates } from '../utils/coordinates-utils';

@Pipe({ name: 'coordinate' })
export class CoordinatePipe implements PipeTransform {
    transform(value: number, lat?, lng?, coordinateSystem?, degreeMinuteSecond?) {
        switch (coordinateSystem) {
            case 'EPSG:28992':
                const transformedCoordinates = transformCoordinates(
                    parseFloat(lng),
                    parseFloat(lat),
                    'EPSG:4326',
                    'EPSG:28992'
                );
                if (value === lat) {
                    return transformedCoordinates[1].toFixed(4);
                } else {
                    return transformedCoordinates[0].toFixed(4);
                }
                break;
            default:
                if (degreeMinuteSecond) {
                    return getDegreeMinuteSecond(value);
                } else {
                    return value.toFixed(4);
                }
        }
    }
}
