<div class="flex flex-col w-full h-full p-6">
    <mat-form-field>
        <mat-select [(value)]="selectedLayerType">
            <mat-option *ngFor="let type of layerTypes" [value]="type.value">
                {{ type.viewValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="flex flex-col" *ngIf="selectedLayerType === 'productLayer'">
        <mat-form-field *ngIf="layerList.length > 1" class="layers-selector flex flex-col">
            <input
                type="text"
                placeholder="Type product or group name"
                aria-label="Layer name"
                matInput
                [formControl]="searchControl"
                [matAutocomplete]="auto"
                cdkFocusInitial
                (click)="checkInputValue()"
                class="map-product-selector"
            />
            <button
                *ngIf="searchControl.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSelection()"
            >
                <mat-icon><i class="fa fa-times"></i></mat-icon>
            </button>
            <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onProductChange($event.option.value)"
                [displayWith]="displayProduct"
            >
                <mat-option *ngFor="let prod of filteredProducts$ | async" [value]="prod">
                    {{ prod.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="product-box" *ngIf="layerSelected && layerList.length === 1">
            <span>{{ layerSelected.name }}</span>
        </div>

        <div class="flex">
            <mat-form-field class="map-date-selector w-full">
                <input
                    matInput
                    [(ngModel)]="dateSelected"
                    [matDatepicker]="rangeDatepicker"
                    [disabled]="productAvailability.isEmpty()"
                    [matDatepickerFilter]="productAvailability.isIncluded"
                />
                <mat-datepicker-toggle matSuffix [for]="rangeDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #rangeDatepicker></mat-datepicker>
            </mat-form-field>

            <button
                mat-button
                mat-raised-button
                class="same-date-btn"
                [disabled]="productAvailability.isEmpty() || !sameDateAvailable"
                [matTooltip]="'Select the same date as the current map layer'"
                (click)="setSameDateAvailability($event)"
            >
                <span class="same-date-icon">
                    <mat-icon><i class="fa fa-history"></i></mat-icon>
                </span>
            </button>
        </div>

        <div class="flex justify-end items-center gap-2">
            <button
                class="grow-0"
                mat-raised-button
                color="accent"
                (click)="onClickAddLayer()"
                [disabled]="productAvailability.isEmpty()"
            >
                <div class="flex justify-center items-center gap-2">
                    <i class="fa fa-plus"></i>
                    <span>Create layer</span>
                </div>
            </button>
        </div>
    </div>

    <div *ngIf="selectedLayerType === 'specialLayer'">
        <mat-form-field class="layers-selector">
            <mat-select [(ngModel)]="selectedSpecialLayer" name="key">
                <mat-option *ngFor="let layer of specialLayers | keys" [value]="layer">
                    {{ specialLayers[layer].label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="flex justify-end items-center gap-2">
            <button
                class="grow-0"
                mat-raised-button
                color="accent"
                (click)="onClickAddSpecialLayer()"
                [disabled]="selectedSpecialLayer === undefined"
            >
                <div class="flex justify-center items-center gap-2">
                    <i class="fa fa-plus"></i>
                    <span>Create special layer</span>
                </div>
            </button>
        </div>
    </div>
</div>
