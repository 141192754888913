<div class="regions-dialog-wrapper flex flex-col gap-4">
    <div class="flex justify-between items-center">
        <h2 class="roi-title primary-color flex-grow lg:flex-initial font-medium text-lg">
            Regions of interest (ROI)
        </h2>
        <button
            mat-raised-button
            (click)="closeMenu()"
            matTooltip="Close dashboard"
            matTooltipPosition="left"
            class="close-sidenav-button"
            color="warn"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>
    <div class="flex justify-between items-center">
        <div class="search-box flex justify-start items-center">
            <mat-form-field>
                <mat-icon matPrefix class="!flex items-center justify-center"
                    ><i class="fa fa-search"></i
                ></mat-icon>
                <input matInput [formControl]="searchRois" />

                <mat-chip-listbox class="chip-list" *ngIf="showByLabels && showByLabels.length > 0">
                    <mat-chip-option
                        class="roi-label"
                        *ngFor="let label of showByLabels"
                        [ngStyle]="{
                            color: label.color,
                            backgroundColor: label.color,
                            height: 'fit-content'
                        }"
                        [removable]="true"
                        [selectable]="false"
                        [disableRipple]="true"
                        (removed)="deleteLabelToShow(label)"
                        (click)="deleteLabelToShow(label)"
                    >
                        <span>{{ label.title }}</span>
                        <mat-icon matChipRemove class="fa fa-times"></mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
                <mat-label>Search ROI</mat-label>
            </mat-form-field>
        </div>

        <mat-slide-toggle [ngClass]="{ '!hidden': isWeb }" [(ngModel)]="orderRoisChecked">
            Order ROIs
        </mat-slide-toggle>
    </div>

    <div class="flex overflow-hidden h-2/5 lg:h-4/6">
        <div class="flex flex-row flex-grow">
            <div class="w-full">
                <mat-divider></mat-divider>
                <div
                    *ngIf="showRoiSidenavList"
                    class="flex flex-col h-full max-h-full overflow-y-auto"
                >
                    <cdk-virtual-scroll-viewport
                        itemSize="{{ roiItemSize }}"
                        class="roi-list-viewport"
                        minBufferPx="{{ minScrollBuffer }}"
                        maxBufferPx="{{ maxScrollBuffer }}"
                        cdkDropList
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListDisabled]="!isWeb && !orderRoisChecked"
                        [cdkDropListData]="filteredRegionList"
                    >
                        <div class="flex flex-col" role="list" aria-label="ROIs">
                            <div
                                *cdkVirtualFor="
                                    let region of filteredRegionList;
                                    let index = index;
                                    trackBy: regionTrack
                                "
                                class="region-list-item flex flex-grow gap-1 bg-white"
                                (mouseenter)="roiHovered(region)"
                                (mouseleave)="roiHoveredReset()"
                                [class]="
                                    selectedROI &&
                                    selectedROI.options &&
                                    selectedROI.options['region_id'] == region.id
                                        ? 'selected region-list-item'
                                        : 'region-list-item'
                                "
                                cdkDrag
                                [cdkDragData]="index"
                                role="listitem"
                            >
                                <div
                                    class="flex flex-none justify-center items-center cursor-move"
                                    *ngIf="isWeb || orderRoisChecked"
                                >
                                    <i class="fa fa-bars" aria-hidden="true" cdkDragHandle></i>
                                </div>
                                <div
                                    (click)="roiSelected(region)"
                                    id="{{ region.id }}"
                                    class="flex flex-row flex-grow justify-between items-center gap-4"
                                >
                                    <div>{{ region.id }}</div>
                                    <div class="w-full">
                                        <div
                                            matLine
                                            [class]="
                                                'table-field roi-sidenav-container roi-name-short'
                                            "
                                        >
                                            <div class="flex flex-col w-[95%]">
                                                <b class="font-medium">{{ region.name }}</b
                                                >{{ region.description }}
                                            </div>
                                            <mat-icon
                                                class="expand-roi-info-icon"
                                                (click)="updateRoiNameDisplay(region)"
                                                [hidden]="
                                                    (region.name ? region.name.length : 0) +
                                                        (region.description
                                                            ? region.description.length
                                                            : 0) <
                                                    50
                                                "
                                                [matTooltip]="'Expand/Collapse ROI information'"
                                            >
                                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                                            </mat-icon>
                                        </div>
                                        <div
                                            class="table-field roi-sidenav-container roi-labels-container"
                                        >
                                            <mat-chip-listbox
                                                *ngIf="region.labels && region.labels.length > 0"
                                                class="chip-list"
                                            >
                                                <mat-chip-option
                                                    class="roi-label"
                                                    *ngFor="let label of region.labels"
                                                    [ngStyle]="{
                                                        color: label.color,
                                                        backgroundColor: label.color,
                                                        height: 'fit-content'
                                                    }"
                                                    (click)="addLabelToShow(label)"
                                                >
                                                    <span>{{ label.title }}</span>
                                                </mat-chip-option>
                                            </mat-chip-listbox>
                                        </div>
                                    </div>
                                    <div
                                        appAccess="manage-rois"
                                        class="roi-actions flex flex-col flex-none"
                                    >
                                        <button
                                            mat-icon-button
                                            (click)="editRegion(region)"
                                            matTooltip="Edit ROI"
                                            [matTooltipPosition]="'above'"
                                        >
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </button>
                                        <mat-slide-toggle
                                            (change)="updateRegion(region)"
                                            [(ngModel)]="region.display"
                                            [matTooltip]="region.display ? 'Hide ROI' : 'Show ROI'"
                                            [matTooltipPosition]="'above'"
                                            aria-label="show ROI"
                                        >
                                        </mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>
    </div>

    <div class="actions flex flex-col justify-center items-center gap-4">
        <button
            class="margin-bottom !flex !justify-start items-center"
            mat-raised-button
            (click)="showHideRegions()"
        >
            <i class="fa fa-eye" aria-hidden="true"></i>
            <span>{{ showHideAllMessage }}</span>
        </button>

        <button
            mat-raised-button
            (click)="showRoiManagement()"
            appAccess="manage-rois"
            class="!flex !justify-start items-center"
        >
            <i class="fa fa-cogs" aria-hidden="true"></i>
            <span>ROI management</span>
        </button>

        <button mat-raised-button (click)="showRoiHelp()" class="!flex !justify-start items-center">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <span>How to draw a ROI</span>
        </button>

        <button
            mat-raised-button
            (click)="uploadFileDialog()"
            class="label-button !justify-start"
            appAccess="draw-roi"
        >
            <label class="w-full !flex !justify-start items-center">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <span>Upload file </span>
            </label>
        </button>
        <button
            *ngIf="showMaxROIsMessage"
            class="roi-help"
            mat-button
            (click)="showMaxRoi()"
            class="max-roi-button"
        >
            <i class="fa fa-info-circle"></i>
            <span>You cannot create more ROIs</span>
        </button>

        <button
            mat-raised-button
            (click)="showLabelManagement()"
            appAccess="manage-rois"
            class="!flex !justify-start items-center"
        >
            <i class="fa fa-cogs" aria-hidden="true"></i>
            <span>Label management</span>
        </button>
    </div>
</div>
