<div
    class="login-background flex flex-col justify-center items-center w-full h-full"
    [style.background-image]="backgroundImage"
>
    <div class="card" [ngClass]="{ flipped: forgotPassword }">
        <div
            class="card-face card-back flex flex-col justify-center items-center"
            [ngClass]="{ 'no-backface': !forgotPassword }"
        >
            <app-landing-logo></app-landing-logo>

            <span class="forgot-text">Forgot your password?</span>

            <form
                novalidate
                [formGroup]="forgotForm"
                (ngSubmit)="restorePassword()"
                class="flex flex-col justify-center items-center gap-4"
            >
                <mat-form-field>
                    <input
                        matInput
                        class="form-control"
                        placeholder="Username"
                        type="email"
                        formControlName="username"
                        name="username"
                        required
                        autocomplete="username"
                    />
                </mat-form-field>

                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="forgotForm.invalid"
                    color="accent"
                >
                    Restore password
                </button>

                <a class="vds-link" (click)="toggleForgotPassword()">Go back to login</a>
            </form>
        </div>

        <div
            class="card-face card-front flex flex-col justify-center items-center"
            [ngClass]="{ 'no-backface': forgotPassword }"
        >
            <app-landing-logo></app-landing-logo>

            <form
                novalidate
                [formGroup]="loginForm"
                (ngSubmit)="login()"
                class="login-form flex flex-col justify-center items-center"
            >
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input
                        matInput
                        type="email"
                        formControlName="username"
                        required
                        autocomplete="username"
                        data-testid="login-username"
                    />
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="password"
                        required
                        autocomplete="current-password"
                        data-testid="login-password"
                    />
                </mat-form-field>

                <ng-container *ngIf="timeUntilNextTry$ | async; let timeUntilNextTry">
                    <mat-progress-bar
                        [mode]="'determinate'"
                        [value]="
                            100 - (timeUntilNextTry.timeToWait / timeUntilNextTry.totalTime) * 100
                        "
                    >
                    </mat-progress-bar>
                    <div>
                        <p>Account blocked.</p>
                        <p>
                            Try again after {{ convertSecondsToTime(timeUntilNextTry.timeToWait) }}
                        </p>
                    </div>
                </ng-container>
                <mat-list>
                    <mat-list-item>
                        <button
                            mat-raised-button
                            type="submit"
                            color="accent"
                            [disabled]="loginForm.invalid"
                            [hidden]="(timeUntilNextTry$ | async)?.timeToWait > 0"
                        >
                            Sign in
                        </button>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <button mat-raised-button type="button" (click)="loginWithPlanet()">
                            Sign in with Planet
                        </button>
                    </mat-list-item>
                </mat-list>

                <div class="flex flex-col justify-center items-center">
                    <a class="vds-link" (click)="toggleForgotPassword()">Forgot your password?</a>
                    <a class="vds-link" *ngIf="register" (click)="createAccount()">
                        Create an account
                    </a>
                    <a class="vds-link" *ngIf="register" (click)="reactivateAccount()">
                        Re-send activation email
                    </a>
                </div>
            </form>
        </div>
    </div>

    <mat-card appearance="outlined" *ngIf="!browserData.supported" class="browser-message">
        <i class="fa fa-exclamation-triangle"></i> {{ browserData.name }} browser is not fully
        supported. Please use Chrome or Firefox for an optimal experience.
    </mat-card>
</div>
