import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ReplaySubject, Subject } from 'rxjs';
import { concatMap, filter, takeUntil } from 'rxjs';
import moment from 'moment';
import { Moment } from 'moment';
import * as L from 'leaflet';
import { LatLng } from 'leaflet';

import { GeoJsonObject } from 'geojson';
import { environment } from '../../environments/environment';
import { VERSION } from '../../environments/version';
import { getDomain, getUserForApi, linkSimpleComponentParamsAreValid } from '../../utils/utils';
import { roiDefaultStyle, roiOverStyle } from '../../utils/variables';
import { LayersControlComponent } from '../layers-control/layers-control.component';
import { TimeSeries } from '../time-series/time-series.component';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { RoiSidenavComponent } from '../roi-sidenav/roi-sidenav.component';
import {
    ShareSimpleDialogComponent,
    ShareSimpleDialogData,
} from '../share-simple-dialog/share-simple-dialog.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { ProductService } from '../../services/product.service';
import { UserService, UserSettingsInfo } from '../../services/user.service';
import { DialogService } from '../dialog/dialog.service';
import { AuthService } from '../auth/auth.service';
import { LayersService } from '../../services/layers.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { Region } from '../../models/region';
import { Product } from '../../models/product';
import { Kpi } from '../../models/kpi';
import { KpiService } from '../../services/kpi.service';
import { SimpleService } from '../../services/simple.service';
import { SimpleInfoDialogComponent } from '../simple-info-dialog/simple-info-dialog.component';
import {
    UserSelectDialogComponent,
    UserSelectDialogData,
    UserSelectDialogResult,
} from '../user-select-dialog/user-select-dialog.component';

/**
 * Default map base layer key.
 */
const DEFAULT_BASE_LAYER = 'hybrid';

@Component({
    selector: 'app-simple',
    templateUrl: './simple.component.html',
    styleUrls: ['./simple.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-50px)', opacity: 0 }),
                animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('200ms', style({ transform: 'translateX(-50px)', opacity: 0 })),
            ]),
        ]),
        trigger('lateralMenuAnimation', [
            state(
                'open',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(-400px)',
                })
            ),
            state(
                'closed',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(0)',
                })
            ),
            state(
                'openDashboard',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(-50vw)',
                })
            ),
            transition('* => closed', animate('250ms ease-in-out')),
            transition('* => open', animate('80ms')),
            transition('* => openDashboard', animate('80ms')),
        ]),
    ],
})
export class SimpleComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * App copyright.
     */
    public readonly copyright = environment.copyrightText;

    /**
     * App version string.
     */
    public readonly version = VERSION.version;

    /**
     * Layer group containing all the other layer groups (product, region and region edit).
     * @type {[LayerGroup , LayerGroup , LayerGroup]}
     */
    public layers: L.LayerGroup[] = [];

    /**
     * Dom reference to the Leaflet map.
     */
    public map: L.Map;

    /**
     * Default region style.
     */
    protected roiDefaultStyle = <L.PathOptions>roiDefaultStyle;

    /**
     * Mouseover region style.
     */
    protected roiOverStyle = <L.PathOptions>roiOverStyle;

    /**
     * Event emitter that will trigger an event when the map is loaded.
     * @type {EventEmitter<Map>}
     */
    @Output() mapReady: EventEmitter<L.Map> = new EventEmitter<L.Map>();

    /**
     * Reference to the Layers Control Component.
     */
    @ViewChild(LayersControlComponent, { static: true })
    layersControlComponent: LayersControlComponent;

    /**
     * Reference to time series component
     */
    @ViewChild(TimeSeries, { static: true }) timeSeries: TimeSeries;

    /**
     * Reference to roi sidenav component
     */
    @ViewChild(RoiSidenavComponent) roiSidenavComponent: RoiSidenavComponent;

    /**
     * String containing the attribution when using Open Street Maps base layers
     */
    private osmAttribution =
        '<span>© <a href="https://www.openstreetmap.org/copyright"  target="_blank">OpenStreetMap</a> contributors</span>';

    /**
     * Base layers included in the map.
     */
    public baseLayers: any = {
        'open-street-map': {
            label: 'OpenStreetMap',
            layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: this.osmAttribution,
            }),
        },
        'black-and-white': {
            label: 'Black and White',
            layer: L.tileLayer('http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: this.osmAttribution,
            }),
        },
        satellite: {
            label: 'Satellite',
            layer: (L as any).gridLayer.googleMutant({
                type: 'satellite',
            }),
        },
        terrain: {
            label: 'Terrain',
            layer: (L as any).gridLayer.googleMutant({
                type: 'terrain',
            }),
        },
        hybrid: {
            label: 'Hybrid',
            layer: (L as any).gridLayer.googleMutant({
                type: 'hybrid',
            }),
        },
    };

    // TODO: Read default layer and grid from user settings
    /**
     * Map baseLayer that will be shown
     */
    private currentBaseLayer = DEFAULT_BASE_LAYER;

    /**
     * Options for creating the Leaflet Map
     */
    public options = {
        zoom: 4,
        center: L.latLng([39.0, -1.85]),
        worldCopyJump: true,
        layers: [],
        scrollWheelZoom: 'center',
        touchZoom: 'center',
    } as L.MapOptions;

    /**
     * zIndex value to order panes
     */
    private zIndexPanes = {
        spyModePane: '400',
        areaAllowedPane: '450',
        embedAreaAllowedPane: '460',
        roiPane: '500',
        spyModeBorderPane: '510',
        measurePane: '600',
    };

    /**
     * Currently selected date.
     */
    public dateSelected: Moment = moment.utc();

    /**
     * Array of ROI for the user
     */
    public userRegions: Array<Region> = [];

    /**
     * Current ROI selected, whose info is shown in the dashboard
     */
    public selectedRegion: Region;

    /**
     * Array of the products the user can consult
     */
    public userProducts: Array<Product> = [];

    /**
     * Current product selected by the user
     */
    public selectedProduct: Product;

    /**
     * User settings
     */
    public userSettings = {} as UserSettingsInfo;

    /**
     * Name of the cookie of the previous ROI
     */
    public regionCookieName = 'selectedRegion';

    /**
     * Will contain all the shapes added to the map.
     * @type {LayerGroup}
     */
    public roiLayerGroup = L.featureGroup([]);

    /**
     * Indicates if the roi list have been loaded
     */
    public loadingRois = true;

    /**
     * Page Initial loading dialog reference
     */
    public loadingDialogRef: any;

    /**
     * Boolean to indicate if the necessary env vars for the info dialog are present
     */
    public infoDialogAvailable: boolean;

    /**
     * Info dialog reference
     */
    public infoDialogRef: any;

    /**
     * Zoom level of the map. Used to set the view to the selected region
     */
    mapZoom: number;

    /**
     * Latitude and longitude of the center of the map. Used to set the view to the selected region
     */
    mapCenter: LatLng;

    /**
     * Control for the selected roi
     */
    public roiCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * Control for the MatSelect filter keyword
     */
    public roiFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * List of rois filtered by search keyword
     */
    public filteredUserRois: ReplaySubject<Region[]> = new ReplaySubject<Region[]>(1);

    /**
     * Control for the selected user product
     */
    public userProductsCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * Control for the MatSelect filter keyword
     */
    public userProductsFilterCtrl: UntypedFormControl = new UntypedFormControl();

    /**
     * List of user products filtered by search keyword
     */
    public filteredUserProducts: ReplaySubject<Product[]> = new ReplaySubject<Product[]>(1);

    /**
     * Subject that emits when the component has been destroyed.
     */
    protected _onDestroy = new Subject<void>();

    /**
     * User current KPIs for selectedRegion
     */
    public currentRegionKpis: Array<Kpi>;

    /**
     * Flag to indicate whether or not the header is displayed
     */
    public showHeader: boolean;

    /**
     * Time series year mode.
     */
    public yearMode = false;

    /**
     * if year mode is always enabled
     * @type {boolean}
     */
    public yearModeOnly = false;

    /**
     * year mode start day of year
     */
    public yearModeStart = 1;

    /**
     * Start date of the roi time series
     */
    dateStart;

    /**
     * End date of the roi time series
     */
    dateEnd;

    /**
     * Number of values expected in the time series (used in the loading bar)
     */
    timeSeriesExpectedValues = 0;

    /**
     * User for impersonated API calls
     */
    public userForApi: string;

    /**
     * Domain in which to set cookies
     */
    private domain = getDomain();

    /**
     * Perform the component initial configuration and load the products.
     */
    constructor(
        private productService: ProductService,
        public userService: UserService,
        private dialogService: DialogService,
        private authService: AuthService,
        private router: Router,
        private matDialogService: MatDialog,
        private snackBar: CustomSnackbarService,
        private cookieService: CookieService,
        private layersService: LayersService,
        private kpiService: KpiService,
        private simpleService: SimpleService
    ) {
        this.showHeader = !!environment.headerConfig?.headerText;
        const paramsIndex = this.router.url.indexOf('?');
        const shareLink = paramsIndex > -1;
        this.loadingDialogRef = this.matDialogService.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            disableClose: true,
            data: { shareLink: shareLink },
        });
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.userForApi = getUserForApi();

        this.layersService.layers$.subscribe(() => {
            const layer = this.layersService.getSelectedLayer();
            this.dateSelected = layer.getDate();

            if (layer.availability) {
                this.dateSelected = layer.availability.findNearestDate(this.dateSelected);
            }
            this.requestRegionKPIs();

            // at the moment, we do not add the product layer to the map.
            // this.setProductLayer(layer);
        });

        this.productService.list(true).subscribe((results: Product[]) => {
            if (results) {
                this.userProducts = results;
                this.filteredUserProducts.next(this.userProducts.slice());

                if (this.userProducts && this.userProducts.length === 1) {
                    this.productSelected(this.userProducts[0]);
                }
            }
        });

        this.userService.getDefaultProduct().subscribe((product: any) => {
            if (product.apiName) {
                if (!this.selectedProduct) {
                    this.selectedProduct = product;
                }
                this.timeSeries.setProducts([this.selectedProduct]);
                this.productService
                    .getAvailability(this.selectedProduct.apiName)
                    .subscribe((results: any) => {
                        if (results.availability) {
                            if (results.availability && results.availability.length >= 1) {
                                // We are supposing that intervals are sorted by date,
                                // otherwise we would have to sort them to pick the most recent one
                                this.dateSelected =
                                    results.availability[results.availability.length - 1];
                                this.layersService.addProductLayer(
                                    product,
                                    results,
                                    this.dateSelected,
                                    { select: true }
                                );
                            }
                        }
                        this.simpleService.updateLoadingValue();
                    });
            } else {
                this.currentRegionKpis = [];
            }
        });

        this.infoDialogAvailable = !!(
            environment.simpleInfoHeader &&
            environment.simpleInfoText &&
            environment.simpleInfoAttribution
        );
    }

    /**
     * Angular method called after the children are initialized.
     */
    ngAfterViewInit() {
        this.simpleService.watchLoadingOptionsUpdated().subscribe((result: any) => {
            this.productSelected(this.selectedProduct);
        });
        // listen for search field value changes
        this.roiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterRois();
        });

        this.userProductsFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterUserProducts();
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        this.simpleService.restartLoadingValues();
    }

    public showRegions(regions: Region[]): void {
        this.userRegions = regions;
        this.filteredUserRois.next(this.userRegions.slice());
        this.roiLayerGroup.clearLayers();

        this.setInitialData();

        if (this.userRegions.length > 0) {
            regions.reverse();
            regions.forEach((region) => {
                this.addRegion(region);
            });

            this.cookieService.set(
                this.regionCookieName,
                this.selectedRegion.id.toString(),
                undefined,
                undefined,
                this.domain,
                true
            );
            this.setViewInSelectedRegion();
        }
        this.loadingRois = false;

        this.checkInitialLoading('rois');
        this.setMapEvents();
        this.simpleService.updateLoadingValue();
    }

    /**
     * Checks for parameters in the url and then set the initial values for the component
     */
    setInitialData() {
        const selectedRegionCookie = this.cookieService.get(this.regionCookieName);

        if (this.router.url.indexOf('?') > -1) {
            if (this.userForApi) {
                const searchParams = new URLSearchParams(this.router.url);
                const username = searchParams.get('username');
                if (this.userForApi === username) {
                    this.initComponentWithUrlParams();
                } else {
                    const deleteDialogRef = this.dialogService.openConfirm(
                        'End impersonation',
                        'You are impersonating ' +
                            this.userForApi +
                            '.' +
                            ' Would you like to end impersonation before loading shared link?',
                        'fa-users',
                        'End impersonation',
                        'Keep impersonation'
                    );
                    deleteDialogRef.afterClosed().subscribe((confirm) => {
                        if (confirm) {
                            this.forgetUser();
                        } else {
                            this.initComponentWithUrlParams();
                        }
                    });
                }
            } else {
                this.initComponentWithUrlParams();
            }
        }
        if (!this.selectedRegion) {
            if (selectedRegionCookie === '') {
                this.selectedRegion = this.userRegions[0];
            } else {
                const regionObj = this.userRegions.find(
                    (r) => r.id.toString() === selectedRegionCookie
                );
                this.selectedRegion = regionObj !== undefined ? regionObj : this.userRegions[0];
            }
        }

        this.roiCtrl.setValue(this.selectedRegion);
        if (this.selectedProduct) {
            const selectedProduct = this.userProducts.find(
                (r) => r.apiName === this.selectedProduct.apiName
            );
            this.userProductsCtrl.setValue(selectedProduct);
        }

        this.checkInitialLoading('link');
    }

    initComponentWithUrlParams() {
        const params = new URLSearchParams(decodeURI(this.router.url.split('?')[1]));
        const paramValues = this.getParamValues(params);
        let isImpersonationNeeded = false;

        if (paramValues) {
            if (
                this.userProducts &&
                this.userProducts.find((p) => p.name === paramValues.product)
            ) {
                this.selectedProduct = this.userProducts.find(
                    (p) => p.name === paramValues.product
                );
                this.productService
                    .getAvailability(this.selectedProduct.apiName)
                    .subscribe((results: any) => {
                        if (results.availability) {
                            if (results.availability && results.availability.length >= 1) {
                                this.dateStart = results.availability[0];
                                this.dateEnd =
                                    results.availability[results.availability.length - 1];
                                this.timeSeriesExpectedValues = results.availability.length;
                                this.dateSelected =
                                    results.availability[results.availability.length - 1];
                                this.layersService.addProductLayer(
                                    this.selectedProduct,
                                    results,
                                    this.dateSelected,
                                    { select: true }
                                );
                                this.setDashboardChart();
                            }
                        }
                    });
            } else {
                isImpersonationNeeded = true;
            }

            if (this.userRegions.find((r) => r.id.toString() === paramValues.region)) {
                this.selectedRegion = this.userRegions.find(
                    (r) => r.id.toString() === paramValues.region
                );
            } else {
                isImpersonationNeeded = true;
            }
            if (isImpersonationNeeded) {
                this.userService.getUserList(undefined).subscribe((res: any) => {
                    const userToImpersonate = res.find(
                        (user) => user.username === paramValues['username']
                    );
                    if (userToImpersonate) {
                        this.authService.setUserForApi(
                            userToImpersonate,
                            userToImpersonate.permissions_names
                        );
                        let url = window.location.href;
                        if (url.indexOf('?') > -1) {
                            url += '&loaded=true';
                        } else {
                            url += '?loaded=true';
                        }
                        window.location.href = url;
                    } else {
                        this.snackBar.present(
                            'Error loading the share link! The default view has been loaded instead.',
                            'error'
                        );
                    }
                });
            }
        }
    }

    /**
     * Checks the url params and returns only the param values used
     * @param params
     */
    getParamValues(params) {
        if (linkSimpleComponentParamsAreValid(params)) {
            return {
                region: params.get('region'),
                product: params.get('product'),
                username: params.get('username'),
            };
        } else {
            this.snackBar.present(
                'Error loading the share link! The default view has been loaded instead.',
                'error'
            );
        }
        return undefined;
    }

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;
        this.map.doubleClickZoom.disable();
        this.resize();
        this.map.addLayer(this.baseLayers[this.currentBaseLayer].layer);
        if (document.getElementsByClassName('leaflet-google-mutant')[0]) {
            (
                document.getElementsByClassName('leaflet-google-mutant')[0] as HTMLElement
            ).style.width = '100%';
        }
        this.checkInitialLoading('map');
        this.roiLayerGroup.addTo(this.map);
        const roiPane = this.map.createPane('roiPane');
        roiPane.style.zIndex = this.zIndexPanes['roiPane'];
        this.mapReady.emit(map);
    }

    /**
     * Method to show (or update) the chart with the info for the selected ROI, Product and Date.
     */
    setDashboardChart() {
        this.requestRegionKPIs();
        this.yearMode = false; // Reset year mode
        if (this.selectedRegion && this.selectedProduct) {
            this.timeSeries.setROIData(
                this.selectedRegion,
                [this.selectedProduct],
                this.dateStart,
                this.dateEnd
            );
            this.timeSeries.updateChart();
        }
    }

    /**
     * Update the value of selectedRegion to the one given
     * @param {Region} region
     */
    roiSelected(region) {
        let previousSelectedRegionId;
        if (region.id !== this.selectedRegion.id) {
            previousSelectedRegionId = this.selectedRegion.id;
            this.selectedRegion = region;
            this.cookieService.set(
                this.regionCookieName,
                this.selectedRegion.id.toString(),
                undefined,
                undefined,
                this.domain,
                true
            );
            this.setViewInSelectedRegion(previousSelectedRegionId);
            this.setDashboardChart();
        }
    }

    /**
     * Adjust the size of the map to fit with the parent controller
     */
    public resize(): void {
        // Patch to re-calculate the map size after flexLayout gets its final size
        setTimeout(() => {
            this.map.invalidateSize();
        }, 20);
    }

    /**
     * Update the value of selectedProduct to the one given
     * @param {Product} selectedProduct
     */
    productSelected(selectedProduct: Product) {
        const product = selectedProduct;
        if (product !== this.selectedProduct) {
            this.selectedProduct = product;
            if (this.selectedProduct.isYearModeOnly()) {
                this.yearModeOnly = true;
                this.yearMode = true;
            } else {
                this.yearMode = false;
            }
            this.productService
                .getAvailability(this.selectedProduct.apiName)
                .subscribe((result: any) => {
                    if (result.availability) {
                        this.dateStart = result.availability[0];
                        this.dateEnd = result.availability[result.availability.length - 1];
                        this.timeSeriesExpectedValues = result.availability.length;
                        this.dateSelected = result.availability[result.availability.length - 1];
                        this.layersService.addProductLayer(
                            this.selectedProduct,
                            result,
                            this.dateSelected,
                            { select: true }
                        );
                    }
                    this.setDashboardChart();
                });
        }
    }

    /**
     * Comparison mode to determine if two products are the same
     * @param {Product} p1
     * @param {Product} p2
     */
    compareProducts(p1: Product, p2: Product): boolean {
        return p1 && p2 && p1.name === p2.name;
    }

    /**
     * Show the dialog to update the user password.
     */
    showPasswordDialog() {
        const dialogRef = this.dialogService.openComponent(PasswordChangeComponent, {
            data: {
                forceChange: false,
            },
        });
    }

    /**
     * Call the method logout from AuthService
     */
    public logout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    /**
     * Call the method showRoiManagement from roiSidenav
     */
    showRoiManagement() {
        this.roiSidenavComponent.showRoiManagement();
    }

    /**
     * Call the method importRegion from roiSidenav
     */
    importRegion(event: Event) {
        this.roiSidenavComponent.importRegion(event);
    }

    /**
     * Add a GeoJSON to the map.
     * @param {Region} region Region object.
     */
    addRegion(region: Region) {
        // GeoJSON
        const data: GeoJsonObject = <GeoJsonObject>region.geojson;
        // Create the Leaflet Layer
        const geoJSONLayer = L.geoJSON(data, this.roiDefaultStyle);
        geoJSONLayer.options['region_id'] = region.id;
        this.roiLayerGroup.addLayer(geoJSONLayer);
    }

    /**
     * Unused at the moment. It could be used in the future
     */
    // setProductLayer(layer) {
    //     const productLayer = L.layerGroup();
    //     const zIndex = 1;
    //     const tileLayer = layer.tileLayer;
    //
    //     if (tileLayer !== undefined && layer.isVisible()) {
    //         tileLayer.setZIndex(zIndex);
    //         productLayer.addLayer(tileLayer);
    //     }
    //
    //     this.layers = [productLayer];
    // }

    /**
     * Set map view in the selected region
     * @param previousSelectedRegionId (optional, it is used to set the default style to the previous selected region)
     */
    setViewInSelectedRegion(previousSelectedRegionId?) {
        const regionLayer = this.setRoiStyle(this.selectedRegion.id, 'selected');
        const selectedRegionBounds = regionLayer._layers[regionLayer._leaflet_id - 1].getBounds();
        this.map.fitBounds(selectedRegionBounds);
        this.mapZoom = this.map.getZoom();
        this.mapCenter = this.map.getCenter();

        if (previousSelectedRegionId) {
            this.setRoiStyle(previousSelectedRegionId, 'default');
        }
    }

    /**
     * Groups the events of leaflet
     */
    setMapEvents() {
        let userDragging = false;

        this.map.on('moveend', (e) => {
            if (userDragging) {
                userDragging = false;
                this.map.setView(this.mapCenter, this.mapZoom);
            }
        });

        this.map.on('zoomend', (e: any) => {
            this.mapZoom = this.map.getZoom();
        });

        this.map.on('mousedown', (e) => {
            userDragging = true;
            this.map.scrollWheelZoom.disable();
        });

        this.map.on('mouseup', (e) => {
            this.map.scrollWheelZoom.enable();
        });
    }

    /**
     * Set style to a region.
     * @param id, region id
     * @param roiStyle, style to apply to the roi
     */
    setRoiStyle(id, roiStyle) {
        const regions: any = this.roiLayerGroup.getLayers();
        const regionStyle = roiStyle === 'default' ? this.roiDefaultStyle : this.roiOverStyle;
        const region = regions.find((r) => r.options.region_id === id);
        const regionLayer: any = this.roiLayerGroup.getLayer(region._leaflet_id);
        regionLayer.setStyle(regionStyle);
        return regionLayer;
    }

    /**
     * Updates the Loading message on the loading dialog
     *  If the loads are done the dialog is closed
     */
    public checkInitialLoading(elementLabel) {
        if (this.loadingDialogRef && this.loadingDialogRef.componentInstance) {
            this.loadingDialogRef.componentInstance.updateLoadingMessage(elementLabel);
            if (this.loadingDialogRef.componentInstance.messagesNumber < 1) {
                this.loadingDialogRef.close();
            }
        }
    }

    /**
     * Displays the dialog containing the simple component share link
     */
    shareSimpleMap() {
        const shareSimpleMetadata = {
            region: this.selectedRegion,
            product: this.selectedProduct,
        } as ShareSimpleDialogData;

        const username = this.authService.getUserData()?.username;
        if (username) {
            shareSimpleMetadata['username'] = username;
        }

        this.dialogService.openComponent(ShareSimpleDialogComponent, {
            data: shareSimpleMetadata,
        });
    }

    /**
     * Filter ROI by user input
     */
    filterRois() {
        if (!this.userRegions) {
            return;
        }
        let search = this.roiFilterCtrl.value;
        if (!search) {
            this.filteredUserRois.next(this.userRegions.slice());
            return;
        }
        search = search.toLowerCase();
        this.filteredUserRois.next(
            this.userRegions.filter(
                (region) => region.name && region.name.toLowerCase().indexOf(search) > -1
            )
        );
    }

    /**
     * Filter products by user input
     */
    filterUserProducts() {
        if (!this.userProducts) {
            return;
        }
        let search = this.userProductsFilterCtrl.value;
        if (!search) {
            this.filteredUserProducts.next(this.userProducts.slice());
            return;
        }
        search = search.toLowerCase();
        this.filteredUserProducts.next(
            this.userProducts.filter(
                (product) => product.name && product.name.toLowerCase().indexOf(search) > -1
            )
        );
    }

    /**
     * Get the available KPIs for the current region
     */
    requestRegionKPIs() {
        this.currentRegionKpis = undefined;
        if (this.selectedRegion && this.selectedProduct) {
            this.kpiService.getSelectedKpis().subscribe((selKpis) => {
                this.kpiService
                    .getRegionKpis(this.dateSelected, this.selectedRegion, selKpis)
                    .subscribe(
                        (kpis) => {
                            this.currentRegionKpis = kpis;
                        },
                        (error) => {
                            this.kpiService.emptyKpis().subscribe((kpis) => {
                                this.currentRegionKpis = kpis;
                            });
                        }
                    );
            });
        } else {
            this.currentRegionKpis = [];
        }
    }

    /**
     * Export time series nvd3 as png
     */
    exportAsPng() {
        this.timeSeries.saveChartAsPng();
    }

    /**
     * Export time series nvd3 as csv
     */
    exportAsCsv() {
        this.timeSeries.saveAsCsv();
    }

    showInfoDialog() {
        if (this.infoDialogAvailable) {
            if (this.infoDialogRef) {
                this.infoDialogRef.close();
            } else {
                this.infoDialogRef = this.matDialogService.open(SimpleInfoDialogComponent, {
                    width: '35%',
                    height: 'auto',
                });
            }
            this.infoDialogRef.afterClosed().subscribe(() => {
                this.infoDialogRef = undefined;
            });
        }
    }

    /**
     * Shows user selection dialog
     */
    showUserSelection() {
        const dialogRef = this.matDialogService.open<
            UserSelectDialogComponent,
            UserSelectDialogData,
            UserSelectDialogResult
        >(UserSelectDialogComponent, { panelClass: 'user-select-dialog' });

        dialogRef
            .afterClosed()
            .pipe(
                filter((selected) => selected !== null && selected !== undefined),
                concatMap(({ id }) =>
                    this.userService.getUserData(id, ['permission_names', 'username', 'email'])
                )
            )
            .subscribe((user) => {
                this.authService.setUserForApi(user, user.permission_names);
                this.userForApi = getUserForApi();
                window.location.reload();
            });
    }

    /**
     * Forget user impersonation
     */
    forgetUser() {
        const oldUserForApi = this.userForApi;
        this.authService.setUserForApi(undefined);
        this.userForApi = undefined;
        let url = window.location.href;
        const searchParams = new URLSearchParams(url);
        const username = searchParams.get('username');

        if (url.indexOf('?') > -1 && username === oldUserForApi) {
            url = url.split('?')[0];
            window.location.href = url;
        } else {
            window.location.reload();
        }
    }
}
