import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { matchPassword } from '../../utils/utils';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
    /**
     * DialogInterface: Reference to the dynamic dialog component.
     */
    public dialogRef: MatDialogRef<any>;

    /**
     * Dialog injected data.
     */
    public dialogData: any;

    /**
     * Password form
     */
    public passwordForm: UntypedFormGroup;

    /**
     * Force the password change.
     */
    public forceChange = false;

    /**
     * Default constructor.
     */
    constructor(
        private userService: UserService,
        private authService: AuthService,
        private snackbar: CustomSnackbarService
    ) {
        this.passwordForm = new UntypedFormGroup(
            {
                currentPassword: new UntypedFormControl('', Validators.required),
                password: new UntypedFormControl('', Validators.required),
                repeatPassword: new UntypedFormControl('', Validators.required),
            },
            matchPassword
        );
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.forceChange =
            this.dialogData.forceChange == undefined ? false : this.dialogData.forceChange;
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Update password.
     */
    save() {
        const { currentPassword, password, repeatPassword } = this.passwordForm.value;
        this.userService
            .updatePassword(currentPassword, password, repeatPassword)
            .subscribe((response: any) => {
                if (response.access_token) {
                    this.authService.setAccessToken(response.access_token);
                }

                this.snackbar.present('Password updated successfully');
                this.close();
            });
    }
}
