<dialog-title icon="fa-info-circle" title="Maximum ROIs"></dialog-title>

<div class="roi-help-wrapper gap-4 flex">
    <div class="flex flex-col gap-4">
        <span>You have reached the maximun number of ROIs that you can create.</span>
        <span>To draw a new ROI, you can delete one of the previous ROIs.</span>
        <span>You can delete a ROI in the <b>Roi management</b> tab</span>

        <div class="flex justify-end items-center">
            <button mat-raised-button color="warn" (click)="close()">
                <i class="fa fa-times"></i> Close
            </button>
        </div>
    </div>
</div>
