import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { RoiService } from '../../services/roi.service';
import { Label } from '../../models/label';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

export type LabelEditDialogData = Label;

export type LabelEditDialogResult = Label | undefined;

@Component({
    selector: 'app-label-edit-dialog',
    templateUrl: './label-edit-dialog.component.html',
    styleUrls: ['./label-edit-dialog.component.scss'],
})
export class LabelEditDialogComponent
    implements
        OnInit,
        DynamicDialog<LabelEditDialogComponent, LabelEditDialogData, LabelEditDialogResult>
{
    dialogRef: MatDialogRef<
        DynamicLoaderComponent<
            DynamicDialog<LabelEditDialogComponent, LabelEditDialogData, LabelEditDialogResult>,
            LabelEditDialogData,
            LabelEditDialogResult
        >,
        LabelEditDialogResult
    >;

    dialogData: LabelEditDialogData;

    /**
     * Object with label structure with the values before updating any of them
     */
    label: Label = new Label();

    /**
     * New label form control
     */
    editLabelForm: UntypedFormGroup;

    constructor(
        private roiService: RoiService,
        private snackBar: CustomSnackbarService
    ) {}

    ngOnInit() {
        Object.assign(this.label, this.dialogData);
        this.editLabelForm = new UntypedFormGroup({
            title: new UntypedFormControl(this.label.title, Validators.required),
            description: new UntypedFormControl(this.label.description),
            color: new UntypedFormControl(this.label.color, Validators.required),
        });
    }

    /**
     * Closes the dialog showing the component
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Detects a color choose in the color picker
     * @param event
     */
    colorChanged(event) {
        if (event.color && event.color.hex) {
            this.editLabelForm.get('color').setValue(event.color.hex);
        }
    }

    /**
     * Update the values for this.label
     */
    saveLabel() {
        if (this.editLabelForm.valid) {
            this.label.updateLabel(
                this.editLabelForm.get('title').value,
                this.editLabelForm.get('description').value,
                this.editLabelForm.get('color').value
            );
            this.roiService.updateUserRoiLabel(this.label).subscribe((updatedLabel) => {
                this.label = updatedLabel;
                this.snackBar.present('Label updated correctly', 'success');
                this.dialogRef.close(this.label);
            });
        } else {
            this.snackBar.present("Something went wrong. Can't update the label.", 'error');
        }
    }
}
