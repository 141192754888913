import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';

import { Product } from '../../models/product';
import { LayerList } from '../../models/layer-list';
import { ProductAvailability } from '../../models/product-availability';
import { ProductService } from '../../services/product.service';
import { LayersService } from '../../services/layers.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { getUTCDateWithoutConversion } from '../../utils/utils';

@Component({
    selector: 'app-layers-add',
    templateUrl: './layers-add.component.html',
    styleUrls: ['./layers-add.component.scss'],
})
export class LayersAddComponent implements OnInit {
    /**
     * Form control for input field
     * @type {FormControl}
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Product list.
     */
    products: Product[] = [];

    /**
     * Observable for user list query
     */
    filteredProducts$: Observable<LayerList[]>;

    /**
     * Selected layer
     */
    layerSelected;

    /**
     * Date selected model.
     */
    dateSelected;

    /**
     * If the availability is obtained from cache or server
     */
    getAvailabilityFromCache = false;

    /**
     * Product availability list for the current product selected.
     * @type {ProductAvailability}
     */
    productAvailability = new ProductAvailability([]);

    /**
     * Select top selected
     */
    selectedLayerType = 'productLayer';

    /**
     * Object that contains all the special layers objects
     */
    specialLayers: object;

    /**
     * Key name of the special layer selected
     */
    selectedSpecialLayer: string;

    /**
     * Types of layers that can be added
     */
    layerTypes = [
        { value: 'productLayer', viewValue: 'Product layer' },
        { value: 'specialLayer', viewValue: 'Special layer' },
    ];

    /**
     * List of layers available to be added to the map
     */
    layerList: LayerList[] = [];

    /**
     * Boolean to determine if adding a product with the same date as the selected layer is possible
     */
    sameDateAvailable = false;

    /**
     * Date value to be used for save the value of the selected layer date
     */
    sameDateAvailabilityValue;

    /**
     * Default constructor.
     *
     * @param {ProductService} productService
     * @param {MatDialogRef<LayersAddComponent>} dialogRef
     * @param {LayersService} layersService
     * @param {GoogleAnalyticsService} googleAnalyticsService
     */
    constructor(
        private productService: ProductService,
        public dialogRef: MatDialogRef<LayersAddComponent>,
        private layersService: LayersService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        // Set the crudService config
        this.productService.list().subscribe((data: Product[]) => {
            this.layerList = this.layersService.getProductGroupsList(data, true);
            this.products = data;

            this.filteredProducts$ = this.searchControl.valueChanges.pipe(
                debounceTime(100),
                distinctUntilChanged(),
                map((query) => this.filterProducts(query))
            );
            if (this.layerList.length === 1) {
                this.onProductChange(this.layerList[0]);
            }
        });

        this.specialLayers = this.layersService.getSpecialLayers();
    }

    /**
     * Angular NgOnInit.
     */
    ngOnInit() {}

    /**
     * This method is called everytime the product selector is clicked to check the input value.
     * Is is used to trigger the product list (since FRON-434, the first value is null to avoid display the list when the pop us is opened
     * for the first time)
     */
    checkInputValue() {
        if (this.searchControl.value === null || this.searchControl.value === undefined) {
            this.searchControl.setValue('');
        }
    }

    /**
     * Add layer button click event handler.
     */
    onClickAddLayer() {
        this.dateSelected = getUTCDateWithoutConversion(this.dateSelected);
        this.dialogRef.close({
            isLayer: true,
            layer: this.layerSelected,
            date: this.dateSelected,
            availability: this.productAvailability,
        });
        this.googleAnalyticsService.eventEmitter(
            'create_layer_button_clicked',
            'layer_control',
            'layer_control_add',
            this.layerSelected.name
        );
    }

    /**
     * Callback for product select change
     * @param layerItem selected layer
     */
    onProductChange(layerItem) {
        if (layerItem === undefined) {
            return;
        }
        this.layerSelected = layerItem;
        let productApiName;
        if (this.layerSelected.groupType) {
            productApiName = this.layerSelected.mainProduct.apiName;
        } else {
            productApiName = this.layerSelected.apiName;
        }
        this.googleAnalyticsService.eventEmitter(
            'product_chosen',
            'layer_control',
            'layer_control_product_chosen',
            productApiName
        );
        this.productService
            .getAvailability(productApiName, undefined, this.getAvailabilityFromCache)
            .subscribe((obj) => {
                this.productAvailability = obj;
                // Set date-picker to the most recent date available
                if (obj.availability.length > 0) {
                    this.dateSelected = obj.availability[obj.availability.length - 1];
                }
                this.checkSameDateAvailability();

                return obj;
            });
    }

    /**
     * Filter product list
     *
     * @param query
     */
    private filterProducts(query): LayerList[] {
        if (query === undefined) {
            return undefined;
        } else if (query.name) {
            query = query.name;
        }
        const filterValue = query.toLowerCase();
        return this.layerList.filter((layer) => {
            return layer.name && layer.name.toLowerCase().includes(filterValue);
        });
    }

    /**
     * Returns product name for display
     */
    displayProduct(prod: Product): string {
        return prod ? `${prod.name}` : undefined;
    }

    /**
     * Clear current selection
     */
    clearSelection() {
        this.layerSelected = undefined;
        this.dateSelected = undefined;
        this.productAvailability = new ProductAvailability([]);
        this.searchControl.setValue('');
    }

    /**
     * Add special layer button click event handler
     */
    onClickAddSpecialLayer() {
        this.dialogRef.close({
            isProduct: false,
            layer: this.selectedSpecialLayer,
        });
        this.googleAnalyticsService.eventEmitter(
            'add_special_layer',
            'layer_control',
            'layer_control_add',
            this.selectedSpecialLayer
        );
    }

    /**
     * Determine if there is availability for the chosen product for the date of the current layer
     */
    private checkSameDateAvailability() {
        if (this.layersService.getSelectedLayer()) {
            this.sameDateAvailabilityValue = this.layersService.getSelectedLayer().getDate();
        }
        this.sameDateAvailable =
            this.sameDateAvailabilityValue &&
            this.productAvailability.isIncluded(this.sameDateAvailabilityValue);
    }

    /**
     * Sets the date of the layer being added to the value of the selected layer if possible
     */
    setSameDateAvailability(event) {
        if (this.sameDateAvailable && this.sameDateAvailabilityValue !== undefined) {
            this.dateSelected = this.sameDateAvailabilityValue;
        }
    }
}
