import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs';

import { Deserialize } from 'cerialize';
import { Moment } from 'moment';
import { environment } from '../environments/environment';
import { Kpi } from '../models/kpi';
import { Product, ProductMetadata } from '../models/product';
import { ProductAvailability } from '../models/product-availability';
import { ViewportData } from '../models/viewport-data';
import { cmpStr, getISODateString } from '../utils/utils';

export interface GriddedDataParams {
    latMax: number;
    latMin: number;
    lonMax: number;
    lonMin: number;
    startDate: string;
    endDate: string;
    format: any;
    zipped: any;
    notify: any;
}

export interface CreatePointTimeSeriesParams {
    start_date: Moment;
    end_date: Moment;
    lng: number;
    lat: number;
    notify?: boolean;
    format?;
    exp_filter_t?;
    avg_window_days?;
    include_masked_data?;
    climatology?;
    avg_window_direction?;
}

/**
 *Product Service
 */
@Injectable()
export class ProductService {
    /** API endpoint path to retrieve the data from */
    protected apiURL = `${environment.apiV2}/products`;

    /**
     * Product availability cache
     */
    productAvailabilityCache: object = {};

    /**
     * Rounded minimum longitude to cache availability
     */
    roundedLonMin;

    /**
     * Rounded maximum longitude to cache availability
     */
    roundedLonMax;

    /**
     * Rounded minimum latitude to cache availability
     */
    roundedLatMin;

    /**
     * Rounded maximum latitude to cache availability
     */
    roundedLatMax;

    private http = inject(HttpClient);

    /**
     * Return a stream with the list of products.
     *
     * @returns {Observable<Response>}
     */
    list(sortProducts = false): Observable<Product[]> {
        return this.http.get(`${this.apiURL}/`).pipe(
            map((res: any) => {
                const products = Deserialize(res.products, Product);
                if (sortProducts) {
                    products.sort(function (a, b) {
                        return cmpStr(a.name, b.name);
                    });
                }
                return products;
            })
        );
    }

    /**
     * Return a stream with the product metadata.
     *
     * @param {number} product_api_name Product API Name
     */
    getProductMetadata(product_api_name: string): Observable<ProductMetadata> {
        return this.http
            .get(`${this.apiURL}/${product_api_name}/metadata`)
            .pipe(map((res: any) => Deserialize(res, ProductMetadata)));
    }

    /**
     * Return a stream with the product metadata.
     *
     * @param {string} product_api_name Product API Name
     */
    getProductLegend(product_api_name: string) {
        return this.http.get(`${this.apiURL}/${product_api_name}/legend`);
    }

    /**
     * Return a stream with the legend data of a product.
     *
     * @param {string} product_api_name Product API Name
     * @param {string} hash
     */
    getProductLegendInEmbedView(product_api_name: string, hash: string) {
        return this.http.get(`${this.apiURL}/${product_api_name}/embed_view/${hash}/legend`);
    }

    /**
     * Get the availability for one product
     *
     * @param product_api_name Product API Name
     * @param {lonMin, lonMax, latMin, latMax} bbox : bounding box
     * @param {boolean} getAvailabilityFromCache: if return cached availability or not
     * @returns {Observable<ProductAvailability>} Availability information
     */
    getAvailability(
        product_api_name: string,
        bbox?: ViewportData,
        getAvailabilityFromCache = true
    ): Observable<ProductAvailability> {
        if (bbox) {
            this.roundedLonMin = Math.floor(bbox.lonMin / 5) * 5;
            this.roundedLonMax = Math.round(bbox.lonMax / 5) * 5;
            this.roundedLatMin = Math.floor(bbox.latMin / 5) * 5;
            this.roundedLatMax = Math.round(bbox.latMax / 5) * 5;
            if (
                this.productAvailabilityCache[product_api_name] &&
                this.productAvailabilityCache[product_api_name][
                    `${this.roundedLonMin.toString()},${this.roundedLonMax.toString()},${this.roundedLatMin.toString()},${this.roundedLatMax.toString()}`
                ]
            ) {
                return observableOf(
                    this.productAvailabilityCache[product_api_name][
                        `${this.roundedLonMin.toString()},${this.roundedLonMax.toString()},${this.roundedLatMin.toString()},${this.roundedLatMax.toString()}`
                    ]
                );
            }
        }

        if (
            bbox === undefined &&
            this.productAvailabilityCache[product_api_name] &&
            getAvailabilityFromCache
        ) {
            // cache hit
            return observableOf(this.productAvailabilityCache[product_api_name]);
        }

        const apiPath = `${this.apiURL}/${product_api_name}/availability`;
        let observable;

        if (bbox === undefined) {
            observable = this.http.get(apiPath);
        } else {
            const httpParams = {
                lon_min: bbox.lonMin.toString(),
                lon_max: bbox.lonMax.toString(),
                lat_min: bbox.latMin.toString(),
                lat_max: bbox.latMax.toString(),
            };
            observable = this.http.get(apiPath, { params: httpParams });
        }

        return observable.pipe(
            map((data: any) => {
                const availability = new ProductAvailability(data.availability);
                if (bbox === undefined) {
                    this.productAvailabilityCache[product_api_name] = availability;
                } else {
                    this.productAvailabilityCache[product_api_name][
                        `${this.roundedLonMin.toString()},${this.roundedLonMax.toString()},${this.roundedLatMin.toString()},${this.roundedLatMax.toString()}`
                    ] = availability;
                }
                return availability;
            })
        );
    }

    /**
     * Check the availability for one product in a selected date
     *
     * @param productApiName Product API Name
     * @param selectedProductDate Selected product date
     * @param selectedDate Selected Availability date
     * @returns Availability information
     */
    checkProductAvailabilitySelectedDate(
        productApiName: string,
        selectedProductDate: Moment,
        productForecastDate?: Moment
    ): Observable<any> {
        const apiPath = `${this.apiURL}/${productApiName}/availability`;
        const selectedProductDateString = getISODateString(selectedProductDate);

        const httpParams = {
            start_time: selectedProductDateString,
            end_time: selectedProductDateString,
        };

        return this.http.get(apiPath, { params: httpParams }).pipe(
            map((data: any) => {
                return {
                    forecastDate: productForecastDate ? productForecastDate : selectedProductDate,
                    availability: data.availability,
                };
            })
        );
    }

    /**
     * Get the cached availability for one product
     *
     * @param product_api_name Product API Name
     * @returns Availability information
     */
    getProductAvailabilityCache(product_api_name: string) {
        if (this.productAvailabilityCache[product_api_name]) {
            // cache hit
            return this.productAvailabilityCache[product_api_name];
        } else {
            return undefined;
        }
    }

    /**
     * Get selected kpis for a given product
     *
     * @param {string} product_api_name Product API Name
     */
    getSelectedKpis(product_api_name: string): Observable<Kpi[]> {
        return this.http.get(`${this.apiURL}/${product_api_name}/selected_kpis`).pipe(
            map((data: any) => {
                return data.kpis.map((e) => new Kpi(e));
            })
        );
    }

    /**
     * Set selected kpis for a given product
     *
     * @param {string} product_api_name Product API Name
     * @param {Kpi []} kpis
     */
    setSelectedKpis(product_api_name: string, kpis: Kpi[]): Observable<object> {
        const kpiNames = kpis.map((kpi) => kpi.name);
        return this.http.post(`${this.apiURL}/${product_api_name}/selected_kpis`, {
            kpis: kpiNames,
        });
    }

    /**
     * Get time series point value for a given product
     *
     * @param {string} product_api_name Product API Name
     * @param {lat, lon, date} params
     */
    getTimeSeriesPointValue(product_api_name: string, params: any) {
        return this.http.get(`${this.apiURL}/${product_api_name}/point-value`, {
            params: params,
        });
    }

    /** Get the product gridded data */
    getProductGriddedData(productApiName: string, params: GriddedDataParams): Observable<any> {
        const httpParams = {
            start_date: params.startDate.toString(),
            end_date: params.endDate.toString(),
            lon_min: params.lonMin.toString(),
            lon_max: params.lonMax.toString(),
            lat_min: params.latMin.toString(),
            lat_max: params.latMax.toString(),
            format: params.format,
            zipped: params.zipped.toString(),
        };

        if (params.notify !== undefined) {
            httpParams['notify'] = params.notify;
        }

        return this.http.get(`${this.apiURL}/${productApiName}/gridded-data`, {
            params: httpParams,
        });
    }

    /**
     * Create a point time series API request
     */
    createProductPointTimeSeriesRequest(
        productApiName: string,
        params: CreatePointTimeSeriesParams
    ) {
        const httpParams = {
            start_time: getISODateString(params.start_date),
            end_time: getISODateString(params.end_date),
            lon: params.lng.toString(),
            lat: params.lat.toString(),
        };

        if (params.notify) {
            httpParams['notify'] = params.notify;
        }
        if (params.format) {
            httpParams['format'] = params.format;
        }
        if (params.exp_filter_t) {
            httpParams['exp_filter_t'] = params.exp_filter_t;
        }
        if (params.avg_window_days !== undefined) {
            httpParams['avg_window_days'] = params.avg_window_days;
        }
        if (params.include_masked_data !== undefined) {
            httpParams['include_masked_data'] = params.include_masked_data;
        }
        if (params.climatology !== undefined) {
            httpParams['climatology'] = params.climatology;
        }
        if (params.avg_window_direction !== undefined) {
            httpParams['avg_window_direction'] = params.avg_window_direction;
        }

        return this.http.get(`${this.apiURL}/${productApiName}/point-time-series`, {
            params: httpParams,
        });
    }

    /**
     * Create a ROI time series API request
     */
    createProductROITimeSeriesRequest(productApiName: string, roi_id: number, params) {
        const httpParams = {
            roi_id: roi_id.toString(),
            start_time: getISODateString(params.start_date),
            end_time: getISODateString(params.end_date),
        };
        if (params.notify) {
            httpParams['notify'] = params.notify;
        }
        if (params.format) {
            httpParams['format'] = params.format;
        }
        if (params.exp_filter_t) {
            httpParams['exp_filter_t'] = params.exp_filter_t;
        }
        if (params.avg_window_days) {
            httpParams['avg_window_days'] = params.avg_window_days;
        }
        if (params.include_masked_data !== undefined) {
            httpParams['include_masked_data'] = params.include_masked_data;
        }
        if (params.climatology !== undefined) {
            httpParams['climatology'] = params.climatology;
        }
        if (params.avg_window_direction !== undefined) {
            httpParams['avg_window_direction'] = params.avg_window_direction;
        }
        if (params.coverage !== undefined) {
            httpParams['provide_coverage'] = params.coverage;
        }

        return this.http.get<any>(`${this.apiURL}/${productApiName}/roi-time-series`, {
            params: httpParams,
        });
    }

    /**
     * Create a animation layer API request
     *
     * @param {string} productApiName Product API Name
     * @param {object} params
     */
    getAnimationApiRequest(productApiName, params) {
        const httpParams = {
            start_date: params.startDate.toString(),
            end_date: params.endDate.toString(),
            geojson: params.geojson,
            zoom_level: params.zoomLevel.toString(),
            duration: params.duration.toString(),
            base_layer: params.baseLayer.toString(),
        };

        if (params.notify !== undefined) {
            httpParams['notify'] = params.notify;
        }
        if (params.legendStart !== undefined) {
            httpParams['legendStart'] = params.legendStart;
        }
        if (params.legendEnd !== undefined) {
            httpParams['legendEnd'] = params.legendEnd;
        }
        return this.http.get(`${this.apiURL}/${productApiName}/animation`, {
            params: httpParams,
        });
    }
}
