<!-- sortable -->
<div
    *ngIf="sortable; else nonSortable"
    class="flex flex-wrap justify-center items-center lg:justify-around gap-2"
>
    <app-kpi
        *ngFor="let kpi of kpis"
        [kpi]="kpi"
        [edit]="edit"
        [mode]="mode"
        (kpiDeleted)="onKpiDeleted($event)"
        (kpiAdded)="onKpiAdded($event)"
    ></app-kpi>
</div>

<!-- non-sortable -->
<ng-template #nonSortable>
    <div
        *ngIf="!sortable"
        class="flex flex-wrap justify-center items-center lg:justify-around gap-2"
    >
        <app-kpi
            *ngFor="let kpi of kpis"
            [kpi]="kpi"
            [edit]="edit"
            [mode]="mode"
            (kpiDeleted)="onKpiDeleted($event)"
            (kpiAdded)="onKpiAdded($event)"
        ></app-kpi>
    </div>
</ng-template>
