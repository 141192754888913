import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as L from 'leaflet';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import {
    AnimationPreviewComponent,
    AnimationPreviewData,
    AnimationPreviewResult,
} from '../animation-preview/animation-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiRequestsService } from '../../services/api-requests.service';
import { Subject } from 'rxjs';
import {
    ApiAccessInfoDialogComponent,
    ApiAccessInfoDialogData,
} from '../api-access-info-dialog/api-access-info-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { GeoJSON } from 'geojson';
import { roiStyle } from '../roi-management/roi-management.component';
import { MatPaginator } from '@angular/material/paginator';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { ApiRequest } from '../../models/api-request';
import { ApiRequestsListInterface } from '../../services/api-requests.service';
import { UserSettingsInfo } from '../../services/user.service';

@Component({
    selector: 'app-animation-sidenav',
    templateUrl: './animation-sidenav.component.html',
    styleUrls: ['./animation-sidenav.component.scss'],
})
export class AnimationSidenavComponent implements OnInit {
    /**
     * Reference to Map component edition modes object:
     * captureRegionMode: Whether the capture region mode is enabled or not
     */
    @Input() public editionModes: object;

    /**
     * Leaflet map reference.
     */
    @Input() public mapReference: L.Map;

    @Input() public allowedArea: GeoJSON | null;

    @Input() public userSettings: UserSettingsInfo;

    /**
     * Close sidenav event.
     * @type {EventEmitter<Map>}
     */
    @Output() closeSidenav = new EventEmitter<any>(true);

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<any>(true);

    /**
     * Material table paginator.
     */
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * Leaflet AreaSelect plugin object.
     * @type {L.AreaSelect}
     */
    private areaSelect: L.AreaSelect;

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = ['map', 'product', 'status', 'info', 'repeat'];

    /**
     * Flag to show/hide loading spinner
     */
    loadingList = false;

    /**
     * Activate/desactivate generate animation content.
     * @type {boolean}
     */
    generateAnimation = true;

    /**
     * Material table data source.
     * @type {MatTableDataSource<any>}
     */
    dataSource = new MatTableDataSource([]);

    /**
     * List of rows with the region info.
     * @type {any[]}
     */
    apiAccessRows: ApiRequest[] = [];

    /**
     * HttpParams to retrieve the animation api requests
     */
    httpParams = { api_request_type: 'animation' };

    /**
     * Pagesize value for mobile devices
     */
    pagesize = 2;

    /**
     * Subject to remove the observable that makes request every 10 seconds when closing sidenav
     */
    protected _onDestroy = new Subject<void>();

    constructor(
        private snackBar: CustomSnackbarService,
        public matDialog: MatDialog,
        private apiRequestsService: ApiRequestsService,
        private dialogService: DialogService,
        private deviceService: DeviceDetectorService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        if (this.deviceService.isDesktop()) {
            this.pagesize = 4;
        }
    }

    ngOnInit() {}

    /**
     * Open the sidenav.
     */
    open() {
        this.getAnimationApiRequests();
    }

    /**
     * Close animation sidenav
     */
    closeMenu() {
        this.apiAccessRows = [];
        this._onDestroy.next();
        this._onDestroy.complete();
        this.googleAnalyticsService.eventEmitter(
            'animation_sidenav_close',
            'sidenavs',
            'close_sidenav',
            'animation_sidenav'
        );
        this.closeSidenav.emit();
    }

    /**
     * Add the region as a layer to the preview map.
     * @param {Map} map
     * @param region
     */
    setRegion(map: L.Map, region) {
        const regionLayer = L.geoJSON(<GeoJSON>region.geojson);
        regionLayer.setStyle(roiStyle);
        regionLayer.addTo(map);
        setTimeout(() => {
            map.invalidateSize();
            map.fitBounds(regionLayer.getBounds());
        }, 200);
    }

    /**
     * Apply filter to the data source
     * @param filterValue
     */
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Api call to get animation layer list
     */
    getAnimationApiRequests() {
        this.loadingList = true;
        this.apiRequestsService
            .list(this.httpParams)
            .subscribe((results: ApiRequestsListInterface) => {
                this.setDataSource(results.requests);
            });
    }

    /**
     * Creates area select and add it to the map.
     */
    animationOverTime() {
        this.editionModes['evolutionEditMode'] = true;
        this.disableMapClick();
        this.generateAnimation = false;
        // Leaflet Area Select
        this.areaSelect = L.areaSelect({ width: 100, height: 100 });
        this.areaSelect.addTo(this.mapReference);
    }

    /**
     * Creates animation layer
     * @param event
     */
    confirmAnimation(event) {
        if (event) {
            if (this.boundsInsideAreaAllowed(this.areaSelect.getBounds(), this.allowedArea)) {
                const bounds = this.areaSelect.getBounds();
                const geojson = {
                    type: 'Polygon',
                    coordinates: [
                        [
                            [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
                            [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
                            [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
                            [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
                            [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
                        ],
                    ],
                } as GeoJSON;
                this.cancelBoundingBox();
                this.openAnimationPreviewContonent(geojson);
            } else {
                this.snackBar.present('The bounding box is outside of the allowed area.', 'error');
            }
        }
    }

    /**
     * Open animation layer component
     */
    openAnimationPreviewContonent(geojson: GeoJSON) {
        const previewDialogRef = this.matDialog.open<
            AnimationPreviewComponent,
            AnimationPreviewData,
            AnimationPreviewResult
        >(AnimationPreviewComponent, {
            data: {
                geojson,
                baseLayer: this.userSettings?.settings?.baseLayer,
                notificationEmail: this.userSettings?.settings?.notificationEmail,
            },
            width: '80%',
            height: '80%',
            disableClose: true,
            panelClass: 'animation-preview-dialog',
        });

        previewDialogRef.componentInstance.onRequestCreated.subscribe((res: any) => {
            this.loadingList = true;
            this.apiRequestsService
                .list(this.httpParams)
                .subscribe((result: ApiRequestsListInterface) => {
                    this.setDataSource(result.requests);
                });
        });
    }

    setDataSource(requests) {
        this.apiAccessRows = requests;
        this.dataSource.data = this.apiAccessRows;
        this.dataSource.paginator = this.paginator;
        this.loadingList = false;
    }

    /**
     * Cancel area select and remove it from map
     */
    cancelBoundingBox() {
        this.editionModes['evolutionEditMode'] = false;
        // Leaflet Area Select
        this.areaSelect.remove();

        // Enable text select
        document.body.classList.remove('disable-select');
        this.enableMapClick();
    }

    /**
     * Disable the map click event.
     */
    disableMapClick() {
        this.mapReference.off('click');
    }

    /**
     * Restore the map click event.
     */
    enableMapClick() {
        this.mapClick.emit(true);
    }

    /**
     * Open the API Access info dialog.
     * @param apiRequest
     */
    openApiAccessDialog(apiRequest: { uuid: string }) {
        this.apiRequestsService
            .getApiRequestInfo(apiRequest.uuid)
            .subscribe((result: ApiRequest) => {
                this.dialogService.openComponent<
                    ApiAccessInfoDialogComponent,
                    ApiAccessInfoDialogData
                >(ApiAccessInfoDialogComponent, {
                    data: {
                        request: result,
                        userSettings: this.userSettings,
                    },
                });
            });
    }

    boundsInsideAreaAllowed(bounds, areaAllowed) {
        let found = false;
        let coordinates;
        let polygon;
        let holeCoordinates;
        let holePolygon;
        // JSON.parse and JSON.stringify are used to clone area allowed
        const userAreaAllowed = JSON.parse(JSON.stringify(areaAllowed));
        switch (userAreaAllowed.type) {
            case 'MultiPolygon':
                let x = 0;
                while (x < userAreaAllowed.coordinates.length && !found) {
                    if (userAreaAllowed.coordinates[x][1]) {
                        holeCoordinates = [];
                        for (let i = 0; i < userAreaAllowed.coordinates[x][1].length; i++) {
                            holeCoordinates.push(userAreaAllowed.coordinates[x][1][i].reverse());
                        }
                        holePolygon = L.polygon(holeCoordinates);
                        if (holePolygon.getBounds().contains(bounds)) {
                            return found;
                        }
                    }
                    coordinates = [];
                    for (let i = 0; i < userAreaAllowed.coordinates[x][0].length; i++) {
                        coordinates.push(userAreaAllowed.coordinates[x][0][i].reverse());
                    }
                    polygon = L.polygon(coordinates);
                    if (polygon.getBounds().contains(bounds)) {
                        found = true;
                    }
                    x++;
                }
                return found;
                break;
            case 'Polygon':
                coordinates = [];
                if (userAreaAllowed.coordinates[1]) {
                    holeCoordinates = [];
                    for (let i = 0; i < userAreaAllowed.coordinates[1].length; i++) {
                        holeCoordinates.push(userAreaAllowed.coordinates[1][i].reverse());
                    }
                    holePolygon = L.polygon(holeCoordinates);
                    if (holePolygon.getBounds().contains(bounds)) {
                        return found;
                    }
                }
                for (let i = 0; i < userAreaAllowed.coordinates[0].length; i++) {
                    coordinates.push(userAreaAllowed.coordinates[0][i].reverse());
                }
                polygon = L.polygon(coordinates);
                if (polygon.getBounds().contains(bounds)) {
                    found = true;
                }
                return found;
                break;
        }
    }
}
