import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, map, takeUntil, share } from 'rxjs';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user';

export interface UserSelectDialogData {}

export type UserSelectDialogResult = UserSelectDialogUserResult | undefined;

export interface UserSelectDialogUserResult {
    id: number;
    name: string;
    email: string;
    username: string;
}

@Component({
    selector: 'app-user-select-dialog',
    templateUrl: './user-select-dialog.component.html',
    styleUrls: ['./user-select-dialog.component.scss'],
})
export class UserSelectDialogComponent implements OnInit, OnDestroy {
    /**
     * Form control for input field
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Observable for user list query
     */
    filteredUsers$: Observable<UserSelectDialogResult[]>;

    /**
     * User selected in autocomplete
     */
    userSelected: UserSelectDialogResult;

    /** Unsubscribe from observables */
    stop$ = new Subject<void>();

    constructor(
        private dialogRef: MatDialogRef<UserSelectDialogComponent, UserSelectDialogResult>,
        @Inject(MAT_DIALOG_DATA) private data: { name: string },
        private userService: UserService
    ) {}

    ngOnInit() {
        const users$ = this.userService
            .getUserList(undefined, {
                users: ['id', 'name', 'email', 'username'],
            })
            .pipe(share());

        this.filteredUsers$ = combineLatest([
            users$,
            this.searchControl.valueChanges.pipe(
                startWith(''),
                debounceTime(100),
                distinctUntilChanged()
            ),
        ]).pipe(
            takeUntil(this.stop$),
            map(([users, query]) => this.filterUsers(users, query))
        );
    }

    ngOnDestroy(): void {
        this.stop$.next();
        this.stop$.complete();
    }

    /**
     * Closes the dialog and returns the selected user
     */
    closeOk() {
        this.dialogRef.close(this.userSelected);
    }

    /**
     * Closes the dialog
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Returns user name for display
     */
    displayUser(user: UserSelectDialogResult): string {
        return user && `${user.username} - ${user.name}`;
    }

    /**
     * Event for user selection in autocomplete
     *
     * @param {MatAutocompleteSelectedEvent} event
     */
    onUserSelect(event): void {
        this.userSelected = event.option.value;
    }

    /**
     * Filter user list
     *
     * @param query
     */
    private filterUsers(
        users: UserSelectDialogResult[],
        query: User | string
    ): UserSelectDialogResult[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof User) {
            query = query.username;
        }
        const filterValue = query.toLowerCase();
        return users.filter((user) => {
            return (
                user.username.toLowerCase().includes(filterValue) ||
                (user.name && user.name.toLowerCase().includes(filterValue))
            );
        });
    }
}
