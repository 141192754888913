import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiRequest, ApiRequestTypeEnum } from '../../models/api-request';
import * as L from 'leaflet';
import { GeoJsonObject } from 'geojson';
import { roiStyle } from '../roi-management/roi-management.component';
import {
    AnimationPreviewComponent,
    AnimationPreviewData,
    AnimationPreviewResult,
} from '../animation-preview/animation-preview.component';
import { RoiService } from '../../services/roi.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';
import { UserSettingsInfo } from '../../services/user.service';

export interface ApiAccessInfoDialogData {
    request: ApiRequest;
    userSettings: UserSettingsInfo;
}

@Component({
    selector: 'app-api-access-info-dialog',
    templateUrl: './api-access-info-dialog.component.html',
    styleUrls: ['./api-access-info-dialog.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ApiAccessInfoDialogComponent
    implements DynamicDialog<ApiAccessInfoDialogComponent, ApiAccessInfoDialogData>
{
    /**
     * List of types
     */
    ApiRequestTypeInterface = ApiRequestTypeEnum;

    /**
     * Reference to the dynamic dialog.
     */
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<ApiAccessInfoDialogComponent, ApiAccessInfoDialogData>
    >;

    /**
     * Dialog data.
     */
    public dialogData: ApiAccessInfoDialogData;

    /**
     * Material table columns.
     * @type {string[]}
     */
    displayedColumns = ['filename', 'download'];

    constructor(
        public matDialog: MatDialog,
        private roiService: RoiService
    ) {}

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Add the region as a layer to the preview map.
     * @param {Map} map
     * @param region
     */
    setRegion(map: L.Map) {
        if (this.dialogData.request.type === this.ApiRequestTypeInterface.AnimationRequest) {
            const regionLayer = L.geoJSON(<GeoJsonObject>this.dialogData.request.geojson);
            this.addRegionLayer(map, regionLayer);
        } else if (
            this.dialogData.request.type === this.ApiRequestTypeInterface.PointTimeSeriesRequest
        ) {
            const point = L.marker(
                L.latLng(this.dialogData.request.lat, this.dialogData.request.lon)
            ).addTo(map);
            map.setView(point.getLatLng(), 5);
        } else if (
            this.dialogData.request.type === this.ApiRequestTypeInterface.GriddedDataRequest
        ) {
            const geojson = {
                type: 'Polygon',
                coordinates: [
                    [
                        [this.dialogData.request.lonMin, this.dialogData.request.latMax],
                        [this.dialogData.request.lonMax, this.dialogData.request.latMax],
                        [this.dialogData.request.lonMax, this.dialogData.request.latMin],
                        [this.dialogData.request.lonMin, this.dialogData.request.latMin],
                        [this.dialogData.request.lonMin, this.dialogData.request.latMax],
                    ],
                ],
            };
            const regionLayer = L.geoJSON(<GeoJsonObject>geojson);
            this.addRegionLayer(map, regionLayer);
        } else if (
            this.dialogData.request.type === this.ApiRequestTypeInterface.RoiTimeSeriesRequest
        ) {
            this.roiService.getRegion(this.dialogData.request.roiId).subscribe((result: any) => {
                const regionLayer = L.geoJSON(<GeoJsonObject>result.geojson);
                this.addRegionLayer(map, regionLayer);
            });
        }
        setTimeout(() => {
            map.invalidateSize();
        }, 200);
    }

    addRegionLayer(map, regionLayer) {
        regionLayer.setStyle(roiStyle);
        regionLayer.addTo(map);
        map.fitBounds(regionLayer.getBounds());
    }

    openAnimationPreviewContent() {
        this.matDialog.open<
            AnimationPreviewComponent,
            AnimationPreviewData,
            AnimationPreviewResult
        >(AnimationPreviewComponent, {
            data: {
                geojson: this.dialogData.request.geojson,
                duration: this.dialogData.request.duration,
                startDate: this.dialogData.request.startDate,
                endDate: this.dialogData.request.endDate,
                product: this.dialogData.request.productName,
                baseLayer: this.dialogData.userSettings?.settings?.baseLayer,
                notificationEmail: this.dialogData.userSettings?.settings?.notificationEmail,
            },
            width: '80%',
            height: '80%',
            disableClose: true,
            panelClass: 'animation-preview-dialog',
        });
    }
}
