import { autoserialize } from 'cerialize';

/**
 * ROI Label class
 */
export class Label {
    /**
     * ID number for the label
     */
    @autoserialize id?: number;

    /**
     * Title string that will be shown for the label
     */
    @autoserialize title?: string;

    /**
     * Description for the label
     */
    @autoserialize description?: string;

    /**
     * String with the hex color value for the label
     */
    @autoserialize color?: string;

    /**
     * Default constructor
     * @param {number} id label id
     * @param {string} title: label title
     * @param {string} description: label description
     * @param {string} color: label color
     */
    constructor(id?: number, title?: string, description?: string, color?: string) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.color = color;
    }

    /**
     * Shallow Label copy.
     * @return {Label}
     */
    public clone(): Label {
        return new Label(this.id, this.title, this.description, this.color);
    }

    /**
     * Update label properties
     * @param title
     * @param description
     * @param color
     */
    public updateLabel(title?, description?, color?) {
        if (title) {
            this.title = title;
        }
        if (description) {
            this.description = description;
        }
        if (color) {
            this.color = color;
        }
    }
}
