<div class="product-multi-select-wrapper flex flex-col">
    <div class="title-wrapper flex justify-center items-center">
        <span class="title">Add more products</span>
    </div>

    <div class="product-multi-select-content">
        <div class="product-select justify-center items-center flex flex-col">
            <mat-form-field>
                <mat-label>Product</mat-label>
                <input
                    placeholder="Type product name"
                    aria-label="Product name"
                    matInput
                    [formControl]="searchControl"
                    [matAutocomplete]="auto"
                    class="map-product-selector"
                    tabindex="-1"
                />
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onProductChange($event)"
                    [displayWith]="displayProduct"
                >
                    <mat-option
                        *ngFor="let prod of filteredProducts$ | async"
                        [value]="prod"
                        [disabled]="isSelectable(prod)"
                    >
                        {{ prod.name }} [{{ prod.unit }}]
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div class="unit-text">
                <span>(max. 2 different units)</span>
            </div>
        </div>

        <div class="product-card-wrapper" *ngFor="let prod of selectedProducts">
            <mat-card appearance="outlined" class="product-card !flex !flex-row justify-between">
                <div
                    *ngIf="prod.unit === selectedUnitsArray[0]"
                    [ngStyle]="{ backgroundColor: timeSeriesLeftYColor }"
                    class="left-axis"
                >
                    L
                </div>
                <div
                    *ngIf="prod.unit !== selectedUnitsArray[0]"
                    [ngStyle]="{ backgroundColor: timeSeriesRightYColor }"
                    class="right-axis"
                >
                    R
                </div>
                <div class="selected-product max-w-[60%]">{{ prod.name }} [{{ prod.unit }}]</div>

                <div class="layer-actions flex">
                    <button
                        mat-icon-button
                        class="action-button"
                        [disabled]="!timeSeriesStatus"
                        (click)="hideProduct(prod)"
                    >
                        <i
                            [class]="
                                productTimeSeriesStatus[prod.name] ? 'fa fa-eye-slash' : 'fa fa-eye'
                            "
                            aria-hidden="true"
                        ></i>
                    </button>

                    <button mat-icon-button class="action-button" (click)="remove(prod)">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                </div>
            </mat-card>
        </div>
    </div>
</div>
