import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

import { matchPassword } from '../../utils/utils';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
    /**
     * Background image
     */
    public backgroundImage = 'url("/assets/img/blurred_world.jpg")';

    /**
     * Register form.
     */
    registerForm: UntypedFormGroup;

    /**
     * Default constructor.
     * @param userService
     * @param router
     * @param snackbar
     */
    constructor(
        private userService: UserService,
        private router: Router,
        private snackbar: CustomSnackbarService
    ) {
        this.registerForm = new UntypedFormGroup(
            {
                name: new UntypedFormControl('', Validators.required),
                email: new UntypedFormControl('', Validators.required),
                username: new UntypedFormControl('', Validators.required),
                affiliation: new UntypedFormControl('', Validators.required),
                password: new UntypedFormControl('', Validators.required),
                repeatPassword: new UntypedFormControl('', Validators.required),
            },
            matchPassword
        );
    }

    /**
     * Create a new user.
     */
    register() {
        this.userService.register(this.registerForm.value).subscribe((user) => {
            this.snackbar.present(
                'Successful registration. Check your email to activate your account'
            );
            this.goToLogin();
        });
    }

    /**
     * Navigate to login page.
     */
    goToLogin() {
        this.router.navigateByUrl('/login');
    }
}
