import { Component, Input, OnChanges, OnInit, Output, inject } from '@angular/core';

import { NonNullableFormBuilder } from '@angular/forms';
import { Observable, map, tap } from 'rxjs';
import { Legend } from '../../models/legend';
import { Product } from '../../models/product';

export type RangeChangeEvent = [number, number];

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit, OnChanges {
    private fb = inject(NonNullableFormBuilder);

    range = this.fb.group({
        min: [undefined as number],
        max: [undefined as number],
    });

    /** Legend information */
    @Input({ required: true }) legend: Legend;

    /** Product related with the legend */
    @Input({ required: true }) product: Product;

    /** Event: Legend component changes. */
    @Output() rangeChange: Observable<RangeChangeEvent> = this.range.valueChanges.pipe(
        map((range) => [range.min, range.max] as [number, number]),
        tap((range) => {
            this.legend.currentRange = range;
        })
    );

    ngOnInit(): void {
        this.setRange();
    }

    ngOnChanges(): void {
        this.setRange();
    }

    private setRange() {
        const [min, max] = (this.legend?.currentRange || this.product?.getValueRange()) ?? [0, 100];
        this.range.reset({ min, max });
    }

    getMin() {
        return this.product.getValueRange()[0];
    }

    getMax() {
        return this.product.getValueRange()[1];
    }
}
