import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ROITimeSeriesParams, TimeSeriesStreamParams } from '../../services/dam.service';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

export type TimeSeriesInfoDialogData = Partial<ROITimeSeriesParams & TimeSeriesStreamParams>;

export type TimeSeriesInfoDialogResult = undefined;

@Component({
    selector: 'app-time-series-info-dialog',
    templateUrl: './time-series-info-dialog.component.html',
    styleUrls: ['./time-series-info-dialog.component.scss'],
})
export class TimeSeriesInfoDialogComponent
    implements
        DynamicDialog<
            TimeSeriesInfoDialogComponent,
            TimeSeriesInfoDialogData,
            TimeSeriesInfoDialogResult
        >
{
    dialogRef: MatDialogRef<
        DynamicLoaderComponent<
            DynamicDialog<TimeSeriesInfoDialogComponent, TimeSeriesInfoDialogData, undefined>,
            TimeSeriesInfoDialogData,
            undefined
        >,
        undefined
    >;

    dialogData: TimeSeriesInfoDialogData;

    constructor() {}

    /**
     * Closes the generated time series info dialog
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
