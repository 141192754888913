<div class="flex max-w-full">
    <div *ngIf="advancedMode" class="flex justify-between items-start grow-[15]">
        <app-user-product-multi-select (updateProductVisibility)="updateProductVisibility($event)">
        </app-user-product-multi-select>
    </div>

    <div class="time-series-wrapper flex flex-col grow-[85] max-w-full">
        <div class="title-wrapper flex justify-around items-center gap-2 overflow-x-auto">
            <div class="grow flex flex-wrap justify-between items-center gap-2">
                <div class="flex justify-start items-center">
                    <span class="title">Time series</span>
                </div>
                <div class="flex justify-center items-center grow">
                    <span class="title">{{ data.product.name }}</span>
                </div>
                <div class="flex justify-center items-center gap-4">
                    <button
                        mat-raised-button
                        (click)="showTimeSeriesParamsDialog()"
                        matTooltip="Parameters used to generate this time series"
                    >
                        <i class="fa fa-info-circle"></i>
                    </button>
                    <button
                        *ngIf="data.mode === 'point'"
                        mat-raised-button
                        (click)="handleTimeSeriesDialog()"
                        [disabled]="advancedMode"
                        matTooltip="Create API request"
                        [appAccess]="[
                            'can-create-roi-time-series-request',
                            'can-create-point-time-series-request'
                        ]"
                    >
                        New request
                    </button>
                    <button
                        mat-raised-button
                        [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                        (click)="exportAsPng()"
                        matTooltip="Export chart as PNG"
                    >
                        <i class="fa fa-image" aria-hidden="true"></i>
                    </button>
                    <button
                        mat-raised-button
                        [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                        (click)="exportAsCsv()"
                        matTooltip="Export series as CSV"
                    >
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    </button>
                    <button
                        mat-raised-button
                        [disabled]="timeSeries ? !timeSeries.isLoaded : true"
                        (click)="reload()"
                        matTooltip="Reload the time series"
                    >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>

                    <mat-slide-toggle
                        *ngIf="data.mode === 'point'"
                        color="warn"
                        class="advanced-mode-slide"
                        (change)="toggleAdvanceMode($event)"
                    >
                        Advanced
                    </mat-slide-toggle>
                </div>
            </div>
            <button class="shrink" mat-raised-button color="warn" (click)="closeDialog()">
                <i class="fa fa-times"></i>
            </button>
        </div>

        <div class="content flex flex-col">
            <div
                class="flex flex-row flex-wrap justify-center items-baseline gap-2 lg:gap-8"
                *ngIf="data.mode === 'point'"
            >
                <mat-form-field>
                    <mat-label>Start date</mat-label>
                    <input
                        matInput
                        [(ngModel)]="dateStart"
                        [matDatepicker]="rangeDatepickerStart"
                        [disabled]="!timeSeries.isLoaded"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="rangeDatepickerStart"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #rangeDatepickerStart></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>End date</mat-label>
                    <input
                        matInput
                        [(ngModel)]="dateEnd"
                        [matDatepicker]="rangeDatepickerEnd"
                        [disabled]="!timeSeries.isLoaded"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="rangeDatepickerEnd"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
                </mat-form-field>

                <ng-container *ngIf="coordinateSystem !== 'EPSG:28992'">
                    <mat-form-field>
                        <mat-label>Latitude</mat-label>
                        <input
                            matInput
                            type="number"
                            [disabled]="!timeSeries.isLoaded"
                            [(ngModel)]="lat"
                            step="0.00001"
                            min="-90"
                            max="90"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Longitude</mat-label>
                        <input
                            matInput
                            type="number"
                            [disabled]="!timeSeries.isLoaded"
                            [(ngModel)]="lng"
                            step="0.00001"
                            min="-180"
                            max="180"
                        />
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="coordinateSystem === 'EPSG:28992'">
                    <mat-form-field>
                        <mat-label>X</mat-label>
                        <input
                            matInput
                            type="number"
                            [disabled]="!timeSeries.isLoaded"
                            [ngModel]="lng | coordinate: lat : lng : coordinateSystem"
                            (ngModelChange)="updateCoordinates($event, 'lng')"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Y</mat-label>
                        <input
                            matInput
                            type="number"
                            [disabled]="!timeSeries.isLoaded"
                            [ngModel]="lat | coordinate: lat : lng : coordinateSystem"
                            (ngModelChange)="updateCoordinates($event, 'lat')"
                        />
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="!yearModeOnly">
                    <mat-slide-toggle
                        [(ngModel)]="isYearMode"
                        [disabled]="!timeSeries.isLoaded || advancedMode"
                    >
                        Year mode
                    </mat-slide-toggle>

                    <app-month-day-selector
                        [ngClass]="isYearMode ? 'visible' : 'hidden'"
                        [(ngModel)]="yearModeStart"
                    >
                    </app-month-day-selector>
                </ng-container>
            </div>

            <div
                class="actions flex flex-wrap justify-start items-start gap-4"
                *ngIf="data.mode === 'roi' && !yearModeOnly"
            >
                <mat-form-field>
                    <mat-label>Start date</mat-label>
                    <input
                        matInput
                        [(ngModel)]="dateStart"
                        [matDatepicker]="rangeDatepickerStart"
                        [disabled]="!timeSeries.isLoaded"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="rangeDatepickerStart"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #rangeDatepickerStart></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>End date</mat-label>
                    <input
                        matInput
                        [(ngModel)]="dateEnd"
                        [matDatepicker]="rangeDatepickerEnd"
                        [disabled]="!timeSeries.isLoaded"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="rangeDatepickerEnd"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="flex justify-between items-start">
                <app-time-series
                    class="flex-grow"
                    (seriesLoaded)="timeSeriesLoadedEvent()"
                    [withFocusChart]="true"
                    [variant]="'point'"
                    [yearMode]="isYearMode"
                    [yearModeStart]="yearModeStart"
                    [userSettings]="data.userSettings"
                ></app-time-series>
            </div>
        </div>
    </div>
</div>
