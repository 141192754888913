import { Component, Input } from '@angular/core';
import * as L from 'leaflet';

import { MapPreviewComponent } from './map-preview.component';

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-map-preview-small',
    templateUrl: './map-preview.component.html',
    styleUrls: ['./map-preview.component.css'],
})

/**
 * Map preview small Component
 */
export class MapPreviewSmallComponent extends MapPreviewComponent {
    /**
     * Map height CSS.
     * @type {string}
     */
    @Input() height = '80px';

    /**
     * Map width CSS.
     * @type {string}
     */
    @Input() width = '80px';

    /**
     * Map preview options
     */
    public options = {
        zoom: 7,
        center: L.latLng([50.5, 25.5]),
        zoomControl: false,
    };
}
