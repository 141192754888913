<div
    class="login-background flex flex-col justify-center items-center w-full h-full"
    [style.background-image]="backgroundImage"
>
    <div class="card">
        <div class="card-front flex flex-col justify-center items-center">
            <app-landing-logo></app-landing-logo>

            <span class="text">Activating account</span>
            <div class="spinner flex flex-col justify-center items-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</div>
