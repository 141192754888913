import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';

import { SimpleSnackbarComponent } from './simple-snackbar/simple-snackbar.component';
import { CustomSnackbarService } from './custom-snackbar.service';
import { ReleaseSnackbarComponent } from './release-snackbar/release-snackbar.component';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatTooltipModule, MatSnackBarModule, MatDividerModule],
    declarations: [SimpleSnackbarComponent, ReleaseSnackbarComponent],
    providers: [CustomSnackbarService],
})
export class CustomSnackbarModule {}
