import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-areaselect';
import * as MiniMap from 'leaflet-minimap';
import { GeoJSON } from 'geojson';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { combineLatest, from as observableFrom, fromEvent, Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import 'leaflet-side-by-side';
import 'leaflet-tilelayer-mask';
import 'leaflet.latlng-graticule';
import 'leaflet-mouse-position';
import 'leaflet.polylinemeasure';
import 'leaflet.vectorgrid';
import 'leaflet-vectorgrid-mask';
import 'leaflet.gridlayer.googlemutant';
import 'googlemutant-mask';

import {
    TimeSeriesDialog,
    TimeSeriesDialogData,
} from '../time-series-dialog/time-series-dialog.component';
import { Region } from '../../models/region';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { RoiSidenavComponent } from '../roi-sidenav/roi-sidenav.component';
import { LayersControlComponent } from '../layers-control/layers-control.component';
import { ClickType, MapPopupComponent } from '../map-popup/map-popup.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { UserService, UserSettingsInfo } from '../../services/user.service';
import { LayersService } from '../../services/layers.service';
import { ProductService } from '../../services/product.service';
import { EmbedService } from '../../services/embed.service';
import {
    ShareDialogComponent,
    ShareDialogData,
    ShareDialogResult,
    ShareMetadata,
} from '../share-dialog/share-dialog.component';
import { decodeEmbedLinkParams, EmbedComponent, loadEmbedLink } from '../embed/embed.component';
import { LayerKey } from '../../models/layer-data';
import { ViewportService } from '../../services/viewport.service';
import { DialogService } from '../dialog/dialog.service';
import { parseCoordinates } from '../../utils/coordinates-utils';
import { getCoordinatesFromGeometry, getUserForApi, pointInPolygon } from '../../utils/utils';
import { environment } from '../../environments/environment';
import { RoiService } from '../../services/roi.service';
import { ApiAccessComponent } from '../api-access/api-access.component';
import { EmbedSidenavComponent } from '../embed-sidenav/embed-sidenav.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { AnimationSidenavComponent } from '../animation-sidenav/animation-sidenav.component';
import { roiDefaultStyle, roiOverStyle } from '../../utils/variables';
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { AuthService } from '../auth/auth.service';
import { EmbedLinkData } from '../../models/embed-link';
import { DismissComponent, DismissData, DismissResult } from '../dialog/dismiss/dismiss.component';
import { Product } from '../../models/product';
import { Moment } from 'moment';

// https://github.com/Leaflet/Leaflet/issues/4968 fix start

/**
 * Leaflet Marker Icon image bug fix.
 */
L.Icon.Default.imagePath = '/';

/**
 * Default marker icons
 */
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'assets/img/marker-icon-2x.png',
    iconUrl: 'assets/img/marker-icon.png',
    shadowUrl: 'assets/img/marker-shadow.png',
});

// https://github.com/Leaflet/Leaflet/issues/4968 fix end

/**
 * Default map base layer key.
 */
const DEFAULT_BASE_LAYER = 'satellite';

/**
 * Default grid key.
 */
const DEFAULT_GRID = 'none';

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-50px)', opacity: 0 }),
                animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('200ms', style({ transform: 'translateX(-50px)', opacity: 0 })),
            ]),
        ]),
        trigger('lateralMenuAnimation', [
            state(
                'open',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(-400px)',
                })
            ),
            state(
                'closed',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(0)',
                })
            ),
            state(
                'openDashboard',
                style({
                    overflow: 'hidden',
                    transform: 'translateX(-50vw)',
                })
            ),
            transition('* => closed', animate('250ms ease-in-out')),
            transition('* => open', animate('80ms')),
            transition('* => openDashboard', animate('80ms')),
        ]),
    ],
})

/**
 * Main Map Component.
 */
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Bool to load the map in the embed view or in the normal view
     */
    @Input() embed = false;

    /**
     * Map side (in case it's used on a compare map view).
     * @type {string}
     */
    @Input() side = '';

    /**
     * Whether this component shows the minimap or not.
     */
    @Input() hideMinimap = false;

    /**
     * Event emitter that will trigger an event when the map is loaded.
     * @type {EventEmitter<Map>}
     */
    @Output() mapReady: EventEmitter<L.Map> = new EventEmitter<L.Map>();

    /**
     * Reference to the ROI sidenav component.
     */
    @ViewChild(RoiSidenavComponent) roiSidenavComponent: RoiSidenavComponent;

    /**
     * Reference to the Api Access sidenav component.
     */
    @ViewChild(ApiAccessComponent) apiAccessComponent: ApiAccessComponent;

    /**
     * Reference to the Embed sidenav component.
     */
    @ViewChild(EmbedSidenavComponent)
    embedSidenavComponent: EmbedSidenavComponent;

    /**
     * Reference to the Embed sidenav component.
     */
    @ViewChild(AnimationSidenavComponent)
    animationApiRequestSidenavComponent: AnimationSidenavComponent;

    /**
     * Reference to the sidenav child component.
     */
    @ViewChild(MatSidenav, { static: true }) endSidenav: MatSidenav;

    /**
     * Reference to the ROI sidenav component.
     */
    @ViewChild(MapPopupComponent, { static: true })
    mapPopupComponent: MapPopupComponent;

    /**
     * Reference to the Layers Control Component.
     */
    @ViewChild(LayersControlComponent)
    layersControlComponent: LayersControlComponent;

    /**
     * Reference to the Dashboard component.
     */
    @ViewChild(DashboardComponent) dashboardComponent: DashboardComponent;

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<any>(true);

    /**
     * Leaflet AreaSelect plugin object.
     * @type {L.AreaSelect}
     */
    private areaSelect: L.AreaSelect;

    /**
     * Activate/desactivate generate evolution content.
     * @type {boolean}
     */
    generateEvolution = true;

    /**
     * Map popup data.
     * @type {}
     */
    public mapPopupData: any = {
        lat: 0,
        lng: 0,
        x: 0,
        y: 0,
        insideRoi: false,
    };

    /**
     * String containing the attribution when using Open Street Maps base layers
     */
    private osmAttribution =
        '<span>© <a href="https://www.openstreetmap.org/copyright"  target="_blank">OpenStreetMap</a> contributors</span>';

    /**
     * Base layers included in the map.
     */
    public baseLayers: any = {
        'open-street-map': {
            label: 'OpenStreetMap',
            layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: this.osmAttribution,
            }),
            openStreetMapBaseLayer: true,
        },
        satellite: {
            label: 'Satellite',
            layer: (L as any).gridLayer.googleMutant({
                type: 'satellite', // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            }),
            googleBaseLayer: true,
        },
        terrain: {
            label: 'Terrain',
            layer: (L as any).gridLayer.googleMutant({
                type: 'terrain', // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            }),
            googleBaseLayer: true,
        },
        hybrid: {
            label: 'Hybrid',
            layer: (L as any).gridLayer.googleMutant({
                type: 'hybrid', // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            }),
            googleBaseLayer: true,
        },
    };

    /**
     * Grids that can be added to the map.
     */
    public grids: any = {
        '5x5Grid': {
            label: '5x5 Grid',
            grid: (<any>L).latlngGraticule({
                showLabel: true,
                weight: 1,
                fontColor: '#fff',
                dashArray: [5, 5],
                zoomInterval: [{ start: 0, end: 18, interval: 5 }],
            }),
        },
        '1x1Grid': {
            label: '1x1 Grid',
            grid: (<any>L).latlngGraticule({
                showLabel: true,
                weight: 1,
                fontColor: '#fff',
                dashArray: [5, 5],
                zoomInterval: [{ start: 0, end: 18, interval: 1 }],
            }),
        },
    };

    /**
     * Default base layer
     * @type {string}
     */
    public currentLayer = DEFAULT_BASE_LAYER;

    /**
     * Leaflet map default options.
     * @type {{zoom: number; center: LatLng}}
     */
    public options = {
        zoom: 4,
        center: L.latLng([50.5, 25.5]),
        worldCopyJump: true,
        layers: [this.baseLayers[this.currentLayer].layer],
    };

    /**
     * Will contain all the shapes added to the map.
     * @type {LayerGroup}
     */
    public roiLayerGroup = L.featureGroup([]);

    /**
     * Search map marker.
     * @type {LayerGroup}
     */
    private mapMarker: L.Marker = L.marker([0, 0], { opacity: 0 });

    /**
     * Layer group containing all the other layer groups (product, region and region edit).
     * @type {[LayerGroup , LayerGroup , LayerGroup]}
     */
    public layers: L.LayerGroup[] = [];

    /**
     * Dom reference to the Leaflet map.
     */
    public map: L.Map = <L.Map>{};

    /**
     * object that encloses edition modes
     * @type {{}}
     */
    public editionModes: object = {
        shapeEditMode: false, // Whether the region edit mode is enabled or not
        captureRegionMode: false, // Whether the capture region mode is enabled or not
        embedEditMode: false, // Whether the capture region mode to create embed link is enabled or not
    };

    /**
     * Default region style.
     */
    protected roiDefaultStyle = <L.PathOptions>roiDefaultStyle; // Default leaflet color

    /**
     * Mouseover region style.
     */
    protected roiOverStyle = <L.PathOptions>roiOverStyle;

    /**
     * Sidebar type
     */
    public sidebarType: string = undefined;

    /**
     * The current selected roi
     */
    public roiSelected;

    /**
     * Buttons animation status.
     */
    public lateralMenuStatus = 'closed';

    private center$ = new Subject<void>();

    /**
     * Legend display mode
     * @type {string}
     */
    public legendMode: string;

    /**
     * Leaflet side by side plugin.
     */
    public sideBySide;

    /**
     * User settings
     */
    public userSettings: UserSettingsInfo | null = null;

    public allowedArea: GeoJSON | null = null;

    /**
     * Open the ROI dashboard at the init map with url params, once.
     * @type {boolean}
     */
    private openRoiDashboardOnInit = true;

    /**
     * Layer with the area allowed of the product.
     */
    private productAreaAllowed: any;

    /**
     * Array of layers that will be displayed in spy mode.
     */
    private spyModeLayers = [];

    /**
     * Base layers included in the spy mode.
     */
    private spyModeBaseLayer: any;

    /**
     * zIndex value to order panes
     */
    private zIndexPanes = {
        spyModePane: '400',
        areaAllowedPane: '450',
        embedAreaAllowedPane: '460',
        roiPane: '500',
        spyModeBorderPane: '510',
        measurePane: '600',
    };

    /**
     * Rectangle layer with the bounds of the spy mode window
     */
    private spyModeBorder;

    /**
     * Base64 string with the image of the shape of the spy mode window (a square in this case)
     */
    private spyModeImage = 'assets/spy-mode-shape.png';

    /**
     * Flag to enable/disable drag movement of the spy-mode and the download area corners
     * @type {boolean}
     */
    public dragMovementEnabled = false;

    /**
     * Last center point of the spy mode layer. Used to set the correct view when zooming.
     */
    spyModeCenterPoint: any;

    /**
     * Initial pane of the spy mode to set the z-index
     */
    initialPane;

    /**
     * Flag to detect if the spy mode is inside a roi to enable the roi dashboard in map pop up
     */
    spyInsideRoi = false;

    /**
     * Get the help page url
     */
    helpUrl = environment.helpPage;

    /**
     * Default grid
     * @type {string}
     */
    public currentGrid = DEFAULT_GRID;

    /**
     * Page Initial loading dialog reference
     */
    public loadingDialogRef: any;

    /**
     * L.control with the coordinates overlay
     */
    coordinatesOverlay;

    /**
     * Flag to show the coordinates in degrees minutes seconds format
     */
    degreeMinuteSecond = false;

    /**
     * Pane for the measure tool to set the zIndex
     */
    private measurePane: any;

    /**
     * Measure tool control reference to the instance in use
     */
    private measureControlRef: any;

    /**
     * User sent in api calls
     */
    userForApi: string;

    /**
     * Name of the pane used for the measurement tool elements
     */
    private measurePaneName = 'measurePane';

    /**
     * Object containing the options for visuals and behaviour of the measure tool
     */
    measureOptions = {
        position: 'topleft',
        unit: 'metres',
        clearMeasurementsOnStop: false,
        showBearings: true,
        bearingTextIn: 'In',
        bearingTextOut: 'Out',
        tooltipTextDraganddelete:
            'Click and drag to <b>move point</b><br>Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',

        measureControlTitleOn: 'Turn on Measure tool',
        measureControlTitleOff: 'Turn off Measure tool',
        measureControlClasses: [],
        showClearControl: true,
        clearControlTitle: 'Clear Measurements',
        clearControlLabel: '&times',
        clearControlClasses: [],
        showUnitControl: true,
        unitControlTitle: {
            text: 'Change Units',
            metres: 'metres',
            landmiles: 'land miles',
            nauticalmiles: 'nautical miles',
        },
        unitControlLabel: {
            metres: 'm',
            kilometres: 'km',
            feet: 'ft',
            landmiles: 'mi',
            nauticalmiles: 'nm',
        },
        tempLine: {
            color: '#00f',
            weight: 2,
            pane: this.measurePaneName,
        },
        fixedLine: {
            color: '#006',
            weight: 2,
            pane: this.measurePaneName,
        },
        startCircle: {
            color: '#000',
            weight: 1,
            fillColor: '#0f0',
            fillOpacity: 1,
            radius: 5,
            pane: this.measurePaneName,
        },
        intermedCircle: {
            color: '#000',
            weight: 1,
            fillColor: '#ff0',
            fillOpacity: 1,
            radius: 3,
            pane: this.measurePaneName,
        },
        currentCircle: {
            color: '#000',
            weight: 1,
            fillColor: '#f0f',
            fillOpacity: 1,
            radius: 4,
            pane: this.measurePaneName,
        },
        endCircle: {
            color: '#000',
            weight: 1,
            fillColor: '#f00',
            fillOpacity: 1,
            radius: 5,
            pane: this.measurePaneName,
        },
        pane: this.measurePaneName,
    };

    private destroyed$ = new Subject<void>();

    constructor(
        public matDialog: MatDialog,
        private router: Router,
        private snackBar: CustomSnackbarService,
        private el: ElementRef,
        private userService: UserService,
        private ngZone: NgZone,
        public layersService: LayersService,
        private productService: ProductService,
        private viewportService: ViewportService,
        private dialogService: DialogService,
        private roiService: RoiService,
        private embedService: EmbedService,
        private deviceService: DeviceDetectorService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService
    ) {
        const paramsIndex = this.router.url.indexOf('?');
        const shareLink = paramsIndex > -1;

        this.userForApi = getUserForApi();

        this.dragMovementEnabled = this.deviceService.isDesktop();
        this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            disableClose: true,
            data: { shareLink },
        });
    }

    ngOnInit() {
        if (!this.embed) {
            this.userService.userSettings$
                .pipe(takeUntil(this.destroyed$))
                .subscribe((settings) => {
                    this.userSettings = settings;
                });

            combineLatest([this.mapReady, this.userService.userAllowedArea$])
                .pipe(take(1), takeUntil(this.destroyed$))
                .subscribe(([_, response]) => {
                    this.userDataInitialization(response);
                });

            combineLatest([this.center$, this.userService.userAllowedArea$])
                .pipe(take(1), takeUntil(this.destroyed$))
                .subscribe(() => {
                    if (this.roiLayerGroup.getLayers().length > 0) {
                        this.map.fitBounds(this.roiLayerGroup.getBounds());
                    } else {
                        this.setAllowedAreaMapView();
                    }
                });
        }
    }

    ngAfterViewInit() {
        this.layersService.layers$.subscribe((layerStatus) => {
            // time series slider change the size of the map
            this.resize();

            const layer = this.layersService.getSelectedLayer();
            if (this.productAreaAllowed !== undefined) {
                this.map.removeLayer(this.productAreaAllowed);
            }
            if (layer?.product?.areaAllowed) {
                const userProductareaAllowedPane = this.map.createPane(
                    'userProductareaAllowedPane'
                );
                userProductareaAllowedPane.style.zIndex = this.zIndexPanes['areaAllowedPane'];
                this.productAreaAllowed = L.geoJSON(layer.product.areaAllowed, <L.PathOptions>{
                    color: environment.productAreaAllowedColor,
                    fillOpacity: 0,
                    dashArray: '5,10',
                    opacity: 0.75,
                    pane: 'userProductareaAllowedPane',
                });
                this.productAreaAllowed.setStyle({
                    className: 'area-allowed-path',
                });
                if (layer.showBorder) {
                    this.map.addLayer(this.productAreaAllowed);
                    // We set this layer to the back so the roi layers can be selected
                    this.productAreaAllowed.bringToBack();
                } else {
                    this.map.removeLayer(this.productAreaAllowed);
                }
            }

            if (layerStatus.order !== undefined) {
                this.refreshLayers();
            }
            if (layerStatus.selected === undefined && this.sidebarType === 'dashboard') {
                // if no product selected close the dashboard if opened
                this.closeSidenav();
            }
            this.refreshCoordinatesDialog();
        });

        this.layersService.redraw$.subscribe(() => {
            this.initLayers();
        });

        if (this.embed) {
            this.checkInitialLoading('rois');
        }

        this.embedService.watchShareLink().subscribe(() => {
            this.checkInitialLoading('link');
        });
    }

    public showRegions(regions: Region[]) {
        this.roiLayerGroup.clearLayers();
        this.closePopup();

        regions.reverse();
        regions.forEach((region) => {
            this.addRegion(region);
        });

        // Center if it's not a share link
        if (this.router.url.indexOf('?') === -1) {
            this.center$.next();
        }
        this.checkInitialLoading('rois');
        this.refreshCoordinatesDialog();
    }

    /**
     * Updates the Loading message on the loading dialog
     *  If the loads are done the dialog is closed
     */
    public checkInitialLoading(elementLabel) {
        if (this.loadingDialogRef && this.loadingDialogRef.componentInstance) {
            this.loadingDialogRef.componentInstance.updateLoadingMessage(elementLabel);
            if (this.loadingDialogRef.componentInstance.messagesNumber < 1) {
                this.loadingDialogRef.close();
            }
        }
    }

    ngOnDestroy() {
        this.layersService.clear();
        this.destroyed$.next();
        this.destroyed$.unsubscribe();
        this.center$.unsubscribe();
    }

    /**
     * Given an array of layer keys, it generates a LayerGroup and an array of the tile layers.
     *
     * @param {Array<LayerKey>} layers
     * @returns {{layerGroup: LayerGroup; tileLayers: any[]}}
     */
    private parseLayers(layers: Array<LayerKey>) {
        this.deactivateMeasureTool();
        const tilelayers = [];
        const layerGroup = L.layerGroup();
        let zIndex = layers.length;

        for (const layerKey of layers) {
            const layer = this.layersService.get(layerKey);
            const tileLayer = layer.tileLayer ? layer.tileLayer : layer.layer;
            if (tileLayer !== undefined && layer.isVisible()) {
                tilelayers.push(tileLayer);
                tileLayer.setZIndex(zIndex);
                layerGroup.addLayer(tileLayer);
                zIndex -= 1;
            }
        }

        return { layerGroup: layerGroup, tileLayers: tilelayers };
    }

    /**
     * Given an array of layer keys, it generates the spy mode tile layers.
     *
     * @param {Array<LayerKey>} layers
     */
    private initSpyModeLayers(layers: Array<LayerKey>) {
        let zIndex = layers.length;
        const spyModePane = this.map.createPane('spyModePane');
        spyModePane.style.zIndex = this.zIndexPanes['spyModePane'];
        const spyModeSize = this.layersService.getSpyModeSize();
        if (this.baseLayers[this.currentLayer].openStreetMapBaseLayer) {
            this.spyModeBaseLayer = (<any>L.tileLayer)
                .mask(this.baseLayers[this.currentLayer].layer._url, {
                    maskUrl: this.spyModeImage,
                    maskSize: spyModeSize,
                    pane: 'spyModePane',
                    subdomains: this.baseLayers[this.currentLayer].layer.options.subdomains,
                })
                .addTo(this.map);
        } else {
            this.spyModeBaseLayer = (L as any).GridLayer.GoogleMutant.mask({
                maskUrl: this.spyModeImage,
                maskSize: spyModeSize,
                pane: 'spyModePane',
                type: this.currentLayer,
            }).addTo(this.map);
        }

        let p = this.map.getSize().divideBy(2);
        p = p.subtract(this.spyModeBaseLayer.getMaskSize().divideBy(2));

        const northEastPoint = L.point(p.x + spyModeSize, p.y);
        const southWestPoint = L.point(p.x, p.y + spyModeSize);
        const latLngNorthEastPoint = this.map.containerPointToLatLng(northEastPoint);
        const latLngSouthWestPoint = this.map.containerPointToLatLng(southWestPoint);

        this.map.createPane('spyModeBorderPane');
        this.initialPane = 'spyModeBorderPane';
        this.spyModeBorder = L.rectangle(
            [
                [latLngNorthEastPoint.lat, latLngNorthEastPoint.lng],
                [latLngSouthWestPoint.lat, latLngSouthWestPoint.lng],
            ],
            {
                pane: this.initialPane,
                color: '#000000',
                fillOpacity: 0,
                className: 'area-allowed-path',
            }
        ).addTo(this.map);
        this.spyModeLayers = [];
        const layers$ = observableFrom(layers);
        layers$.subscribe((layerKey: any) => {
            const layer = this.layersService.get(layerKey);

            if (layer.product) {
                const tileLayer = layer.tileLayer;
                let layerUrl = layer.tileLayer['_url'];
                if (tileLayer !== undefined && layer.isVisible()) {
                    tileLayer.setZIndex(zIndex);
                    zIndex -= 1;
                    Object.keys(tileLayer.options).forEach(function (key, index) {
                        layerUrl = layerUrl.replace('{' + key + '}', tileLayer.options[key]);
                    });
                    const spyLayer = (<any>L.tileLayer)
                        .mask(layerUrl, {
                            maskUrl: this.spyModeImage,
                            maskSize: spyModeSize,
                            pane: 'spyModePane',
                        })
                        .addTo(this.map);
                    spyLayer.setOpacity(layer.opacity / 100.0);
                    this.spyModeLayers.push(spyLayer);
                }
            } else {
                const specialLayer = layer.layer;
                if (specialLayer !== undefined && layer.isVisible()) {
                    specialLayer.setZIndex(zIndex);
                    zIndex -= 1;
                    this.layersService
                        .getSpecialLayerData(layer.layerName)
                        .subscribe((result: any) => {
                            const spyLayer = (L as any).vectorGrid.slicer
                                .mask(result, {
                                    maskUrl: this.spyModeImage,
                                    maskSize: spyModeSize,
                                    pane: 'spyModePane',
                                    vectorTileLayerStyles: {
                                        sliced: {
                                            color: environment.specialLayersColor,
                                            weight: 2,
                                        },
                                    },
                                })
                                .addTo(this.map);
                            spyLayer.setOpacity(layer.opacity / 100.0);
                            this.spyModeLayers.push(spyLayer);
                        });
                }
            }
        });
        this.setSpyMode();
    }

    /**
     * initialize layer group array
     */
    public initLayers() {
        const layersOrder = this.layersService.getLayersOrder();
        const layersCompareOrder = this.layersService.getLayersCompareOrder();
        const { layerGroup: leftLayerGroup, tileLayers: leftTileLayers } =
            this.parseLayers(layersOrder);
        let mapLayers = [leftLayerGroup];
        for (let i = 0; i < this.spyModeLayers.length; i++) {
            this.map.removeLayer(this.spyModeLayers[i]);
        }
        if (this.spyModeBaseLayer !== undefined) {
            this.map.removeLayer(this.spyModeBaseLayer);
        }
        if (this.spyModeBorder !== undefined) {
            this.map.removeLayer(this.spyModeBorder);
        }
        this.map.off('mousemove');
        if (layersCompareOrder.length === 0) {
            if (this.sideBySide !== undefined) {
                this.map.removeControl(this.sideBySide);
                this.sideBySide = undefined;
            }
        } else {
            const compareMode = this.layersService.getLayersCompareMode();
            if (compareMode === 'sideBySide') {
                if (this.sideBySide === undefined) {
                    this.sideBySide = (<any>L.control).sideBySide([], []);
                    this.sideBySide.addTo(this.map);
                }
                const { layerGroup: rightLayerGroup, tileLayers: rightTileLayers } =
                    this.parseLayers(layersCompareOrder);

                mapLayers = [...mapLayers, rightLayerGroup];

                this.sideBySide.setLeftLayers(leftTileLayers);
                this.sideBySide.setRightLayers(rightTileLayers);
            } else {
                if (this.sideBySide !== undefined) {
                    this.map.removeControl(this.sideBySide);
                    this.sideBySide = undefined;
                }

                this.initSpyModeLayers(layersCompareOrder);
            }
        }

        this.layers = [...mapLayers, this.roiLayerGroup];
    }

    /**
     * Load the time series and display a dialog with a chart.
     *
     * @param lat Latitude click.
     * @param lng Longitude click.
     * @param product Time series product.
     * @param date TIme series date.
     */
    loadTimeSeries(
        lat: number,
        lng: number,
        product: Product | undefined = undefined,
        date: Moment | undefined = undefined
    ): void {
        const selectedLayer = this.layersService.getSelectedLayer();

        if (selectedLayer !== undefined) {
            this.matDialog.open<TimeSeriesDialog, TimeSeriesDialogData>(TimeSeriesDialog, {
                width: '80%',
                // height: '',
                data: {
                    mode: 'point',
                    userSettings: this.userSettings,
                    product: product || selectedLayer.product,
                    date: date || selectedLayer.getDate(),
                    lat: lat,
                    lng: lng,
                    // 'interval': 15 // Start date = End date - Interval (in days) - Default 30
                },
                panelClass: 'time-series-dialog',
            });
        } else {
            this.snackBar.present('No product selected');
        }
    }

    /**
     * Set base layer
     * @param baseLayer New base layer
     */
    changeBaseLayer(baseLayer: string) {
        if (this.baseLayers[baseLayer] !== undefined) {
            this.map.removeLayer(this.baseLayers[this.currentLayer].layer);
            this.currentLayer = baseLayer;
            this.map.addLayer(this.baseLayers[this.currentLayer].layer);
            const compareMode = this.layersService.getLayersCompareMode();
            if (compareMode === 'spy') {
                for (let i = 0; i < this.spyModeLayers.length; i++) {
                    this.map.removeLayer(this.spyModeLayers[i]);
                }
                if (this.spyModeBaseLayer !== undefined) {
                    this.map.removeLayer(this.spyModeBaseLayer);
                }
                if (this.spyModeBorder) {
                    this.map.removeLayer(this.spyModeBorder);
                }

                const layersCompareOrder = this.layersService.getLayersCompareOrder();
                this.initSpyModeLayers(layersCompareOrder);
            }
            this.refreshCoordinatesDialog();
        }
    }

    /**
     * Set legend mode
     * @param newMode New legend display mode
     */
    changeLegendMode(newMode) {
        this.legendMode = newMode.value;
    }

    /**
     * Get the map reference when it's ready.
     *
     * @param {Map} map: leaflet map object.
     */
    onMapReady(map: L.Map) {
        this.map = map;

        this.resize();

        if (this.embed) {
            const paramsIndex = this.router.url.indexOf('?');

            if (paramsIndex > -1) {
                // Share link
                this.initEmbedMap(this.router.url.substr(paramsIndex + 1));
            }
        }

        if (this.deviceService.isDesktop() && !this.embed) {
            this.measurePane = this.map.createPane('measurePane');
            this.measurePane.style.zIndex = this.zIndexPanes['measurePane'];
            this.measureControlRef = (L as any).control
                .polylineMeasure(this.measureOptions)
                .addTo(this.map);
            this.map.on('polylinemeasure:toggle', (e: any) => {
                if (this.measureControlRef._measuring) {
                    this.disableMapClick();
                    this.googleAnalyticsService.eventEmitter(
                        'measure_tool_enabled',
                        'measure_tool',
                        'measure_tool_switch',
                        'measure_tool_on'
                    );
                } else {
                    this.enableMapClick();
                    this.googleAnalyticsService.eventEmitter(
                        'measure_tool_disabled',
                        'measure_tool',
                        'measure_tool_switch',
                        'measure_tool_off'
                    );
                }
            });
        }

        // Click event
        this.setMapClick();

        // TODO: Popup doesn't move with the map
        map.on('resize', (e: any) => {
            this.closePopup();
        });

        map.on('movestart', (e: any) => {
            this.closePopup();
        });
        map.on('zoomstart', (e: any) => {
            this.closePopup();
        });

        map.on('zoomend', (e: any) => {
            const compareMode = this.layersService.getLayersCompareMode();
            const layersCompareOrder = this.layersService.getLayersCompareOrder();
            if (layersCompareOrder.length > 0 && compareMode === 'spy') {
                const spyModeSize = this.layersService.getSpyModeSize();
                this.setSpyModeBorder(this.spyModeCenterPoint, spyModeSize, 'spyModePane');
            }
        });

        // attach 'moveend' event to viewport service update
        fromEvent(map, 'moveend')
            .pipe(debounceTime(1000))
            .subscribe((data) => {
                const bounds = this.map.getBounds();
                this.viewportService.set({
                    latMin: bounds.getSouth(),
                    latMax: bounds.getNorth(),
                    lonMin: bounds.getWest(),
                    lonMax: bounds.getEast(),
                    zoom: this.map.getZoom(),
                });
            });

        // Disable double click zoom
        map.doubleClickZoom.disable();

        this.mapMarker.addTo(this.map); // Search map marker

        if (!this.hideMinimap) {
            // This should be: L.Control.MiniMap() but there is no typings for it
            const minimap = new MiniMap(
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
                { zoom: 5 }
            );
            const scale = L.control.scale(<L.Control.ScaleOptions>{
                position: 'bottomright',
            });

            this.userService.userSettings$.subscribe((settings: any) => {
                const coordSystem =
                    settings['settings'] && settings['settings']['coordinateSystem']
                        ? settings['settings']['coordinateSystem']
                        : 'EPSG:4326';

                const options = {
                    position: 'bottomright',
                    separator: ' ',
                    emptyString: '- N - W',
                    lngFirst: false,
                    numDigits: 5,
                    latLngFormatter: (lat, lng) => {
                        return parseCoordinates(lng, lat, coordSystem, this.degreeMinuteSecond);
                    },
                    prefix: '',
                };
                this.coordinatesOverlay = (L as any).control.mousePosition(options);
                this.coordinatesOverlay.addTo(this.map);
            });

            // Patch to re-calculate the map size after flexLayout gets its final size
            setTimeout(() => {
                minimap.addTo(this.map);
                scale.addTo(map);
            }, 20);
        }

        this.checkInitialLoading('map');
        // Prevent "Expression has changed after it was checked" with `setTimeout`
        setTimeout(() => {
            this.mapReady.emit(map);
        }, 0);
    }

    /**
     * Initialize map from URL parameters.
     */
    initMapWithUrlPrams(params) {
        ShareDialogComponent.loadShareLink(
            params,
            this.productService,
            this.layersService,
            this.embedService,
            this.userService,
            this.snackBar,
            this.authService
        ).subscribe((shareLinkParams: any) => {
            if (shareLinkParams !== null) {
                if (shareLinkParams.type === 'showErrorDialog') {
                    this.checkInitialLoading('link');
                    this.dialogService.openComponent<DismissComponent, DismissData, DismissResult>(
                        DismissComponent,
                        {
                            data: {
                                title: 'Invalid share link',
                                closeMessage: 'Close',
                                message: shareLinkParams.value,
                                icon: 'fa-share-alt',
                            },
                        }
                    );
                } else {
                    this.map.setView([shareLinkParams.y, shareLinkParams.x], shareLinkParams.z);
                    this.changeBaseLayer(shareLinkParams.baseLayer);
                    if (!isNaN(shareLinkParams.roi)) {
                        this.layersService.layers$.subscribe((layerStatus) => {
                            if (this.openRoiDashboardOnInit) {
                                this.roiService
                                    .getRegion(shareLinkParams.roi)
                                    .subscribe((roi: Region) => {
                                        if (roi.id !== null) {
                                            this.dashboardComponent.open(roi);
                                            this.setSidebar(
                                                document.createEvent('MouseEvents'),
                                                'dashboard',
                                                () => this.dashboardComponent.resize(),
                                                true
                                            );
                                        } else {
                                            this.snackBar.present(
                                                'The share link is not valid.',
                                                'error'
                                            );
                                            this.checkInitialLoading('link');
                                        }
                                        this.openRoiDashboardOnInit = false;
                                    });
                            }
                        });
                    }
                }
            } else {
                this.snackBar.present('The share link is not valid.', 'error');
                this.checkInitialLoading('link');
            }
        });
    }

    /**
     * Load the default user product, its availability and the area allowed.
     */
    private userDataInitialization(response) {
        // Show user area allowed
        const areaAllowedPane = this.map.createPane('areaAllowedPane');
        areaAllowedPane.style.zIndex = this.zIndexPanes['areaAllowedPane'];
        this.allowedArea = response.allowedArea;
        const userAreaAllowed = L.geoJSON(this.allowedArea, <L.PathOptions>{
            pane: 'areaAllowedPane',
            color: '#FFF',
            fillOpacity: 0,
            dashArray: '5,10',
            opacity: 0.75,
        });
        userAreaAllowed.setStyle({ className: 'area-allowed-path' });
        this.map.addLayer(userAreaAllowed);

        const paramsIndex = this.router.url.indexOf('?');

        this.userService.userSettings$.pipe(take(1)).subscribe((settings: any) => {
            if (paramsIndex > -1) {
                // Share link
                if (this.userForApi) {
                    const searchParams = new URLSearchParams(this.router.url);
                    const username = searchParams.get('username');
                    if (this.userForApi === username) {
                        this.initMapWithUrlPrams(this.router.url.substr(paramsIndex));
                        this.changeGrid(settings.settings.grid);
                    } else {
                        const deleteDialogRef = this.dialogService.openConfirm(
                            'End impersonation',
                            'You are impersonating ' +
                                this.userForApi +
                                '.' +
                                ' Would you like to end impersonation before loading shared link?',
                            'fa-users',
                            'End impersonation',
                            'Keep impersonation'
                        );
                        deleteDialogRef.afterClosed().subscribe((confirm) => {
                            if (confirm) {
                                this.forgetUserForApi();
                            } else {
                                this.initMapWithUrlPrams(this.router.url.substr(paramsIndex));
                                this.changeGrid(settings.settings.grid);
                            }
                        });
                    }
                } else {
                    this.initMapWithUrlPrams(this.router.url.substr(paramsIndex));
                    this.changeGrid(settings.settings.grid);
                }
            } else {
                this.layersControlComponent.loadUserDefaultProduct();
                this.changeBaseLayer(settings.settings.baseLayer);
                this.changeGrid(settings.settings.grid);
            }
        });
    }

    /**
     * Add a GeoJSON to the map.
     * @param {Region} region Region object.
     */
    addRegion(region: Region) {
        // GeoJSON
        const data: GeoJSON = <GeoJSON>region.geojson;
        const roiPane = this.map.createPane('roiPane');
        roiPane.style.zIndex = this.zIndexPanes['roiPane'];
        // Create the Leaflet Layer
        const geoJSONLayer = L.geoJSON(data, this.roiDefaultStyle);
        geoJSONLayer.options['region_id'] = region.id;
        // Layer events
        geoJSONLayer.on('mouseover', (event: L.LeafletEvent) => {
            if (!this.editionModes['shapeEditMode']) {
                geoJSONLayer.setStyle(this.roiOverStyle);
            }
        });
        geoJSONLayer.on('mouseout', (event: L.LeafletEvent) => {
            if (!this.editionModes['shapeEditMode'] && geoJSONLayer != this.roiSelected) {
                geoJSONLayer.setStyle(this.roiDefaultStyle);
            }
        });
        geoJSONLayer.on('click', (event: L.LeafletEvent) => {
            if (!this.editionModes['shapeEditMode']) {
                this.removeROIStyleSelected();
                this.roiSelected = geoJSONLayer;
                geoJSONLayer.setStyle(this.roiOverStyle);
                this.mapPopupData.roi = region;
            }
        });

        this.roiLayerGroup.addLayer(geoJSONLayer);
    }

    /**
     * Method triggered when a shape on the sidenav is clicked
     * @param {Region} region the clicked region
     * @param {Array<number>} withOffset offset for the panning [x, y], in pixels
     */
    shapeClicked(region: Region, withOffset: Array<number> = [0, 0]) {
        // Un-highlight previously selected ROI
        this.removeROIStyleSelected();

        // search current geoJSON layer and emit the click event
        for (const layer of this.roiLayerGroup.getLayers()) {
            const geoJSONLayer: L.GeoJSON = <L.GeoJSON>layer;
            if (<number>geoJSONLayer.options['region_id'] === region.id) {
                geoJSONLayer.fireEvent('click');

                // Center the map when the ROI is clicked (Sidenav only)
                this.panWithOffset(this.map, geoJSONLayer.getBounds().getCenter(), withOffset);
            }
        }
    }

    /**
     * Pan to a Leaflet map with a defined offset (array of [horiz, vert] offset
     * @param {Map} map Leaflet map to pan
     * @param latlng Position to pan to
     * @param offset Offset array [horiz, vert]
     * @return {Map} Panned map
     */
    panWithOffset(map: L.Map, latlng, offset) {
        const x = map.latLngToContainerPoint(latlng).x - offset[0];
        const y = map.latLngToContainerPoint(latlng).y - offset[1];
        const point = map.containerPointToLatLng([x, y]);
        return map.setView(point, map.getZoom());
    }

    /**
     * Method triggered when a shape on the sidenav is hovered
     * @param {Region} region the hovered region
     */
    highlightRegion(region: Region | null) {
        this.roiLayerGroup.eachLayer((layer: L.GeoJSON) => {
            // If the region id is blank (un-hover event) just highlight the ROI selected (if any)
            if (region === null && this.roiSelected) {
                layer.setStyle(this.roiDefaultStyle);

                this.roiSelected.setStyle((feature: any) => {
                    return this.roiOverStyle;
                });
            } else {
                // Else, highlight the layer that corresponds with the region
                if (<number>layer.options['region_id'] !== region?.id) {
                    layer.setStyle(this.roiDefaultStyle);
                } else {
                    layer.setStyle(this.roiOverStyle);
                }
            }
        });
    }

    /**
     * Refresh layers
     */
    refreshLayers() {
        this.initLayers();
        this.closePopup();
    }

    /**
     * Adjust the size of the map to fit with the parent controller
     */
    public resize(): void {
        // Patch to re-calculate the map size after flexLayout gets its final size
        setTimeout(() => {
            this.map.invalidateSize();
        }, 20);
    }

    /**
     * Remove the selected style from all the ROI
     */
    private removeROIStyleSelected(): void {
        this.roiLayerGroup.eachLayer((layer: L.GeoJSON) => {
            layer.setStyle(this.roiDefaultStyle);
        });
    }

    /**
     * Close the sidenav.
     */
    public closeSidenav() {
        this.endSidenav.close();
        this.lateralMenuStatus = 'closed';
        if (this.sidebarType === 'roi') {
            this.roiSidenavComponent.close();
        }
        this.sidebarType = undefined;
    }

    /**
     * Open the sidenav.
     */
    public openSidenav() {
        if (this.sidebarType === 'roi') {
            this.roiSidenavComponent.open();
            this.googleAnalyticsService.eventEmitter(
                'roi_sidenav_opened',
                'sidenavs',
                'open_sidenav',
                'roi_sidenav'
            );
        }
        if (this.sidebarType === 'embed') {
            this.embedSidenavComponent.open();
            this.googleAnalyticsService.eventEmitter(
                'embed_sidenav_opened',
                'sidenavs',
                'open_sidenav',
                'embed_sidenav'
            );
        }
        if (this.sidebarType === 'evolution') {
            this.animationApiRequestSidenavComponent.open();
            this.googleAnalyticsService.eventEmitter(
                'animation_sidenav_opened',
                'sidenavs',
                'open_sidenav',
                'animation_sidenav'
            );
        }
        if (this.sidebarType === 'apiAccess') {
            this.apiAccessComponent.open();
            this.googleAnalyticsService.eventEmitter(
                'api_access_sidenav_opened',
                'sidenavs',
                'open_sidenav',
                'api_access_sidenav'
            );
        }
    }

    /**
     * Sets the sidebar type
     * @param e Click event
     * @param identifier Can be 'roi', 'product' or 'apiAccess'
     * @param callback Callback function for when the sidebar is open
     * @param forceOpen Do not toggle, but always open the section
     */
    setSidebar(e, identifier, callback = () => {}, forceOpen = false) {
        e.stopPropagation();

        if (this.sidebarType === 'roi' && identifier === 'dashboard') {
            this.roiSidenavComponent.close();
        }

        if (this.sidebarType === identifier && !forceOpen) {
            this.closeSidenav();
        } else {
            this.sidebarType = identifier;
            this.lateralMenuStatus = identifier === 'dashboard' ? 'openDashboard' : 'open';
            this.endSidenav.open().then(() => {
                callback();
            });
        }
    }

    /**
     * Get the current viewport width
     * @return {number} Current viewport width
     */
    private getViewportWidth() {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

    /**
     * Set the map click event handler.
     */
    setMapClick() {
        if (!this.embed) {
            this.map.off('click');
            this.map.on('click', (e) => {
                this.showPopupOnMapClick(e);
            });
        }
    }

    /**
     * Generate the share map link.
     */
    shareMap(embed = false, bounds?, description?) {
        const shareLinkMetadata = <ShareMetadata>{
            layers: this.layersService.getLayers(),
            layerCompareOrder: this.layersService.getLayersCompareOrder(),
            center: this.map.getCenter(),
            zoom: this.map.getZoom(),
            linkedLayers: this.layersService.getLinkedLayers().map((l) => l.key),
            selectedLayer: this.layersService.getSelectedLayerKey(),
        };

        shareLinkMetadata.layerOrder = this.layersService.getLayersOrder().slice();
        shareLinkMetadata.layerOrder.reverse();

        // Set the base layer if it's not the default one
        if (this.currentLayer !== DEFAULT_BASE_LAYER) {
            shareLinkMetadata.baseLayer = this.currentLayer;
        }

        if (this.dashboardComponent.region !== undefined && this.sidebarType === 'dashboard') {
            shareLinkMetadata.roi = this.dashboardComponent.region.id;
        }
        const dialogRef = this.dialogService.openComponent<
            ShareDialogComponent,
            ShareDialogData,
            ShareDialogResult
        >(ShareDialogComponent, {
            data: { shareLinkMetadata, embed, bounds, description },
        });
        this.googleAnalyticsService.eventEmitter(
            'share_button_clicked',
            'share_embed',
            'open_share_dialog',
            'share_button_clicked'
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (this.editionModes['captureRegionMode'] && result) {
                this.cancelBoundingBox();
            }
            // reload list of embed links in the embed sidenav
            if (embed) {
                this.embedSidenavComponent.listEmbedLinks();
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // MAP POPUP
    // -----------------------------------------------------------------------------------------------------------------

    /**
     * Closes the popup.
     */
    closePopup() {
        this.ngZone.run(
            // Change detection outside angular
            () => {
                this.mapPopupComponent.close();
            }
        );
    }

    /**
     * Open the popup.
     */
    private openPopup() {
        this.ngZone.run(
            // Change detection outside angular
            () => {
                this.mapPopupComponent.open();
            }
        );
    }

    /**
     * Popup button click event.
     * @param event
     */
    handlePopupClick(event) {
        let closeDialog = false;
        switch (event.type) {
            case ClickType.TimeSeries:
                this.loadTimeSeries(event.lat, event.lng, event.product, event.date);
                closeDialog = true;
                break;
            case ClickType.Dashboard:
                const roi = event.roi;

                this.dashboardComponent.open(roi);

                this.setSidebar(
                    document.createEvent('MouseEvents'),
                    'dashboard',
                    () => this.dashboardComponent.resize(),
                    true
                );

                this.shapeClicked(
                    roi,
                    // Offset to -1/4 of the width of the viewport (as the Dasboard occupies the 50%)
                    [-this.getViewportWidth() / 4, 0]
                );
                closeDialog = true;
                break;
        }
        if (closeDialog) {
            this.closePopup();
        }
    }

    /**
     * Map click handler: shows a popup in the given lat/lng.
     *
     * @param e Event
     */
    showPopupOnMapClick(e) {
        this.closePopup();

        const selectedLayer = this.layersService.getSelectedLayer();
        if (selectedLayer !== undefined) {
            const latlng = e.latlng;
            const parentROI =
                (e.originalEvent.target.nodeName === 'path' &&
                    !e.originalEvent.target.classList.contains('area-allowed-path')) ||
                this.spyInsideRoi;
            this.mapPopupData.lat = latlng.lat;
            this.mapPopupData.lng = latlng.lng;
            this.mapPopupData.degreeMinuteSecond = this.degreeMinuteSecond;
            this.mapPopupData.x = e.containerPoint.x;
            this.mapPopupData.y = e.containerPoint.y;
            this.mapPopupData.insideRoi = parentROI;
            this.mapPopupData.value = 'Loading...';
            this.mapPopupData.width = this.el.nativeElement.children[0].offsetWidth;
            this.mapPopupData.unit = selectedLayer.product.unit;
            this.openPopup();
        }
        this.spyInsideRoi = false;
    }

    /**
     * Search place handler
     *
     * @param data {PlaceResult} place details result
     */
    onPlaceSelected(data: PlaceResult) {
        const viewport = data.geometry.viewport;
        const center = data.geometry.location;

        this.map.fitBounds([
            [viewport.getNorthEast().lat(), viewport.getNorthEast().lng()],
            [viewport.getSouthWest().lat(), viewport.getSouthWest().lng()],
        ]);

        this.mapMarker.setOpacity(1);
        this.mapMarker.setLatLng([center.lat(), center.lng()]);
    }

    /**
     * Set the user's view to his allowed area
     */
    setAllowedAreaMapView() {
        const userAreaAllowed: GeoJSON | null = JSON.parse(JSON.stringify(this.allowedArea));
        let userAreaAllowedCoordinates;
        if (userAreaAllowed) {
            userAreaAllowedCoordinates = getCoordinatesFromGeometry(userAreaAllowed);
        }
        const coordinates = [];
        if (userAreaAllowedCoordinates) {
            for (let i = 0; i < userAreaAllowedCoordinates.length; i++) {
                coordinates.push(userAreaAllowedCoordinates[i].reverse());
            }
            const polygon = L.polygon(coordinates);
            this.map.fitBounds(polygon.getBounds());
        } else {
            this.snackBar.present('Error retrieving the allowed area.', 'error');
        }
        this.googleAnalyticsService.eventEmitter(
            'area_allowed_overview_button_clicked',
            'map_controls',
            'area_allowed_overview',
            'area_allowed_overview_button_clicked'
        );
    }

    /**
     * Disable the map click event.
     */
    disableMapClick() {
        this.map.off('click');
    }

    /**
     * Cancel the creation of the embed content
     */
    cancelBoundingBox() {
        this.editionModes['captureRegionMode'] = false;
        // Leaflet Area Select
        this.areaSelect.remove();

        // Enable text select
        document.body.classList.remove('disable-select');
        this.enableMapClick();
    }

    /**
     * Restore the map click event.
     */
    enableMapClick() {
        this.setMapClick();
    }

    /**
     * Initialize map from URL parameters.
     */
    initEmbedMap(embedLink) {
        this.embedService.decodeEmbedLink(embedLink).subscribe((result: EmbedLinkData) => {
            const params = decodeEmbedLinkParams(result.metadataLink);
            const embedLinkParams = loadEmbedLink(
                params,
                result.products,
                result.dates,
                this.layersService,
                result.hash,
                this.embedService
            );
            if (embedLinkParams !== null) {
                this.map.setView([embedLinkParams.y, embedLinkParams.x], embedLinkParams.z);
                this.changeBaseLayer(embedLinkParams.baseLayer);
            } else {
                this.snackBar.present('The embed link is not valid.');
            }
            const leftCorner = L.latLng(result.coordinates[0][0][1], result.coordinates[0][0][0]);
            const rightCorner = L.latLng(result.coordinates[0][2][1], result.coordinates[0][2][0]);
            const bounds = L.latLngBounds(leftCorner, rightCorner);
            const embedAreaAllowedPane = this.map.createPane('embedAreaAllowedPane');
            embedAreaAllowedPane.style.zIndex = this.zIndexPanes['embedAreaAllowedPane'];
            const boundsLayer = L.rectangle(bounds, {
                pane: 'embedAreaAllowedPane',
                color: '#FFF',
                fillOpacity: 0,
                dashArray: '5,10',
                opacity: 0.75,
            });

            this.map.addLayer(boundsLayer);
            this.map.fitBounds(bounds);
        });
    }

    /**
     * Set the spy mode depending on the device (drag and drop for small devices)
     */
    setSpyMode() {
        const spyModeSize = this.layersService.getSpyModeSize();
        if (!this.dragMovementEnabled) {
            this.map.getPane(this.initialPane).style.zIndex = this.zIndexPanes['spyModeBorderPane'];
            this.map.off('mousemove');
            this.spyModeBorder.on('mousedown', (e) => {
                this.map.dragging.disable();
                this.map.off('click');
                this.map.on('mousemove', (newCenterPoint) => {
                    const centerPoint = (<any>newCenterPoint).containerPoint;
                    this.spyModeCenterPoint = (<any>newCenterPoint).containerPoint;
                    this.setSpyModeBorder(centerPoint, spyModeSize, this.initialPane);
                    this.spyModeBaseLayer.setCenter(centerPoint);
                    for (let i = 0; i < this.spyModeLayers.length; i++) {
                        this.spyModeLayers[i].setCenter((<any>newCenterPoint).containerPoint);
                    }
                });
            });
            this.spyModeBorder.on('mouseup', (mouseUp) => {
                this.map.off('mousemove');
                this.map.dragging.enable();
                this.setMapClick();
            });
            this.spyModeBorder.on('click', (e) => {
                for (const layer of this.roiLayerGroup.getLayers()) {
                    const geoJSONLayer: any = <L.GeoJSON>layer;
                    const coordinates = getCoordinatesFromGeometry(
                        geoJSONLayer._layers[geoJSONLayer._leaflet_id - 1].feature.geometry
                    );
                    if (pointInPolygon(e.latlng.lng, e.latlng.lat, coordinates)) {
                        if (!this.editionModes['shapeEditMode']) {
                            this.roiService
                                .getRegion(geoJSONLayer.options.region_id)
                                .subscribe((region: Region) => {
                                    this.removeROIStyleSelected();
                                    this.roiSelected = geoJSONLayer;
                                    this.mapPopupData.roi = region;
                                });
                            this.spyInsideRoi = true;
                        }
                    }
                }
            });
        } else {
            this.spyModeBorder.off('mousedown');
            this.spyModeBorder.off('mouseup');
            this.spyModeBorder.off('click');
            this.map.getPane(this.initialPane).style.zIndex = this.zIndexPanes['spyModePane'];
            this.map.on('mousemove', (e) => {
                const centerPoint = (<any>e).containerPoint;
                this.spyModeCenterPoint = (<any>e).containerPoint;
                this.setSpyModeBorder(centerPoint, spyModeSize, this.initialPane);
                this.spyModeBaseLayer.setCenter((<any>e).containerPoint);
                for (let i = 0; i < this.spyModeLayers.length; i++) {
                    this.spyModeLayers[i].setCenter((<any>e).containerPoint);
                }
            });
        }
    }

    /**
     * Create the spy mode border from the center point of the spy mode window
     * @param centerPoint
     * @param spyModeSize
     * @param pane
     */
    setSpyModeBorder(centerPoint, spyModeSize, pane) {
        if (centerPoint !== undefined) {
            const northEastPoint =
                spyModeSize < 256
                    ? L.point(centerPoint.x + spyModeSize / 2, centerPoint.y + spyModeSize / 2)
                    : L.point(
                          centerPoint.x + spyModeSize / 2 - 3,
                          centerPoint.y + spyModeSize / 2 - 3
                      );
            const southWestPoint =
                spyModeSize < 256
                    ? L.point(centerPoint.x - spyModeSize / 2, centerPoint.y - spyModeSize / 2)
                    : L.point(
                          centerPoint.x - spyModeSize / 2 + 3,
                          centerPoint.y - spyModeSize / 2 + 3
                      );
            const latLngNorthEastPoint = this.map.containerPointToLatLng(northEastPoint);
            const latLngSouthWestPoint = this.map.containerPointToLatLng(southWestPoint);

            const rectangle = L.rectangle(
                [
                    [latLngNorthEastPoint.lat, latLngNorthEastPoint.lng],
                    [latLngSouthWestPoint.lat, latLngSouthWestPoint.lng],
                ],
                { pane: pane, color: '#000000', fillOpacity: 0 }
            );
            this.spyModeBorder.setLatLngs((<any>rectangle)._latlngs);
        }
    }

    /**
     * Generate Embed Link input event handler
     * @param event
     */
    generateEmbedLink(event) {
        this.shareMap(event.embed, event.bounds, event.description);
    }

    /**
     * Set the grid.
     * @param grid New grid
     */
    changeGrid(grid: string) {
        if (this.currentGrid !== 'none') {
            this.map.removeLayer(this.grids[this.currentGrid].grid);
        }

        if (this.grids[grid] !== undefined) {
            this.currentGrid = grid;
            this.map.addLayer(this.grids[this.currentGrid].grid);
        } else {
            this.currentGrid = 'none';
        }
    }

    /**
     * Method for deactivating the measure tool
     */
    deactivateMeasureTool() {
        if (this.measureControlRef && this.measureControlRef._measuring) {
            this.measureControlRef._toggleMeasure();
            this.googleAnalyticsService.eventEmitter(
                'measure_tool_disabled',
                'measure_tool',
                'measure_tool_switch',
                'measure_tool_off'
            );
        }
    }

    /**
     * Remove and the control to the map to attach the move mouse event to this control
     */
    refreshCoordinatesDialog() {
        // We have to remove and add the coordinates overlaye every time a new layer event is triggered to set the movemouse event to
        // the L.control.
        if (this.coordinatesOverlay) {
            this.coordinatesOverlay.remove();
            this.coordinatesOverlay.addTo(this.map);
            const coordinatesOverlayElement: any = document.getElementsByClassName(
                'leaflet-control-mouseposition'
            );
            const coordinateSystem = this.userSettings?.settings?.coordinateSystem ?? 'EPSG:4326';
            if (this.baseLayers[this.currentLayer].openStreetMapBaseLayer) {
                if (coordinateSystem === 'EPSG:4326') {
                    coordinatesOverlayElement[0].className =
                        'leaflet-control-mouseposition leaflet-control default-coordinates';
                } else {
                    coordinatesOverlayElement[0].className =
                        'leaflet-control-mouseposition leaflet-control modified-coordinates';
                }
            }
            if (coordinatesOverlayElement[0]) {
                coordinatesOverlayElement[0].ondblclick = () => {
                    this.degreeMinuteSecond = !this.degreeMinuteSecond;
                    this.refreshCoordinatesDialog();
                    return true;
                };
            }
        }
    }

    /**
     * Remove userForApi cookie and local storage and refresh the page to end user impersonation.
     */
    forgetUserForApi() {
        const oldUserForApi = this.userForApi;

        this.authService.setUserForApi(undefined);
        this.userForApi = undefined;
        let url = window.location.href;
        const searchParams = new URLSearchParams(url);
        const username = searchParams.get('username');

        if (url.indexOf('?') > -1 && username === oldUserForApi) {
            url = url.split('?')[0];
            window.location.href = url;
        } else {
            window.location.reload();
        }
    }
}
