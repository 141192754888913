import moment from 'moment';
import { Moment } from 'moment';
import {
    binarySearch,
    cmpMoment,
    findNearestDate,
    findNearestDateBound,
    getUTCDateWithoutConversion,
} from '../utils/utils';

/**
 * Product availability class
 */
export class ProductAvailability {
    /**
     * Availability list (UTC Dates)
     */
    public availability: Array<Moment>;

    /**
     * Default constructor
     * @param {any[]} availability array of interval objects {start_date, end_date}
     */
    constructor(availability: moment.MomentInput[]) {
        this.availability = availability.map((i) => moment.utc(i));
    }

    /**
     * Checks whether the availability has no intervals
     */
    isEmpty(): boolean {
        return this.availability.length === 0;
    }

    /**
     * Checks whether the given date is included in any availability interval.
     * This method is defined with fat arrow because it is used as a callback
     * for material datepicker, which doesn't support 'this'.
     * @param {Moment} date
     * @returns {boolean}
     */
    isIncluded = (date: Moment | null): boolean => {
        // Material datepicker dates are local to the user, so we need the 'literal' day
        const searchElement = getUTCDateWithoutConversion(date);
        return this.availability.some((currentElement) =>
            searchElement.isSame(currentElement, 'day')
        );
    };

    /**
     * Returns an array with available days as iso string dates
     */
    toStringArray(): string[] {
        const days = [];
        for (const interval of this.availability) {
            const current = interval;
            while (current.isBefore(interval, 'day') || current.isSame(interval, 'day')) {
                days.push(current.format('YYYY/MM/DD'));
                current.add(1, 'days');
            }
        }
        return days;
    }

    /**
     * Calculates the nearest date to target
     */
    findNearestDate(target) {
        return findNearestDate(target, this.availability)[1];
    }

    /**
     * Find the nearest date to the target, specifying the lower/upper bound. The target is not within the array.
     */
    findNearestDateBound(date: Moment, bound: 'lower' | 'upper') {
        return findNearestDateBound(date, this.availability, bound);
    }

    /**
     * Find a element that is at some distance (offset) from other. Return that element or null if the base element
     * can't be in the array.
     *
     * @param {moment.Moment} date
     * @param {number} offset
     * @returns {moment.Moment}
     */
    getOffsetBound(date: Moment, offset: number): Moment {
        const currentIndex = binarySearch(this.availability, date, cmpMoment);

        if (currentIndex === -1) {
            return null;
        }

        let nextIndex = currentIndex + offset;

        if (nextIndex >= this.availability.length) {
            nextIndex = this.availability.length - 1;
        } else if (nextIndex < 0) {
            nextIndex = 0;
        }

        return this.availability[nextIndex];
    }
}
