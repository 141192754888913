import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, first } from 'rxjs';
import { environment } from '../../environments/environment';

import { Product } from '../../models/product';
import { ProductService } from '../../services/product.service';
import { UserProductMultiSelectService } from '../../services/user-product-multi-select.service';

@Component({
    selector: 'app-user-product-multi-select',
    templateUrl: './user-product-multi-select.component.html',
    styleUrls: ['./user-product-multi-select.component.scss'],
})
export class UserProductMultiSelectComponent implements OnInit {
    /**
     * Emitter that will be fired when the product time series visibility status is changed
     *
     * @type {EventEmitter<any>}
     */
    @Output() updateProductVisibility: EventEmitter<object> = new EventEmitter<object>();

    /**
     * Maximum of selected units among the selected products
     */
    readonly maxUnits = 2;

    /**
     * Form control for input field
     * @type {FormControl}
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Product list.
     */
    products: Product[] = [];

    /**
     * Observable for product list query
     */
    filteredProducts$: Observable<Product[]>;

    /**
     * Selected product list.
     */
    selectedProducts: Product[] = [];

    /**
     * Observable to update the product list displayed in the autocomplete
     */
    private updatedProductList = new Subject<Product[]>();

    /**
     * Units present in selectedProducts list
     */
    private selectedUnits = new Set<string>();

    /**
     * Units present in selectedProducts array
     */
    public selectedUnitsArray;

    /**
     * Time series left Y axis color
     */
    timeSeriesLeftYColor = environment.timeSeriesLeftYColor;

    /**
     * Time series right Y axis color
     */
    timeSeriesRightYColor = environment.timeSeriesRightYColor;

    /**
     * Object that containts product time series visibility status
     */
    productTimeSeriesStatus = {};

    /**
     * Load status of the time series
     */
    timeSeriesStatus = false;

    /**
     * Default constructor
     * @param productService
     * @param productSelectService
     */
    constructor(
        private productService: ProductService,
        private productSelectService: UserProductMultiSelectService
    ) {}

    ngOnInit() {
        this.productSelectService
            .watchProductSelected()
            .pipe(first())
            .subscribe((products) => {
                // get only the first current value of selected products
                this.initialize(products);
            });
    }

    /**
     * Initialize the component with a pre selection of products
     *
     * @param selected
     */
    private initialize(selected: Product[]) {
        this.productService.list().subscribe((data: Product[]) => {
            let preSelected = new Set([]);

            if (selected) {
                this.selectedProducts = selected;
                preSelected = new Set(this.selectedProducts.map((p) => p.apiName));
            }

            for (const product of this.selectedProducts) {
                this.productTimeSeriesStatus[product.name] = true;
            }

            this.products = data
                .filter((p) => !preSelected.has(p.apiName))
                .map((prodData) => prodData);

            this.filteredProducts$ = merge(
                this.searchControl.valueChanges.pipe(
                    startWith(''),
                    debounceTime(100),
                    distinctUntilChanged(),
                    map((query) => this.filterProducts(query))
                ),
                this.updatedProductList
            );
            this.updateSelectedUnits();
        });
    }

    /**
     * Callback for product select change
     * @param event Change event containing the new product
     */
    onProductChange(event) {
        if (event.option.value === undefined) {
            return;
        }
        const product = event.option.value;
        this.productTimeSeriesStatus[product.name] = true;
        // add the product to the list of selected
        this.moveToList(product, this.products, this.selectedProducts);
        // clear the input field and update the search list
        this.searchControl.setValue('');
        this.updateSelectedUnits();
        this.updatedProductList.next(this.products);
    }

    /**
     * Returns product name for display
     */
    displayProduct(prod: Product): string {
        return prod ? `${prod.apiName}` : undefined;
    }

    /**
     * Remove product from chip list
     */
    remove(prod: Product) {
        this.moveToList(prod, this.selectedProducts, this.products);
        this.updateSelectedUnits();
        this.updatedProductList.next(this.products);
    }

    /**
     * Hide product time series from chart
     */
    hideProduct(product: Product) {
        this.productTimeSeriesStatus[product.name] = !this.productTimeSeriesStatus[product.name];
        const emit = {
            product,
            show: this.productTimeSeriesStatus[product.name],
        };
        this.updateProductVisibility.emit(emit);
    }

    /**
     * Get the visible status of the product time series
     */
    getProductTimeSeriesStatus() {
        return this.productTimeSeriesStatus;
    }

    /**
     * Move a product between lists, the product is removed from listFrom and added to listTo
     *
     * @param prod
     * @param listFrom
     * @param listTo
     */
    private moveToList(prod: Product, listFrom: Product[], listTo: Product[]) {
        let index = listFrom.findIndex((p) => p.apiName === prod.apiName);
        if (index >= 0) {
            listFrom.splice(index, 1);
        }

        index = listTo.findIndex((p) => p.apiName === prod.apiName);
        if (index < 0) {
            listTo.push(prod);
        }
    }

    /**
     * Filter product list
     *
     * @param query
     */
    private filterProducts(query): Product[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof Product) {
            query = query.name;
        }
        const filterValue = query.toLowerCase();
        return this.products.filter((prod) => {
            return prod.name && prod.name.toLowerCase().includes(filterValue);
        });
    }

    /**
     * Update selected units set
     */
    private updateSelectedUnits() {
        this.selectedUnits = new Set<string>(this.selectedProducts.map((p) => p.unit));
        this.selectedUnitsArray = Array.from(this.selectedUnits);
    }

    /**
     * Checks whether the product is selectable, according to the units criteria
     * @param product
     */
    public isSelectable(product) {
        return this.selectedUnits.size >= this.maxUnits && !this.selectedUnits.has(product.unit);
    }

    /**
     * Set the load status of the time series (true/false)
     * @param status
     */
    timeSeriesLoadedStatus(status) {
        this.timeSeriesStatus = status;
    }
}
