<div class="label-management-wrapper flex flex-col">
    <div class="dialog-header flex justify-between items-center">
        <div>
            <span class="title">Label edit</span>
        </div>
        <div>
            <button class="dismiss-button" mat-raised-button color="warn" (click)="close()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="labels-data flex flex-col gap-4">
        <mat-form-field class="chip-list">
            <mat-chip-listbox>
                <mat-chip-option
                    class="label"
                    [ngStyle]="{
                        color: label.color,
                        backgroundColor: label.color
                    }"
                    [selectable]="false"
                    [removable]="false"
                    [matTooltip]="label.description"
                >
                    <span>{{ label.title }}</span>
                </mat-chip-option>
            </mat-chip-listbox>
        </mat-form-field>
    </div>
    <div class="new-label-form flex flex-col">
        <h4>Edit Label</h4>
        <form [formGroup]="editLabelForm">
            <mat-form-field>
                <input
                    matInput
                    [matTooltip]="'Title'"
                    placeholder="Title"
                    formControlName="title"
                />
                <mat-error>A title for the label is required.</mat-error>
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    [matTooltip]="'Description'"
                    placeholder="Description"
                    formControlName="description"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    [matTooltip]="'Color'"
                    placeholder="Color"
                    formControlName="color"
                />
            </mat-form-field>
            <color-github
                class="color-picker"
                color="editLabelForm['color']"
                (onChangeComplete)="colorChanged($event)"
            ></color-github>
        </form>
    </div>
    <div class="dialog-actions flex justify-end items-end">
        <button
            class="roi-accept"
            mat-raised-button
            (click)="saveLabel()"
            color="accent"
            [disabled]="!editLabelForm.valid"
        >
            <div class="flex justify-center items-center">
                <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;Update label
            </div>
        </button>
    </div>
</div>
