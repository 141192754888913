import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize } from 'cerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { environment } from '../environments/environment';
import { ApiRequest, ApiRequestStatus } from '../models/api-request';

/**
 * Model for each row of the Region table.
 */
export interface ApiRequestsListInterface {
    requests: ApiRequest[];
    total: number;
}

@Injectable({
    providedIn: 'root',
})
export class ApiRequestsService {
    /** API prefix. */
    protected apiURL = environment.apiV2;

    /** API endpoint with service path. */
    protected route = `${this.apiURL}/api-requests`;

    private http = inject(HttpClient);

    /**
     * Return a stream with the list of api requests.
     *
     * @returns {Observable<Response>}
     */
    list(params?): Observable<ApiRequestsListInterface> {
        return this.http.get(this.route + '/', { params: params }).pipe(
            map((data: object) => {
                const response: ApiRequestsListInterface = {
                    total: data['total_items'],
                    requests: [],
                };
                for (const request of data['requests']) {
                    response['requests'].push(Deserialize(request.api_request, ApiRequest));
                }
                return response;
            })
        );
    }

    /**
     * Return a stream with API Request info
     * @param {string} uuid api request identifier
     * @returns {Observable<Response>}
     */
    getApiRequestInfo(uuid: string): Observable<ApiRequest> {
        return this.http
            .get(`${this.route}/${uuid}`)
            .pipe(map((res: any) => Deserialize(res.api_request, ApiRequest)));
    }

    /**
     * Return a stream with API Request status
     * @param {string} uuid api request identifier
     * @returns {Observable<Response>}
     */
    getApiRequestStatus(uuid: string) {
        return this.http
            .get(`${this.route}/${uuid}/status`)
            .pipe(map((res: any) => Deserialize(res, ApiRequestStatus)));
    }

    /**
     * Download API request data
     * @param uuid
     * @param filename
     */
    downloadApiRequestData(uuid, filename) {
        return this.http.get(`${this.route}/${uuid}/data/${filename}/download`, {
            responseType: 'text',
        });
    }
}
