import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { Region } from '../../models/region';
import { Product } from '../../models/product';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

/**
 * Simple component base URL
 */
const BASE_SHARE_SIMPLE_URL = `${window.location.protocol}//${window.location.host}/simple?`;

/**
 * Share dialog data input.
 */
export interface ShareSimpleDialogData {
    region: Region;
    product: Product;
    username?: string;
}

export type ShareSimpleDialogResult = true | undefined;

@Component({
    selector: 'app-share-simple-dialog',
    templateUrl: './share-simple-dialog.component.html',
    styleUrls: ['./share-simple-dialog.component.scss'],
})
export class ShareSimpleDialogComponent
    implements
        OnInit,
        DynamicDialog<ShareSimpleDialogComponent, ShareSimpleDialogData, ShareSimpleDialogResult>
{
    /**
     * Simple component map share link.
     */
    public shareLink: string;

    /**
     * Metada for loading the share link
     */
    public dialogData: ShareSimpleDialogData;

    /**
     * Reference to the dynamic dialog.
     */
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<
            ShareSimpleDialogComponent,
            ShareSimpleDialogData,
            ShareSimpleDialogResult
        >,
        ShareSimpleDialogResult
    >;

    constructor(
        private clipboard: ClipboardService,
        private snackbar: CustomSnackbarService
    ) {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.shareLink = BASE_SHARE_SIMPLE_URL;
        if (this.dialogData.region) {
            this.shareLink += 'region=' + this.dialogData.region.id;
        }
        if (this.dialogData.product) {
            this.shareLink += '&product=' + this.dialogData.product.name;
        }
        if (this.dialogData.username) {
            this.shareLink += '&username=' + this.dialogData.username;
        }
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Copy the share link to the user clipboard.
     */
    copyLinkToClipboard() {
        this.clipboard.copyFromContent(this.shareLink);
        this.snackbar.present('Link copied to clipboard.');
        this.dialogRef.close(true);
    }
}
