import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'booltoyesno' })
export class BoolToYesNoPipe implements PipeTransform {
    transform(value: boolean) {
        if (value) {
            return 'Yes';
        } else {
            return 'No';
        }
    }
}
