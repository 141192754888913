import { Component } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import moment from 'moment';
import { Moment } from 'moment';

/**
 * Selector, template and styles for the progress bar component
 */
@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    animations: [
        trigger('hideTransition', [
            transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})

/**
 * Progress bar component
 */
export class ProgressBarComponent {
    /**
     * Measure of the progress when it's completed.
     * @type {number}
     */
    public total = 0;

    /**
     * Measure of the current progress.
     * @type {number}
     */
    public count = 0;

    /**
     * Current progress (%).
     * @type {number}
     */
    public progress = 0;

    /**
     * Progress start time.
     * @type {Date}
     */
    public start_time: Moment = moment();

    /**
     * Progress update time.
     * @type {Date}
     */
    public last_time: Moment = moment();

    /**
     * Estimated time in seconds.
     * @type {number}
     */
    public estimated_time: string;

    /**
     * Progress Bar visibility.
     * @type {boolean}
     */
    public display = true;

    /**
     * Increases the current progress and updates the component parameters.
     * @param {number} amount
     */
    increase(amount = 1) {
        this.count += amount;
        this.last_time = moment();
        this.progress = Math.round((this.count / this.total) * 100);
        const estimated_seconds = Math.round(
            ((this.total - this.count) *
                ((this.last_time.toDate().getTime() - this.start_time.toDate().getTime()) /
                    this.count)) /
                1000
        );

        const duration = moment.duration({
            seconds: estimated_seconds,
        });

        this.estimated_time = duration.humanize();

        if (this.progress >= 100) {
            this.hide();
        }
    }

    /**
     * Set the amount of reference of the total progress.
     * @param {number} amount
     */
    setTotal(amount: number) {
        this.total = amount;
    }

    /**
     * Reset the current status of the progress bar.
     *
     * This method must be called just before measuring any kind of progress.
     */
    reset() {
        this.display = true;
        this.count = 0;
        this.estimated_time = 'unknown yet, PLEASE BE PATIENT';
        this.start_time = moment();
        this.last_time = moment();
        this.progress = 0;
    }

    /**
     * Hide the progressbar
     */
    hide() {
        setTimeout(() => {
            this.display = false;
        }, 1000);
    }
}
