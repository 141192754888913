import { Injectable, NgZone } from '@angular/core';
import { Subject, of as observableOf, tap } from 'rxjs';
import * as L from 'leaflet';
import { Moment } from 'moment';
import moment from 'moment';
import { Md5 } from 'ts-md5/dist/md5';

import { LayerData, LayerKey } from '../models/layer-data';
import { SpecialLayerData } from '../models/specialLayer-data';

import { cmpStr, getISODateString } from '../utils/utils';
import { DamService } from './dam.service';
import { ProductAvailability } from '../models/product-availability';
import { Product } from '../models/product';
import { environment } from '../environments/environment';
import { AuthService } from '../app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { LayerList } from '../models/layer-list';

export interface LayerAddOptions {
    select?: boolean;
    compare?: boolean;
    emit?: boolean;
}

export type CompareMode = 'spy' | 'sideBySide';

@Injectable({
    providedIn: 'root',
})
export class LayersService {
    /**
     * Contain all the product layers added to the map.
     * @type {Map<any, LayerData>}
     */
    private layers: Map<LayerKey, any> = new Map([]);

    /**
     * Array of LayerData keys that keeps the layers ordered.
     */
    private layersOrder: Array<LayerKey> = [];

    /**
     * Array of LayerData keys that keeps the layers compare ordered.
     */
    private layersCompareOrder: Array<LayerKey> = [];

    /**
     * Array of LayerData. The date of the layers in this group are linked in time.
     */
    private linkedLayers: Array<LayerData> = [];

    /**
     * Layer Key generator.
     */
    private layerKeyCount = 0;

    /**
     * Selected layer key.
     */
    private selectedLayerKey: LayerKey = undefined;

    /**
     * Linked layers date.
     */
    private linkedDate;

    /**
     * RxJs Subject that emits the layers status.
     */
    private outputSubjectLayers = new Subject<any>();

    public layers$ = this.outputSubjectLayers.asObservable();

    /**
     * RxJs Subject that emits map redraw event.
     */
    private outputMapRedraw = new Subject<void>();

    /**
     * Emit an event when the tilelayer has to be redrawn but there isn't any model update.
     *
     * [!] Only the map should listen to this event, if you need layer updates see `layers$`.
     */
    public redraw$ = this.outputMapRedraw.asObservable();

    /**
     * List of the user visible ROIs, to prevent tiles from caching.
     */
    private rois: Array<number> = [];

    /**
     * Layer compare mode.
     */
    private layersCompareMode: CompareMode = 'sideBySide';

    /**
     * Special layers included in the map.
     */
    public specialLayers: any = {
        borders: {
            label: 'World borders',
            url: './assets/special_layers/borders.json',
        },
        'NL-1x1-km-grid': {
            label: 'NL 1x1 km grid',
            url: './assets/special_layers/NL-RDgrid1.json',
        },
    };

    /**
     * Object to store the downloaded special layers to avoid making multiple request of the same data.
     */
    public downloadedSpecialLayers: { [layerName: string]: unknown } = {};

    /**
     * Size in pixels of the spy mode window
     */
    private spyModeSize = 256;

    /**
     * Animation Layer Key generator.
     */
    animationLayerKeyCount = 0;

    /**
     * Default constructor.
     */
    constructor(
        private ngZone: NgZone,
        private damService: DamService,
        private authService: AuthService,
        private http: HttpClient
    ) {}

    /**
     * Add a new layer to the map.
     * @param layerItem
     * @param availability
     * @param date
     * @param {LayerAddOptions} options
     * @param layerOptions
     * @param opacity
     * @param hash
     * @returns {LayerData}
     */
    addProductLayer(
        layerItem: any,
        availability: ProductAvailability,
        date: Moment,
        options: LayerAddOptions = {},
        layerOptions = {},
        opacity?,
        hash?
    ): LayerData {
        const layerKey = this.layerKeyCount;
        this.layerKeyCount += 1;
        const _options = { select: false, compare: false, emit: true };
        const layer = new LayerData({
            key: layerKey,
            display: true,
            opacity: opacity ? opacity : 100,
            date: date,
            linked: false,
            showLegend: true,
            loading: false,
            showBorder: true,
            pinned: false,
            available: true,
            availability: availability,
            hash: hash,
        });

        if (layerItem.groupType) {
            const groupOptions = {
                product: layerItem.product ? layerItem.product : layerItem.mainProduct,
                group: layerItem,
                range: layerItem.mainProduct.getValueRange(),
            };
            Object.assign(layer, groupOptions);
        } else {
            const productOptions = {
                product: layerItem,
                range: layerItem.getValueRange(),
            };
            Object.assign(layer, productOptions);
        }
        Object.assign(_options, options);
        Object.assign(layer, layerOptions);

        this.layers.set(layer.key, layer);

        if (_options.compare) {
            this.layersCompareOrder.push(layer.key);
        } else {
            this.layersOrder.unshift(layer.key);
        }

        this.updateTilelayer(layer.key, hash);

        if (_options.select) {
            this.selectedLayerKey = layerKey;
        }

        if (_options.emit) {
            this.emitLayersUpdated();
        }
        return layer;
    }

    /**
     * Add a new layer to the map.
     */
    addSpecialLayer(layer, layerData, opacity?, options = {}) {
        const layerKey = this.layerKeyCount;
        const _options = { compare: false, emit: true };
        this.layerKeyCount += 1;
        const specialLayerOptions = {
            maxZoom: 16,
            pane: 'overlayPane',
            vectorTileLayerStyles: {
                sliced: {
                    color: environment.specialLayersColor,
                    weight: 2,
                },
            },
        };

        const specialLayer = new SpecialLayerData({
            key: layerKey,
            name: this.specialLayers[layer].label,
            layerName: layer,
            loading: false,
            opacity: opacity ? opacity : 100,
            display: true,
            pinned: true,
            layer: (L as any).vectorGrid.slicer(layerData, specialLayerOptions),
        });

        Object.assign(_options, options);
        this.layers.set(specialLayer.key, specialLayer);
        if (_options.compare) {
            this.layersCompareOrder.push(specialLayer.key);
        } else {
            this.layersOrder.unshift(specialLayer.key);
        }

        specialLayer.layer.on('loading', () => {
            const _layer = this.layers.get(layerKey);

            if (!_layer.loading) {
                this.ngZone.run(() => {
                    _layer.loading = true;
                });
            }
        });

        specialLayer.layer.on('load', () => {
            const _layer = this.layers.get(layerKey);

            if (_layer.loading) {
                this.ngZone.run(() => {
                    _layer.loading = false;
                });
            }
        });

        if (_options.emit) {
            this.emitLayersUpdated();
        }

        return specialLayer;
    }

    /**
     * Get the geojson of the special layer.
     * @param layerName
     */
    getSpecialLayerData(layerName: string) {
        if (this.downloadedSpecialLayers[layerName]) {
            return observableOf(this.downloadedSpecialLayers[layerName]);
        }
        return this.http.get(this.specialLayers[layerName].url).pipe(
            tap((result) => {
                this.downloadedSpecialLayers[layerName] = result;
            })
        );
    }

    /**
     * Set the current selected layer and emits the layers updated event.
     * @param {LayerKey} layerKey
     */
    selectLayer(layerKey: LayerKey, showLoadingTimeLineBar?) {
        const layer = this.get(layerKey);
        if (layer.product) {
            this.selectedLayerKey = layerKey;
        }
        this.emitLayersUpdated(showLoadingTimeLineBar);
    }

    /** Get the current selected layer key. */
    getSelectedLayerKey() {
        return this.selectedLayerKey;
    }

    /**
     * Get the current selected layer.
     * @returns {LayerData}
     */
    getSelectedLayer(): LayerData {
        return this.layers.get(this.selectedLayerKey);
    }

    /**
     * Generate the URL and the tilelayer object for some Layer.
     * @param {string} layerKey The layer key
     * @param {boolean} hash
     */
    updateTilelayer(layerKey, hash?) {
        const layer = this.layers.get(layerKey);
        const productSelected = layer.product;
        const dateSelected = layer.getDate();
        const opacity = layer.opacity;
        const legendRange = layer.range;
        const maxZoom = 20;
        const maxNativeZoom = productSelected.maxZoom || 9;
        let md5 = new Md5();
        const roisHash = md5.appendStr(this.rois.join(',')).end();

        const options: any = {
            date: getISODateString(dateSelected),
            product: productSelected.apiName,
            metadata: false,
            file_format: 'PNG',
            as_attachment: false,
            legendStart: legendRange[0],
            legendEnd: legendRange[1],
            rois: roisHash,
            // Leaflet options
            attribution: environment.copyrightMap,
            pane: 'overlayPane',
            tileSize: 256,
            transparent: true,
            maxZoom: maxZoom,
            maxNativeZoom: maxNativeZoom,
        };

        if (environment.tileServers !== undefined && environment.tileURL !== undefined) {
            options.subdomains = environment.tileServers;
        }

        let url = '';
        if (hash !== undefined) {
            url = this.damService.setTileMethod(productSelected.apiName, true);
            url = url + `&embedLink=${hash}&` + Math.random();
            // With the math random we avoid tile caching in the browser, we create unique link for each browser 'session'
        } else {
            if (!this.authService.hasPermission('can-render-tiles')) {
                return;
            }
            url = this.damService.setTileMethod(productSelected.apiName);
        }

        if (layer.product.areaAllowed) {
            // We create a hash with the area allowed so in case it changes a new hash is created and we avoid tile caching
            md5 = new Md5();
            const encodedAreaAllowed = md5
                .appendStr(layer.product.areaAllowed.coordinates.toString())
                .end();
            url = url + `&areaAllowed=${encodedAreaAllowed}`;
        }

        const tileLayer = new L.TileLayer(url, options);

        tileLayer.on('loading', () => {
            const _layer = this.layers.get(layerKey);

            if (!_layer.loading) {
                this.ngZone.run(() => {
                    _layer.loading = true;
                });
            }
        });

        tileLayer.on('load', () => {
            const _layer = this.layers.get(layerKey);

            if (_layer.loading) {
                this.ngZone.run(() => {
                    _layer.loading = false;
                });
            }
        });

        tileLayer.on('tileerror', (error) => {
            if (!this.authService.loggedIn()) {
                this.authService.logout();
                window.location.reload();
            }
        });

        // Update tilelayer opacity
        layer.tileLayer = tileLayer;
        layer.tileLayer.setOpacity(opacity / 100.0);
    }

    /** Get a layer given its key. */
    get(layerKey: LayerKey) {
        return this.layers.get(layerKey);
    }

    /**
     * Delete a layer given its key.
     * @param {number} layerKey
     */
    delete(layerKey: LayerKey) {
        const layer = this.get(layerKey);
        let index = this.layersOrder.findIndex((e) => e === layerKey);

        if (index === -1) {
            // Compare layer
            index = this.layersCompareOrder.findIndex((e) => e === layerKey);
            this.layersCompareOrder.splice(index, 1);
        } else {
            // Normal layer
            this.layersOrder.splice(index, 1);
        }

        this.layers.delete(layerKey);

        if (this.selectedLayerKey === layerKey) {
            this.selectedLayerKey = undefined;
            if (this.getNumberOfLayers() > 0) {
                // select the first layer
                const layersArray = Array.from(this.layers.values());
                const productLayers = layersArray.filter((l) => l.product);
                if (productLayers.length > 0) {
                    this.selectedLayerKey = productLayers[0].key;
                }
            }
        }

        // emit the corresponding events at the end of the updating
        if (layer.linked) {
            this.updateLinkedLayers(layer, false);
        }

        this.emitLayersUpdated();
    }

    /**
     * Get the number of layers.
     * @returns {number}
     */
    getNumberOfLayers(): number {
        return this.layers.size;
    }

    /**
     * Get the layer keys.
     * @returns {IterableIterator<LayerKey>}
     */
    getKeys(): IterableIterator<LayerKey> {
        return this.layers.keys();
    }

    /**
     * Get the layer object.
     * @returns {Map<LayerKey, LayerData>}
     */
    getLayers(): Map<LayerKey, LayerData> {
        return this.layers;
    }

    /** Get an array with the layer keys ordered by the user. */
    getLayersOrder() {
        return this.layersOrder;
    }

    /** Set an array with the layer keys ordered by the user. */
    setLayersOrder(newLayersOrder: LayerKey[]) {
        this.layersOrder = newLayersOrder;
        this.emitLayersUpdated();
    }

    /** Get an array with the compare layer keys ordered by the user. */
    getLayersCompareOrder() {
        return this.layersCompareOrder;
    }

    /** Get an array with the compare layer keys ordered by the user. */
    setLayersCompareOrder(newLayersOrder: LayerKey[]) {
        this.layersCompareOrder = newLayersOrder;
    }

    /** Get list of linked layers ids */
    getLinkedLayers(): Array<LayerData> {
        return this.linkedLayers;
    }

    /**
     * Add or removes the layer to/from the linked group and emits the linked layer event.
     * @param layer {LayerData} Layer data
     * @param emit {boolean} whether to emit the layers update event
     */
    updateLinkedLayers(layer: LayerData, emit = true) {
        const index = this.linkedLayers.findIndex((e) => e.key === layer.key);
        const resultObj = {};

        if (index === -1) {
            // not in linked layer list -> to be linked

            if (this.linkedLayers.length === 0) {
                this.linkedDate = layer.getDate();
            }

            resultObj['linked'] = layer;
            layer.setDate(this.linkedDate);
            this.updateTilelayer(layer.key);
            this.linkedLayers.push(layer);
        } else {
            resultObj['unlinked'] = layer;
            this.linkedLayers.splice(index, 1);
        }

        if (emit) {
            this._emitLayersUpdated(resultObj);
        }
    }

    /**
     * remove a layer from the linked list
     * @param {number} layerKey
     */
    removeLinkedLayer(layerKey: LayerKey) {
        const index = this.linkedLayers.findIndex((e) => e.key === layerKey);
        this.linkedLayers.splice(index, 1);
    }

    /**
     * Checks whether the layerId belongs to a layer in linked layer group
     * @param layerKey
     * @returns {boolean}
     */
    public isInLinkedLayers(layerKey: LayerKey): boolean {
        const index = this.linkedLayers.findIndex((e) => e.key === layerKey);
        return index >= 0;
    }

    /**
     * Emit layers order event.
     */
    emitLayersUpdated(showLoadingTimeLineBar?, updateEmittedLayers = true) {
        this._emitLayersUpdated(showLoadingTimeLineBar, updateEmittedLayers);
    }

    /**
     * Emit layers order event.
     */
    private _emitLayersUpdated(extraFields: object = {}, updateEmittedLayers = true) {
        const data = {
            order: this.layersOrder,
            compareOrder: this.layersCompareOrder,
            selected: this.selectedLayerKey,
            emit: updateEmittedLayers,
        };
        Object.assign(data, extraFields);
        this.outputSubjectLayers.next(data);
    }

    /**
     * Update the selected layer date, and the linked group if necessary.
     *
     * @param {moment.Moment} date
     */
    setSelectedDate(date: Moment) {
        const layer = this.get(this.getSelectedLayerKey());

        if (layer.linked) {
            this.linkedDate = date;
            // Update the linked group
            for (const linkedLayer of this.getLinkedLayers()) {
                linkedLayer.setDate(date);
                this.updateTilelayer(linkedLayer.key);
            }
        } else {
            layer.setDate(date);
            this.updateTilelayer(layer.key);
        }

        this.emitLayersUpdated();
    }

    /**
     * Clear the layers.
     */
    clear() {
        this.layerKeyCount = 0;
        this.layers.clear();
        this.layersOrder = [];
        this.layersCompareOrder = [];
        this.linkedLayers = [];
        this.selectedLayerKey = undefined;
    }

    /**
     * Update ROI list. This is a GET parameter to avoid tile caching.
     */
    updateRois(rois: Array<number>) {
        this.rois = rois;

        this.layers.forEach((value, key) => {
            if (value.product) {
                this.updateTilelayer(key);
            }
        });

        this.outputMapRedraw.next();
    }

    /**
     * Set the zoom dates for the selected layers
     * @param {moment.Moment} startDate
     * @param {moment.Moment} endDate
     */
    setZoomDate(startDate: Moment, endDate: Moment) {
        const layer = this.get(this.getSelectedLayerKey());

        if (layer.linked) {
            // Update the linked group
            for (const linkedLayer of this.getLinkedLayers()) {
                linkedLayer.setZoomDate(startDate, endDate);
            }
        } else {
            layer.setZoomDate(startDate, endDate);
        }
    }

    /** Get the current compare mode. */
    getLayersCompareMode() {
        return this.layersCompareMode;
    }

    /** Set layer compare mode */
    setLayersCompareMode(mode: CompareMode) {
        this.layersCompareMode = mode;
        this.emitLayersUpdated();
    }

    /**
     * Get the current spy mode size.
     */
    getSpyModeSize(): number {
        return this.spyModeSize;
    }

    /**
     * Set the size of the spy mode window
     */
    setSpyModeSize(size: number) {
        this.spyModeSize = size;
        this.emitLayersUpdated();
    }

    getSpecialLayers() {
        return this.specialLayers;
    }

    updateLayerLegend(layerKey, legend) {
        const layer = this.layers.get(layerKey);
        layer['legend'] = legend.data;
    }

    getProductGroupsList(products: Product[], sort = false): LayerList[] {
        const layerList: LayerList[] = [];
        const groupsList = {};
        for (const product of products) {
            if (!product.fieldBased) {
                if (product.groups.length === 0) {
                    layerList.push(product);
                } else {
                    for (const group of product.groups) {
                        if (!groupsList[group.groupName]) {
                            groupsList[group.groupName] = {
                                name: group.groupName,
                                groupName: group.groupName,
                                groupType: group.groupType,
                                products: [],
                            };
                        }

                        product.setGroupProduct(group.memberName, group.memberValue);
                        groupsList[group.groupName].products.push(product);
                        if (group.isMainMember) {
                            groupsList[group.groupName]['mainProduct'] = product;
                        }
                    }
                }
            }
        }

        for (const key in groupsList) {
            if (groupsList.hasOwnProperty(key)) {
                if (groupsList[key].products.length > 1) {
                    groupsList[key].products.sort(
                        (a, b) =>
                            moment.duration(a.productGroupValue).asHours() -
                            moment.duration(b.productGroupValue).asHours()
                    );
                    layerList.push(groupsList[key]);
                } else if (groupsList[key].products.length === 1) {
                    // If the user can only access a single product in the group we use that product instead of the group
                    layerList.push(groupsList[key].products[0]);
                }
            }
        }
        if (sort) {
            layerList.sort(function (a, b) {
                return cmpStr(a.name, b.name);
            });
        }
        return layerList;
    }

    /**
     * Generate the URL and the animation layer object for some Layer.
     * @param {Product} product
     * @param {ProductAvailability} availability
     * @param {Moment} date
     * @param {LatLng} boundary
     * @param legendStart
     * @param legendEnd
     */
    addAnimationLayer(
        product: Product,
        availability: ProductAvailability,
        date: Moment,
        boundary,
        legendStart,
        legendEnd
    ) {
        const layerKey = this.animationLayerKeyCount;
        this.animationLayerKeyCount += 1;

        const tileLayerStart = legendStart ? legendStart : product.getValueRange()[0];
        const tileLayerEnd = legendEnd ? legendEnd : product.getValueRange()[1];

        const options: any = {
            key: layerKey,
            date: getISODateString(date),
            product: product.apiName,
            metadata: false,
            file_format: 'PNG',
            as_attachment: false,
            legendStart: tileLayerStart,
            legendEnd: tileLayerEnd,
            rois: this.rois.join(','),
            // Leaflet options
            attribution: environment.copyrightMap,
            pane: 'overlayPane',
            tileSize: 256,
            transparent: true,
            maxZoom: 20,
            className: 'animationLayer',
            maxNativeZoom: 9,
            boundary: boundary,
        };
        if (!this.authService.hasPermission('can-render-tiles')) {
            return;
        }
        const url = this.damService.setTileMethod(product.apiName, undefined);
        const tileLayer = new (L as any).TileLayer.boundaryCanvas(url, options);

        return tileLayer;
    }
}
