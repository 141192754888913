<div class="embed-dialog-wrapper flex flex-col gap-4">
    <div class="flex justify-start items-center">
        <h2 mat-dialog-title class="embed-title primary-color !flex grow text-xl">Embed links</h2>
        <button
            mat-raised-button
            (click)="closeMenu()"
            matTooltip="Close dashboard"
            matTooltipPosition="left"
            class="close-sidenav-button"
            color="warn"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="flex justify-between items-center">
        <div class="search-box flex justify-start items-center">
            <mat-form-field>
                <mat-icon matPrefix class="!flex items-center justify-center"
                    ><i class="fa fa-search"></i
                ></mat-icon>
                <input matInput placeholder="Search embed link" [formControl]="searchLinks" />
            </mat-form-field>

            <div *ngIf="foundLinks && searchLinks.value.length" class="flex gap-1">
                <div class="found-counter">{{ foundIndex + 1 }}/{{ foundLinks.length }}</div>
                <div class="flex gap-2">
                    <i class="search-button fa fa-angle-up" (click)="selectFoundLink(-1)"></i>
                    <i class="search-button fa fa-angle-down" (click)="selectFoundLink(1)"></i>
                    <i class="search-button fa fa-times" (click)="clearSearch()"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="embed-table flex flex-col">
        <mat-divider></mat-divider>
        <div class="embed-list embed-list-container flex flex-col">
            <div *ngFor="let embedLink of embedLinksList" class="embed-list-item flex gap-2">
                <div id="{{ embedLink.id }}" class="flex justify-start items-center gap-4 grow">
                    <ng-container *ngIf="embedLink.description; else noDescription">
                        {{ embedLink.description }}<br />
                    </ng-container>
                    <ng-template #noDescription> &lt;no-description&gt; </ng-template>
                </div>
                <div class="flex">
                    <button
                        mat-icon-button
                        color=""
                        (click)="generateShareableEmbedLink(embedLink.link)"
                        matTooltip="Get embed link"
                        [matTooltipPosition]="'above'"
                    >
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                    </button>
                </div>

                <div class="flex">
                    <button
                        mat-icon-button
                        color=""
                        (click)="previewEmbedLink(embedLink)"
                        matTooltip="Embed link info"
                        [matTooltipPosition]="'above'"
                    >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="actions flex flex-col justify-center items-center">
        <div class="flex justify-start items-center">
            <h2 mat-dialog-title class="roi-title primary-color grow">Create embed link</h2>
        </div>

        <div
            class="flex justify-center items-center gap-4"
            *ngIf="editionModes['embedEditMode']"
            style="width: 100%"
        >
            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="confirmBoundingBox()"
            >
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
            </button>

            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="cancelBoundingBox()"
            >
                <i class="fa fa-times" aria-hidden="true"></i>
                Cancel
            </button>
        </div>

        <button
            mat-raised-button
            class="margin-bottom flex justify-start items-center"
            [disabled]="editionModes['shapeEditMode']"
            (click)="generateBoundingBox()"
            matTooltip="Export area as image"
            matTooltipPosition="right"
            *ngIf="!editionModes['embedEditMode']"
        >
            <i class="fa fa-code"></i>
            Create embed link
        </button>
    </div>
</div>
