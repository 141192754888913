import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessDirective } from './access.directive';
import { StructuralAccessDirective } from './structural-access.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [AccessDirective, StructuralAccessDirective],
    exports: [AccessDirective, StructuralAccessDirective],
})
export class AppDirectivesModule {}
