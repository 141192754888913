<div class="api-access-management-wrapper flex flex-col">
    <div class="title-wrapper p-2 flex justify-around items-center gap-2">
        <div class="flex flex-col flex-grow justify-center items-center">
            <span class="title">Time series API Request parameters</span>
        </div>
        <div class="flex justify-end items-center">
            <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="closeDialog()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>

    <div class="flex flex-col gap-4 p-4 overflow-y-auto max-h-[calc(100vh-100px)]">
        <form
            novalidate
            [formGroup]="timeSeriesApiRequestForm"
            class="flex flex-wrap justify-between gap-2"
        >
            <mat-form-field class="flex-grow overflow-x-hidden">
                <mat-label>Enter start date</mat-label>
                <input
                    matInput
                    [matDatepicker]="rangeDatepickerStart"
                    formControlName="start_date"
                    placeholder="Start date"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="rangeDatepickerStart"
                ></mat-datepicker-toggle>
                <mat-datepicker #rangeDatepickerStart></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="flex-grow overflow-x-hidden">
                <mat-label>Enter end date</mat-label>
                <input
                    matInput
                    [matDatepicker]="rangeDatepickerEnd"
                    formControlName="end_date"
                    placeholder="End date"
                />
                <mat-datepicker-toggle matSuffix [for]="rangeDatepickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
            </mat-form-field>
            <ng-container *ngIf="data.mode === 'point' && data.coordinateSystem !== 'EPSG:28992'">
                <mat-form-field class="flex flex-grow">
                    <mat-label>Latitude</mat-label>
                    <input
                        formControlName="lat"
                        placeholder="Latitude"
                        matInput
                        type="number"
                        step="0.00001"
                        min="-90"
                        max="90"
                    />
                </mat-form-field>
                <mat-form-field class="flex flex-grow">
                    <mat-label>Longitude</mat-label>
                    <input
                        formControlName="lng"
                        placeholder="Longitude"
                        matInput
                        type="number"
                        step="0.00001"
                        min="-180"
                        max="180"
                    />
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="data.mode === 'point' && data.coordinateSystem === 'EPSG:28992'">
                <mat-form-field class="flex flex-grow">
                    <mat-label>X</mat-label>
                    <input formControlName="lng" placeholder="X" matInput type="number" />
                </mat-form-field>
                <mat-form-field class="flex flex-grow">
                    <mat-label>Y</mat-label>
                    <input formControlName="lat" placeholder="Y" matInput type="number" />
                </mat-form-field>
            </ng-container>
            <mat-form-field class="flex flex-grow">
                <mat-label>Average windows days</mat-label>
                <input
                    formControlName="avg_window_days"
                    placeholder="Average windows days"
                    matInput
                    type="number"
                    step="1"
                />
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Average windows direction</mat-label>
                <mat-select
                    formControlName="avg_window_direction"
                    placeholder="Avg window direction"
                >
                    <mat-option [value]="'center'"> Center</mat-option>
                    <mat-option [value]="'backward'"> Backward</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Include masked data</mat-label>
                <mat-select formControlName="include_masked_data" placeholder="Include masked data">
                    <mat-option [value]="true"> Yes</mat-option>
                    <mat-option [value]="false"> No</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Include climatology</mat-label>
                <mat-select formControlName="climatology" placeholder="Climatology">
                    <mat-option [value]="true"> Yes</mat-option>
                    <mat-option [value]="false"> No</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex flex-grow" *ngIf="data.mode === 'roi'">
                <mat-label>Coverage</mat-label>
                <mat-select formControlName="coverage" placeholder="Coverage">
                    <mat-option [value]="true"> Yes</mat-option>
                    <mat-option [value]="false"> No</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Format</mat-label>
                <mat-select formControlName="format" placeholder="Format">
                    <mat-option [value]="'csv'"> CSV</mat-option>
                    <mat-option [value]="'json'"> JSON</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Exponential filter t</mat-label>
                <input
                    formControlName="exp_filter_t"
                    placeholder="Exponential filter t"
                    matInput
                    type="number"
                    step="1"
                />
            </mat-form-field>
            <mat-form-field class="flex flex-grow">
                <mat-label>Email</mat-label>
                <input matInput formControlName="notify" placeholder="Email to notify" />
            </mat-form-field>
        </form>

        <button
            [disabled]="timeSeriesApiRequestForm.invalid"
            mat-raised-button
            (click)="save()"
            color="accent"
            class="flex justify-end items-center"
        >
            <div class="inline-flex justify-center items-center gap-2">
                <i class="fa fa-check"></i> Save
            </div>
        </button>
    </div>
</div>
