import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { VERSION } from '../../environments/version';

@Component({
    selector: 'app-simple-info-dialog',
    templateUrl: './simple-info-dialog.component.html',
    styleUrls: ['./simple-info-dialog.component.scss'],
})
export class SimpleInfoDialogComponent implements OnInit {
    /**
     * Text to be shown as header of the info dialog
     */
    headerText: string;

    /**
     * Text to be shown in the body of the info dialog
     */
    mainText: string;

    /**
     * Text to be shown as footer of the info dialog
     */
    attribution: string;

    /**
     * Current build version value
     */
    version = VERSION.version;

    constructor(private matDialog: MatDialog) {}

    ngOnInit() {
        if (
            environment.simpleInfoHeader &&
            environment.simpleInfoText &&
            environment.simpleInfoText.length &&
            environment.simpleInfoAttribution
        ) {
            this.headerText = environment.simpleInfoHeader;
            this.mainText = environment.simpleInfoText.join('\n');
            this.attribution = environment.simpleInfoAttribution;
        }
    }

    /**
     * Close dialog.
     */
    close() {
        this.matDialog.closeAll();
    }
}
