import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Moment } from 'moment';
import { Observable, forkJoin, of as observableOf } from 'rxjs';
import { map } from 'rxjs';

import { environment } from '../environments/environment';
import { Kpi } from '../models/kpi';
import { Product } from '../models/product';
import { Region } from '../models/region';
import { getISODateString } from '../utils/utils';
import { LayersService } from './layers.service';
import { ProductService } from './product.service';

/**
 * KPI Service
 *
 * This service retrieves the KPIs for a region
 */
@Injectable()
export class KpiService {
    /** API base path */
    protected apiURL = environment.apiV2;

    private http = inject(HttpClient);
    private layersService = inject(LayersService);
    private productService = inject(ProductService);

    /**
     * Get KPIs for a Region, Product and date
     *
     * @param {Moment} date
     * @param {Region} region
     * @param {Kpi[]} selectedKpis
     * @returns {Observable<Array<Kpi>>}
     */
    public getRegionKpis(date: Moment, region: Region, selectedKpis: Kpi[]) {
        const product = this.getSelectedProduct();
        const httpParams = {
            roi_id: region.id.toString(),
            product_api_name: product.apiName,
            date: getISODateString(date),
        };

        const promises = [];
        const selected = selectedKpis !== undefined ? selectedKpis : [];

        selected.forEach((kpi) => {
            promises.push(
                this.http.get(`${this.apiURL}/kpis/${kpi.name}`, {
                    params: httpParams,
                })
            );
        });

        if (promises.length === 0) {
            return observableOf([]);
        }

        return forkJoin(promises).pipe(
            map((values) => {
                const kpis = [];
                for (let i = 0; i < values.length; i++) {
                    const kpi = selectedKpis[i].copy();
                    kpi.setValue(values[i]['values']);
                    kpis.push(kpi);
                }
                return kpis;
            })
        );
    }

    /**
     * Method that returns a list of selected empty KPIs
     */
    public emptyKpis(): Observable<Array<Kpi>> {
        return this.getSelectedKpis().pipe(
            map((kpis) => {
                kpis.forEach((kpi) => kpi.setZero());
                return kpis;
            })
        );
    }

    /**
     * Get catalog of all available kpis for the current selected product
     *
     * @returns {Observable<Array<Kpi>>}
     */
    public getAvailableKpis(): Observable<Array<Kpi>> {
        const product = this.getSelectedProduct();

        if (product === undefined) {
            return observableOf([]);
        }

        return this.http.get(`${this.apiURL}/products/${product.apiName}/supported_kpis`).pipe(
            map((data: object) => {
                const kpis = data['kpis'].map((kpi) => new Kpi(kpi));
                return kpis;
            })
        );
    }

    /**
     * Get list of kpis (empty) selected by user
     *
     * @returns {Observable<Array<Kpi>>}
     */
    public getSelectedKpis(): Observable<Array<Kpi>> {
        const product = this.getSelectedProduct();
        return this.productService.getSelectedKpis(product.apiName);
    }

    /**
     * Set the selected kpis for the current user
     * @param {Array<Kpi>} kpis
     * @returns {Observable<object>}
     */
    public setSelectedKpis(kpis: Array<Kpi>): Observable<object> {
        const product = this.getSelectedProduct();
        return this.productService.setSelectedKpis(product.apiName, kpis);
    }

    /**
     * Get selected product from layers service
     */
    private getSelectedProduct(): Product {
        const layer = this.layersService.getSelectedLayer();
        return layer !== undefined ? layer.product : undefined;
    }

    /**
     * Get the metadata for a kpi
     */
    public getKpiMetadata(kpi) {
        return this.http.get(`${this.apiURL}/kpis/${kpi.name}/metadata`);
    }
}
