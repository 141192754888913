<div class="flex flex-col w-full h-full">
    <div class="title-wrapper flex justify-between items-center">
        <div>
            <span class="title">Parameters used to generate this time series</span>
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="closeDialog()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="ts-info flex flex-col justify-between items-center">
        <div *ngIf="dialogData.roiId" class="field flex">
            <p class="field-key">ROI ID:</p>
            <p>{{ dialogData.roiId }}</p>
        </div>
        <div *ngIf="dialogData.lat" class="field flex">
            <p class="field-key">Latitude:</p>
            <p>{{ dialogData.lat }}</p>
        </div>
        <div *ngIf="dialogData.lon" class="field flex">
            <p class="field-key">Longitude:</p>
            <p>{{ dialogData.lon }}</p>
        </div>
        <div *ngIf="dialogData.startDate" class="field flex">
            <p class="field-key">Start date:</p>
            <p>{{ dialogData.startDate }}</p>
        </div>
        <div *ngIf="dialogData.endDate" class="field flex">
            <p class="field-key">End date:</p>
            <p>{{ dialogData.endDate }}</p>
        </div>
        <div *ngIf="dialogData.climatology !== undefined" class="field flex">
            <p class="field-key">Climatology:</p>
            <p>{{ dialogData.climatology }}</p>
        </div>
        <div class="field flex">
            <p class="field-key">Average window days:</p>
            <p>
                {{ dialogData.avgWindowDays ? dialogData.avgWindowDays : 20 }}
            </p>
        </div>
        <div *ngIf="dialogData.avgWindowDirection" class="field flex">
            <p class="field-key">Average window direction:</p>
            <p>{{ dialogData.avgWindowDirection }}</p>
        </div>
    </div>
</div>
