<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-info-circle icon-w-text"></i>Draw a ROI</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div class="roi-help-wrapper flex gap-4">
    <div>
        <img src="/assets/img/roi-help.png" />
    </div>
    <div class="flex flex-col gap-4">
        <span
            >When you open the ROI dashboard, two new buttons appear in the top left corner, under
            the zoom buttons.</span
        >
        <span
            >To start drawing a ROI, you have to click one of them. Then, you can click on the map
            to draw the shape.</span
        >
        <span
            >When you want to Finish or Cancel the drawing, you can click on the buttons that will
            be shown next to the polygon button.</span
        >
    </div>
</div>
