import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-impersonate-dialog',
    templateUrl: './impersonate-dialog.component.html',
    styleUrls: ['./impersonate-dialog.component.scss'],
})
export class ImpersonateDialogComponent implements OnInit {
    /**
     * Popup metadata: Position, Lat/Lng, Product...
     */
    @Input() userForApi: any;

    /**
     * Restore map click event.
     */
    @Output() forgetUserEvent = new EventEmitter<any>(true);

    constructor() {}

    ngOnInit() {}

    forgetUser() {
        this.forgetUserEvent.emit();
    }
}
