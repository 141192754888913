<div class="settings-wrapper">
    <section class="flex flex-col">
        <div class="settings-header flex justify-start items-center">
            <h2 class="settings-title primary-color font-medium text-lg">User settings</h2>
            <button
                mat-raised-button
                (click)="close()"
                matTooltip="Close dashboard"
                matTooltipPosition="left"
                class="close-sidenav-button"
                color="warn"
            >
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="flex flex-col w-11/12 mx-auto">
            <mat-form-field>
                <mat-select [(ngModel)]="currentLayer" (selectionChange)="setBaseLayer($event)">
                    <mat-option *ngFor="let layer of baseLayers | keys" [value]="layer"
                        >{{ baseLayers[layer].label }}
                    </mat-option>
                </mat-select>
                <mat-label>Base layer</mat-label>
            </mat-form-field>
            <mat-form-field>
                <mat-select [(ngModel)]="areaUnit" (selectionChange)="setAreaUnit($event.value)">
                    <mat-option *ngFor="let unit of supportedUnits" [value]="unit.value"
                        >{{ unit.label }}
                    </mat-option>
                </mat-select>
                <mat-label>Area unit</mat-label>
            </mat-form-field>
            <mat-form-field>
                <mat-select [(ngModel)]="currentGrid" (selectionChange)="setGrid($event)">
                    <mat-option value="none">None</mat-option>
                    <mat-option *ngFor="let grid of supportedGrids | keys" [value]="grid"
                        >{{ supportedGrids[grid].label }}
                    </mat-option>
                </mat-select>
                <mat-label>Grid</mat-label>
            </mat-form-field>
            <mat-form-field>
                <input
                    type="text"
                    aria-label="Product name"
                    matInput
                    [formControl]="searchControl"
                    [matAutocomplete]="auto"
                    tabindex="-1"
                />
                <button
                    *ngIf="searchControl.value"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearSelection()"
                >
                    <mat-icon><i class="fa fa-times"></i></mat-icon>
                </button>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onProductChange($event)"
                    [displayWith]="displayProduct"
                >
                    <mat-option *ngFor="let prod of filteredProducts$ | async" [value]="prod">
                        {{ prod.name }}
                    </mat-option>
                </mat-autocomplete>
                <mat-label>Default product</mat-label>
            </mat-form-field>
            <span
                *ngIf="defaultProduct && defaultProduct.productGroupName"
                class="group-info font-medium"
                appAccess="can-set-default-product"
            >
                Product group:
                <span class="font-normal italic">{{ defaultProduct.productGroupName }}</span>
            </span>
            <mat-form-field>
                <input matInput [formControl]="emailSettingsControl" type="email" name="email" />
                <mat-label>Email for notifications</mat-label>
            </mat-form-field>
            <button
                mat-raised-button
                (click)="showPasswordDialog()"
                appAccess="can-change-password"
                class="expand-button"
            >
                <div class="icon-text flex justify-center items-center">
                    <i class="fa fa-user"></i>Change password
                </div>
            </button>
        </div>
    </section>

    <!--<section>-->
    <!--<h2 class="primary-color">Legend display mode</h2>-->

    <!--<mat-form-field class="map-base-layer-selector" floatLabel="never">-->
    <!--<mat-select placeholder="Legend display mode" [(ngModel)]="legendDisplay" (selectionChange)="setLegendMode($event)">-->
    <!--<mat-option *ngFor="let mode of legendModes" [value]="mode">{{ mode }}</mat-option>-->
    <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--</section>-->

    <!-- Account request settings -->
    <section [appAccess]="['can-manage-account-request', 'can-update-account-request-status']">
        <h2 class="primary-color font-medium text-lg">Account request management</h2>
        <div class="flex flex-col justify-center items-center gap-4 w-11/12 mx-auto">
            <button
                mat-raised-button
                (click)="requestAccount()"
                class="expand-button"
                appAccess="can-manage-account-request"
            >
                <div class="icon-text flex justify-center items-center">
                    <i class="fa fa-user"></i>Request account
                </div>
            </button>

            <button
                mat-raised-button
                (click)="manageAccountRequests()"
                class="expand-button"
                appAccess="can-update-account-request-status"
            >
                <div class="icon-text flex justify-center items-center">
                    <i class="fa fa-user"></i>Manage requests
                </div>
            </button>

            <button
                mat-raised-button
                (click)="listUsers()"
                class="expand-button"
                [appAccess]="['can-manage-accounts', 'can-list-all-users']"
            >
                <div class="icon-text flex justify-center items-center">
                    <i class="fa fa-user"></i>List users
                </div>
            </button>
        </div>
    </section>

    <!-- Superadmin - User selection button -->
    <section [appAccess]="['can-impersonate', 'can-impersonate-everybody']">
        <h2 class="primary-color font-medium text-lg">Impersonation settings</h2>

        <div class="flex flex-col justify-center items-center w-11/12 mx-auto">
            <button
                mat-raised-button
                class="button-fab icon-text expand-button"
                (click)="showUserSelection()"
                matTooltip="Select an user"
                matTooltipPosition="right"
            >
                <i class="fa fa-users" aria-hidden="true"></i>
                <span>Impersonate user</span>
            </button>

            <button
                mat-raised-button
                class="button-fab icon-text expand-button"
                *ngIf="userForApi !== undefined"
                (click)="forgetUser()"
                matTooltip="Forget user"
                matTooltipPosition="right"
            >
                <i class="fa fa-user-secret" aria-hidden="true"></i>
                <span>Forget {{ userForApi }}</span>
            </button>
        </div>
    </section>

    <!-- Logout - Logout button -->
    <section>
        <h2 class="primary-color font-medium text-lg">Logout</h2>

        <div class="flex flex-col justify-center items-center w-11/12 mx-auto">
            <button
                mat-raised-button
                (click)="logout()"
                class="button-fab icon-text expand-button"
                matTooltip="Logout"
                matTooltipPosition="left"
            >
                <i class="fa fa-sign-out"></i>
                <span>Logout</span>
            </button>
        </div>
    </section>

    <!-- JWT -->
    <section *ngIf="authService.getToken() as token">
        <h2 class="primary-color no-y-margin font-medium text-lg">Token</h2>
        <div class="flex flex-col items-center w-11/12 mx-auto">
            <mat-form-field class="w-full">
                <input matInput disabled [value]="token" />

                <button
                    matSuffix
                    mat-icon-button
                    aria-label="Copy"
                    (click)="copyToken(token)"
                    matTooltip="Copy"
                >
                    <i class="fa fa-files-o"></i>
                </button>
                <mat-label>JWT Token</mat-label>
            </mat-form-field>
        </div>
    </section>

    <div class="flex flex-col justify-center items-center gap-10">
        <span class="txt-info">
            Legal:<br />
            Contains modified ESA Copernicus Sentinel data 2015-2018<br />
            Contains modified NASA AQUA/AMSRE data 2002-2011<br />
            Contains modified JAXA GCOM-W1/AMSR2 data 2012-2018<br />
            Contains modified NASA SMAP data 2015-2018<br />
            <br />
            Copyright:<br />
            <div *ngFor="let text of copyright">
                {{ text }}
            </div>
        </span>

        <span class="version"
            >v{{ version }}
            <button
                mat-icon-button
                class="action-button"
                (click)="openReleaseInfoDialog($event)"
                matTooltip="Release information"
                matTooltipPosition="right"
            >
                <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
        </span>
    </div>
</div>
