import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    AbstractSecurityStorage,
    AuthInterceptor,
    AuthModule,
    DefaultLocalStorageService,
} from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                authority: environment.planetAuthServer,
                redirectUrl: `${window.location.origin}/redirect`,
                postLogoutRedirectUri: `${window.location.origin}/redirect`,
                clientId: environment.planetAuthClientId,
                scope: 'openid profile offline_access email',
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                renewTimeBeforeTokenExpiresInSeconds: 30,
                secureRoutes: ['/api/'],
            },
        }),
    ],
    providers: [
        { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    exports: [AuthModule],
})
export class AuthConfigModule {}
