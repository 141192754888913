<mat-card
    appearance="outlined"
    class="popup-wrapper"
    *ngIf="show"
    [ngStyle]="{
        left: posX + 'px',
        top: posY + 'px',
        width: width + 'px',
        height: height + 'px'
    }"
>
    <div class="popup-header flex flex-row justify-between items-center gap-1">
        <div class="header-icon flex flex-row justify-start items-center">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <h3 class="fond-bold">Point info</h3>
        </div>
        <div
            *ngIf="coordinateSystem === 'EPSG:4326'"
            class="coords flex flex-row gap-4"
            (dblclick)="copyCoords()"
        >
            <div class="latlng">
                Lat
                <span class="latlng-kpi">{{
                    data.lat
                        | coordinate
                            : data.lat
                            : data.lng
                            : coordinateSystem
                            : data.degreeMinuteSecond
                }}</span>
            </div>
            <div class="latlng">
                Lon
                <span class="latlng-kpi">{{
                    data.lng
                        | coordinate
                            : data.lat
                            : data.lng
                            : coordinateSystem
                            : data.degreeMinuteSecond
                }}</span>
            </div>
        </div>
        <div
            *ngIf="coordinateSystem === 'EPSG:28992'"
            class="coords flex flex-row gap-4"
            (dblclick)="copyCoords()"
        >
            <div class="latlng">
                X
                <span class="latlng-kpi">{{
                    data.lng
                        | coordinate
                            : data.lat
                            : data.lng
                            : coordinateSystem
                            : data.degreeMinuteSecond
                }}</span>
            </div>
            <div class="latlng">
                Y
                <span class="latlng-kpi">{{
                    data.lat
                        | coordinate
                            : data.lat
                            : data.lng
                            : coordinateSystem
                            : data.degreeMinuteSecond
                }}</span>
            </div>
        </div>
        <button mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times"></i>
        </button>
    </div>

    <mat-card-content
        class="flex flex-col gap-4"
        [ngClass]="dataSource.length > 0 ? 'popup-value-list' : 'empty-popup-value-list'"
    >
        <table
            class="popup-table"
            mat-table
            *ngIf="dataSource.length > 0"
            [dataSource]="dataSource"
            multiTemplateDataRows
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Product</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container
                matColumnDef="time-series"
                *appStructuralAccess="'can-create-point-time-series-request'"
            >
                <th mat-header-cell *matHeaderCellDef>Time series</th>
                <td mat-cell *matCellDef="let element">
                    <button
                        class="mat-icon-button popup-button-ts"
                        (click)="clickTimeSeries(element.key)"
                        matTooltip="Open time series chart"
                        matTooltipPosition="right"
                    >
                        <i class="fa fa-area-chart" aria-hidden="true"></i>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Value</th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        {{ element.value }}
                        <mat-progress-spinner
                            *ngIf="!element.value"
                            color="primary"
                            mode="indeterminate"
                            [diameter]="20"
                        ></mat-progress-spinner>
                        <i
                            *ngIf="element.interpretation"
                            [class]="element == expandedElement ? 'fa fa-eye-slash' : 'fa fa-eye'"
                            aria-hidden="true"
                        ></i>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div
                        class="element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                    >
                        <div style="width: 100%">
                            <mat-table
                                *ngIf="expandedElement && expandedElement.interpretation.length > 0"
                                #table
                                [dataSource]="expandedElement.interpretation"
                            >
                                <ng-container matColumnDef="bit">
                                    <mat-header-cell *matHeaderCellDef style="flex: 10%"
                                        >Bit</mat-header-cell
                                    >
                                    <mat-cell *matCellDef="let selectedElement" style="flex: 10%">
                                        {{ selectedElement.bit }}</mat-cell
                                    >
                                    <mat-footer-cell
                                        *matFooterCellDef
                                        style="flex: 10%"
                                    ></mat-footer-cell>
                                </ng-container>

                                <ng-container matColumnDef="meaning">
                                    <mat-header-cell *matHeaderCellDef style="flex: 50%"
                                        >Meaning</mat-header-cell
                                    >
                                    <mat-cell *matCellDef="let selectedElement" style="flex: 50%">
                                        {{ selectedElement.meaning }}</mat-cell
                                    >
                                    <mat-footer-cell
                                        *matFooterCellDef
                                        style="flex: 50%"
                                    ></mat-footer-cell>
                                </ng-container>

                                <ng-container matColumnDef="binary">
                                    <mat-header-cell *matHeaderCellDef style="flex: 40%"
                                        >Value</mat-header-cell
                                    >
                                    <mat-cell *matCellDef="let selectedElement" style="flex: 40%">
                                        <span [innerHTML]="selectedElement.binary"></span>
                                    </mat-cell>
                                    <mat-footer-cell *matFooterCellDef style="flex: 40%">
                                        <span [innerHTML]="element.bitMask"></span>
                                    </mat-footer-cell>
                                </ng-container>

                                <mat-header-row
                                    *matHeaderRowDef="displayedBitMaskColumns"
                                ></mat-header-row>
                                <mat-row
                                    *matRowDef="let row; columns: displayedBitMaskColumns"
                                ></mat-row>
                                <mat-footer-row
                                    *matFooterRowDef="displayedBitMaskColumns"
                                ></mat-footer-row>
                            </mat-table>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let element; columns: displayedColumns"
                [class]="element.filtered ? 'element-row element-row-highlighted' : 'element-row'"
                [class.expanded-row]="expandedElement === element"
                (click)="toggleExpandedElement(element)"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
    </mat-card-content>

    <mat-card-footer class="!flex flex-row justify-end items-center gap-4">
        <button
            *ngIf="height > pointInfoOnlyHeight"
            [disabled]="!data.insideRoi"
            appAccess="view-roi-dashboard"
            mat-raised-button
            color="accent"
            class="popup-button-dash"
            (click)="clickDashboard()"
        >
            {{ roiInformationDashboardText }}
        </button>
    </mat-card-footer>

    <div
        class="arrow-down"
        *ngIf="!popupUpsidedown()"
        [ngStyle]="{
            position: 'absolute',
            left: 'calc(50% - ' + posXOffset + 'px)',
            top: '100%'
        }"
    ></div>
    <div
        class="arrow-up"
        *ngIf="popupUpsidedown()"
        [ngStyle]="{
            position: 'absolute',
            left: 'calc(50% - ' + posXOffset + 'px)',
            top: '-20px'
        }"
    ></div>
</mat-card>
