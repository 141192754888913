import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { NewReleasePopupComponent } from '../../new-release-popup/new-release-popup.component';

@Component({
    selector: 'app-release-snackbar',
    templateUrl: './release-snackbar.component.html',
    styleUrls: ['./release-snackbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReleaseSnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<ReleaseSnackbarComponent>,
        private dialog: MatDialog
    ) {}

    /**
     * Open the release info dialog.
     * @param event
     */
    openReleaseInfoDialog(event) {
        event.stopPropagation();
        this.dialog.open(NewReleasePopupComponent, {
            width: 'auto',
            height: 'auto',
            panelClass: 'new-release-popup',
        });
        this.snackBarRef.dismiss();
    }
}
