<mat-form-field class="flex flex-col justify-around items-center field basis-24 search-bar-wrapper">
    <mat-icon matPrefix class="!flex items-center justify-center">
        <i class="fa fa-search" aria-hidden="true"></i>
    </mat-icon>
    <input
        matInput
        type="text"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        #search
        (keypress)="onSearchEnter($event)"
    />
</mat-form-field>
