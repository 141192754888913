import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';

import { Product } from '../models/product';

@Injectable({
    providedIn: 'root',
})
export class UserProductMultiSelectService {
    /**
     * RxJs BehaviorSubject that emits current selected products
     */
    private outputSubjectSelected = new BehaviorSubject<Product[]>([]);

    /**
     * Selected products
     */
    private products: Product[] = [];

    /**
     * Default constructor
     */
    constructor() {}

    /**
     * Set selected products
     * @param products
     */
    setProducts(products: Product[]) {
        this.products = products;
        this.outputSubjectSelected.next(products);
    }

    /**
     * Get selected products
     */
    getProducts(): Product[] {
        return this.products;
    }

    /**
     * Return an observable from the Subject, that emits the selected products.
     * @returns {Observable<Product[]>}
     */
    watchProductSelected(): Observable<Product[]> {
        return from(this.outputSubjectSelected);
    }
}
