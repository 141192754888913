<div class="title-wrapper flex justify-around items-center h-16 lg:h-auto">
    <div class="flex flex-col justify-center items-center grow">
        <span class="title">Gridded data API Request parameters</span>
    </div>
    <div class="flex justify-end items-center">
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>

<div class="dialog-params-inputs flex justify-around items-center flex-wrap">
    <mat-form-field>
        <input
            matInput
            [(ngModel)]="startDateApiRequest"
            [matDatepicker]="rangeDatepickerStart"
            placeholder="Start date"
        />
        <mat-datepicker-toggle matSuffix [for]="rangeDatepickerStart"></mat-datepicker-toggle>
        <mat-datepicker #rangeDatepickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <input
            matInput
            [(ngModel)]="endDateApiRequest"
            [matDatepicker]="rangeDatepickerEnd"
            placeholder="End date"
        />
        <mat-datepicker-toggle matSuffix [for]="rangeDatepickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #rangeDatepickerEnd></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-select [(value)]="formatApiRequest" placeholder="Format">
            <mat-option [value]="'gtiff'"> gtiff </mat-option>
            <mat-option [value]="'netcdf4'"> netcdf4 </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select [(value)]="zippedApiRequest" placeholder="Zipped">
            <mat-option [value]="true"> Yes </mat-option>
            <mat-option [value]="false"> No </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Email to notify" [formControl]="emailControlApiRequest" />
        <mat-error *ngIf="emailControlApiRequest.invalid"
            >Please enter a valid email address</mat-error
        >
    </mat-form-field>
</div>
<div class="w-full flex justify-end items-center gap-4">
    <button
        mat-raised-button
        class="button-save justify-center items-center"
        (click)="save()"
        color="accent"
    >
        <i class="fa fa-check" aria-hidden="true"></i>
        <span> Save</span>
    </button>
</div>
