import { autoserialize, autoserializeAs } from 'cerialize';
import { Group } from './group';

/**
 * Product CRUD class
 */
export class Product {
    /**
     * Product type identifier
     */
    @autoserialize type?: string;

    /**
     * Product name
     */
    @autoserialize name?: string;

    /**
     * Product API Name
     */
    @autoserializeAs('api_name') apiName?: string;

    /**
     * Product Tiles Max Zoom level.
     */
    @autoserializeAs('max_zoom') maxZoom?: number;

    /**
     * Product Field based value
     */
    @autoserializeAs('field_based') fieldBased?: boolean;

    /**
     * Product legend
     */
    @autoserialize legend?: any;

    /**
     * Product unit
     */
    @autoserialize unit = '';

    /**
     * Minimum product value
     */
    @autoserializeAs('min_val') minVal: number | null = 0;

    /**
     * Maximum product value
     */
    @autoserializeAs('max_val') maxVal: number | null = 100;

    /**
     * Time series type
     */
    @autoserializeAs('time_series_type') timeSeriesType?: string;

    /**
     * Product area allowed
     */
    @autoserializeAs('area_allowed') areaAllowed?: any;

    /**
     * Product groups
     */
    @autoserializeAs(Group, 'groups') groups?: Group[];

    /**
     * Name of the group, if the product belong to it
     */
    @autoserializeAs('product_group_name') productGroupName?: string;

    /**
     * Value of the product in the group
     */
    @autoserializeAs('product_group_value') productGroupValue?: string;

    /**
     * Default constructor
     */
    constructor(
        name?: string,
        apiName?: string,
        maxZoom?: number,
        fieldBased?: boolean,
        legend?: any,
        unit = '',
        minVal: number | null = 0,
        maxVal: number | null = 100,
        timeSeriesType?: string,
        areaAllowed?: any,
        groups?: Group[],
        productGroupName?: string,
        productGroupValue?: string,
        type?: string
    ) {
        this.name = name;
        this.apiName = apiName;
        this.maxZoom = maxZoom;
        this.fieldBased = fieldBased;
        this.legend = legend;
        this.type = type;
        if (this.legend !== undefined && this.legend.product_type !== undefined) {
            this.type = this.legend.product_type;
        } else {
            this.type = this.apiName;
        }
        this.unit = unit;
        this.minVal = minVal;
        this.maxVal = maxVal;
        this.timeSeriesType = timeSeriesType;
        this.areaAllowed = areaAllowed;
        this.groups = groups;
        this.productGroupName = productGroupName;
        this.productGroupValue = productGroupValue;
    }

    /**
     * Return the product [min, max] values.
     */
    getValueRange(): [number, number] {
        let min = this.minVal;
        let max = this.maxVal;

        if (min === null || max === null) {
            min = 0;
            max = 100;
        }

        return [min, max];
    }

    /**
     * Return true if the time series related to the product are year mode only
     */
    isYearModeOnly(): boolean {
        return false;
    }

    /**
     * Return true if the product is expressed in area units
     */
    isAreaUnit(): boolean {
        return this.unit === 'm2';
    }

    /**
     * Shallow product copy.
     * @return {Product}
     */
    public clone(): Product {
        return new Product(
            this.name,
            this.apiName,
            this.maxZoom,
            this.fieldBased,
            this.legend,
            this.unit,
            this.minVal,
            this.maxVal,
            this.timeSeriesType,
            this.areaAllowed,
            this.groups,
            this.productGroupName,
            this.productGroupValue
        );
    }

    /**
     * Set group product values
     * @param productGroupName
     * @param productGroupValue
     */
    setGroupProduct(productGroupName, productGroupValue) {
        this.productGroupName = productGroupName;
        this.productGroupValue = productGroupValue;
    }
}

export class DefaultProduct {
    /**
     * Product groups
     */
    @autoserializeAs(Group, 'group_product') groupProduct?: Group;
    /**
     * Product groups
     */
    @autoserializeAs(Product, 'product') product?: Product;
}

export class Metadata {
    @autoserialize name: string;
    @autoserialize type: string;
    @autoserialize content: string;

    constructo(name: string, type: string, content: string) {
        this.name = name;
        this.type = type;
        this.content = content;
    }
}

export class ProductMetadata {
    @autoserializeAs('api_name') apiName?: string;

    @autoserialize name?: string;

    @autoserializeAs(Metadata) metadata?: Metadata[];

    constructor(apiName?: string, name?: string, metadata?: Metadata[]) {
        this.apiName = apiName;
        this.name = name;
        this.metadata = metadata;
    }
}
