import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService, AccessInput } from '../auth/auth.service';

@Directive({
    selector: '[appStructuralAccess]',
})
export class StructuralAccessDirective {
    private hasView = false;

    @Input() set appStructuralAccess(permissions: AccessInput) {
        if (this.authService.hasPermission(permissions) && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {}
}
