import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import 'leaflet-areaselect';
import { debounceTime, distinctUntilChanged, startWith, map, filter, concatMap } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

import {
    UserSelectDialogComponent,
    UserSelectDialogData,
    UserSelectDialogResult,
} from '../user-select-dialog/user-select-dialog.component';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { DialogService } from '../dialog/dialog.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { VERSION } from '../../environments/version';
import { UserService } from '../../services/user.service';
import { environment } from '../../environments/environment';
import { DefaultProduct, Product } from '../../models/product';
import { ProductService } from '../../services/product.service';
import {
    GroupSelectorComponent,
    GroupSelectorData,
    GroupSelectorResult,
} from '../group-selector/group-selector.component';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { NewReleasePopupComponent } from '../new-release-popup/new-release-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { getUserForApi } from '../../utils/utils';
import moment from 'moment';

/**
 * LegendMode type.
 */
export type LegendMode = 'full' | 'dense' | 'collapsed' | 'extended';

/**
 * Selector, template and styles for the settings sidenav component
 */
@Component({
    selector: 'app-settings-sidenav',
    templateUrl: './settings-sidenav.component.html',
    styleUrls: ['./settings-sidenav.component.scss'],
})

/**
 * Settings sidenav component
 */
export class SettingsSidenavComponent implements OnInit {
    /**
     * Base layers map.
     */
    @Input() public baseLayers: object = {};

    /**
     * Identifier of the current base layer.
     */
    @Input() public currentLayer: string;

    /**
     * Supported grids
     */
    @Input() public supportedGrids: object = {};

    /**
     * Identifier of the current grid.
     */
    @Input() public currentGrid: string;

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<any>(true);

    /**
     * Base layer changed emitter.
     * @type {EventEmitter<Map>}
     */
    @Output() baseLayerChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Legend display mode changed emitter.
     * @type {EventEmitter<String>}
     */
    @Output() legendDisplayChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Close sidenav event.
     * @type {EventEmitter<Map>}
     */
    @Output() closeSidenav = new EventEmitter<any>(true);

    /**
     * Grid changed emitter.
     * @type {EventEmitter<string>}
     */
    @Output() gridChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Leaflet AreaSelect plugin object.
     * @type {L.AreaSelect}
     */
    private areaSelect: L.AreaSelect;

    /**
     * Legend display modes
     * @type {string[]}
     */
    public legendModes: Array<LegendMode> = ['full', 'dense', 'collapsed'];

    /**
     * Mode of legend display
     * @type {string}
     */
    public legendDisplay: LegendMode;

    /**
     * User for impersonated API calls
     */
    public userForApi: string;

    /**
     * User area unit.
     */
    public areaUnit: string;

    /**
     * Supported area units.
     */
    public supportedUnits = [
        { label: 'Hectares', value: 'hectares' },
        { label: 'Acres', value: 'acres' },
    ];

    /**
     * App version string.
     */
    public readonly version = VERSION.version;

    /**
     * App copyright.
     */
    public readonly copyright = environment.copyrightText;

    /**
     * Product list.
     */
    products: Product[] = [];

    /**
     * Form control for input field
     * @type {FormControl}
     */
    searchControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Form control for email input field
     * @type {FormControl}
     */
    emailSettingsControl: UntypedFormControl = new UntypedFormControl();

    /**
     * Observable for user list query
     */
    filteredProducts$: Observable<Product[]>;

    /**
     * Flag to avoid first api request on init
     */
    updateEmailForm = false;

    /**
     * Default product
     */
    defaultProduct: Product;

    constructor(
        public authService: AuthService,
        private router: Router,
        private snackBar: CustomSnackbarService,
        private dialogService: DialogService,
        private userService: UserService,
        private productService: ProductService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private dialog: MatDialog,
        public clipboard: ClipboardService
    ) {}

    ngOnInit() {
        this.userForApi = getUserForApi();
        this.userService.userSettings$.subscribe((settings) => {
            const { areaUnit } = settings.settings;
            this.emailSettingsControl.setValue(settings.settings.notificationEmail);
            if (areaUnit === undefined) {
                this.areaUnit = 'hectares';
            } else {
                this.areaUnit = areaUnit;
            }
        });
        this.userService.getDefaultProduct().subscribe((data: DefaultProduct) => {
            const product = data.product;
            if (data.groupProduct) {
                product.setGroupProduct(data.groupProduct.groupName, data.groupProduct.groupValue);
            }
            this.defaultProduct = product;
            this.searchControl.setValue(product);
            this.displayProduct(this.searchControl.value);
        });
        // Set the crudService config
        this.productService.list().subscribe((data: Product[]) => {
            this.products = data;

            this.filteredProducts$ = this.searchControl.valueChanges.pipe(
                startWith(''),
                debounceTime(100),
                distinctUntilChanged(),
                map((query) => this.filterProducts(query))
            );
        });

        this.emailSettingsControl.valueChanges.pipe(debounceTime(1500)).subscribe((email) => {
            if (this.updateEmailForm) {
                this.setNotificacionEmail(email);
            } else {
                this.updateEmailForm = true;
            }
        });
    }

    /**
     * Emit event to set the base layer.
     * @param baseLayer
     */
    setBaseLayer(baseLayer: MatSelectChange) {
        this.userService.updateUserSettings({ baseLayer: baseLayer.value });
        this.baseLayerChanged.emit(baseLayer.value);
        this.googleAnalyticsService.eventEmitter(
            'base_layer_setting_change',
            'settings_sidenav',
            'settings_base_layer',
            baseLayer.value
        );
    }

    /**
     * Emit event to set legend mode.
     * @param newMode
     */
    setLegendMode(newMode) {
        this.legendDisplayChanged.emit(newMode);
    }

    /**
     * Emit event to set the grid
     */
    setGrid(grid: MatSelectChange) {
        this.userService.updateUserSettings({ grid: grid.value });
        this.gridChanged.emit(grid.value);
        this.googleAnalyticsService.eventEmitter(
            'grid_setting_change',
            'settings_sidenav',
            'settings_grid',
            grid.value
        );
    }

    /**
     * Call the method logout from AuthService
     */
    public logout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }

    /**
     * Restore the map click event.
     */
    enableMapClick() {
        this.mapClick.emit(true);
    }

    /**
     * Shows user selection dialog
     */
    showUserSelection() {
        const dialogRef = this.dialog.open<
            UserSelectDialogComponent,
            UserSelectDialogData,
            UserSelectDialogResult
        >(UserSelectDialogComponent, { panelClass: 'user-select-dialog' });

        dialogRef
            .afterClosed()
            .pipe(
                filter((selected) => selected !== null && selected !== undefined),
                concatMap(({ id }) =>
                    this.userService.getUserData(id, ['permission_names', 'username', 'email'])
                )
            )
            .subscribe((user) => {
                this.authService.setUserForApi(user, user.permission_names);
                this.userForApi = getUserForApi();
                window.location.reload();
            });
    }

    /**
     * Forget user impersonation
     */
    forgetUser() {
        this.authService.setUserForApi(undefined);
        this.userForApi = undefined;
        window.location.reload();
    }

    /**
     * Show the dialog to update the user password.
     */
    showPasswordDialog() {
        const dialogRef = this.dialogService.openComponent(PasswordChangeComponent, {
            data: {
                forceChange: false,
            },
        });
    }

    /**
     * Go to the request account view.
     */
    requestAccount() {
        this.router.navigate(['/account/request']);
    }

    /**
     * Go to the account management view.
     */
    manageAccountRequests() {
        this.router.navigate(['/account/management']);
    }

    /**
     * Go to the user listing view.
     */
    listUsers() {
        this.router.navigate(['/account/users']);
    }

    /**
     * Update the user default area measure.
     * @param unit
     */
    setAreaUnit(unit: string) {
        this.userService.updateUserSettings({ areaUnit: unit });
        this.googleAnalyticsService.eventEmitter(
            'area_setting_change',
            'settings_sidenav',
            'settings_area',
            unit
        );
    }

    /**
     * Clear current selection
     */
    clearSelection() {
        this.searchControl.setValue(undefined);
    }

    /**
     * Update the user default notification email.
     * @param email
     */
    setNotificacionEmail(email: string) {
        this.userService.updateUserSettings({ notificationEmail: email });
    }

    /**
     * Returns product name for display
     */
    displayProduct(prod: Product): string {
        if (prod && prod.name !== null) {
            return `${prod.name}`;
        } else {
            return undefined;
        }
    }

    /**
     * Callback for product select change
     * @param event Change event containing the new product
     */
    onProductChange(event) {
        if (event.option.value === undefined) {
            return;
        }
        let groupName;
        this.defaultProduct = event.option.value;
        const productApiName = this.defaultProduct.apiName;
        if (this.defaultProduct.groups.length > 0) {
            if (this.defaultProduct.groups.length === 1) {
                groupName = this.defaultProduct.groups[0].groupName;
                this.defaultProduct.productGroupName = groupName;
                this.setDefaultProduct(productApiName, groupName);
            } else {
                const dialogRef = this.dialogService.openComponent<
                    GroupSelectorComponent,
                    GroupSelectorData,
                    GroupSelectorResult
                >(GroupSelectorComponent, {
                    data: {
                        groups: this.defaultProduct.groups,
                    },
                    disableClose: true,
                    panelClass: 'group-select-dialog',
                });

                dialogRef.afterClosed().subscribe((selected) => {
                    if (selected) {
                        groupName = selected.groupName;
                        this.defaultProduct.productGroupName = groupName;
                        this.setDefaultProduct(productApiName, groupName);
                    }
                });
            }
        } else {
            this.setDefaultProduct(productApiName);
        }
        this.googleAnalyticsService.eventEmitter(
            'default_product_setting_change',
            'settings_sidenav',
            'settings_default_product',
            productApiName ? productApiName : groupName
        );
    }

    /**
     * Filter product list
     *
     * @param query
     */
    private filterProducts(query): Product[] {
        if (query === undefined) {
            return undefined;
        } else if (query instanceof Product) {
            query = query.name;
        }
        const filterValue = query.toLowerCase();
        return this.products.filter((prod) => {
            return !prod.fieldBased && prod.name && prod.name.toLowerCase().includes(filterValue);
        });
    }

    /**
     * Close settings sidenav
     */
    close() {
        this.closeSidenav.emit();
    }

    setDefaultProduct(productApiName, groupName?) {
        this.userService.setDefaultProduct(productApiName, groupName).subscribe((data) => {
            this.snackBar.present(
                'Default product updated. Changes will be applied after reloading.'
            );
        });
    }

    /**
     * Open the release info dialog.
     * @param event
     */
    openReleaseInfoDialog(event) {
        event.stopPropagation();
        this.dialog.open(NewReleasePopupComponent, {
            width: '30%',
            height: 'auto',
            panelClass: 'new-release-popup',
        });
    }

    copyToken(token: string): void {
        this.clipboard.copyFromContent(token);

        const validUntil = this.authService.getExpirationDate();
        if (validUntil) {
            const hours = validUntil.diff(moment.utc(), 'hours');
            if (hours === 0) {
                this.snackBar.present(
                    `Token copied to the clipboard, it is valid for less than an hour`
                );
            } else if (hours === 1) {
                this.snackBar.present('Token copied to the clipboard, it is valid for 1 hour');
            } else {
                this.snackBar.present(
                    `Token copied to the clipboard, it is valid for ${hours} hours`
                );
            }
        } else {
            this.snackBar.present('Token copied to the clipboard');
        }
    }
}
