import { autoserialize, autoserializeAs } from 'cerialize';

/**
 * User CRUD class
 */
export class User {
    /**
     * User ID
     */
    id: number;

    /**
     * User name
     */
    name: string;

    /**
     * User email
     */
    email: string;

    /**
     * User username
     */
    username: string;

    /**
     * Number of logins
     */
    login_count: number;

    /**
     * Time of the last login
     */
    last_login_at: string;

    /**
     * Is enabled
     */
    enabled: boolean;

    /**
     * Is password change needed
     */
    need_password_change: boolean;

    /**
     * User affiliation
     */
    affiliation: string;

    /**
     * User max area
     */
    max_area: number;

    /**
     * User max regions
     */
    max_rois: number;

    /** User permission names */
    permission_names: string[];

    /**
     * Default constructor
     * @param {Object} jsonData
     */
    constructor(jsonData?: object) {
        Object.assign(this, jsonData);
    }
}

export class ResetPasswordTokenInfo {
    @autoserializeAs('has_expired') hasExpired?: boolean;
    @autoserialize username?: string;

    constructor(hasExpired: boolean, username: string) {
        this.hasExpired = hasExpired;
        this.username = username;
    }
}
