<div class="title-wrapper flex justify-between items-center">
    <div>
        <span class="title"><i class="fa fa-info-circle icon-w-text"></i>Product information</span>
    </div>
    <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
        <i class="fa fa-times icon-close"></i>
    </button>
</div>

<div
    class="product-metadata-wrapper flex flex-col gap-4"
    *ngIf="productInfo$ | async; let productInfo"
>
    <div class="flex justify-center items-center">
        <h2 class="title">{{ productInfo.name }}</h2>
    </div>

    <div
        class="flex flex-col justify-between items-center"
        *ngFor="let prop of productInfo.metadata"
    >
        <h3 class="prop-name font-bold">{{ prop.name }}</h3>
        <span class="prop-value" *ngIf="prop.type === 'html'" [innerHTML]="prop.content"></span>
        <span class="prop-value" *ngIf="prop.type !== 'html'">{{ prop.content }}</span>
    </div>
</div>
