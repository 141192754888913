import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Moment } from 'moment';

import { TimeSeries } from '../time-series/time-series.component';
import { UserProductMultiSelectComponent } from '../user-product-multi-select/user-product-multi-select.component';
import { UserProductMultiSelectService } from '../../services/user-product-multi-select.service';
import { ProductService } from '../../services/product.service';
import {
    TimeSeriesApiDialogComponent,
    TimeSeriesApiDialogData,
} from '../time-series-api-dialog/time-series-api-dialog.component';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { transformCoordinates } from '../../utils/coordinates-utils';
import {
    TimeSeriesInfoDialogComponent,
    TimeSeriesInfoDialogData,
    TimeSeriesInfoDialogResult,
} from '../time-series-info-dialog/time-series-info-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { assertNever } from '../../utils/utils';
import { Product } from '../../models/product';
import { Region } from '../../models/region';
import { UserSettingsInfo } from '../../services/user.service';

export type TimeSeriesDialogMode = 'point' | 'roi';

export type TimeSeriesDialogData = TimeSeriesDialogROIData | TimeSeriesDialogPointData;

export interface TimeSeriesDialogDataBase {
    product: Product | undefined;
    userSettings: UserSettingsInfo | undefined;
}

export interface TimeSeriesDialogROIData extends TimeSeriesDialogDataBase {
    mode: 'roi';
    region: Region;
}

export interface TimeSeriesDialogPointData extends TimeSeriesDialogDataBase {
    mode: 'point';
    date: Moment;
    lat: number;
    lng: number;
    interval?: number | undefined;
}

@Component({
    selector: 'app-time-series-dialog',
    templateUrl: './time-series-dialog.component.html',
    styleUrls: ['./time-series-dialog.component.scss'],
})
export class TimeSeriesDialog implements OnInit {
    /**
     * Default interval, in days, to compute the initial date for the time series.
     */
    protected static defaultInterval = 30;

    /**
     * Selected start date for displaying the data.
     */
    public dateStart: Moment;

    /**
     * Selected end date for displaying the data.
     */
    public dateEnd: Moment;

    /**
     * Latitude of the selected point
     */
    public lat: number | undefined;

    /**
     * Longitude of the selected point
     */
    public lng: number | undefined;

    /**
     * Reference to time series component in this dialog
     */
    @ViewChild(TimeSeries, { static: true }) timeSeries: TimeSeries;

    /**
     * Reference to time series component in this dialog
     */
    @ViewChild(UserProductMultiSelectComponent)
    userProductMultiSelect: UserProductMultiSelectComponent;

    /**
     * if year mode is enabled
     * @type {boolean}
     */
    public isYearMode = false;

    /**
     * if year mode is always enabled
     * @type {boolean}
     */
    public yearModeOnly = false;

    /**
     * year mode start day of year
     */
    public yearModeStart = 1;

    /**
     * if advanced mode is enabled
     */
    public advancedMode = false;

    /**
     * Variable used to store the latitude in the user coordinate system
     */
    transformedLat;

    /**
     * Variable used to store the longitude in the user coordinate system
     */
    transformedLng;

    /**
     * Reference to the loading dialog shown while processing a new api request
     */
    private loadingDialogRef: MatDialogRef<LoadingDialogComponent>;

    /**
     * Number of previous products that were in the time series. Used to set the start date and end date in the time series when
     * there is only one product.
     */
    previousProductsLength: number;

    /**
     * String that defines the current coordinate system for a user
     */
    get coordinateSystem(): string {
        return this.data.userSettings?.settings?.coordinateSystem ?? 'EPSG:4326';
    }

    /**
     * Initialize the dialog with the required data (point, product and date)
     */
    constructor(
        private productSelectService: UserProductMultiSelectService,
        public dialogRef: MatDialogRef<TimeSeriesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: TimeSeriesDialogData,
        private productService: ProductService,
        public matDialog: MatDialog,
        private snackBar: CustomSnackbarService,
        private dialogService: DialogService
    ) {
        switch (this.data.mode) {
            case 'point':
                this.lat = +this.data.lat.toFixed(5); // five decimal digits only
                this.lng = +this.data.lng.toFixed(5);

                const interval = this.data.interval || TimeSeriesDialog.defaultInterval;
                this.dateStart = (this.data.date ? moment(this.data.date) : moment()).subtract(
                    interval,
                    'days'
                );

                this.dateEnd = this.data.date ? moment(this.data.date) : moment();
                break;
            case 'roi':
                this.productSelectService.setProducts([this.data.product]);
                this.advancedMode = true;
                this.dateStart = undefined;
                this.dateEnd = undefined;
                break;
            default:
                assertNever(this.data);
        }
    }

    /**
     * Closes the dialog.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Update the chart with the current parameters.
     */
    reload() {
        const productsList = this.advancedMode
            ? this.productSelectService.getProducts()
            : [this.data.product];

        const products = productsList.map((product) => product.clone());

        if (this.userProductMultiSelect !== undefined) {
            this.userProductMultiSelect.timeSeriesLoadedStatus(false);
        }

        switch (this.data.mode) {
            case 'point':
                this.timeSeries.setPointData(
                    products,
                    this.dateStart,
                    this.dateEnd,
                    this.lat,
                    this.lng
                );
                break;
            case 'roi':
                const region = this.data.region;
                if (products.length > 0) {
                    if (products.length === 1) {
                        this.productService
                            .getAvailability(products[0].apiName)
                            .subscribe((result: any) => {
                                const productStartDate = result.availability[0];
                                const productEndDate =
                                    result.availability[result.availability.length - 1];
                                if (!this.dateStart || !this.dateEnd) {
                                    this.setRoiDates(productStartDate, productEndDate);
                                }
                                if (
                                    this.previousProductsLength > 1 ||
                                    this.previousProductsLength === 0
                                ) {
                                    this.setRoiDates(productStartDate, productEndDate);
                                }
                                this.timeSeries.setROIData(
                                    region,
                                    products,
                                    this.dateStart,
                                    this.dateEnd
                                );
                            });
                    } else {
                        this.timeSeries.setROIData(region, products, this.dateStart, this.dateEnd);
                    }
                } else {
                    this.timeSeries.setROIData(region, products);
                }
                this.previousProductsLength = products.length;
                break;
            default:
                assertNever(this.data);
        }

        if (this.data.product.isYearModeOnly()) {
            this.yearModeOnly = true;
            this.isYearMode = true;
            this.timeSeries.setYearMode();
        } else {
            this.isYearMode = false;
            this.timeSeries.setLinearMode();
        }
        // It overflows-x if we don't wait
        setTimeout(() => {
            this.timeSeries.updateChart();
        }, 10);
    }

    /**
     * Angular default initialization method
     */
    ngOnInit() {
        this.reload();
        this.productSelectService.setProducts([this.data.product]);
        if (this.data.mode === 'roi') {
            this.dialogRef.updateSize('95%');
        }
    }

    /**
     * Export time series nvd3 as png
     */
    exportAsPng() {
        this.timeSeries.saveChartAsPng();
    }

    /**
     * Export time series nvd3 as csv
     */
    exportAsCsv() {
        this.timeSeries.saveAsCsv();
    }

    /**
     * On advance mode toggle change event
     * @param event
     */
    toggleAdvanceMode(event) {
        this.advancedMode = !this.advancedMode;
        if (this.advancedMode) {
            // disable year mode if advance mode is selected
            this.isYearMode = false;
            this.dialogRef.updateSize('95%');
            this.timeSeries.updateChart();
        } else {
            // restore initial product and reload graph
            this.reload();
            this.productSelectService.setProducts([this.data.product]);
            this.dialogRef.updateSize('80%');
        }
    }

    /**
     * On change product time series event
     * @param event
     */
    updateProductVisibility(event) {
        this.timeSeries.updateProductVisibility(event);
    }

    /**
     * Set start date and end date of the roi time series
     * @param startDate
     * @param endDate
     */
    setRoiDates(startDate, endDate) {
        this.dateStart = startDate;
        this.dateEnd = endDate;
    }

    /**
     * Time series loaded event handler
     */
    timeSeriesLoadedEvent() {
        if (this.userProductMultiSelect) {
            this.userProductMultiSelect.timeSeriesLoadedStatus(true);
        }
    }

    handleTimeSeriesDialog() {
        const dialogRef = this.matDialog.open<
            TimeSeriesApiDialogComponent,
            TimeSeriesApiDialogData
        >(TimeSeriesApiDialogComponent, {
            width: '500px',
            data: {
                mode: this.data.mode,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                lat: this.lat,
                lng: this.lng,
                coordinateSystem: this.coordinateSystem,
                notificationEmail: this.data.userSettings?.settings?.notificationEmail,
            },
            panelClass: 'time-series-api-dialog',
        });

        dialogRef.afterClosed().subscribe((params: any) => {
            if (params !== undefined) {
                this.showProcessingApiRequestDialog();
                this.productService
                    .createProductPointTimeSeriesRequest(this.data.product.apiName, params)
                    .subscribe(
                        (result: any) => {
                            if (result.message === 'Download request received') {
                                this.snackBar.present(
                                    `API request ${result.uuid} has been created. You can check the status of the request` +
                                        ' in the API access menu.',
                                    'success'
                                );
                                this.checkProcessingApiRequest();
                            }
                        },
                        (error) => {
                            this.checkProcessingApiRequest();
                        }
                    );
            }
        });
    }

    /**
     * Method to show a dialog with a processing api request message
     */
    showProcessingApiRequestDialog() {
        this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            data: {
                loadingMessages: { 'api-request': 'Submitting API request...' },
            },
        });
    }

    /**
     * Method that closes the processing api request dialog
     */
    checkProcessingApiRequest() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }

    updateCoordinates(event, type) {
        if (this.coordinateSystem === 'EPSG:28992') {
            const transformedCoordinates = transformCoordinates(
                this.lng,
                this.lat,
                'EPSG:4326',
                'EPSG:28992'
            );
            if (!this.transformedLng) {
                this.transformedLng = transformedCoordinates[0];
            }
            if (!this.transformedLat) {
                this.transformedLat = transformedCoordinates[1];
            }
            if (type === 'lat') {
                this.transformedLat = event;
            }
            if (type === 'lng') {
                this.transformedLng = event;
            }
            const latLngCoordinates = transformCoordinates(
                this.transformedLng,
                this.transformedLat,
                'EPSG:28992',
                'EPSG:4326'
            );
            this.lng = latLngCoordinates[0];
            this.lat = latLngCoordinates[1];
        }
    }

    /**
     * Method to show the information and param values used to generate the current time series
     */
    showTimeSeriesParamsDialog() {
        this.dialogService.openComponent<
            TimeSeriesInfoDialogComponent,
            TimeSeriesInfoDialogData,
            TimeSeriesInfoDialogResult
        >(TimeSeriesInfoDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: this.timeSeries.params,
        });
    }
}
