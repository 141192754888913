import {
    Component,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnInit,
    Output,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import * as L from 'leaflet';
import 'leaflet-draw';

import { Region } from '../../models/region';
import {
    RoiPreviewDialogComponent,
    RoiPreviewResult,
} from '../roi-preview-dialog/roi-preview-dialog.component';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { MapPopupComponent } from '../map-popup/map-popup.component';
import { RoiService } from '../../services/roi.service';
import { RoiHelpComponent } from '../roi-help/roi-help.component';
import { RoiMaxComponent } from '../roi-max/roi-max.component';
import { AuthService } from '../auth/auth.service';
import { RoiManagementComponent } from '../roi-management/roi-management.component';
import { LayersService } from '../../services/layers.service';
import { DialogService } from '../dialog/dialog.service';
import { RoiUploadFileComponent } from '../roi-upload-file/roi-upload-file.component';
import {
    RoiUploadResult,
    UploadRoiProgressComponent,
} from '../upload-roi-progress/upload-roi-progress.component';
import { debounceTime } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { LabelManagementComponent } from '../label-management/label-management.component';
import { Label } from '../../models/label';
import { GeoJsonObject } from 'geojson';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { decodeBase64Unicode, PRECISION } from '../../utils/utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

const SHOW_HIDE_ALL_MSG = 'Show / Hide all ROIs';
const SHOW_HIDE_FILTERED_MSG = 'Show / Hide filtered ROIs';

/**
 * Component selector, template and style definitions.
 */
@Component({
    selector: 'app-roi-sidenav',
    templateUrl: './roi-sidenav.component.html',
    styleUrls: ['./roi-sidenav.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-50px)', opacity: 0 }),
                animate('200ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('200ms', style({ transform: 'translateX(-50px)', opacity: 0 })),
            ]),
        ]),
    ],
})

/**
 * Regions dialog component
 */
export class RoiSidenavComponent implements OnInit {
    /**
     * List of regionList. Contains the actual info for each region.
     */
    public regionList: Region[] = [];

    /**
     * List of regionList. Only used to be shown in the sidenav.
     */
    public filteredRegionList: Region[] = [];

    /**
     * Leaflet map reference.
     */
    @Input() public mapReference: L.Map;

    /**
     * Selected region reference.
     */
    @Input() public selectedROI;

    /**
     * Maximum number of ROIs that can be created by an user.
     */
    @Input() public userSettings;

    /**
     * Reference to Map component edition modes object:
     * captureRegionMode: Whether the capture region mode is enabled or not
     */
    @Input() public editionModes: object;

    /**
     * Reference to the map popup component
     */
    @Input() public mapPopupReference: MapPopupComponent;

    /**
     *  New region added event.
     */
    @Output() regionAdd = new EventEmitter<Region>(true);

    /**
     * Restore map click event.
     */
    @Output() mapClick = new EventEmitter<boolean>(true);

    /**
     *  Repaint region event.
     */
    @Output() regionsShow = new EventEmitter<Region[]>(true);

    /**
     * Shape clicked emitter.
     */
    @Output() shapeClicked = new EventEmitter<Region>(true);

    /**
     * Shape hovered emitter.
     */
    @Output() shapeHovered = new EventEmitter<Region | null>(true);

    /**
     * Close sidenav event.
     */
    @Output() closeSidenav = new EventEmitter<void>(true);

    /**
     * Material virtual scroll viewport
     */
    @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

    /**
     * Show all regions flag
     */
    private showAll = true;

    /**
     * Show maximun ROIs message flag
     */
    public showMaxROIsMessage = false;

    /**
     * Sortable ROI component options
     */
    public options: {
        disabled: boolean;
        onUpdate: (event: any) => void;
        onEnd: (event: any) => void;
    };

    /**
     * Leaflet draw control reference.
     */
    protected leafletDrawRef;

    /**
     * Order rois button flag.
     */
    public orderRoisChecked = false;

    /**
     * Form control for search rois input field
     */
    searchRois = new FormControl('', { nonNullable: true });

    /**
     * List of regions that contains the searched word
     */
    foundRegions = [];

    /**
     * Index of the search list
     */
    foundIndex = 0;

    /**
     * Flag to detect if the device is a computer or a mobile device
     */
    isDesktopDevice: boolean;

    /**
     * Reference to the loading dialog shown while processing a new api request
     */
    private loadingDialogRef: MatDialogRef<LoadingDialogComponent>;

    /**
     * Flag to show/hide the roi sidenav list (if there are lot of rois, it affects the performance of the app)
     */
    showRoiSidenavList = false;

    /**
     * Reference to the label management dialog
     */
    private labelManagementDialogRef: MatDialogRef<any>;

    /**
     * Array to contain the labels that region having them will be shown in the map
     */
    showByLabels: Label[] = [];

    /**
     * Boolean to control the show-hide behaviour for the region list when it is filtered
     */
    private showFiltered = true;

    /**
     * Message to be shown in the Show/Hide all button
     */
    showHideAllMessage = SHOW_HIDE_ALL_MSG;

    /**
     * Value for itemSize property for the cdk-virtual-scroll-viewport element containing the ROI list
     * Set as max desktop height divided the number of elements that height allows
     */
    roiItemSize = 520 / 6;

    /**
     * Value for the min of pixels that the cdk-virtual-scroll-viewport element will render off-screen for a smoother scroll
     * It's set as the size of two "pages" of scroll
     */
    minScrollBuffer = 2 * 520;

    /**
     * Value for the max of pixels that the cdk-virtual-scroll-viewport element will render off-screen for a smoother scroll
     * It's set as the size of three "pages" of scroll
     */
    maxScrollBuffer = 3 * 520;

    /**
     * Reference to the roi management component
     */
    roiManagementComponentDialogRef;

    private breakpointObserver = inject(BreakpointObserver);

    isWeb: boolean;

    constructor(
        private roiService: RoiService,
        private matDialogService: MatDialog,
        private snackBar: CustomSnackbarService,
        private ngZone: NgZone,
        private authService: AuthService,
        private layersService: LayersService,
        private dialogService: DialogService,
        private deviceService: DeviceDetectorService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.breakpointObserver.observe(Breakpoints.WebLandscape).subscribe((result) => {
            this.isWeb = result.matches;
        });
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    ngOnInit() {
        this.updateList();
        this.leafletDrawRef = new L.Control.Draw({
            draw: {
                marker: false,
                circlemarker: false,
                polyline: false,
            },
        });

        this.searchRois.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
            this.applySearchFilter(value);
            this.googleAnalyticsService.eventEmitter(
                'roi_search_used',
                'roi_sidenav',
                'roi_sidenav_filter',
                this.searchRois.value
            );
        });
    }

    /** Apply filter by given value on the region list and returns the resulting list */
    private applySearchFilter(value: string) {
        this.filteredRegionList = [...this.regionList];
        this.showHideAllMessage = SHOW_HIDE_ALL_MSG;
        if (value.length > 0) {
            this.filteredRegionList = this.regionList.filter(
                (x) =>
                    x.name?.toUpperCase().includes(value.toUpperCase()) ||
                    x.id.toString().includes(value) ||
                    x.description?.toUpperCase().includes(value.toUpperCase()) ||
                    x.labels.some((l) => l.title.toUpperCase().indexOf(value.toUpperCase()) > -1)
            );
            this.showHideAllMessage = SHOW_HIDE_FILTERED_MSG;
        }
        if (this.showByLabels.length > 0) {
            this.filteredRegionList = this.filteredRegionList.filter((r) =>
                this.regionHasLabels(r, this.showByLabels)
            );
            this.showHideAllMessage = SHOW_HIDE_FILTERED_MSG;
        }

        this.showFiltered = !this.filteredRegionList.some((r) => r.display === true);
    }

    /**
     * Clear the searchRois form comntrol value and the found regions list
     */
    clearSearch() {
        this.searchRois.setValue('');
        this.foundRegions = [];
    }

    /**
     * Open the sidenav.
     */
    open() {
        this.showRoiSidenavList = true;
        if (
            this.authService.hasPermission('draw-roi') ||
            this.authService.hasPermission('delete-roi') ||
            this.authService.hasPermission('manage-rois')
        ) {
            if (
                this.userSettings.max_rois > 0 &&
                this.regionList.length >= this.userSettings.max_rois
            ) {
                this.showMaxROIsMessage = true;
            } else {
                this.mapReference.addControl(this.leafletDrawRef);
                if (!this.isDesktopDevice) {
                    const leafletDrawControl =
                        document.getElementsByClassName('leaflet-draw-section');
                    if (leafletDrawControl.length > 0) {
                        leafletDrawControl[0].className = 'leaflet-draw-section-mobile-device';
                    }
                }
            }
        }

        // TODO: Move to (?). mapRef must be initialized
        this.mapReference.on('draw:created', (e: any) => {
            const layer = e.layer;
            const region = new Region();
            region.geojson = {
                type: 'GeometryCollection',
                geometries: [layer.toGeoJSON(PRECISION).geometry],
            };
            region.labels = [];
            if (e.layerType === 'circle') {
                // Leaflet considers a circle to be a point with radius. This region will be type point and the coordinates will be the
                // center of the circle.
                region.geojson.geometries[0].radius = layer._mRadius;
            }

            this.ngZone.run(() => {
                this.previewRegion(region);
            });
        });
        this.mapReference.on('draw:drawstart', () => {
            this.disableMapClick();
        });
        this.mapReference.on('draw:drawstop', () => {
            this.enableMapClick();
        });
    }

    /**
     * Enable map click event.
     */
    disableMapClick() {
        this.editionModes['shapeEditMode'] = true;
        this.mapReference.off('click');
        this.mapPopupReference.close();
    }

    /**
     * Enable map click event.
     */
    enableMapClick() {
        this.editionModes['shapeEditMode'] = false;
        this.mapReference.off('click');
        this.mapClick.emit(true);
    }

    /**
     * Close the sidenav.
     */
    close() {
        if (
            this.authService.hasPermission('draw-roi') ||
            this.authService.hasPermission('delete-roi') ||
            this.authService.hasPermission('manage-rois')
        ) {
            this.mapReference.removeControl(this.leafletDrawRef);
            this.mapReference.off('draw:created');
            this.mapReference.off('draw:drawstart');
            this.mapReference.off('draw:drawstop');
        }
    }

    /**
     * Request the list of products and updates the model.
     **/
    updateList() {
        this.showRoiSidenavList = false;
        this.roiService.list(false).subscribe((data) => {
            this.regionList = data.rois.reverse();
            this.filteredRegionList = [...this.regionList];
            this.getVisibleGeojson();
        });
    }

    /**
     *  Save the region that is in the preview and add it to the map.
     */
    addRegion(regionList: Region[]) {
        // upload rois one by one with the UploadRoiProgressComponent
        const uploadProgressRef = this.matDialogService.open(UploadRoiProgressComponent, {
            data: { rois: regionList },
            panelClass: 'upload-roi-dialog',
            disableClose: true,
        });

        uploadProgressRef.afterClosed().subscribe((dialogResult: RoiUploadResult) => {
            if (dialogResult !== undefined) {
                // add uploaded rois to the map
                for (const r of dialogResult.uploaded) {
                    this.regionAdd.emit(r); // Send it to the map
                    this.regionList.unshift(r);
                }
                if (
                    this.userSettings.max_rois > 0 &&
                    this.regionList.length >= this.userSettings.max_rois
                ) {
                    this.showMaxROIsMessage = true;
                    this.mapReference.removeControl(this.leafletDrawRef);
                }

                if (dialogResult.uploaded) {
                    this.refreshRegionsOnMap();
                }
                this.refreshRegionList();
                this.applySearchFilter(this.searchRois.value);
            }
        });
    }

    /**
     *  Send the regionList that have display=true to the map.
     */
    showHideRegions() {
        this.selectedROI = undefined;
        if (this.filteredRegionList.length > 0) {
            const roisWithoutGeojson = this.filteredRegionList.filter((r) => !r.geojson);
            if ((!this.showAll || this.showFiltered) && roisWithoutGeojson.length > 0) {
                const visibleRoisIds = roisWithoutGeojson.map((roi) => roi.id);
                this.roiService.list(true, visibleRoisIds).subscribe((result) => {
                    this.updateRoisGeojson(result.rois);
                    this.showHideAll();
                });
            } else {
                this.showHideAll();
            }
        }
    }

    /**
     * Set display value to all/filtered regions
     */
    showHideAll() {
        let filteredListIds;
        let showParam;
        if (
            this.filteredRegionList.length > 0 &&
            this.filteredRegionList.length !== this.regionList.length
        ) {
            filteredListIds = this.filteredRegionList.map((r) => r.id);
            showParam = !this.filteredRegionList.some((r) => r.display === true);
        } else {
            this.showAll = !this.showAll;
            showParam = this.showAll;
        }

        this.roiService.showHideAll(showParam, filteredListIds).subscribe(() => {
            if (filteredListIds && filteredListIds.length > 0) {
                this.filteredRegionList.forEach((r: Region) => {
                    r.display = showParam;
                });
            } else {
                this.regionList.forEach((r: Region) => {
                    r.display = showParam;
                });
            }
            this.applySearchFilter(this.searchRois.value);
            this.refreshRegionList();
            this.refreshRegionsOnMap();
            this.googleAnalyticsService.eventEmitter(
                'rois_show_hide_all_button_clicked',
                'roi_sidenav',
                'rois_show_hide_all',
                this.showAll.toString()
            );
        });
    }

    /**
     * Refresh all regions on the map, taking the displayed parameter into account.
     *
     * @param redrawLayers: update the roi id list and redraw the product layers.
     */
    refreshRegionsOnMap(redrawLayers = true) {
        let displayedRegions = this.filteredRegionList.filter((reg: Region) => {
            return reg.display;
        });

        if (this.regionList.length !== this.filteredRegionList.length) {
            displayedRegions = displayedRegions.concat(
                this.regionList.filter((reg: Region) => {
                    return reg.display === true && displayedRegions.indexOf(reg) === -1;
                })
            );
        }

        if (displayedRegions.length === 0) {
            this.showAll = false;
        }

        if (redrawLayers) {
            // Update the layer service ROIs ID list to avoid tile caching
            const roisId = this.regionList.filter((r) => r.display).map((r) => r.id);
            this.layersService.updateRois(roisId); // Update layers
        }
        this.regionsShow.emit(displayedRegions); // Update regions
    }

    /**
     * Delete a region.
     *
     * @param {Region} region
     **/
    deleteRegion(region: Region) {
        // HTTP Request
        const stream = this.roiService.del(region);
        stream.subscribe((data) => {
            // Delete the region from the model
            const shapeIndex = this.regionList.map((x) => x.id).indexOf(region.id);
            this.regionList.splice(shapeIndex, 1);
            if (
                this.userSettings.max_rois > 0 &&
                this.regionList.length < this.userSettings.max_rois
            ) {
                this.showMaxROIsMessage = false;
                this.mapReference.addControl(this.leafletDrawRef);
            }
            this.applySearchFilter(this.searchRois.value);
            this.refreshRegionList();
            this.refreshRegionsOnMap();
            this.snackBar.present('Region deleted successfully.');
        });
    }

    /**
     * Update a region.
     *
     * @param {Region} region
     **/
    updateRegion(region: Region) {
        this.selectedROI = undefined;
        const index = this.regionList.indexOf(region);
        // HTTP Request
        const stream = this.roiService.update(region);
        stream.subscribe((data) => {
            if (!this.regionList[index].geojson) {
                this.regionList[index].geojson = data['geojson'];
            }
            this.refreshRegionList();
            this.refreshRegionsOnMap(false);
        });
    }

    /**
     * Region preview.
     *
     * @param {Region} region Region to preview.
     */
    previewRegion(region: Region) {
        // Region preview
        const validateLayer = L.geoJSON(<GeoJsonObject>region.geojson);
        if (validateLayer.getLayers().length === 0) {
            this.snackBar.present('Shapefile does not include any feature.', 'error');
            return;
        }
        const previewDialogRef = this.matDialogService.open(RoiPreviewDialogComponent, {
            data: { region: new Region(region) },
            panelClass: 'roi-preview-dialog',
        });

        previewDialogRef.afterClosed().subscribe((dialogResult: RoiPreviewResult) => {
            if (dialogResult === undefined) {
                return;
            }

            if (dialogResult.delete === true) {
                this.deleteRegion(region);
            } else if (dialogResult.mode === 'create') {
                this.addRegion(dialogResult.regions);
            } else if (dialogResult.mode === 'edit') {
                const updatedRegion = dialogResult.regions[0];
                const regionIndex = this.regionList.findIndex((r) => r.id === updatedRegion.id);
                this.regionList[regionIndex] = updatedRegion;
                this.applySearchFilter(this.searchRois.value);
                this.updateRegion(updatedRegion);
            }
        });
    }

    /**
     * Method triggered when a region is selected
     * @param {Region} region The selected region
     */
    roiSelected(region: Region) {
        this.shapeClicked.emit(region);
    }

    /**
     * Method triggered when a region is hovered
     * @param {Region} region The hovered region
     */
    roiHovered(region: Region) {
        this.shapeHovered.emit(region);
    }

    /**
     * Method triggered when you exit with the mouse any region hovered
     */
    roiHoveredReset() {
        this.shapeHovered.emit(null);
    }

    /**
     * Upload a GeoJSON (.json) or Shapefile (.zip with [.dbf, .shp, .shx]) file using the input type='file' change event.
     *
     * @param event ngChange event.
     */
    importRegion(event: Event) {
        if (!this.authService.hasPermission('manage-rois')) {
            // this role cannot upload shapefiles
            return;
        }

        const srcElement: any = event.target;

        // Check files selected
        if (srcElement.files.length === 1) {
            const file: File = (event.target as any).files[0];
            const extension = file.name.split('.').pop();
            const fileName = file.name.split('.')[0];
            if (file.size > 50 * 1024 * 1024) {
                this.snackBar.present('Max. file size: 50MB.', 'error');
                return;
            }

            // Check the file extension
            if (extension === 'json' || extension === 'geojson') {
                const reader = new FileReader();
                reader.onload = (data) => {
                    try {
                        const fileData = decodeBase64Unicode(
                            (<any>data.target).result.split(',')[1]
                        );
                        const geoJSON = JSON.parse(fileData);
                        const region = new Region({ geojson: geoJSON });
                        region.fileName = fileName;
                        this.previewRegion(region);
                    } catch (e) {
                        // JSON Parse error
                        if (e instanceof SyntaxError) {
                            this.snackBar.present('Bad JSON Syntax.', 'error');
                        } else {
                            console.error(e);
                        }
                    }
                };

                // Read the file
                reader.readAsDataURL(file);
            } else if (extension === 'zip') {
                // upload rois one by one with the UploadRoiProgressComponent
                const uploadProgressRef = this.matDialogService.open(UploadRoiProgressComponent, {
                    data: { file: file, autoClose: true },
                    panelClass: 'upload-roi-dialog',
                    disableClose: true,
                });

                uploadProgressRef.afterClosed().subscribe((dialogResult: RoiUploadResult) => {
                    if (dialogResult !== undefined) {
                        if (dialogResult.region === null) {
                            this.snackBar.present('The zip shapefile cannot be read.', 'error');
                        } else {
                            const region = dialogResult.region;
                            region.fileName = fileName;
                            this.previewRegion(region);
                        }
                    }
                });
            } else {
                this.snackBar.present('Invalid file extension.', 'error');
            }
        } else {
            this.snackBar.present('Please select one file.', 'error');
        }

        // Clear the input model
        srcElement.value = null;
    }

    /**
     * Open the ROI Help dialog.
     */
    showRoiHelp() {
        this.dialogService.openComponent(RoiHelpComponent);
        this.googleAnalyticsService.eventEmitter(
            'roi_help_button_clicked',
            'roi_sidenav',
            'roi_help',
            'roi_help_dialog_open'
        );
    }

    /**
     * Open the MAX ROI Help dialog.
     */
    showMaxRoi() {
        this.dialogService.openComponent(RoiMaxComponent);
    }

    /**
     * Open the ROI management dialog.
     */
    showRoiManagement() {
        const roisWithoutGeosjon = this.regionList.filter((r) => !r.geojson);
        if (roisWithoutGeosjon.length > 0) {
            const visibleRoisIds = roisWithoutGeosjon.map((roi) => roi.id);
            this.showProcessingApiRequestDialog();
            this.roiService.list(true, visibleRoisIds).subscribe(
                (result) => {
                    this.updateRoisGeojson(result.rois);
                    this.onManageRoiManagementDialog();
                    this.checkProcessingApiRequest();
                },
                (_) => {
                    this.checkProcessingApiRequest();
                }
            );
        } else {
            this.onManageRoiManagementDialog();
        }
    }

    showLabelManagement() {
        this.labelManagementDialogRef = this.dialogService.openComponent(LabelManagementComponent, {
            width: '50%',
        });
        this.labelManagementDialogRef.afterClosed().subscribe(() => {
            this.setAllRegionsLabels();
            this.applySearchFilter(this.searchRois.value);
        });
        this.googleAnalyticsService.eventEmitter(
            'roi_label_management_button_clicked',
            'roi_sidenav',
            'roi_labels',
            'roi_label_management_dialog_open'
        );
    }

    /**
     * Roi Management Dialog operations
     */
    onManageRoiManagementDialog() {
        this.roiManagementComponentDialogRef = this.matDialogService.open(RoiManagementComponent, {
            width: '80%',
            data: { regions: this.regionList },
            panelClass: 'roi-management-dialog',
        });

        this.roiManagementComponentDialogRef.componentInstance.deleteRegion.subscribe(
            (regionId) => {
                const region = this.regionList.find((e) => e.id === regionId);
                this.deleteRegion(region);
            }
        );

        this.roiManagementComponentDialogRef.componentInstance.updateRegion.subscribe((roiData) => {
            const region = this.regionList.find((e) => e.id === roiData.id);
            Object.assign(region, roiData);
            this.updateRegion(region);
        });
        this.googleAnalyticsService.eventEmitter(
            'roi_management_button_clicked',
            'roi_sidenav',
            'roi_order_management',
            'roi_management_dialog_open'
        );
    }

    /**
     * Open the upload file dialog.
     */
    uploadFileDialog() {
        const dialogRef = this.matDialogService.open(RoiUploadFileComponent, {
            width: '50%',
            panelClass: 'roi-upload-file-dialog',
        });

        dialogRef.componentInstance.uploadFileEvent.subscribe((event) => {
            this.importRegion(event);
        });
        this.googleAnalyticsService.eventEmitter(
            'roi_upload_file_button_clicked',
            'roi_sidenav',
            'roi_upload',
            'roi_upload_file_dialog_open'
        );
    }

    /**
     * Close ROI sidenav
     */
    closeMenu() {
        this.googleAnalyticsService.eventEmitter(
            'roi_sidenav_close',
            'sidenavs',
            'close_sidenav',
            'roi_sidenav_closed'
        );
        this.closeSidenav.emit();
    }

    /**
     * Edit region
     * @param region
     */
    editRegion(region: Region) {
        if (region.geojson) {
            this.previewRegion(region);
        } else {
            this.roiService.getRegion(region.id).subscribe((data) => {
                region.geojson = data['geojson'];
                this.previewRegion(region);
            });
        }
    }

    /**
     * Get geojson of visible ROIs
     */
    getVisibleGeojson() {
        const visibleRois = this.regionList.filter((r) => r.display);
        const visibleRoisIds = visibleRois.map((roi) => roi.id);

        if (visibleRoisIds.length === 0) {
            this.refreshRegionsOnMap();
        } else {
            this.roiService.list(true, visibleRoisIds).subscribe((result) => {
                this.updateRoisGeojson(result.rois);
                this.refreshRegionsOnMap();
            });
        }
    }

    /**
     * Update regionList with geojson retrieved from the server
     */
    updateRoisGeojson(rois: Region[]) {
        for (const region of rois) {
            this.regionList[this.regionList.findIndex((r) => r.id === region.id)] = region;
            this.filteredRegionList[this.filteredRegionList.findIndex((r) => r.id === region.id)] =
                region;
        }
    }

    /**
     * Method to show a dialog with a processing api request message
     */
    showProcessingApiRequestDialog() {
        this.loadingDialogRef = this.matDialogService.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            data: {
                loadingMessages: {
                    rois: 'Loading regions of interest list...',
                },
            },
        });
    }

    /**
     * Method that closes the processing api request dialog
     */
    checkProcessingApiRequest() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }

    /**
     * CDK scrolling does not refresh the list when it is updated, so we have to manually refresh it
     */
    refreshRegionList() {
        this.regionList = [...this.regionList];
        if (this.roiManagementComponentDialogRef) {
            this.roiManagementComponentDialogRef.componentInstance.setRegionList(this.regionList);
        }
    }

    /**
     * Establishes the label array attribute for each Region
     */
    setAllRegionsLabels() {
        if (this.regionList && this.regionList.length > 0) {
            const regionsIds = this.regionList.map((r) => r.id);
            this.getAllLabels(regionsIds);
        }
    }

    /** Get and update the labels for a set of region IDs given */
    private getAllLabels(regionsIds: number[]) {
        if (regionsIds && regionsIds.length > 0) {
            this.roiService.list(false, regionsIds).subscribe((result) => {
                const dbRois = result.rois;
                for (let i = 0; i < dbRois.length; i++) {
                    const actual = this.regionList.find(
                        (r) => Number(r.id) === Number(dbRois[i].id)
                    );
                    if (actual) {
                        this.regionList.find((r) => Number(r.id) === Number(dbRois[i].id)).labels =
                            dbRois[i].labels;
                    }
                }
            });
        }
    }

    /**
     * Gets and updates for a region by a given ID
     * @param region_id
     */
    public setRegionLabels(region_id) {
        if (
            this.regionList &&
            this.regionList.length > 0 &&
            this.regionList.find((r) => Number(r.id) === Number(region_id))
        ) {
            this.roiService.getRegion(region_id).subscribe((result) => {
                this.regionList.find((r) => Number(r.id) === Number(region_id)).labels =
                    result['labels'];
            });
        }
    }

    /**
     * Method to add label to the ones for regions having them will be shown in the map
     * @param label: Label
     */
    addLabelToShow(label) {
        if (!this.showByLabels.find((l) => l.title === label.title)) {
            this.showByLabels.push(label);
        }
        this.applySearchFilter(this.searchRois.value);
    }

    /**
     * Method to remove label from the ones for regions having them will be shown in the map
     * @param label
     */
    deleteLabelToShow(label) {
        const index = this.showByLabels.indexOf(label);
        if (index > -1) {
            this.showByLabels.splice(index, 1);
            this.applySearchFilter(this.searchRois.value);
        }
    }

    /** Method to check if a region has all the labels contained in a given array */
    private regionHasLabels(region: Region, labels: Label[]) {
        let hasTheLabels = true;
        for (const filterLabel of labels) {
            if (
                !region.labels.find(
                    (regionLabel) => Number(regionLabel.id) === Number(filterLabel.id)
                )
            ) {
                hasTheLabels = false;
            }
        }
        return hasTheLabels;
    }

    /**
     * Method to expand-collapse the info for a ROI when clicked on
     * @param region
     */
    updateRoiNameDisplay(region: Region) {
        const element = document
            .getElementById(region.id.toString())
            .getElementsByTagName('div')[2];
        const icon_element = document
            .getElementById(region.id.toString())
            .getElementsByTagName('mat-icon')[0];
        const regionInfoLen =
            region.name.length + (region.description ? region.description.length : 0);
        try {
            if (regionInfoLen >= 50) {
                icon_element.children[0].className =
                    icon_element.children[0].className.indexOf('fa-caret-up') > -1
                        ? 'fa fa-caret-down'
                        : 'fa fa-caret-up';
                element.className =
                    icon_element.children[0].className.indexOf('fa-caret-down') > -1
                        ? 'table-field roi-sidenav-container roi-name-short'
                        : 'table-field roi-sidenav-container roi-name-expanded';
                icon_element.className =
                    icon_element.children[0].className.indexOf('fa-caret-down') > -1
                        ? 'expand-roi-info-icon'
                        : 'collapse-roi-info-icon';

                const scrollChangeSubscribeRef = this.viewPort.scrolledIndexChange.subscribe(() => {
                    this.collapseRoiInfo(region);
                    scrollChangeSubscribeRef.unsubscribe();
                });
            }
        } catch {
            this.refreshRegionList();
            return;
        }
    }

    /**
     * Method that restarts the styles for a given region item in the ROI sidenav
     * @param Region region
     */
    private collapseRoiInfo(region: Region) {
        this.viewPort.renderedRangeStream.subscribe(() => {
            try {
                const element = document
                    .getElementById(region.id.toString())
                    .getElementsByTagName('div')[2];
                element.className = 'table-field roi-sidenav-container roi-name-short';
                const icon_element = document
                    .getElementById(region.id.toString())
                    .getElementsByTagName('mat-icon')[0];
                icon_element.className = 'expand-roi-info-icon';
                icon_element.children[0].className = 'fa fa-caret-down';
            } catch {
                return;
            }
        });
    }

    drop(event: CdkDragDrop<Region[], Region[], number>) {
        // For some reason, there is an offset, maybe due to the cdk-virtual-scroll-viewport
        const offset = event.item.data - event.previousIndex;
        moveItemInArray(
            event.container.data,
            event.previousIndex + offset,
            event.currentIndex + offset
        );
        const result = [...this.filteredRegionList];
        if (
            this.regionList.length !== this.filteredRegionList.length &&
            this.filteredRegionList.length > 0
        ) {
            const missing = this.regionList.filter(
                (region) =>
                    !result.find((regionFound) => Number(regionFound.id) === Number(region.id))
            );
            for (const reg of missing) {
                let insertPos = this.regionList.findIndex((r) => Number(r.id) === Number(reg.id));
                if (insertPos === -1) {
                    insertPos = 0;
                }
                result.splice(insertPos, 0, reg);
            }
        }
        this.regionList = [...result];
        this.applySearchFilter(this.searchRois.value);
        this.refreshRegionsOnMap(false);
    }

    regionTrack: TrackByFunction<Region> = (index, region) => region.id;
}
