import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { transformCoordinates } from '../../utils/coordinates-utils';
import { Moment } from 'moment';
import { assertNever, CoordinateSystem } from '../../utils/utils';

export type TimeSeriesApiDialogData = TimeSeriesApiDialogROIData | TimeSeriesApiDialogPointData;

interface TimeSeriesApiDialogBase {
    dateStart;
    dateEnd;
    notificationEmail: string;
    coordinateSystem?: CoordinateSystem | undefined;
}

export interface TimeSeriesApiDialogROIData extends TimeSeriesApiDialogBase {
    mode: 'roi';
}

export interface TimeSeriesApiDialogPointData extends TimeSeriesApiDialogBase {
    mode: 'point';
    lng: number;
    lat: number;
}

export type TimeSeriesApiDialogResult =
    | TimeSeriesApiDialogROIResult
    | TimeSeriesApiDialogPointResult
    | undefined;

interface TimeSeriesApiDialogResultBase {
    start_date: Moment;
    end_date: Moment;
    exp_filter_t: number;
    avg_window_days: number;
    avg_window_direction: 'center' | 'backward';
    include_masked_data: boolean;
    climatology: boolean;
    format: 'csv' | 'json';
    notify: string;
}

export interface TimeSeriesApiDialogROIResult extends TimeSeriesApiDialogResultBase {
    coverage: boolean;
}

export interface TimeSeriesApiDialogPointResult extends TimeSeriesApiDialogResultBase {
    lat: number;
    lng: number;
}

@Component({
    selector: 'app-time-series-api-dialog',
    templateUrl: './time-series-api-dialog.component.html',
    styleUrls: ['./time-series-api-dialog.component.scss'],
})
export class TimeSeriesApiDialogComponent implements OnInit {
    /**
     * Time series API request form.
     */
    public timeSeriesApiRequestForm: UntypedFormGroup;

    constructor(
        public dialogRef: MatDialogRef<TimeSeriesApiDialogComponent, TimeSeriesApiDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: TimeSeriesApiDialogData
    ) {}

    ngOnInit() {
        this.timeSeriesApiRequestForm = new UntypedFormGroup({
            start_date: new UntypedFormControl(this.data.dateStart, Validators.required),
            end_date: new UntypedFormControl(this.data.dateEnd, Validators.required),
            exp_filter_t: new UntypedFormControl(''),
            avg_window_days: new UntypedFormControl(0),
            avg_window_direction: new UntypedFormControl('center'),
            include_masked_data: new UntypedFormControl(false),
            climatology: new UntypedFormControl(false),
            format: new UntypedFormControl('csv'),
            notify: new UntypedFormControl(this.data.notificationEmail),
        });

        switch (this.data.mode) {
            case 'point':
                let latVal, lngVal;
                if (this.data.coordinateSystem === 'EPSG:28992') {
                    const translatedCoordinates = transformCoordinates(
                        this.data.lng,
                        this.data.lat,
                        'EPSG:4326',
                        'EPSG:28992'
                    );
                    lngVal = translatedCoordinates[0];
                    latVal = translatedCoordinates[1];
                } else {
                    lngVal = this.data.lng;
                    latVal = this.data.lat;
                }
                this.timeSeriesApiRequestForm.addControl(
                    'lat',
                    new UntypedFormControl(latVal, Validators.required)
                );
                this.timeSeriesApiRequestForm.addControl(
                    'lng',
                    new UntypedFormControl(lngVal, Validators.required)
                );
                break;
            case 'roi':
                this.timeSeriesApiRequestForm.addControl(
                    'coverage',
                    new UntypedFormControl(false, Validators.required)
                );
                break;
            default:
                assertNever(this.data);
        }
    }

    /**
     * Close the dialog.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /**
     * Close the dialog and emit the time series api request params
     */
    save() {
        const value = this.timeSeriesApiRequestForm.value;
        const baseResult = {
            start_date: value.start_date,
            end_date: value.end_date,
            exp_filter_t: value.exp_filter_t,
            avg_window_days: value.avg_window_days,
            avg_window_direction: value.avg_window_direction,
            include_masked_data: value.include_masked_data,
            climatology: value.climatology,
            format: value.format,
            notify: value.notify,
        } as TimeSeriesApiDialogResultBase;

        let result: TimeSeriesApiDialogResult;
        switch (this.data.mode) {
            case 'point':
                const [lng, lat] =
                    this.data.coordinateSystem === 'EPSG:28992'
                        ? transformCoordinates(value.lng, value.lat, 'EPSG:28992', 'EPSG:4326')
                        : [value.lng, value.lat];
                result = {
                    ...baseResult,
                    lat: lat,
                    lng: lng,
                } as TimeSeriesApiDialogPointResult;
                break;
            case 'roi':
                result = {
                    ...baseResult,
                    coverage: value.coverage,
                } as TimeSeriesApiDialogROIResult;
                break;
            default:
                assertNever(this.data);
        }

        this.dialogRef.close(result);
    }
}
