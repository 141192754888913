import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DialogService } from './dialog.service';
import { ConfirmComponent } from './confirm/confirm.component';
import { DismissComponent } from './dismiss/dismiss.component';
import { DynamicLoaderComponent } from './dynamic-loader/dynamic-loader.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatDialogModule],
    declarations: [
        ConfirmComponent,
        DialogTitleComponent,
        DynamicLoaderComponent,
        DismissComponent,
    ],
    providers: [DialogService],
    exports: [DialogTitleComponent],
})
export class DialogModule {}
