import { PipeTransform, Pipe } from '@angular/core';

/**
 * Pipe that retrieves the object's keys
 */
@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
    transform(value, ...args: string[]): any {
        return Object.keys(value);
    }
}

/**
 * Pipe that retrieves the object's values
 */
@Pipe({ name: 'values', pure: false })
export class ValuesPipe implements PipeTransform {
    transform(value, ...args: string[]): any {
        return Object.values(value);
    }
}
