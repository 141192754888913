import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs';

import { environment } from '../environments/environment';
import { ResetPasswordTokenInfo, User } from '../models/user';
import { AuthService } from '../app/auth/auth.service';
import { DefaultProduct } from '../models/product';
import { Deserialize } from 'cerialize';
import { formatMask } from '../utils/utils';

/**
 * User settings.
 */
export interface UserSettings {
    baseLayer?: string;
    areaUnit?: string;
    notificationEmail?: string;
    grid?: string;
    coordinateSystem?: string;
}

export interface UserSettingsInfo {
    settings: UserSettings;
    max_rois: number;
    max_area: number;
}

/**
 * User Service
 *
 * This service retrieves user data.
 */
@Injectable()
export class UserService {
    /**
     * API endpoint path to retrieve the data
     */
    private path = '/users';

    /**
     * API endpoint prefix
     */
    private apiURL = `${environment.apiV2}${this.path}`;

    private invalidateUserSettings$ = new Subject<UserSettingsInfo | null>();

    /**
     * User settings observable.
     */
    public userSettings$ = this.invalidateUserSettings$.pipe(
        startWith<null | UserSettingsInfo>(null),
        switchMap((settings) => {
            return settings === null
                ? this.http.get<UserSettingsInfo>(`${this.apiURL}/me/settings`)
                : of(settings);
        }),
        shareReplay(1)
    );

    /**
     * User allowed area observable.
     */
    public userAllowedArea$ = this.http.get(`${this.apiURL}/me/allowed-area`).pipe(shareReplay(1));

    /**
     * Default service constructor
     */
    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    /**
     * Return a stream with the user default product.
     */
    getDefaultProduct(): Observable<DefaultProduct> {
        return this.http
            .get(`${this.apiURL}/me/default-product`)
            .pipe(map((res: any) => Deserialize(res, DefaultProduct)));
    }

    /**
     * Return a stream with the user default product.
     */
    setDefaultProduct(productApiName, groupName?) {
        let body;
        if (groupName) {
            body = { product_api_name: productApiName, group_name: groupName };
        } else {
            body = { product_api_name: productApiName };
        }
        return this.http.post(`${this.apiURL}/me/default-product`, body);
    }

    /**
     * Get the list of users. For admins only
     *
     * @param query
     * @param mask
     */
    public getUserList(
        query: string | undefined,
        mask?: { users: (keyof User)[] }
    ): Observable<User[]> {
        let observable: Observable<{ users: Partial<User>[] }>; // Not Partial<User> because of snake to camel case convertion
        const userListUrl = `${this.apiURL}/`;

        const options = mask && { headers: { 'X-Fields': formatMask(mask) } };

        if (query === undefined) {
            observable = this.http.get<{ users: Partial<User>[] }>(userListUrl, options);
        } else {
            const httpParams = {
                email: query,
                name: query, // 'name' is not used in back-end but we'll keep it for the future
            };
            observable = this.http.get<{ users: Partial<User>[] }>(userListUrl, {
                ...options,
                params: httpParams,
            });
        }

        return observable.pipe(map(({ users }) => users.map((user) => new User(user))));
    }

    /**
     * Change user password
     *
     * @param currentPwd
     * @param newPwd
     * @param newPwdConfirm
     * @returns {Observable<ArrayBuffer>}
     */
    public updatePassword(currentPwd, newPwd, newPwdConfirm) {
        const data = {
            old_pwd: currentPwd,
            new_pwd: newPwd,
            new_pwd_confirm: newPwdConfirm,
        };
        return this.http.post(this.apiURL + '/me/change-password', data);
    }

    /**
     * Restore password.
     *
     * @param username User username.
     */
    public restorePassword(username: string) {
        return this.http.post(this.apiURL + '/restore-password', {
            username: username,
        });
    }

    /**
     * Reset password.
     *
     * @param token Token auth to change the password.
     * @param password User new password.
     * @param repeatPassword User new password confirmation.
     */
    public resetPassword(token: string, password: string, repeatPassword: string) {
        return this.http.post(this.apiURL + '/reset-password', {
            token: token,
            password: password,
            repeatPassword: repeatPassword,
        });
    }

    public getResetPasswordInfo(token: string): Observable<ResetPasswordTokenInfo> {
        return this.http
            .get(this.apiURL + '/reset-password', { params: { token: token } })
            .pipe(map((tokenInfo) => Deserialize(tokenInfo, ResetPasswordTokenInfo)));
    }

    /**
     * Activate account.
     * @param token Token auth to activate the account.
     */
    public activateAccount(token: string) {
        return this.http.post(this.apiURL + '/activate', {
            token: token,
        });
    }

    /**
     * Re send activation email.
     * @param email.
     */
    public resendActivationEmail(email: string) {
        return this.http.post(this.apiURL + '/reactivate', {
            email: email,
        });
    }

    /**
     * Update the user settings.
     */
    public updateUserSettings(settings: UserSettings): void {
        if (!this.authService.hasRole('demo')) {
            this.http
                .post<UserSettingsInfo>(`${this.apiURL}/me/settings`, settings)
                .subscribe((response) => {
                    this.invalidateUserSettings$.next(response);
                });
        }
    }

    /**
     * Create a new user.
     * @param user: user data.
     */
    public register(user: any) {
        return this.http.post(this.apiURL + '/register', user).pipe(map((response) => response));
    }

    /**
     * Get user data by id.
     * @param userID, id of the user
     * @param mask, coma separated list of field names
     */
    public getUserData(userID: number, mask?: (keyof User)[]): Observable<User> {
        const options = mask && { headers: { 'X-Fields': formatMask(mask) } };

        return this.http
            .get(this.apiURL + `/${userID}`, options)
            .pipe(map((user) => new User(user)));
    }
}
