<div
    class="register-background flex flex-col justify-center items-center flex-grow w-full h-full"
    [style.background-image]="backgroundImage"
>
    <mat-card
        appearance="outlined"
        class="!flex flex-col justify-center items-center p-4 max-w-md w-full"
    >
        <app-landing-logo></app-landing-logo>

        <span>Sign up</span>
        <form [formGroup]="registerForm" (submit)="register()" class="flex flex-col w-full gap-2">
            <div class="flex flex-wrap w-full gap-2">
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Name"
                        type="text"
                        formControlName="name"
                        name="name"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Email"
                        type="email"
                        formControlName="email"
                        name="email"
                        required
                        email
                    />
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Username"
                        type="text"
                        formControlName="username"
                        name="username"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Affiliation"
                        type="text"
                        formControlName="affiliation"
                        name="affiliation"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Password"
                        type="password"
                        formControlName="password"
                        name="password"
                        required
                    />
                </mat-form-field>
                <mat-form-field class="flex-grow">
                    <input
                        matInput
                        class="form-control"
                        placeholder="Confirm password"
                        type="password"
                        formControlName="repeatPassword"
                        name="repeatPassword"
                        required
                    />
                </mat-form-field>
            </div>

            <span class="err-text" [ngClass]="{ 'err-active': registerForm.errors?.matchPassword }">
                Passwords don't match.
            </span>

            <button
                mat-raised-button
                type="submit"
                [disabled]="registerForm.invalid"
                color="accent"
            >
                Sign up
            </button>
        </form>

        <a class="vds-link" (click)="goToLogin()">Go to login</a>
    </mat-card>
</div>
