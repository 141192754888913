<div class="dialog-wrapper flex flex-col gap-4">
    <div class="title-wrapper flex flex-col justify-center items-center gap-4">
        <span class="title">
            <i [class]="'icon-w-text fa ' + dialogData.icon"></i>{{ dialogData.title }}</span
        >
    </div>

    <div class="content flex flex-col gap-4">
        <div class="flex justify-start items-center" [innerHtml]="dialogData.message"></div>

        <div class="flex justify-end items-center gap-4">
            <button mat-raised-button color="warn" class="flex items-center" (click)="cancel()">
                <i class="icon-w-text fa fa-times"></i>
                <span>{{ dialogData.closeMessage || 'Close' }}</span>
            </button>
        </div>
    </div>
</div>
