<div
    class="login-background flex flex-col justify-center items-center flex-grow h-full"
    [style.background-image]="backgroundImage"
>
    <div class="card">
        <div class="card-front flex flex-col justify-center items-center">
            <app-landing-logo></app-landing-logo>

            <span class="text">Re-send activation email</span>

            <form
                novalidate
                [formGroup]="resendActivationForm"
                (ngSubmit)="resendActivation()"
                class="flex flex-col justify-center items-center gap-2"
            >
                <mat-form-field>
                    <input
                        matInput
                        class="form-control"
                        placeholder="Email"
                        type="email"
                        formControlName="email"
                        name="username"
                        required
                    />
                </mat-form-field>

                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="resendActivationForm.invalid"
                    color="accent"
                >
                    Re-send email
                </button>

                <a class="vds-link" (click)="goToLogin()">Go back to login</a>
            </form>
        </div>
    </div>
</div>
