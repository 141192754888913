<div class="animation-dialog-wrapper flex flex-col gap-4">
    <div class="flex justify-start items-center">
        <h2 mat-dialog-title class="embed-title primary-color grow font-medium text-lg">
            Animation API requests
        </h2>
        <button
            mat-raised-button
            (click)="closeMenu()"
            matTooltip="Close dashboard"
            matTooltipPosition="left"
            class="close-sidenav-button"
            color="warn"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="animation-table flex flex-col h-3/4 overflow-hidden lg:h-auto lg:overflow-auto">
        <mat-form-field style="width: 80%">
            <input
                #filterBox
                matInput
                (keyup)="applyFilter(filterBox.value)"
                placeholder="Filter table data"
            />
        </mat-form-field>
        <div [hidden]="loadingList" class="flex flex-col justify-center items-center w-full">
            <mat-table #table [dataSource]="dataSource" style="width: 100%">
                <ng-container matColumnDef="map">
                    <mat-header-cell *matHeaderCellDef style="flex: 30%">Map</mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex: 30%">
                        <app-map-preview
                            (mapReady)="setRegion($event, element)"
                            [width]="'120px'"
                            [height]="'120px'"
                        ></app-map-preview>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="product" style="margin-left: 1%">
                    <mat-header-cell *matHeaderCellDef style="flex: 30%">Product</mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex: 30%">
                        <span>{{ element.productName }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef style="flex: 20%"
                        >Request status</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element" style="flex: 20%">
                        <span>{{ element.processingStatus }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="info">
                    <mat-header-cell *matHeaderCellDef style="flex: 8%">Info</mat-header-cell>
                    <mat-cell
                        class="info-button"
                        (click)="openApiAccessDialog(element)"
                        *matCellDef="let element"
                        style="flex: 8%"
                    >
                        <i class="fa fa-info"></i>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="repeat">
                    <mat-header-cell *matHeaderCellDef style="flex: 8%"></mat-header-cell>
                    <mat-cell *matCellDef="let element" style="flex: 8%">
                        <button
                            mat-icon-button
                            [matTooltip]="'Create similar request'"
                            (click)="openAnimationPreviewContonent(element)"
                        >
                            <i class="fa fa-object-group"></i>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
        <mat-paginator [pageSizeOptions]="[pagesize]" showFirstLastButtons></mat-paginator>
        <div *ngIf="loadingList" class="loading-spinner">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="actions flex flex-col justify-center items-center">
        <div class="flex justify-start items-center">
            <h2 mat-dialog-title class="roi-title primary-color grow">Create animation</h2>
        </div>

        <div
            class="flex justify-center items-center gap-4"
            *ngIf="editionModes['evolutionEditMode']"
            style="width: 100%"
        >
            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="confirmAnimation($event)"
            >
                <i class="fa fa-check" aria-hidden="true"></i>
                Preview
            </button>

            <button
                mat-raised-button
                class="button-fab button-fab-child button-fab-region-accept"
                (click)="cancelBoundingBox()"
            >
                <i class="fa fa-times" aria-hidden="true"></i>
                Cancel
            </button>
        </div>

        <button
            mat-raised-button
            class="margin-bottom flex justify-start items-center"
            [disabled]="editionModes['shapeEditMode']"
            (click)="animationOverTime()"
            matTooltip="Export area as image"
            matTooltipPosition="right"
            *ngIf="!editionModes['evolutionEditMode']"
        >
            <i class="fa fa-code"></i>
            Create animation
        </button>
    </div>
</div>
