import { AfterViewInit, Directive, ElementRef, Input, inject } from '@angular/core';

// Hack: allow mat-slider to have a linear gradient
@Directive({
    selector: 'mat-slider[appGradient]',
})
export class GradientDirective implements AfterViewInit {
    @Input({ required: true }) appGradient: string;

    private el = inject(ElementRef);

    ngAfterViewInit(): void {
        const parent: HTMLElement = this.el.nativeElement;

        parent.style.setProperty('--mdc-slider-active-track-height', '10px');
        parent.style.setProperty('--mdc-slider-inactive-track-height', '2px');
        parent.style.setProperty('--mdc-slider-inactive-track-color', 'gray');
        parent.style.setProperty('--mdc-slider-handle-width', '5px');
        parent.style.setProperty('--mdc-slider-handle-color', 'white');

        const x: HTMLElement = parent.querySelector('* .mdc-slider__track--active_fill');
        x.style.background = `linear-gradient(to right, ${this.appGradient})`;
        x.style.setProperty('border-color', 'transparent', 'important');
        x.style.setProperty('padding', '3px');
    }
}
