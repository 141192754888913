import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'dialog-title',
    templateUrl: './dialog-title.component.html',
    styleUrls: ['./dialog-title.component.scss'],
})
export class DialogTitleComponent implements OnInit {
    /**
     * Title.
     */
    @Input() title: string;

    /**
     * Title.
     */
    @Input() icon: string;

    /**
     * Default constructor.
     */
    constructor() {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {}
}
