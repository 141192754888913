<div class="api-access-info-wrapper">
    <div class="title-wrapper flex justify-between items-center">
        <div>
            <span class="title"
                ><i class="fa fa-info-circle icon-w-text"></i>API Request information</span
            >
        </div>
        <button class="!min-w-[50px]" mat-raised-button color="warn" (click)="close()">
            <i class="fa fa-times icon-close"></i>
        </button>
    </div>

    <div class="content overflow-y-auto flex flex-col gap-4 p-4">
        <div class="api-access-data-wrapper">
            <h3 class="font-bold">API Request info</h3>
            <div>
                <div class="field flex">
                    <p class="field-key">UUID:</p>
                    <p>{{ dialogData.request.uuid }}</p>
                </div>
                <div class="field flex">
                    <p class="field-key">Product:</p>
                    <p>{{ dialogData.request.productApiName }}</p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type === ApiRequestTypeInterface.RoiTimeSeriesRequest"
                >
                    <p class="field-key">Region of interest id:</p>
                    <p>{{ dialogData.request.roiId }}</p>
                </div>
                <div class="field flex">
                    <p class="field-key">Requested date:</p>
                    <p>
                        {{ dialogData.request.requestDt | date: 'yyyy-MM-dd' }}
                    </p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type !== ApiRequestTypeInterface.AnimationRequest"
                >
                    <p class="field-key">Format:</p>
                    <p>{{ dialogData.request.format }}</p>
                </div>
                <div class="field flex">
                    <p class="field-key">Type:</p>
                    <div class="flex justify-center items-center">
                        <p>{{ dialogData.request.type | removeunderscore }}</p>
                        <i
                            *ngIf="
                                dialogData.request.type === ApiRequestTypeInterface.AnimationRequest
                            "
                            (click)="openAnimationPreviewContent()"
                            [matTooltip]="'Create similar request'"
                            class="fa fa-object-group refresh-icon"
                        >
                        </i>
                    </div>
                </div>
                <div
                    class="field flex"
                    *ngIf="
                        dialogData.request.type === ApiRequestTypeInterface.RoiTimeSeriesRequest ||
                        dialogData.request.type === ApiRequestTypeInterface.PointTimeSeriesRequest
                    "
                >
                    <p class="field-key">Average window direction:</p>
                    <div calss="flex justify-center items-center">
                        <p>{{ dialogData.request.avgWindowDirection }}</p>
                    </div>
                </div>
                <div class="field flex">
                    <p class="field-key">Start date:</p>
                    <p>
                        {{ dialogData.request.startDate | date: 'yyyy-MM-dd' }}
                    </p>
                </div>
                <div class="field flex">
                    <p class="field-key">End date:</p>
                    <p>{{ dialogData.request.endDate | date: 'yyyy-MM-dd' }}</p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type !== ApiRequestTypeInterface.AnimationRequest"
                >
                    <p class="field-key">Zipped:</p>
                    <p>{{ dialogData.request.zipped | booltoyesno }}</p>
                </div>
                <div class="field flex">
                    <p class="field-key">Climatology:</p>
                    <p>{{ dialogData.request.climatology | booltoyesno }}</p>
                </div>
                <div *ngIf="dialogData.request.notify" class="field flex">
                    <p class="field-key">Notify:</p>
                    <p>{{ dialogData.request.notify }}</p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type === ApiRequestTypeInterface.GriddedDataRequest"
                >
                    <p class="field-key">Maximium latitude:</p>
                    <p>
                        {{
                            dialogData.request.latMax
                                | coordinate
                                    : dialogData.request.latMax
                                    : dialogData.request.lonMax
                                    : dialogData.userSettings?.settings?.coordinateSystem
                        }}
                    </p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type === ApiRequestTypeInterface.GriddedDataRequest"
                >
                    <p class="field-key">Minimum latitude:</p>
                    <p>
                        {{
                            dialogData.request.latMin
                                | coordinate
                                    : dialogData.request.latMin
                                    : dialogData.request.lonMin
                                    : dialogData.userSettings?.settings?.coordinateSystem
                        }}
                    </p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type === ApiRequestTypeInterface.GriddedDataRequest"
                >
                    <p class="field-key">Maximum longitude:</p>
                    <p>
                        {{
                            dialogData.request.lonMax
                                | coordinate
                                    : dialogData.request.latMax
                                    : dialogData.request.lonMax
                                    : dialogData.userSettings?.settings?.coordinateSystem
                        }}
                    </p>
                </div>
                <div
                    class="field flex"
                    *ngIf="dialogData.request.type === ApiRequestTypeInterface.GriddedDataRequest"
                >
                    <p class="field-key">Minimum longitude:</p>
                    <p>
                        {{
                            dialogData.request.lonMin
                                | coordinate
                                    : dialogData.request.latMin
                                    : dialogData.request.lonMin
                                    : dialogData.userSettings?.settings?.coordinateSystem
                        }}
                    </p>
                </div>
                <div
                    class="field flex"
                    *ngIf="
                        dialogData.request.type === ApiRequestTypeInterface.PointTimeSeriesRequest
                    "
                >
                    <p class="field-key">Latitude:</p>
                    <p>{{ dialogData.request.lat }}</p>
                </div>
                <div
                    class="field flex"
                    *ngIf="
                        dialogData.request.type === ApiRequestTypeInterface.PointTimeSeriesRequest
                    "
                >
                    <p class="field-key">Longitude:</p>
                    <p>{{ dialogData.request.lon }}</p>
                </div>
            </div>
        </div>

        <div class="api-access-data-wrapper" *ngIf="dialogData.request">
            <div class="flex justify-between items-center">
                <div>
                    <h3 class="font-bold">Map preview</h3>
                </div>
            </div>
            <div class="flex justify-start items-center">
                <app-map-preview [width]="'720px'" (mapReady)="setRegion($event)"></app-map-preview>
            </div>
        </div>

        <div class="api-access-data-wrapper">
            <app-api-request-details [request]="dialogData.request"></app-api-request-details>
        </div>
    </div>
</div>
