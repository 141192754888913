import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Deserialize } from 'cerialize';
import { Observable, Subject, from as observableFrom } from 'rxjs';
import { map } from 'rxjs';
import { environment } from '../environments/environment';
import { EmbedLink, EmbedLinkData } from '../models/embed-link';

@Injectable({ providedIn: 'root' })
export class EmbedService {
    private outputShareLinkStatus = new Subject<void>();

    /** API endpoint with service path. */
    protected route = `${environment.apiV2}/embed`;

    private http = inject(HttpClient);

    /**
     * Return a stream with the list of embed links.
     */
    list() {
        return this.http.get<{ embed_links: any[] }>(`${this.route}/`).pipe(
            map((data) => {
                return data.embed_links.map((embedLink) => Deserialize(embedLink, EmbedLink));
            })
        );
    }

    /**
     * Update an embed link.
     *
     * @param {EmbedLink} embedLink
     **/
    public update(embedLink): Observable<EmbedLink> {
        return this.http
            .put(`${this.route}/${embedLink.link}`, {
                description: embedLink.description,
            })
            .pipe(map((res: any) => Deserialize(res.embed_link_info, EmbedLink)));
    }

    /**
     * Delete an embed link.
     *
     * @param {Embed} embedLink
     **/
    public del(embedLink): Observable<null> {
        return this.http.delete(`${this.route}/${embedLink.link}`, {}).pipe(map(() => null));
    }

    /**
     * Generate encoded embed link from params.
     *
     * @param embedLinkData
     * @param geojson
     * @returns {Observable<Response>}
     */
    generateEmbedLink(embedLinkData, geojson, description): Observable<string> {
        return this.http
            .post(`${this.route}/generate-link?${embedLinkData}`, {
                geojson: geojson,
                description: description,
            })
            .pipe(map((response: any) => response.embed_data_link));
    }

    /**
     * Decode embed link.
     *
     * @param embedLink
     * @returns {Observable<Response>}
     */
    decodeEmbedLink(embedLink): Observable<EmbedLinkData> {
        return this.http.get(`${this.route}/decode-link/${embedLink}`).pipe(
            map((res: any) => {
                return Deserialize(res, EmbedLinkData);
            })
        );
    }

    /**
     * Return an observable from the Subject, that emits the loading status.
     * @returns {Observable<any>}
     */
    watchShareLink(): Observable<any> {
        return observableFrom(this.outputShareLinkStatus);
    }

    /**
     * Emit share link updated event.
     */
    emitShareLinkUpdated() {
        this._emitShareLinkUpdated();
    }

    /**
     * Emit share link updated event.
     */
    private _emitShareLinkUpdated() {
        this.outputShareLinkStatus.next();
    }
}
