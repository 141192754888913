<div class="popup-header logo flex">
    <img src="assets/planet-primarymark.png" />
    <h4>The Planetary Variables viewer has been updated</h4>
    <mat-divider class="divider" [vertical]="true"></mat-divider>
    <button class="release-buttons" mat-button (click)="openReleaseInfoDialog($event)">
        Release notes
    </button>
    <mat-divider class="divider" [vertical]="true"></mat-divider>
    <button class="release-buttons" mat-button (click)="snackBarRef.dismiss()">Close</button>
</div>
