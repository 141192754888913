import { Component, Inject, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';

/**
 * Component selector, template and style definitions
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

/**
 * Root App Component
 */
export class AppComponent implements OnInit {
    /**
     * Generic App title
     */
    title = 'app';

    /**
     * Default constructor. Sets the default format for dates
     * @param moment MomentJS library
     */
    constructor(
        @Inject('moment') private moment,
        private oidcSecurityService: OidcSecurityService,
        private authService: AuthService
    ) {
        moment.updateLocale('en', {
            longDateFormat: {
                L: 'YYYY-MM-DD',
                LL: 'MMMM D, YYYY',
                LLL: 'MMMM D, YYYY h:mm A',
                LLLL: 'dddd, MMMM D, YYYY h:mm A',
                LT: 'h:mm A',
                LTS: 'h:mm:ss A',
                l: 'YYYY-MM-DD',
            },
        });
    }

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        this.setFavicon();
        this.oidcSecurityService.checkAuth().subscribe((loginResponse) => {
            this.authService.checkAuth(loginResponse);
        });

        this.authService.checkAuthMessage();
    }

    /**
     * Remove the current favicon and creates a new one dynamically.
     */
    private setFavicon() {
        if (environment.favicon === undefined) {
            return;
        }

        const linkFaviconElements = Array.from(document.querySelectorAll("link[rel*='icon']"));
        for (const link of linkFaviconElements) {
            link.parentNode.removeChild(link);
        }

        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.type = 'image/png';
        newLink.href = `/assets/favicons/${environment.favicon}`;
        document.getElementsByTagName('head')[0].appendChild(newLink);
    }
}
