import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LatLng } from 'leaflet';
import { ClipboardService } from 'ngx-clipboard';

import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import {
    binarySearch,
    cmpMoment,
    getISODateString,
    getUserForApi,
    linkParamsAreValid,
} from '../../utils/utils';
import { LayerData, LayerKey } from '../../models/layer-data';
import { Product } from '../../models/product';
import { of as observableOf, forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs';
import moment from 'moment';
import { ProductService } from '../../services/product.service';
import { LayersService } from '../../services/layers.service';
import { EmbedService } from '../../services/embed.service';
import { SpecialLayerData } from '../../models/specialLayer-data';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { AuthService } from '../auth/auth.service';
import {
    DynamicDialog,
    DynamicLoaderComponent,
} from '../dialog/dynamic-loader/dynamic-loader.component';

export interface ShareDialogData {
    shareLinkMetadata?: ShareMetadata | undefined;
    embed?: boolean | undefined;
    shareLink?: string | undefined;
    bounds?: any;
    description?: string | undefined;
}

export type ShareDialogResult = true | undefined;

/**
 * Share dialog data input.
 */
export interface ShareMetadata {
    center: LatLng;
    layers: Map<LayerKey, LayerData>;
    layerOrder: LayerKey[];
    layerCompareOrder: LayerKey[];
    linkedLayers: LayerKey[];
    selectedLayer: LayerKey;
    zoom: number;
    baseLayer?: string;
    roi: number;
    specialLayers: Map<LayerKey, SpecialLayerData>;
    specialLayersKeys: LayerKey[];
}

/**
 * Share Link output parsed params.
 */
export interface ShareLinkParams {
    x: number;
    y: number;
    z: number;
    baseLayer: string;
    roi: number;
}

function renderMissingProductsError(missingProducts: string[]): string {
    return [
        "Can't open link, because you do not have access to products:",
        ...missingProducts.map((p) => `- ${p}`),
    ].join('<br>');
}

@Component({
    selector: 'app-share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent
    implements OnInit, DynamicDialog<ShareDialogComponent, ShareDialogData, ShareDialogResult>
{
    /**
     * Map share link.
     */
    public shareLink: string;

    /**
     * Reference to the dynamic dialog.
     */
    public dialogRef: MatDialogRef<
        DynamicLoaderComponent<ShareDialogComponent, ShareDialogData, ShareDialogResult>,
        ShareDialogResult
    >;

    /**
     * Dialog data.
     */
    public dialogData: ShareDialogData;

    /**
     * Bool to load the share-link in the embed view or in the normal view
     * @type {boolean}
     */
    embed = false;

    /**
     * Bool to set date as 'latest' in the shared link
     * @type {boolean}
     */
    latestDate: boolean;

    constructor(
        private clipboard: ClipboardService,
        private snackbar: CustomSnackbarService,
        private embedService: EmbedService,
        private layersService: LayersService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private authService: AuthService
    ) {}

    /**
     * Angular lifecycle method.
     */
    ngOnInit() {
        if (this.dialogData.shareLink) {
            this.shareLink = this.dialogData.shareLink;
        } else if (this.dialogData.shareLinkMetadata.layers.size > 0) {
            this.generateShareLink(
                this.dialogData.shareLinkMetadata,
                this.dialogData.embed,
                this.dialogData.bounds,
                this.dialogData.description
            );
        }
    }

    /**
     * Share link generator.
     *
     * @param data Map metadata.
     * @param embed Boolean to generate share link(false) or embed link(true)
     * @params bounds Bounds of the area selected to the embed link
     */
    generateShareLink(data: ShareMetadata, embed, bounds?, description?): void {
        if (embed) {
            this.embed = embed;
        }
        const basePath = embed
            ? `${window.location.protocol}//${window.location.host}/embed?`
            : `${window.location.protocol}//${window.location.host}/?`;
        const allLayerKeys = [...data.layerOrder, ...data.layerCompareOrder];
        const products = [];
        const dates = [];
        const compareLayers = [];
        const pinnedLayers = [];
        const hiddenLayers = [];
        const ranges = [];
        const opacities = [];

        const linkedLayers: number[] = [];

        const params: any = {
            z: data.zoom,
            x: data.center.lng.toFixed(2),
            y: data.center.lat.toFixed(2),
            products: '',
            dates: '',
            opacities: '',
        };

        let index = 0;
        allLayerKeys.forEach((key) => {
            const layer = data.layers.get(key);

            if (layer.product) {
                if (this.latestDate) {
                    dates.push('latest');
                } else {
                    dates.push(getISODateString(layer.getDate()));
                }
                products.push(layer.product.apiName);
                ranges.push(`${layer.range[0]},${layer.range[1]}`);
            } else {
                // At the moment, special layers are only borders, we left the date and ranges param in case we add other kind of layers
                products.push(layer.layerName);
                ranges.push('0,0');
                const date = moment.utc();
                dates.push(getISODateString(date));
            }

            if (layer.key === data.selectedLayer) {
                params.selected = index;
            }

            if (!layer.display) {
                hiddenLayers.push(index);
            }

            if (layer.pinned) {
                pinnedLayers.push(index);
            }

            if (data.layerCompareOrder.findIndex((e) => e === layer.key) > -1) {
                compareLayers.push(index);
            }

            if (data.linkedLayers.findIndex((e) => e === layer.key) > -1) {
                linkedLayers.push(index);
            }
            if (layer.opacity) {
                opacities.push(layer.opacity);
            }

            index += 1;
        });

        // Generate an object with the parameters
        params.products = products.join(',');
        if (dates.length > 0) {
            params.dates = dates.join(',');
        }
        if (ranges.length > 0) {
            params.ranges = ranges.join(';');
        }
        params.opacities = opacities.join(',');

        if (linkedLayers.length > 0) {
            params.linked = linkedLayers.join(',');
        }

        if (compareLayers.length > 0) {
            params.compare = compareLayers.join(',');
            params['compare-mode'] = this.layersService.getLayersCompareMode();
        }

        if (pinnedLayers.length > 0) {
            params.pinned = pinnedLayers.join(',');
        }

        if (hiddenLayers.length > 0) {
            params.hidden = hiddenLayers.join(',');
        }

        if (data.baseLayer !== undefined) {
            params['base-layer'] = data.baseLayer;
        }

        if (data.roi !== undefined) {
            params.roi = data.roi;
        }

        const username = this.authService.getUserData()?.username;
        const userForApi = getUserForApi();
        if (username) {
            params['username'] = username;
        }
        if (userForApi) {
            params['username'] = userForApi;
        }

        // Generate the URL from the parameters object
        const urlParams = [];

        for (const param of Object.keys(params)) {
            urlParams.push(`${param}=${params[param]}`);
        }

        if (embed) {
            const geojson = {
                type: 'Polygon',
                coordinates: [
                    [
                        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
                        [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
                        [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
                        [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
                        [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
                    ],
                ],
            };
            const link = urlParams.join('&');
            this.embedService
                .generateEmbedLink(link, geojson, description)
                .subscribe((embedDataLink: string) => {
                    this.shareLink = basePath + embedDataLink;
                });
        } else {
            this.shareLink = basePath + urlParams.join('&');
        }
    }

    /**
     * Copy the share link to the user clipboard.
     */
    copyLinkToClipboard() {
        this.clipboard.copyFromContent(this.shareLink);
        this.snackbar.present('Link copied to clipboard.');
        this.googleAnalyticsService.eventEmitter(
            'copy_share_link_clicked',
            'share_embed',
            'share_link_dialog',
            this.shareLink
        );
        this.dialogRef.close(true);
    }

    /**
     * Close dialog.
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Load the map status from the share link.
     * @param {string} params URL Path (?products=...&...)
     * @param {ProductService} productService
     * @param {LayersService} layersService
     * @param {EmbedService} embedService
     * @param {UserService} userService
     * @param {snackbar} snackbar
     * @param {AuthService} authService
     * @return {any} null if the link is not valid, {x:, y:, z:} otherwise.
     */
    public static loadShareLink(
        params: string,
        productService: ProductService,
        layersService: LayersService,
        embedService,
        userService,
        snackbar,
        authService
    ): any {
        // Get raw params
        const searchParams = new URLSearchParams(decodeURI(params));
        const _x = searchParams.get('x');
        const _y = searchParams.get('y');
        const _z = searchParams.get('z');
        const _products = searchParams.get('products');
        const _dates = searchParams.get('dates');
        const _linkedLayers = searchParams.get('linked');
        const _selectedLayer = searchParams.get('selected');
        const _compareLayers = searchParams.get('compare');
        const _compareMode = searchParams.get('compare-mode');
        const _hiddenLayers = searchParams.get('hidden');
        const _pinnedLayers = searchParams.get('pinned');
        const _ranges = searchParams.get('ranges');
        const _baseLayer = searchParams.get('base-layer');
        const _roi = searchParams.get('roi');
        const _opacities = searchParams.get('opacities');
        const _username = searchParams.get('username');
        const _loaded = searchParams.get('loaded');

        const paramsValid = linkParamsAreValid(
            _x,
            _y,
            _z,
            _products,
            _dates,
            _selectedLayer,
            _compareLayers,
            _compareMode,
            _opacities,
            _ranges,
            _pinnedLayers,
            _hiddenLayers,
            _baseLayer,
            _linkedLayers,
            _roi,
            _username,
            _loaded
        );
        // Check using RegExp that the parameters are valid
        if (!paramsValid.valid) {
            return observableOf({
                type: 'showErrorDialog',
                value: ["Can't open link:", ...paramsValid.messages.map((m) => `- ${m}`)].join(
                    '<br>'
                ),
            });
        }

        // Parse the input
        const parseInput = (str) => {
            return str.split(',').map((n) => parseInt(n));
        };
        const selectedLayer: LayerKey = _selectedLayer === null ? 0 : parseInt(_selectedLayer);
        const linkedLayers = _linkedLayers === null ? [] : parseInput(_linkedLayers);
        const compareLayers = _compareLayers === null ? [] : parseInput(_compareLayers);
        const compareMode = _compareMode === null ? '' : _compareMode;
        const products = _products?.split(',') ?? [];
        const hidden = _hiddenLayers === null ? [] : parseInput(_hiddenLayers);
        const pinned = _pinnedLayers === null ? [] : parseInput(_pinnedLayers);
        const ranges =
            _ranges === null
                ? []
                : _ranges.split(';').map((r) => {
                      const groups = r.match(/(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)/);
                      return [parseFloat(groups[1]), parseFloat(groups[3])];
                  });
        const roi = parseInt(_roi);

        let dates;
        let opacities;

        if (_dates && _dates !== 'latest') {
            dates = _dates.split(',').map((d) => moment.utc(d));
        } else {
            dates = [];
        }

        if (!_opacities || _opacities.length === 0) {
            opacities = [];
            for (let i = 0; i < products.length; i++) {
                opacities.push(100);
            }
        } else {
            opacities = _opacities.split(',');
        }

        const specialLayers = layersService.getSpecialLayers();

        const notMissingProductStr = 'notMissingProducts';
        // Load products
        return new Observable((obs) => {
            productService.list().subscribe((response: Product[]) => {
                const apiNames = new Set(response.map((p) => p.apiName));
                const missingProducts = products.filter((p) => !apiNames.has(p));

                let userObs = observableOf(notMissingProductStr);
                if (missingProducts.length) {
                    if (
                        authService.hasPermission('can-manage-accounts') ||
                        authService.hasPermission('can-impersonate-everybody')
                    ) {
                        userObs = userService.getUserList();
                    } else {
                        obs.next({
                            type: 'showErrorDialog',
                            value: renderMissingProductsError(missingProducts),
                        });
                        obs.complete();
                        return;
                    }
                }
                userObs
                    .pipe(
                        mergeMap((users: any) => {
                            if (users !== notMissingProductStr) {
                                const userToImpersonate = users.find(
                                    (user) => user.username === _username
                                );
                                if (userToImpersonate) {
                                    return userService.getUserData(userToImpersonate.id);
                                } else {
                                    return observableOf(undefined);
                                }
                            } else {
                                return observableOf(notMissingProductStr);
                            }
                        })
                    )
                    .subscribe(
                        (res: any) => {
                            if (res) {
                                if (res !== notMissingProductStr) {
                                    if (!_loaded) {
                                        authService.setUserForApi(res, res.permission_names);
                                        let url = window.location.href;
                                        if (url.indexOf('?') > -1) {
                                            url += '&loaded=true';
                                        } else {
                                            url += '?loaded=true';
                                        }
                                        window.location.href = url;
                                    }
                                }
                                const productList: Array<Product> = response;
                                const streams = [];
                                const layerList = layersService.getProductGroupsList(productList);

                                // Load availability in the same order
                                for (const productID of products) {
                                    if (specialLayers[productID] !== undefined) {
                                        streams.push(observableOf('specialLayer'));
                                    } else {
                                        streams.push(productService.getAvailability(productID));
                                    }
                                }

                                const availabilityData = [];
                                forkJoin(streams).subscribe((availability) => {
                                    const specialLayersData = {};
                                    for (let i = 0; i < availability.length; i++) {
                                        if (availability[i] !== 'specialLayer') {
                                            availabilityData.push(observableOf(availability[i]));
                                        } else {
                                            if (!specialLayersData[products[i]]) {
                                                specialLayersData[products[i]] =
                                                    layersService.getSpecialLayerData(products[i]);
                                            }
                                            availabilityData.push(specialLayersData[products[i]]);
                                        }
                                    }
                                    forkJoin(availabilityData).subscribe((result: any) => {
                                        for (let i = 0; i < result.length; i++) {
                                            let layer;
                                            const addOptions = {
                                                select: false,
                                                compare: false,
                                                emit: false,
                                            };
                                            let layerDate;
                                            const layerOpacity = opacities[i] ? opacities[i] : 100;
                                            addOptions.compare = compareLayers.indexOf(i) > -1;
                                            const layerProduct = layerList.find((p) =>
                                                p.groupType
                                                    ? p.products.find(
                                                          (pr) => pr.apiName === products[i]
                                                      )
                                                    : p.apiName == products[i]
                                            );

                                            if (result[i].type) {
                                                layer = layersService.addSpecialLayer(
                                                    products[i],
                                                    result[i],
                                                    undefined,
                                                    addOptions
                                                );
                                            } else {
                                                if (
                                                    dates[i] !== undefined &&
                                                    dates[i]._i !== 'latest' &&
                                                    binarySearch(
                                                        result[i].availability,
                                                        dates[i],
                                                        cmpMoment
                                                    ) > -1
                                                ) {
                                                    layerDate = dates[i];
                                                } else {
                                                    layerDate =
                                                        result[i].availability[
                                                            result[i].availability.length - 1
                                                        ];
                                                }
                                                layer = layersService.addProductLayer(
                                                    layerProduct,
                                                    result[i],
                                                    layerDate,
                                                    addOptions,
                                                    {
                                                        range: ranges[i],
                                                    },
                                                    layerOpacity
                                                );
                                                if (layer.group) {
                                                    layer.product = layer.group.products.find(
                                                        (pr) => pr.apiName === products[i]
                                                    );
                                                    layersService.updateTilelayer(layer.key);
                                                }
                                            }

                                            if (i === selectedLayer) {
                                                layersService.selectLayer(layer.key);
                                            }

                                            if (linkedLayers.findIndex((e) => e == i) > -1) {
                                                layer.linked = true;
                                                layersService.updateLinkedLayers(layer);
                                            }

                                            if (hidden.findIndex((e) => e == i) > -1) {
                                                layer.display = false;
                                            }

                                            if (pinned.findIndex((e) => e == i) > -1) {
                                                layer.pinned = true;
                                            }
                                            if (layerOpacity) {
                                                layer.opacity = layerOpacity;
                                            }
                                        }
                                        embedService.emitShareLinkUpdated();
                                        layersService.emitLayersUpdated();
                                    });
                                });
                                if (compareMode === 'spy' || compareMode === 'sideBySide') {
                                    layersService.setLayersCompareMode(compareMode);
                                }
                                obs.next({
                                    y: parseFloat(_y),
                                    x: parseFloat(_x),
                                    z: parseInt(_z),
                                    baseLayer: _baseLayer,
                                    roi: roi,
                                });
                                obs.complete();
                            } else {
                                obs.next({
                                    type: 'showErrorDialog',
                                    value:
                                        _username !== null && _username !== undefined
                                            ? `Couldn't impersonate ${_username}.<br>${renderMissingProductsError(
                                                  missingProducts
                                              )}`
                                            : renderMissingProductsError(missingProducts),
                                });
                                obs.complete();
                                return;
                            }
                        },
                        (err: any) => {
                            snackbar.dismiss();
                            if (err.status === 403) {
                                obs.next({
                                    type: 'showErrorDialog',
                                    value:
                                        _username !== null && _username !== undefined
                                            ? `Couldn't impersonate ${_username}.<br>${renderMissingProductsError(
                                                  missingProducts
                                              )}`
                                            : renderMissingProductsError(missingProducts),
                                });
                            } else {
                                obs.next({
                                    type: 'showErrorDialog',
                                    value: renderMissingProductsError(missingProducts),
                                });
                            }
                            obs.complete();
                            return;
                        }
                    );
            });
        });
    }

    setDateToLatest() {
        this.generateShareLink(
            this.dialogData.shareLinkMetadata,
            this.dialogData.embed,
            this.dialogData.bounds
        );
    }
}
