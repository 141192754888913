/**
 * Region CRUD class
 */
import { squareMetersToAcres, squareMetersToHectares } from '../utils/roi.utils';
import { Label } from './label';

export class Region {
    /**
     * Region ID
     */
    id: number;

    /**
     * GeoJSON object
     */
    geojson: any;

    /**
     * Display on map flag
     */
    display: boolean;

    /**
     * Region name
     */
    name: string;

    /**
     * Region description
     */
    description: string;

    /**
     * Region area (m2).
     */
    area: number;

    /**
     * Created at date.
     */
    created_at: any;

    /**
     * Metadata.
     */
    metadata: any;

    /**
     * File name
     */
    fileName?: string;

    /**
     * List of label objects associated with the Region
     */
    labels: Label[];

    /**
     * Default constructor
     * @param {Object} jsonData
     */
    constructor(jsonData?: object) {
        Object.assign(this, jsonData);
    }

    /**
     * Region meta data retrieval method
     * @returns {Object}
     */
    public meta(): object {
        return {
            name: this.name,
            description: this.description,
        };
    }

    /**
     * Return the area in square meters.
     * @return {number}
     */
    public getArea(): number {
        return this.area;
    }

    /**
     * Return the area in hectares.
     * @return {number}
     */
    public getAreaHectares(): number {
        return squareMetersToHectares(this.area);
    }

    /**
     * Return the area in acres.
     * @return {number}
     */
    public getAreaAcres(): number {
        return squareMetersToAcres(this.area);
    }

    /**
     * Shallow region copy.
     * @return {Region}
     */
    public clone(): Region {
        return new Region(this);
    }
}
