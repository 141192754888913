<div class="upload-roi-wrapper flex flex-col w-full">
    <div class="title-wrapper flex justify-between items-center">
        <div>
            <span class="title">Upload ROI</span>
        </div>
        <button
            *ngIf="autoClose === false"
            mat-raised-button
            class="upload-roi-close !min-w-[50px]"
            color="warn"
            (click)="close()"
        >
            <i class="fa fa-times"></i>
        </button>
    </div>

    <div class="content flex flex-col gap-2">
        <div
            *ngIf="rois !== undefined && uploads !== undefined"
            class="upload-list flex flex-col gap-2"
        >
            <div
                *ngFor="let upload of uploads"
                class="upload-element flex justify-between items-center gap-2"
            >
                <div class="flex justify-start items-center">
                    <app-map-preview-small (mapReady)="setRegion($event, upload.roi)">
                    </app-map-preview-small>
                    <div class="roi-text">
                        <h4 class="roi-name">{{ upload.roi.name }}</h4>
                        <div *ngIf="upload.status === 'fail' || upload.status === 'timeout'">
                            <span class="error-text">Error: </span>{{ upload.error }}
                        </div>
                    </div>
                </div>
                <div class="status-icons justify-end items-center">
                    <div *ngIf="upload.status === 'pending'">
                        <mat-progress-spinner
                            class=""
                            [color]="'primary'"
                            [mode]="'indeterminate'"
                            [diameter]="20"
                        >
                        </mat-progress-spinner>
                    </div>
                    <div *ngIf="upload.status !== 'pending'">
                        <mat-icon
                            class="success-icon flex justify-around items-center"
                            *ngIf="upload.status === 'success'"
                        >
                            <i class="fa fa-check"></i>
                        </mat-icon>
                        <div *ngIf="upload.status === 'fail' || upload.status === 'timeout'">
                            <mat-icon
                                *ngIf="upload.status !== 'timeout'"
                                class="fail-icon flex justify-around items-center"
                            >
                                <i class="fa fa-times"></i>
                            </mat-icon>
                            <div *ngIf="upload.retryingUpload" class="loading-spinner">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </div>
                            <mat-icon
                                *ngIf="upload.status === 'timeout' && !upload.retryingUpload"
                                matTooltip="Retry ROI upload"
                                class="retry-icon flex justify-around items-center"
                                (click)="retry(upload.roi)"
                            >
                                <i class="fa fa-refresh"></i>
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            *ngIf="file !== undefined"
            class="upload-file flex flex-col justify-around items-center gap-2"
        >
            <div><p>Processing shapefile</p></div>
            <mat-progress-spinner
                class=""
                [color]="'primary'"
                [mode]="'indeterminate'"
                [diameter]="80"
            >
            </mat-progress-spinner>
        </div>
        <div
            *ngIf="rois !== undefined && uploads !== undefined"
            class="flex justify-end items-center"
        >
            <table class="mat-table upload-stats-table gap-1">
                <tr></tr>
                <tr>
                    <th>Uploading</th>
                    <th>Uploaded</th>
                    <th>Failed to upload</th>
                </tr>
                <tr>
                    <td>{{ obtainUploadsByStatus('pending').length }}</td>
                    <td>{{ this.correctUploads.length }}</td>
                    <td>
                        {{
                            obtainUploadsByStatus('timeout').length +
                                obtainUploadsByStatus('fail').length
                        }}
                    </td>
                </tr>
            </table>
        </div>
        <div
            *ngIf="rois !== undefined && uploads !== undefined && checkAnyUploadStatus('timeout')"
            [hidden]="!failedUploads || failedUploads.length === 0"
            class="flex gap-2 justify-end items-center"
        >
            <button
                (click)="retryFailedUploads()"
                [disabled]="
                    !failedUploads || failedUploads.length === 0 || checkAnyUploadStatus('pending')
                "
                [matTooltip]="'Retry all timed out uploads at once'"
                class="mat-raised-button mat-accent upload-dialog-action-button flex justify-end items-center"
            >
                Retry all failed uploads
                <mat-icon class="flex justify-end items-center">
                    <i *ngIf="!checkAnyUploadStatus('pending')" class="fa fa-spinner"></i>
                    <mat-progress-spinner
                        *ngIf="checkAnyUploadStatus('pending')"
                        class=""
                        [color]="'primary'"
                        [mode]="'indeterminate'"
                        [diameter]="20"
                    >
                    </mat-progress-spinner>
                </mat-icon>
            </button>
        </div>
        <div
            *ngIf="
                rois !== undefined &&
                uploads !== undefined &&
                (checkAnyUploadStatus('fail') || checkAnyUploadStatus('timeout'))
            "
            [hidden]="!failedUploads || failedUploads.length === 0"
            class="flex justify-end items-center gap-2"
        >
            <button
                (click)="downloadFailedRegions()"
                [disabled]="
                    !failedUploads || failedUploads.length === 0 || checkAnyUploadStatus('pending')
                "
                [matTooltip]="'Download JSON file containing the Regions which failed to upload'"
                class="mat-raised-button mat-accent upload-dialog-action-button flex justify-end items-center"
            >
                Download failed Regions
                <mat-icon class="flex justify-end items-center">
                    <i class="fa fa-download"></i>
                </mat-icon>
            </button>
        </div>
    </div>
</div>
