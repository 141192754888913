import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Moment } from 'moment';

import { Region } from '../../models/region';
import { TimeSeries } from '../time-series/time-series.component';
import { KpiService } from '../../services/kpi.service';
import { getISODateString } from '../../utils/utils';
import {
    RoiInfoDialogComponent,
    RoiInfoDialogData,
    RoiInfoDialogResult,
} from '../roi-info-dialog/roi-info-dialog.component';
import {
    TimeSeriesDialog,
    TimeSeriesDialogData,
} from '../time-series-dialog/time-series-dialog.component';
import { Kpi } from '../../models/kpi';
import { KpiConfigDialogComponent } from '../kpi-config-dialog/kpi-config-dialog.component';
import { LayersService } from '../../services/layers.service';
import { Product } from '../../models/product';
import { DialogService } from '../dialog/dialog.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { ProductService } from '../../services/product.service';
import {
    TimeSeriesApiDialogComponent,
    TimeSeriesApiDialogData,
    TimeSeriesApiDialogROIResult,
} from '../time-series-api-dialog/time-series-api-dialog.component';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import {
    TimeSeriesInfoDialogComponent,
    TimeSeriesInfoDialogData,
    TimeSeriesInfoDialogResult,
} from '../time-series-info-dialog/time-series-info-dialog.component';
import { UserSettingsInfo } from '../../services/user.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    @Input() userSettings: UserSettingsInfo;

    /** Reference to time series component */
    @ViewChild('appTimeSeries') timeSeries?: TimeSeries;

    /**
     * Region selected from map
     */
    public region: Region = undefined;

    /**
     * Currently selected product.
     */
    public productSelected: Product = new Product();

    /**
     * Currently selected date.
     */
    public dateSelected: Moment = moment.utc();

    /**
     * Shown KPIs configuration
     * @type {}
     */
    public kpis: Array<Kpi>;

    /**
     * Time series year mode.
     */
    public yearMode = false;

    /**
     * if year mode is always enabled
     * @type {boolean}
     */
    public yearModeOnly = false;

    /**
     * year mode start day of year
     */
    public yearModeStart = 1;

    /**
     * start date of the roi time series
     */
    public dateStart;

    /**
     * end date of the roi time series
     */
    public dateEnd;

    /**
     * Reference to the loading dialog shown while processing a new api request
     */
    private loadingDialogRef: MatDialogRef<LoadingDialogComponent>;

    /**
     * Flag that determines if the kpis are being loaded
     */
    loadingKpis: boolean;

    /**
     * Flag that determines if the sidenav is open or closed
     */
    sidenavOpen: boolean;

    /**
     * Close sidenav event.
     * @type {EventEmitter<Map>}
     */
    @Output() closeSidenav = new EventEmitter<any>(true);

    /**
     * Constructor
     * @param {KpiService} kpiService
     * @param matDialog
     * @param layersService
     * @param dialogService
     * @param snackBar
     * @param productService
     */
    constructor(
        private kpiService: KpiService,
        private matDialog: MatDialog,
        private layersService: LayersService,
        private dialogService: DialogService,
        private snackBar: CustomSnackbarService,
        private productService: ProductService
    ) {}

    ngOnInit() {
        this.layersService.layers$.subscribe((layerStatus) => {
            if (layerStatus !== undefined && layerStatus.selected !== undefined) {
                const selectedLayer = this.layersService.get(layerStatus.selected);
                if (selectedLayer.product) {
                    const reload = selectedLayer.product.apiName !== this.productSelected.apiName;
                    this.productService.getAvailability(selectedLayer.product.apiName).subscribe(
                        (result: any) => {
                            this.dateStart = result.availability[0];
                            this.dateEnd = result.availability[result.availability.length - 1];
                            this.update(this.region, reload);
                        },
                        () => {
                            this.dateStart = undefined;
                            this.dateEnd = undefined;
                            this.update(this.region, reload);
                        }
                    );
                }
            }
        });
    }

    /**
     * Open the sidenav and update the chart with a new region of interest
     * @param roi, Region model
     */
    open(roi) {
        this.loadingKpis = true;
        this.sidenavOpen = true;
        this.update(roi);
    }

    /**
     * method triggered when the dashboard has change its size
     */
    public resize(): void {
        this.timeSeries?.redrawChart();
    }

    /**
     * Update the dashboard data.
     */
    public update(region: Region, reload = true) {
        let reloadTimeSeries = true;
        const layer = this.layersService.getSelectedLayer();

        this.yearMode = false; // Reset year mode

        if (region === undefined || layer === undefined || !reload) {
            reloadTimeSeries = false;
        }

        this.productSelected = layer.product;
        this.dateSelected = layer.getDate();
        this.region = region;

        if (layer.availability) {
            // in case we are displaying data of a linked layer product we have to find the nearest date
            this.dateSelected = layer.availability.findNearestDate(this.dateSelected);
        }
        this.updateCharts(reloadTimeSeries);
    }

    /**
     * Updates the KPIs and time-line charts with the current data.
     */
    private updateCharts(reloadTimeSeries = true) {
        this.requestRegionKPIs();

        if (this.timeSeries !== undefined) {
            if (this.productSelected.isYearModeOnly()) {
                this.yearModeOnly = true;
                this.yearMode = true;
            } else {
                this.yearMode = false;
            }

            this.timeSeries.setDateSelected(this.dateSelected);
            if (reloadTimeSeries && !this.timeSeries.loadingRoiTimeSeries) {
                // full time series load
                this.timeSeries.setROIData(
                    this.region,
                    [this.productSelected],
                    this.dateStart,
                    this.dateEnd
                );
                this.timeSeries.updateChart();
            } else {
                // update current date marker line only
                this.timeSeries.redrawCurrentDate();
            }
        }
    }

    /**
     * Export time series nvd3 as png
     */
    exportAsPng() {
        this.timeSeries?.saveChartAsPng();
    }

    /**
     * Export time series nvd3 as csv
     */
    exportAsCsv() {
        this.timeSeries?.saveAsCsv();
    }

    /**
     * Request KPIs for the selected product, date and region
     */
    requestRegionKPIs() {
        if (this.region && this.sidenavOpen) {
            this.loadingKpis = true;
            this.kpiService.getSelectedKpis().subscribe((selKpis) => {
                this.kpiService.getRegionKpis(this.dateSelected, this.region, selKpis).subscribe(
                    (kpis) => {
                        this.loadingKpis = false;
                        this.kpis = kpis;
                    },
                    (error) => {
                        this.kpiService.emptyKpis().subscribe((kpis) => {
                            this.kpis = kpis;
                            this.loadingKpis = false;
                        });
                    }
                );
            });
        }
    }

    /**
     * Get ISO String date.
     */
    getDate() {
        return this.dateSelected ? getISODateString(this.dateSelected) : '';
    }

    /**
     * Return the region name string.
     */
    getRegionName() {
        const defaultText = 'Unnamed ROI';
        return this.region !== undefined ? this.region.name || defaultText : defaultText;
    }

    /**
     * Open the region info dialog.
     */
    openRegionInfo() {
        this.dialogService.openComponent<
            RoiInfoDialogComponent,
            RoiInfoDialogData,
            RoiInfoDialogResult
        >(RoiInfoDialogComponent, { data: this.region });
    }

    /**
     * Open the region info dialog.
     */
    openTimeSeriesFullScreen() {
        // TODO: get ROI time series data
        const timeSeriesROI = null;
        this.matDialog.open<TimeSeriesDialog, TimeSeriesDialogData>(TimeSeriesDialog, {
            width: '80%',
            data: {
                mode: 'roi',
                product: this.productSelected,
                region: this.region,
                userSettings: this.userSettings,
            },
            panelClass: 'time-series-dialog',
        });
    }

    /**
     * Open the KPI config dialog
     */
    onManageKpis() {
        const dialogRef = this.matDialog.open(KpiConfigDialogComponent, {
            panelClass: 'kpi-config-dialog',
        });

        dialogRef.afterClosed().subscribe((selected) => {
            if (selected !== undefined) {
                // update selected kpi list
                this.kpiService.setSelectedKpis(selected).subscribe((data) => {
                    if (data !== undefined) {
                        this.requestRegionKPIs();
                        this.snackBar.present('KPIs updated.');
                    }
                });
            }
        });
    }

    /**
     * Close roi dashboard
     */
    close() {
        this.sidenavOpen = false;
        this.closeSidenav.emit();
    }

    /**
     * Open the time series api request dialog
     */
    handleTimeSeriesDialog() {
        const dialogRef = this.matDialog.open<
            TimeSeriesApiDialogComponent,
            TimeSeriesApiDialogData,
            TimeSeriesApiDialogROIResult
        >(TimeSeriesApiDialogComponent, {
            width: '500px',
            data: {
                mode: 'roi',
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                notificationEmail: this.userSettings?.settings?.notificationEmail,
                coordinateSystem: this.userSettings?.settings?.coordinateSystem,
            },
            panelClass: 'time-series-api-dialog',
        });

        dialogRef.afterClosed().subscribe((params) => {
            if (params !== undefined) {
                this.showProcessingApiRequestDialog();
                this.productService
                    .createProductROITimeSeriesRequest(
                        this.productSelected.apiName,
                        this.region.id,
                        params
                    )
                    .subscribe(
                        (result) => {
                            if (result.message === 'Download request received') {
                                this.snackBar.present(
                                    `API request ${result.uuid} has been created. You can check the status
                             of the request  in the API access menu.`,
                                    'success'
                                );
                                this.checkProcessingApiRequest();
                            }
                        },
                        (error) => {
                            this.checkProcessingApiRequest();
                        }
                    );
            }
        });
    }

    /**
     * Method to show a dialog with a processing api request message
     */
    showProcessingApiRequestDialog() {
        this.loadingDialogRef = this.matDialog.open(LoadingDialogComponent, {
            width: '25%',
            height: 'auto',
            data: {
                loadingMessages: { 'api-request': 'Submitting API request...' },
            },
        });
    }

    /**
     * Method that closes the processing api request dialog
     */
    checkProcessingApiRequest() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }

    /**
     * Method to show the information and param values used to generate the current time series
     */
    showTimeSeriesParamsDialog() {
        this.dialogService.openComponent<
            TimeSeriesInfoDialogComponent,
            TimeSeriesInfoDialogData,
            TimeSeriesInfoDialogResult
        >(TimeSeriesInfoDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: this.timeSeries?.params,
        });
    }
}
